/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type uploadMediaEmployeeInput = {
    readonly id: string;
    readonly files?: ReadonlyArray<unknown> | null;
    readonly type?: string | null;
    readonly clientMutationId?: string | null;
};
export type UpdateMediaObjectMutationVariables = {
    input: uploadMediaEmployeeInput;
};
export type UpdateMediaObjectMutationResponse = {
    readonly uploadMediaEmployee: {
        readonly employee: {
            readonly id: string;
            readonly mediaObjects: ReadonlyArray<{
                readonly id: string;
                readonly content: string | null;
            } | null> | null;
        } | null;
    } | null;
};
export type UpdateMediaObjectMutation = {
    readonly response: UpdateMediaObjectMutationResponse;
    readonly variables: UpdateMediaObjectMutationVariables;
};



/*
mutation UpdateMediaObjectMutation(
  $input: uploadMediaEmployeeInput!
) {
  uploadMediaEmployee(input: $input) {
    employee {
      id
      mediaObjects(type: "avatar") {
        id
        content
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "uploadMediaEmployeeInput!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "uploadMediaEmployee",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "uploadMediaEmployeePayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "employee",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Employee",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mediaObjects",
                            "storageKey": "mediaObjects(type:\"avatar\")",
                            "args": [
                                {
                                    "kind": "Literal",
                                    "name": "type",
                                    "value": "avatar"
                                }
                            ],
                            "concreteType": "EmployeeMediaObject",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "content",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdateMediaObjectMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdateMediaObjectMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdateMediaObjectMutation",
            "id": null,
            "text": "mutation UpdateMediaObjectMutation(\n  $input: uploadMediaEmployeeInput!\n) {\n  uploadMediaEmployee(input: $input) {\n    employee {\n      id\n      mediaObjects(type: \"avatar\") {\n        id\n        content\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '193ab1b57bb9a173552cfa2b0dd4737c';
export default node;
