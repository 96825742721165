import React, { useState } from 'react';
import { FormBuilder } from 'components';
import personalDetails from 'data/personal-details.json';
import physicalDetails from 'data/physical-details.json';
import emergencyContacts from 'data/emergency-contacts.json';
import proofOfLife from 'data/proof-of-life.json';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { customTheme } from 'theme';
import { Loader } from 'components/Loader';
import { QueryRenderer } from 'react-relay';
import { GetEmployeePersonalDetailsQuery } from 'graphql/GetEmployeePersonalDetailsQuery';
import { GetEmployeePhysicalDetailsQuery } from 'graphql/GetEmployeePhysicalDetailsQuery';
import { GetEmployeeEmergencyContactsQuery } from 'graphql/GetEmployeeEmergencyContactsQuery';
import { GetEmployeeProofOfLifeQuery } from 'graphql/GetEmployeeProofOfLifeQuery';
import { AutocompletePeopleContext } from 'contexts/AutocompletePeopleContext';

import { commitMutationForm } from 'helper/commitMutationForm';
import { UpdateEmployeeMutation } from 'graphql/UpdateEmployeeMutation';
import { modernEnvironment } from 'config/environment';
import { CURRENT_USER } from 'config/globals';
import { ValidationError } from 'components/ValidationError';
import { UpdateEmployeeEmergencyContactMutation } from 'graphql/UpdateEmployeeEmergencyContactMutation';

interface IComponentProps {
  history: { push(path: string): void };
  push(path: string): void;
}

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
  },
  footer: {
    borderColor: customTheme.colors.brandGrey2,
  },
}));

export const LoginPersonalDetails = (props: IComponentProps) => {
  const { history } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    if (activeStep === 4) {
      goToDashboard();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePrev = () => {
    if (activeStep !== 1) {
      setActiveStep(activeStep - 1);
    }
  };

  const goToDashboard = () => {
    history.push('/dashboard/home');
  };

  return (
    <Grid container justify="center">
      <Box className={classes.content} p={3} mt={8} mb={8}>
        {activeStep === 1 && (
          <Box width={720}>
            <QueryRenderer
              environment={modernEnvironment}
              query={GetEmployeePersonalDetailsQuery}
              variables={{
                id: CURRENT_USER.id,
              }}
              render={({ error, props }) => {
                if (error) {
                  return <ValidationError>{error[0].message}</ValidationError>;
                } else if (props) {
                  const { employee, employees } = props;
                  const data = [
                    { id: 'participants', values: [], options: employees },
                  ];
                  data.forEach((dataObject) => {
                    dataObject.options = dataObject.options.map((option) => ({
                      fullName: `${option.firstName} ${option.lastName}`,
                      ...option,
                    }));
                  });
                  return (
                    <AutocompletePeopleContext.Provider value={data}>
                      <FormBuilder
                        title=""
                        formObject={personalDetails}
                        initialValues={employee}
                        step={personalDetails[0].step}
                        onSubmit={(values) => {
                          // TODO: Refactor AutocompleteField.tsx to work with single (not multiple) value
                          if (
                            values.level1Approver &&
                            values.level1Approver[0]
                          ) {
                            values.level1Approver = values.level1Approver[0];
                          }
                          if (
                            values.level2Approver &&
                            values.level2Approver[0]
                          ) {
                            values.level2Approver = values.level2Approver[0];
                          }
                          if (
                            values.level3Approver &&
                            values.level3Approver[0]
                          ) {
                            values.level3Approver = values.level3Approver[0];
                          }

                          commitMutationForm({
                            values: {
                              id: CURRENT_USER.id,
                              ...values,
                            },
                            mutation: UpdateEmployeeMutation,
                          });
                        }}
                      />
                    </AutocompletePeopleContext.Provider>
                  );
                }
                return <Loader />;
              }}
            />
          </Box>
        )}
        {activeStep === 2 && (
          <Box width={720}>
            <QueryRenderer
              environment={modernEnvironment}
              query={GetEmployeePhysicalDetailsQuery}
              variables={{
                id: CURRENT_USER.id,
              }}
              render={({ error, props }) => {
                if (error) {
                  return <ValidationError>{error[0].message}</ValidationError>;
                } else if (props) {
                  const { employee } = props;
                  return (
                    <FormBuilder
                      title=""
                      formObject={physicalDetails}
                      initialValues={employee}
                      step={physicalDetails[0].step}
                      onSubmit={(values) => {
                        if (values.physicianContactNumber) {
                          delete values.physicianContactNumber.imei;
                        }

                        commitMutationForm({
                          values: {
                            id: CURRENT_USER.id,
                            ...values,
                          },
                          mutation: UpdateEmployeeMutation,
                        });
                      }}
                    />
                  );
                }
                return <Loader />;
              }}
            />
          </Box>
        )}
        {activeStep === 3 && (
          <Box width={720}>
            <QueryRenderer
              environment={modernEnvironment}
              query={GetEmployeeEmergencyContactsQuery}
              variables={{
                id: CURRENT_USER.id,
              }}
              render={({ error, props }) => {
                if (error) {
                  return <ValidationError>{error[0].message}</ValidationError>;
                } else if (props) {
                  const {
                    employee: { employeeEmergencyContacts },
                  } = props;
                  const primaryContact = employeeEmergencyContacts.find(
                    (o) => o.type === 'primary'
                  );
                  const alternateContact = employeeEmergencyContacts.find(
                    (o) => o.type === 'alternate'
                  );

                  return (
                    <>
                      <Box position="relative">
                        <Box pl={1.15}>
                          <Typography variant="h3">
                            Emergency Contacts
                          </Typography>
                        </Box>
                        <Box mb={2} pl={1.15}>
                          <Typography variant="body1" color="textSecondary">
                            Filling in personal details will expedite activity
                            plan creation.
                          </Typography>
                        </Box>
                        <Box position="absolute" top="-10px" right="0">
                          <Typography variant="overline">
                            Step {emergencyContacts[0].step}
                          </Typography>
                        </Box>
                      </Box>
                      <Box pt={3} mb={2} pl={1.15}>
                        <Typography variant="h4" color="primary">
                          Primary contact
                        </Typography>
                      </Box>

                      <FormBuilder
                        formObject={emergencyContacts}
                        initialValues={primaryContact}
                        onSubmit={(values) => {
                          commitMutationForm({
                            values: {
                              id: primaryContact.id,
                              ...values,
                            },
                            mutation: UpdateEmployeeEmergencyContactMutation,
                          });
                        }}
                      />
                      <Box p={3}></Box>
                      <Box mb={2} pl={1.15}>
                        <Typography variant="h4" color="primary">
                          Alternate contact
                        </Typography>
                      </Box>
                      <FormBuilder
                        formObject={emergencyContacts}
                        initialValues={alternateContact}
                        onSubmit={(values) => {
                          commitMutationForm({
                            values: {
                              id: alternateContact.id,
                              ...values,
                            },
                            mutation: UpdateEmployeeEmergencyContactMutation,
                          });
                        }}
                      />
                    </>
                  );
                }
                return <Loader />;
              }}
            />
          </Box>
        )}
        {activeStep === 4 && (
          <Box width={720}>
            <QueryRenderer
              environment={modernEnvironment}
              query={GetEmployeeProofOfLifeQuery}
              variables={{
                id: CURRENT_USER.id,
              }}
              render={({ error, props }) => {
                if (error) {
                  return <ValidationError>{error[0].message}</ValidationError>;
                } else if (props) {
                  const { employee } = props;

                  return (
                    <FormBuilder
                      title=""
                      formObject={proofOfLife}
                      initialValues={employee}
                      step={proofOfLife[0].step}
                      onSubmit={(values) => {
                        commitMutationForm({
                          values: {
                            id: CURRENT_USER.id,
                            ...values,
                          },
                          mutation: UpdateEmployeeMutation,
                        });
                      }}
                    />
                  );
                }
                return <Loader />;
              }}
            />
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="flex-end"
          borderTop={1}
          className={classes.footer}
          mt={3}
          py={1}
        >
          <Box p={1.5}>
            <Button variant="outlined" color="primary" onClick={goToDashboard}>
              Skip
            </Button>
          </Box>
          {activeStep !== 1 && (
            <Box p={1.5} pl={0}>
              <Button variant="contained" color="primary" onClick={handlePrev}>
                Back
              </Button>
            </Box>
          )}
          <Box p={1.5} pl={0}>
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
