import { graphql } from 'react-relay';

export const GetPlanRegionQuery = graphql`
  query GetPlanRegionQuery($id: ID!) {
    activityPlan(id: $id) {
      region
      countries
    }
  }
`;
