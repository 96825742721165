/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type updateRiskAssessmentInput = {
    readonly id: string;
    readonly mitigationSummary?: string | null;
    readonly extantUntreatedRisk?: string | null;
    readonly clientMutationId?: string | null;
};
export type UpdateRiskAssessmentMutationVariables = {
    input: updateRiskAssessmentInput;
};
export type UpdateRiskAssessmentMutationResponse = {
    readonly updateRiskAssessment: {
        readonly riskAssessment: {
            readonly id: string;
            readonly mitigationSummary: string | null;
            readonly extantUntreatedRisk: string | null;
        } | null;
    } | null;
};
export type UpdateRiskAssessmentMutation = {
    readonly response: UpdateRiskAssessmentMutationResponse;
    readonly variables: UpdateRiskAssessmentMutationVariables;
};



/*
mutation UpdateRiskAssessmentMutation(
  $input: updateRiskAssessmentInput!
) {
  updateRiskAssessment(input: $input) {
    riskAssessment {
      id
      mitigationSummary
      extantUntreatedRisk
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "updateRiskAssessmentInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateRiskAssessment",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "updateRiskAssessmentPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "riskAssessment",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "RiskAssessment",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "mitigationSummary",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "extantUntreatedRisk",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdateRiskAssessmentMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdateRiskAssessmentMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdateRiskAssessmentMutation",
            "id": null,
            "text": "mutation UpdateRiskAssessmentMutation(\n  $input: updateRiskAssessmentInput!\n) {\n  updateRiskAssessment(input: $input) {\n    riskAssessment {\n      id\n      mitigationSummary\n      extantUntreatedRisk\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '8c1c6ddc53513f773459487b3a93cab4';
export default node;
