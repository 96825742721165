/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type updateActivityPlanClosingNotesInput = {
    readonly id: string;
    readonly activityPlan?: string | null;
    readonly debriefNotes?: string | null;
    readonly lessonsLearned?: unknown | null;
    readonly submittedStatus?: boolean | null;
    readonly clientMutationId?: string | null;
};
export type UpdatePlanClosingNotesMutationVariables = {
    input: updateActivityPlanClosingNotesInput;
};
export type UpdatePlanClosingNotesMutationResponse = {
    readonly updateActivityPlanClosingNotes: {
        readonly activityPlanClosingNotes: {
            readonly id: string;
            readonly debriefNotes: string | null;
            readonly lessonsLearned: unknown;
            readonly submittedStatus: boolean | null;
        } | null;
    } | null;
};
export type UpdatePlanClosingNotesMutation = {
    readonly response: UpdatePlanClosingNotesMutationResponse;
    readonly variables: UpdatePlanClosingNotesMutationVariables;
};



/*
mutation UpdatePlanClosingNotesMutation(
  $input: updateActivityPlanClosingNotesInput!
) {
  updateActivityPlanClosingNotes(input: $input) {
    activityPlanClosingNotes {
      id
      debriefNotes
      lessonsLearned
      submittedStatus
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "updateActivityPlanClosingNotesInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateActivityPlanClosingNotes",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "updateActivityPlanClosingNotesPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlanClosingNotes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActivityPlanClosingNotes",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "debriefNotes",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lessonsLearned",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "submittedStatus",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdatePlanClosingNotesMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdatePlanClosingNotesMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdatePlanClosingNotesMutation",
            "id": null,
            "text": "mutation UpdatePlanClosingNotesMutation(\n  $input: updateActivityPlanClosingNotesInput!\n) {\n  updateActivityPlanClosingNotes(input: $input) {\n    activityPlanClosingNotes {\n      id\n      debriefNotes\n      lessonsLearned\n      submittedStatus\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '6774d2353e32831efd19512d0eee2cef';
export default node;
