/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanKeyThreatsQueryVariables = {
    id: string;
};
export type GetPlanKeyThreatsQueryResponse = {
    readonly activityPlan: {
        readonly riskDetermination: {
            readonly id: string;
            readonly submittedStatus: boolean | null;
            readonly keyThreats: ReadonlyArray<{
                readonly id: string;
                readonly type: string | null;
                readonly notes: string | null;
            } | null> | null;
        } | null;
    } | null;
};
export type GetPlanKeyThreatsQuery = {
    readonly response: GetPlanKeyThreatsQueryResponse;
    readonly variables: GetPlanKeyThreatsQueryVariables;
};



/*
query GetPlanKeyThreatsQuery(
  $id: ID!
) {
  activityPlan(id: $id) {
    riskDetermination {
      id
      submittedStatus
      keyThreats {
        id
        type
        notes
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "riskDetermination",
        "storageKey": null,
        "args": null,
        "concreteType": "RiskDetermination",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "submittedStatus",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "keyThreats",
                "storageKey": null,
                "args": null,
                "concreteType": "KeyThreat",
                "plural": true,
                "selections": [
                    (v2 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "notes",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanKeyThreatsQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanKeyThreatsQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanKeyThreatsQuery",
            "id": null,
            "text": "query GetPlanKeyThreatsQuery(\n  $id: ID!\n) {\n  activityPlan(id: $id) {\n    riskDetermination {\n      id\n      submittedStatus\n      keyThreats {\n        id\n        type\n        notes\n      }\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '0412677f7d10528c9276a230de6e6d41';
export default node;
