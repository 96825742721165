import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';

interface IComponentProps {
  children: ReactNode;
}

export const ValidationError = ({ children }: IComponentProps) => (
  <Box px={1.7} py={0.75} display="inline">
    <Typography variant="body2" color="error">
      {children}
    </Typography>
  </Box>
);
