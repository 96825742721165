import { graphql } from 'react-relay';

export const GetPlanKeyThreatsQuery = graphql`
  query GetPlanKeyThreatsQuery($id: ID!) {
    activityPlan(id: $id) {
      riskDetermination {
        id
        submittedStatus
        keyThreats {
          id
          type
          notes
        }
      }
    }
  }
`;
