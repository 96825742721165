import { graphql } from 'react-relay';

export const CreatePlanItineraryLegMutation = graphql`
  mutation CreatePlanItineraryLegMutation($input: createItineraryLegInput!) {
    createItineraryLeg(input: $input) {
      itineraryLeg {
        id
        meansOfTravel
        fromLocation
        toLocation
        travelDepartureTime
        travelArrivalTime
      }
    }
  }
`;
