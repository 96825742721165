import { graphql } from 'react-relay';

export const UpdateBusinessIntentStatusMutation = graphql`
  mutation UpdateBusinessIntentStatusMutation(
    $input: submitBusinessIntentActivityPlanInput!
  ) {
    submitBusinessIntentActivityPlan(input: $input) {
      activityPlan {
        id
        businessIntent
        statusBusinessIntent
      }
    }
  }
`;
