import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@material-ui/core';
import KeyThreatForm from 'data/plan/key-threat.json';
import { FormBuilder } from 'components';
import { CustomisedDialog } from 'components/CustomisedDialog';
import { FullscreenLoader } from 'components/FullscreenLoader';
import { CreateKeyThreatMutation } from 'graphql/CreateKeyThreatMutation';
import { commitMutationForm } from 'helper/commitMutationForm';

interface IComponentProps {
  riskDeterminationId: string;
  addHandler(): void;
}

export const AddKeyThreatPopup = ({
  riskDeterminationId,
  addHandler,
}: IComponentProps) => {
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    setOpen(true);
  };
  return (
    <>
      <Button variant="text" color="primary" onClick={handleAdd}>
        + Add Key Threat
      </Button>
      <CustomisedDialog
        title="Add Key Threat"
        open={open}
        onClose={handleClose}
      >
        <FormBuilder
          enableAutosave={false}
          formObject={KeyThreatForm}
          submitButtonName="Add"
          onSubmit={(values) => {
            setIsDisabled(true);
            commitMutationForm({
              values: {
                riskDetermination: riskDeterminationId,
                ...values,
              },
              mutation: CreateKeyThreatMutation,
              onCompleteCallback: (response, errors) => {
                const {
                  createKeyThreat: { keyThreat },
                } = response;
                mounted.current && addHandler(keyThreat);
                mounted.current && handleClose();
                mounted.current && setIsDisabled(false);
              },
              onErrorCallback: (error) => {
                mounted.current && handleClose();
                mounted.current && setIsDisabled(false);
              },
            });
          }}
          onValidate={(values: {}) => {
            const errors = {};
            if (!values.type) {
              errors.type = 'Required';
            }
            if (!values.probability) {
              errors.probability = 'Required';
            }
            if (!values.impact) {
              errors.impact = 'Required';
            }
            if (!values.commentary) {
              errors.commentary = 'Required';
            }

            return errors;
          }}
        />
        <FullscreenLoader isLoading={isDisabled} />
      </CustomisedDialog>
    </>
  );
};
