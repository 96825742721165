import React, { useState, useEffect, useRef } from 'react';
import ActivityForm from 'data/activity.json';
import { FormBuilder } from 'components';
import { commitMutationForm } from 'helper/commitMutationForm';
import { CreateActivityLogMutation } from 'graphql/CreateActivityLogMutation';
import { FullscreenLoader } from 'components/FullscreenLoader';
import moment from 'moment';

interface IComponentProps {
  planId: string;
  currentTime: any;
  localTime: any;
  doneHandler(): void;
}

export const AddActivityDialog = (props: IComponentProps) => {
  const { planId, currentTime, localTime, doneHandler } = props;
  const mounted = useRef(true);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const currentDate = moment(new Date()).format('MM/DD/YYYY');
  const initialValues = {
    dateTimeEST: `${currentDate} ${currentTime.ESTTime}`,
    gmtTime: `${currentDate} ${currentTime.GMTTime}`,
    localizedTime: `${currentDate} ${localTime.formattedTime}`,
  };

  return (
    <>
      <FormBuilder
        enableAutosave={false}
        formObject={ActivityForm}
        submitButtonName="Add"
        initialValues={initialValues}
        onSubmit={(values) => {
          setIsDisabled(true);
          commitMutationForm({
            values: {
              activityPlan: `/api/activity_plans/${planId}`,
              ...values,
            },
            mutation: CreateActivityLogMutation,
            onCompleteCallback: (response, errors) => {
              mounted.current && doneHandler();
              mounted.current && setIsDisabled(false);
            },
            onErrorCallback: (error) => {
              mounted.current && setIsDisabled(false);
            },
          });
        }}
      />
      <FullscreenLoader isLoading={isDisabled} />
    </>
  );
};
