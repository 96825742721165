/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type createItineraryLegInput = {
    readonly activityPlan: string;
    readonly meansOfTravel?: string | null;
    readonly fromLocation: unknown;
    readonly toLocation: unknown;
    readonly details?: string | null;
    readonly travelDepartureTime?: string | null;
    readonly travelArrivalTime?: string | null;
    readonly travelDepartureTimezone?: string | null;
    readonly travelArrivalTimezone?: string | null;
    readonly notes?: string | null;
    readonly visaStatus?: string | null;
    readonly accreditationStatus?: string | null;
    readonly locationDepartureTime?: string | null;
    readonly locationArrivalTime?: string | null;
    readonly locationAccommodationNotes?: string | null;
    readonly locationActivityNotes?: string | null;
    readonly itineraryContacts?: ReadonlyArray<string | null> | null;
    readonly participants?: ReadonlyArray<string | null> | null;
    readonly clientMutationId?: string | null;
};
export type CreatePlanItineraryLegMutationVariables = {
    input: createItineraryLegInput;
};
export type CreatePlanItineraryLegMutationResponse = {
    readonly createItineraryLeg: {
        readonly itineraryLeg: {
            readonly id: string;
            readonly meansOfTravel: string | null;
            readonly fromLocation: unknown;
            readonly toLocation: unknown;
            readonly travelDepartureTime: string | null;
            readonly travelArrivalTime: string | null;
        } | null;
    } | null;
};
export type CreatePlanItineraryLegMutation = {
    readonly response: CreatePlanItineraryLegMutationResponse;
    readonly variables: CreatePlanItineraryLegMutationVariables;
};



/*
mutation CreatePlanItineraryLegMutation(
  $input: createItineraryLegInput!
) {
  createItineraryLeg(input: $input) {
    itineraryLeg {
      id
      meansOfTravel
      fromLocation
      toLocation
      travelDepartureTime
      travelArrivalTime
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "createItineraryLegInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "createItineraryLeg",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "createItineraryLegPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "itineraryLeg",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ItineraryLeg",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "meansOfTravel",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fromLocation",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "toLocation",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "travelDepartureTime",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "travelArrivalTime",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CreatePlanItineraryLegMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "CreatePlanItineraryLegMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "CreatePlanItineraryLegMutation",
            "id": null,
            "text": "mutation CreatePlanItineraryLegMutation(\n  $input: createItineraryLegInput!\n) {\n  createItineraryLeg(input: $input) {\n    itineraryLeg {\n      id\n      meansOfTravel\n      fromLocation\n      toLocation\n      travelDepartureTime\n      travelArrivalTime\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '020398f851a76c61fab88ed2e4b95826';
export default node;
