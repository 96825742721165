import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Box } from '@material-ui/core';

export const Loader = () => (
  <Grid container justify="center">
    <Box p={0.5}>
      <CircularProgress size={26} thickness={2.8} />
    </Box>
  </Grid>
);
