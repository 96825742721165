import React, { useMemo, useState } from 'react';
import { IFieldProps } from './types';
import { FieldRenderProps } from 'react-final-form';
import { DynamicFormComponent } from 'components/DynamicFormComponent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { FieldArray } from 'react-final-form-arrays';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

type Props = FieldRenderProps<string, any> & IFieldProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    removeIcon: {
      cursor: 'pointer',
      margin: '0 0 0 10px',
      color: theme.palette.grey[400],
    },
    fullWidth: {
      width: '100%',
    },
  })
);

export const RepeaterField = (props: Props) => {
  const [count, setCount] = useState(0);
  const classes = useStyles();
  const {
    template,
    input,
    templateOptions: {
      label,
      addLabel,
      fullWidth,
      disabled = false,
      heading,
      subheading,
    },
  } = props;

  const initialValue = useMemo(() => [''], []);
  return (
    <>
      {heading && (
        <Box pt={3} pb={0.5}>
          <Typography variant="h3" color="textPrimary">
            {heading}
          </Typography>
        </Box>
      )}
      {subheading && (
        <Box pb={1.5}>
          <Typography variant="body2" color="textSecondary">
            {subheading}
          </Typography>
        </Box>
      )}
      <Box>
        {label && (
          <Box px={1} mb={2}>
            <Typography variant="body2" color="textSecondary">
              {label}
            </Typography>
          </Box>
        )}
        <Box display="flex" alignItems="start" justifyContent="flexStart">
          <FieldArray name={input.name} initialValue={initialValue}>
            {({ fields }) => {
              const { type, templateOptions } = template;
              return (
                <Box
                  key={count}
                  display="flex"
                  flexDirection="column"
                  className={fullWidth && classes.fullWidth}
                >
                  {fields.map((name, index) => (
                    <Box key={index}>
                      <Box
                        mb={2}
                        key={index}
                        display="inline-flex"
                        alignItems={type === 'phone' ? 'flex-start' : 'center'}
                        justifyContent="flex-start"
                        width={fullWidth && '100%'}
                      >
                        <DynamicFormComponent
                          name={name}
                          type={type}
                          label={templateOptions.label}
                          hideExpression={false}
                          isHiddenField={false}
                          templateOptions={{
                            repeaterIndex: index + 1,
                            ...templateOptions,
                          }}
                        />
                        {addLabel && index !== 0 && !disabled && (
                          <HighlightOffIcon
                            onClick={() => {
                              fields.remove(index);
                              setCount(count + 1);
                            }}
                            className={classes.removeIcon}
                            style={
                              type === 'phone' ? { marginTop: '20px' } : {}
                            }
                            color="secondary"
                            fontSize="small"
                          ></HighlightOffIcon>
                        )}
                        {addLabel && index === 0 && !disabled && (
                          <Box mx={3} mt={type === 'phone' ? 1 : 0}>
                            <Button
                              variant="text"
                              onClick={() => fields.push('')}
                            >
                              {addLabel ? addLabel : 'Add'}
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              );
            }}
          </FieldArray>
        </Box>
      </Box>
    </>
  );
};
