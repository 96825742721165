// Below is the create new employee default state
// "createEmployee": {
//   "employee": {
//     "id": "/employees/4db3de5d-df25-4b1d-b823-73c135be8c8b",
//     "mediaObjects": [],
//     "firstName": null,
//     "middleName": null,
//     "lastName": null,
//     "mobilePhoneNumbers": [],
//     "satellitePhoneNumbers": [],
//     "vismoProfile": null,
//     "email": "dancho@abv.bg",
//     "birthDetails": null,
//     "nationality": [],
//     "employer": null,
//     "employmentStatus": null,
//     "insuranceProvider": null,
//     "policyNumber": null,
//     "department": null,
//     "position": null,
//     "roleInTrip": null,
//     "bureauAssigned": {
//       "country": "",
//       "location": ""
//     },
//     "domicileCity": {
//       "country": "",
//       "location": ""
//     },
//     "lastHefatReceived": null,
//     "level1Approver": null,
//     "level2Approver": null,
//     "level3Approver": null,
//     "employeeEmergencyContacts": [],
//     "biologicalSex": null,
//     "bloodType": null,
//     "height": null,
//     "eyeColor": null,
//     "correctedVision": null,
//     "hairColorLength": null,
//     "identifyingMarksDescription": null,
//     "primaryPhysicianName": null,
//     "physicianContactNumber": {
//       "code": "",
//       "number": ""
//     },
//     "disabilitiesOrOtherMedicalConditions": null,
//     "medicationsAndDosage": null,
//     "drugOrOtherAllergies": null,
//     "pollQuestionsAnswers": []
//   }
// }

// Emergency contacts fields:
// email: ""
// firstName: null
// gender: null
// id: "/api/employee_emergency_contacts/85d9bf56-c7e3-4604-af73-fdc00469c872"
// languagePreference: null
// lastName: null
// middleName: null
// mobilePhoneNumbers: []
// nationality: []
// relationship: null
// specialInstructions: null
// type: "primary"

// tslint:disable-next-line: cyclomatic-complexity
export const validateUser = (user) => {
  if (
    user.firstName === null ||
    user.middleName === null ||
    user.lastName === null ||
    user.vismoProfile === null ||
    user.birthDetails === null ||
    user.employer === null ||
    user.employmentStatus === null ||
    user.insuranceProvider === null ||
    user.policyNumber === null ||
    user.department === null ||
    user.position === null ||
    user.roleInTrip === null ||
    user.lastHefatReceived === null ||
    user.level1Approver === null ||
    user.level2Approver === null ||
    user.biologicalSex === null ||
    user.bloodType === null ||
    user.height === null ||
    user.eyeColor === null ||
    user.correctedVision === null ||
    user.hairColorLength === null ||
    user.identifyingMarksDescription === null ||
    user.primaryPhysicianName === null ||
    user.disabilitiesOrOtherMedicalConditions === null ||
    user.medicationsAndDosage === null ||
    user.drugOrOtherAllergies === null
  ) {
    return false;
  }
  if (user.level3Approver && user.level3Approver === null) {
    return false;
  }
  if (
    user.mobilePhoneNumbers.length === 0 ||
    user.satellitePhoneNumbers.length === 0 ||
    user.nationality.length === 0 ||
    (user.employeeEmergencyContacts &&
      user.employeeEmergencyContacts.length === 0)
  ) {
    return false;
  }
  if (
    user.bureauAssigned.country === '' ||
    user.bureauAssigned.location === '' ||
    user.domicileCity.country === '' ||
    user.domicileCity.location === '' ||
    user.physicianContactNumber.code === '' ||
    user.physicianContactNumber.number === ''
  ) {
    return false;
  }

  // TODO: Add emergency contacts validation below, once we have their data loaded through a separate query, than the employee's one
  // let contactsAreValid = true;
  // user.employeeEmergencyContacts.forEach((contact) => {
  //   if (
  //     contact.firstName === null ||
  //     contact.middleName === null ||
  //     contact.lastName === null
  //   ) {
  //     contactsAreValid = false;
  //   }
  // });

  // if (!contactsAreValid) {
  //   return false;
  // }
  return true;
};
