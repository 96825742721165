/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type updateIncidentInput = {
    readonly id: string;
    readonly quantity?: number | null;
    readonly gap?: number | null;
    readonly incidentDate?: string | null;
    readonly location?: string | null;
    readonly type?: string | null;
    readonly target?: string | null;
    readonly details?: string | null;
    readonly activityPlan?: string | null;
    readonly clientMutationId?: string | null;
};
export type UpdateIncidentMutationVariables = {
    input: updateIncidentInput;
};
export type UpdateIncidentMutationResponse = {
    readonly updateIncident: {
        readonly incident: {
            readonly gap: number | null;
            readonly incidentDate: string | null;
            readonly target: string | null;
            readonly quantity: number | null;
            readonly location: string | null;
            readonly details: string | null;
            readonly type: string | null;
        } | null;
    } | null;
};
export type UpdateIncidentMutation = {
    readonly response: UpdateIncidentMutationResponse;
    readonly variables: UpdateIncidentMutationVariables;
};



/*
mutation UpdateIncidentMutation(
  $input: updateIncidentInput!
) {
  updateIncident(input: $input) {
    incident {
      gap
      incidentDate
      target
      quantity
      location
      details
      type
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "updateIncidentInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "gap",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "incidentDate",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "target",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "quantity",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "location",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "details",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdateIncidentMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "updateIncident",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "updateIncidentPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "incident",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Incident",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdateIncidentMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "updateIncident",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "updateIncidentPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "incident",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Incident",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdateIncidentMutation",
            "id": null,
            "text": "mutation UpdateIncidentMutation(\n  $input: updateIncidentInput!\n) {\n  updateIncident(input: $input) {\n    incident {\n      gap\n      incidentDate\n      target\n      quantity\n      location\n      details\n      type\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '9adeab274e1e75b484917139b5a459c2';
export default node;
