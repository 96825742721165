import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
  Divider,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/Loader';
import { QueryRenderer } from 'react-relay';
import { ValidationError } from 'components/ValidationError';
import { modernEnvironment } from 'config/environment';
import { GetPlanRiskMitigationQuery } from 'graphql/GetPlanRiskMitigationQuery';
import { PlanContentWrapper } from 'components/Plan/PlanContentWrapper';
import { customTheme } from 'theme';
import SECTIONS from 'data/plan/risk-mitigation-sections.json';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AddEditCategoryPopup } from './AddEditCategoryPopup';
import { DeleteCategoryPopup } from './DeleteCategoryPopup';
import { CustomWarning } from 'components/Plan/CustomWarning';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  additionalButtons: {
    position: 'absolute',
    top: -45,
    right: 64,
  },
  panelSummary: {
    backgroundColor: customTheme.colors.veryLightPinkThree,
  },
}));

interface IComponentProps {
  planId: string;
}

export const RiskSection = ({ planId }: IComponentProps) => {
  const { riskSectionId } = useParams();
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const [expanded, setExpanded] = useState('panel0');
  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const currentSection = SECTIONS.find(
    (section) => section.id === riskSectionId
  );

  const handleAdd = () => {
    setCount(count + 1);
  };

  return (
    <PlanContentWrapper>
      <Grid container direction="column">
        <Box p={1.5} pb={0.5}>
          <Typography variant="h1" component="h2">
            {currentSection.title}
          </Typography>
        </Box>
        <Box p={1.5} pb={3.5}>
          <Typography variant="body1">{currentSection.description}</Typography>
        </Box>
        <Box p={1.5}>
          <QueryRenderer
            key={count}
            environment={modernEnvironment}
            query={GetPlanRiskMitigationQuery}
            variables={{
              id: `/api/activity_plans/${planId}`,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error.message}</ValidationError>;
              } else if (props) {
                const {
                  activityPlan: {
                    riskMitigation,
                    riskDetermination: {
                      submittedStatus: riskDeterminationSubmittedStatus,
                    },
                  },
                } = props;

                if (!riskDeterminationSubmittedStatus) {
                  return (
                    <CustomWarning>
                      Risk determination needs to be submitted, in order to
                      view/edit this section.
                    </CustomWarning>
                  );
                }

                let subsections = [];
                let categories = [];
                if (riskMitigation !== null) {
                  subsections = riskMitigation[currentSection.apiKey];

                  const categoriesAll = subsections.map((o) => o.category);
                  categories = [...new Set(categoriesAll)];
                }

                return (
                  <>
                    <Grid container>
                      {categories.map((category, index) => (
                        <ExpansionPanel
                          key={category}
                          className={classes.root}
                          expanded={expanded === `panel${index}`}
                          onChange={handleChange(`panel${index}`)}
                        >
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}a-content`}
                            id={`panel${index}a-header`}
                            className={classes.panelSummary}
                          >
                            <Grid container justify="space-between">
                              <Typography variant="h4">{category}</Typography>
                            </Grid>
                          </ExpansionPanelSummary>
                          <Box position="relative">
                            {!riskMitigation.submittedStatus && (
                              <Box className={classes.additionalButtons}>
                                <AddEditCategoryPopup
                                  category={category}
                                  addHandler={handleAdd}
                                  riskMitigation={riskMitigation}
                                />
                                <DeleteCategoryPopup
                                  category={category}
                                  addHandler={handleAdd}
                                  riskMitigation={riskMitigation}
                                />
                              </Box>
                            )}
                          </Box>
                          <ExpansionPanelDetails className={classes.root}>
                            <Grid container direction="column">
                              {subsections
                                .filter(
                                  (subsection) =>
                                    subsection.category === category
                                )
                                .map((subsection, index) => (
                                  <Box width={'100%'} key={subsection.id}>
                                    {index > 0 && (
                                      <Box my={1}>
                                        <Divider />
                                      </Box>
                                    )}
                                    <Box>{subsection.instruction}</Box>
                                  </Box>
                                ))}
                            </Grid>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      ))}
                    </Grid>
                    {!riskMitigation.submittedStatus && (
                      <Box mt={2.5}>
                        <AddEditCategoryPopup
                          addHandler={handleAdd}
                          riskMitigation={riskMitigation}
                        />
                      </Box>
                    )}
                  </>
                );
              }
              return <Loader />;
            }}
          />
        </Box>
      </Grid>
    </PlanContentWrapper>
  );
};
