import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { SectionHeader } from 'components/Plan/SectionHeader';
import { Loader } from 'components/Loader';
import { customTheme } from 'theme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { QueryRenderer, commitMutation } from 'react-relay';
import { GetParticipantPersonalDetailsQuery } from 'graphql/GetParticipantPersonalDetailsQuery';
import { modernEnvironment } from 'config/environment';
import { ValidationError } from 'components/ValidationError';
import { AutocompletePeopleContext } from 'contexts/AutocompletePeopleContext';
import { FormBuilder } from 'components';
import personalDetails from 'data/personal-details.json';
import personalDetailsDisabled from 'data/disabled/personal-details.json';
import physicalDetails from 'data/physical-details.json';
import physicalDetailsDisabled from 'data/disabled/physical-details.json';
import proofOfLife from 'data/proof-of-life.json';
import proofOfLifeDisabled from 'data/disabled/proof-of-life.json';
import { commitMutationForm } from 'helper/commitMutationForm';
import { UpdateParticipantMutation } from 'graphql/UpdateParticipantMutation';
import { ParticipantEmergencyContacts } from './ParticipantEmergencyContacts';
import { RemoveParticipantBtn } from './RemoveParticipantBtn';
import { SubmitSectionBtn } from 'components/Buttons/SubmitSectionBtn';
import { validateUser } from 'helper/validateUser';
import { ParticipantFiles } from './ParticipantFiles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  panelSummary: {
    backgroundColor: customTheme.colors.veryLightPinkThree,
  },
  filesSummary: {
    width: '100%',
    backgroundColor: customTheme.colors.white,
  },
}));

export const Participant = () => {
  const { participantId, planId } = useParams();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const mounted = useRef(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const changeSectionStatus = (newStatus) => {
    setIsSubmitLoading(true);
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: `/api/participants/${participantId}`,
          status: newStatus,
        },
      },
      mutation: UpdateParticipantMutation,
      updater: (store) => {
        const participants = store.getRootField('updateParticipant');
        const participant = participants.getLinkedRecord('participant');
        participant.setValue(newStatus, 'status');
      },
      onCompleted: (response) => {
        mounted.current && setIsSubmitLoading(false);
      },
      onError: (response) => {
        mounted.current && setIsSubmitted(true);
        mounted.current && setIsSubmitLoading(false);
      },
    });
  };

  const participantUpdater = (store, values, participantData) => {
    const updateParticipant = store.getRootField('updateParticipant');
    const participant = updateParticipant.getLinkedRecord('participant');

    const newParticipantData = { ...participantData };
    Object.keys(values).forEach((item) => {
      participant.setValue(item, values[item]);
      newParticipantData[item] = values[item];
    });

    setIsValid(validateUser(newParticipantData));
  };

  return (
    <>
      <SectionHeader
        title="Participant information"
        backUrl={`/plan/${planId}/participant-info`}
      />
      <Box width={760} pb={13}>
        {participantId === 'newParticipant' ? (
          <Loader />
        ) : (
          <QueryRenderer
            environment={modernEnvironment}
            query={GetParticipantPersonalDetailsQuery}
            variables={{
              id: `/api/participants/${participantId}`,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error[0].message}</ValidationError>;
              } else if (props) {
                const { participant, employees } = props;
                const data = [
                  { id: 'participants', values: [], options: employees },
                ];
                data.forEach((dataObject) => {
                  dataObject.options = dataObject.options.map((option) => ({
                    fullName: `${option.firstName} ${option.lastName}`,
                    ...option,
                  }));
                });

                setIsSubmitted(
                  participant && participant.status !== 'development'
                );
                return (
                  <Grid
                    container
                    justify="space-between"
                    alignItems="flex-start"
                  >
                    <Box pt={1.5} pb={3.5} flex={1}>
                      <Typography variant="h1" component="h2">
                        {participant.firstName} {participant.lastName}
                      </Typography>
                      {participant.role === 'primary_participant' && (
                        <Box mt={0.5}>
                          <Typography
                            variant="h4"
                            component="h4"
                            color="textSecondary"
                          >
                            Primary Participant
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box p={1.5} pb={3.5}>
                      {!isSubmitted && (
                        <RemoveParticipantBtn
                          planId={planId}
                          participantId={participantId}
                        />
                      )}
                    </Box>
                    <ParticipantFiles
                      participantId={participantId}
                      participant={participant}
                      isSubmitted={isSubmitted}
                    />
                    <ExpansionPanel
                      className={classes.root}
                      expanded={expanded === 'panel1'}
                      onChange={handleChange('panel1')}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.panelSummary}
                      >
                        <Typography variant="h4">Section 1: Profile</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.root}>
                        <AutocompletePeopleContext.Provider value={data}>
                          <FormBuilder
                            title=""
                            enabledInnerHeadings={false}
                            formObject={
                              !isSubmitted
                                ? personalDetails
                                : personalDetailsDisabled
                            }
                            initialValues={participant}
                            onSubmit={(values) => {
                              // TODO: Refactor AutocompleteField.tsx to work with single (not multiple) value
                              if (
                                values.level1Approver &&
                                values.level1Approver[0]
                              ) {
                                values.level1Approver =
                                  values.level1Approver[0];
                              }
                              if (
                                values.level2Approver &&
                                values.level2Approver[0]
                              ) {
                                values.level2Approver =
                                  values.level2Approver[0];
                              }
                              if (
                                values.level3Approver &&
                                values.level3Approver[0]
                              ) {
                                values.level3Approver =
                                  values.level3Approver[0];
                              }

                              commitMutationForm({
                                values: {
                                  id: `/api/participants/${participantId}`,
                                  ...values,
                                },
                                mutation: UpdateParticipantMutation,
                                onUpdate: (store) => {
                                  participantUpdater(
                                    store,
                                    values,
                                    participant
                                  );
                                },
                              });
                            }}
                          />
                        </AutocompletePeopleContext.Provider>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      className={classes.root}
                      expanded={expanded === 'panel2'}
                      onChange={handleChange('panel2')}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        className={classes.panelSummary}
                      >
                        <Typography variant="h4">
                          Section 2: Physical details
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.root}>
                        <FormBuilder
                          title=""
                          enabledInnerHeadings={false}
                          formObject={
                            !isSubmitted
                              ? physicalDetails
                              : physicalDetailsDisabled
                          }
                          initialValues={participant}
                          step={physicalDetails[0].step}
                          onSubmit={(values) => {
                            commitMutationForm({
                              values: {
                                id: `/api/participants/${participantId}`,
                                ...values,
                              },
                              mutation: UpdateParticipantMutation,
                              onUpdate: (store) => {
                                participantUpdater(store, values, participant);
                              },
                            });
                          }}
                        />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      className={classes.root}
                      expanded={expanded === 'panel3'}
                      onChange={handleChange('panel3')}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        className={classes.panelSummary}
                      >
                        <Typography variant="h4">
                          Section 3: Emergency contacts
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.root}>
                        <ParticipantEmergencyContacts
                          isSubmitted={isSubmitted}
                          participantId={participantId}
                        />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      className={classes.root}
                      expanded={expanded === 'panel4'}
                      onChange={handleChange('panel4')}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                        className={classes.panelSummary}
                      >
                        <Typography variant="h4">
                          Section 4: Proof of life information
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.root}>
                        <FormBuilder
                          title=""
                          enabledInnerHeadings={false}
                          formObject={
                            !isSubmitted ? proofOfLife : proofOfLifeDisabled
                          }
                          initialValues={participant}
                          onSubmit={(values) => {
                            commitMutationForm({
                              values: {
                                id: `/api/participants/${participantId}`,
                                ...values,
                              },
                              mutation: UpdateParticipantMutation,
                              onUpdate: (store) => {
                                participantUpdater(store, values, participant);
                              },
                            });
                          }}
                        />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <Box pt={1}>
                      {!isValid && (
                        <Typography color="textSecondary" variant="body2">
                          Please, make sure you have fulfilled all fields from
                          Profile, Physical details and Proof of life
                          information sections, before submitting.
                        </Typography>
                      )}
                    </Box>
                    <Box pt={2} width={'100%'}>
                      <SubmitSectionBtn
                        divider={false}
                        isSubmitted={isSubmitted}
                        description={
                          !isSubmitted ? '' : 'Participant submitted.'
                        }
                        isLoading={isSubmitLoading}
                        isValid={
                          isValid !== null ? isValid : validateUser(participant)
                        }
                        onClickSubmitHandler={() => {
                          changeSectionStatus('complete');
                        }}
                      />
                    </Box>
                  </Grid>
                );
              }
              return <Loader />;
            }}
          />
        )}
      </Box>
    </>
  );
};
