import { graphql } from 'react-relay';

export const UpdateIncidentMutation = graphql`
  mutation UpdateIncidentMutation($input: updateIncidentInput!) {
    updateIncident(input: $input) {
      incident {
        gap
        incidentDate
        target
        quantity
        location
        details
        type
      }
    }
  }
`;
