import React from 'react';
import { IFieldProps } from './types';

import { FieldRenderProps } from 'react-final-form';
import { BaseCountrySelector } from './BaseCountrySelector';
import { QueryRenderer } from 'react-relay';
import { modernEnvironment } from 'config/environment';
import { GetCountriesQuery } from 'graphql/GetCountriesQuery';
import { Loader } from 'components/Loader';
import { BaseOfficeField } from 'components/fields/BaseOfficeField';

type Props = FieldRenderProps<string, any> & IFieldProps;

export const OfficeFieldQuery = (props: Props) => {
  const {
    input,
    templateOptions: { required, disabled },
  } = props;

  return (
    <BaseOfficeField
      {...props}
      render={(innerValue, handleChange, showError) => (
        <QueryRenderer
          environment={modernEnvironment}
          query={GetCountriesQuery}
          variables={{
            regionName: undefined,
          }}
          render={({ props }) => {
            if (props) {
              return (
                <BaseCountrySelector
                  fullWidth={true}
                  name={input.name}
                  value={innerValue.country}
                  required={required}
                  disabled={disabled}
                  showError={showError}
                  options={props.locations}
                  onChange={handleChange('country')}
                />
              );
            }
            return <Loader />;
          }}
        />
      )}
    ></BaseOfficeField>
  );
};
