import { graphql } from 'react-relay';

export const UpdateClosingNotesSubmittedStatusMutation = graphql`
  mutation UpdateClosingNotesSubmittedStatusMutation(
    $input: updateActivityPlanClosingNotesInput!
  ) {
    updateActivityPlanClosingNotes(input: $input) {
      activityPlanClosingNotes {
        id
        debriefNotes
        lessonsLearned
        submittedStatus
      }
    }
  }
`;
