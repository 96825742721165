import { commitMutation, GraphQLTaggedNode } from 'react-relay';
import { modernEnvironment } from 'config/environment';
import { PayloadError } from 'relay-runtime';
import { RelayModernStore } from 'relay-runtime/lib/store/RelayModernStore';

export const commitMutationForm = ({
  values,
  mutation,
  onCompleteCallback,
  onErrorCallback,
  optimisticResponse,
  configs,
  onUpdate
}: {
  values: {},
  mutation: GraphQLTaggedNode,
  optimisticResponse?: Object,
  configs?: Array<{ type: string, deletedIDFieldName: string }>,
  onCompleteCallback?(response: any, errors: Array<PayloadError> | null): void,
  onErrorCallback?(err: Error): void,
  onUpdate?(store: RelayModernStore): void,
}) => {
  const variables = {
    input: values,
  };

  commitMutation(modernEnvironment, {
    mutation,
    variables,
    onCompleted: onCompleteCallback,
    onError: onErrorCallback,
    optimisticResponse,
    configs,
    updater: onUpdate
  });
};
