import { graphql } from 'react-relay';

export const GetSettingsQuery = graphql`
  query GetSettingsQuery($type: String) {
    settings(category: $type) {
      category
      value
    }
  }
`;
