import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { QueryRenderer } from 'react-relay';
import { modernEnvironment } from 'config/environment';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import { GetPlanHeaderQuery } from 'graphql/GetPlanHeaderQuery';

interface IComponentProps {
  planId: string;
}

export const PlanName = ({ planId }: IComponentProps) => (
  <Box p={1.5} pb={4}>
    <QueryRenderer
      environment={modernEnvironment}
      query={GetPlanHeaderQuery}
      variables={{
        id: `/api/activity_plans/${planId}`,
      }}
      render={({ error, props }) => {
        if (error) {
          return <ValidationError>{error.message}</ValidationError>;
        } else if (props) {
          const {
            activityPlan: { name: planName },
          } = props;
          return (
            <Typography variant="h4" color="textSecondary">
              {planName}
            </Typography>
          );
        }
        return <Loader />;
      }}
    />
  </Box>
);
