import { graphql } from 'react-relay';

export const UpdateMediaObjectMutation = graphql`
  mutation UpdateMediaObjectMutation($input: uploadMediaEmployeeInput!) {
    uploadMediaEmployee(input: $input) {
      employee {
        id
        mediaObjects(type: "avatar") {
          id
          content
        }
      }
    }
  }
`;
