/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CreatePlanQueryVariables = {};
export type CreatePlanQueryResponse = {
    readonly employees: ReadonlyArray<{
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly id: string;
        readonly email: string;
    } | null> | null;
    readonly freelancers: ReadonlyArray<{
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly id: string;
        readonly email: string;
    } | null> | null;
};
export type CreatePlanQuery = {
    readonly response: CreatePlanQueryResponse;
    readonly variables: CreatePlanQueryVariables;
};



/*
query CreatePlanQuery {
  employees {
    firstName
    lastName
    id
    email
  }
  freelancers {
    firstName
    lastName
    id
    email
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "employees",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": (v0 /*: any*/)
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "freelancers",
            "storageKey": null,
            "args": null,
            "concreteType": "Freelancer",
            "plural": true,
            "selections": (v0 /*: any*/)
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CreatePlanQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "CreatePlanQuery",
            "argumentDefinitions": [],
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "CreatePlanQuery",
            "id": null,
            "text": "query CreatePlanQuery {\n  employees {\n    firstName\n    lastName\n    id\n    email\n  }\n  freelancers {\n    firstName\n    lastName\n    id\n    email\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '5904bf46acf3823404a49a15cd31498c';
export default node;
