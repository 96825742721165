import { graphql } from 'react-relay';

export const CreateActivityLogMutation = graphql`
  mutation CreateActivityLogMutation($input: createActivityLogInput!) {
    createActivityLog(input: $input) {
      activityLog {
        dateTimeEST
        localizedTime
        gmtTime
        remarks
        author
      }
    }
  }
`;
