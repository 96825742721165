import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldRenderProps } from 'react-final-form';
import { IFieldProps, ISelectOptions } from './types';
import { ValidationError } from 'components/ValidationError';

type Props = FieldRenderProps<string, any> & IFieldProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 200,
    },
  })
);

export const SelectField = (props: Props) => {
  const {
    input,
    meta,
    templateOptions: {
      label,
      options,
      required,
      disabled,
      accent,
      accentDescription,
    },
  } = props;

  const showError =
    meta.invalid &&
    meta.error !== undefined &&
    (!meta.pristine || meta.touched);

  const classes = useStyles();
  const inputLabel = useRef<HTMLElement | null>(null); // prettier-ignore
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      input.onChange(event.target.value);
    },
    [input]
  );

  return (
    <>
      {accent && (
        <Box pt={3} pb={0.5}>
          <Typography variant="h5" color="primary">
            {accent}
          </Typography>
        </Box>
      )}
      {accentDescription && (
        <Box pb={3.5}>
          <Typography variant="body2" color="textSecondary">
            {accentDescription}
          </Typography>
        </Box>
      )}
      <FormControl
        variant="outlined"
        className={classes.formControl}
        required={required}
        disabled={disabled}
      >
        <InputLabel ref={inputLabel} id={`${input.name}-label`}>
          {label}
        </InputLabel>
        <Select
          labelId={`${input.name}-label`}
          id={`${input.name}-id`}
          variant={'outlined'}
          defaultValue={''}
          displayEmpty={true}
          value={input.value}
          labelWidth={labelWidth}
          onChange={handleChange}
          error={showError}
        >
          {options &&
            options.map((option: ISelectOptions) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
        </Select>
        {showError && (
          <ValidationError>{meta.error || meta.submitError}</ValidationError>
        )}
      </FormControl>
    </>
  );
};
