import { commitMutation, graphql, Environment } from 'react-relay';

const mutation = graphql`
  mutation UpdateRiskAssessmentMutation($input: updateRiskAssessmentInput!) {
    updateRiskAssessment(input: $input) {
      riskAssessment {
        id
        mitigationSummary
        extantUntreatedRisk
      }
    }
  }
`;

export const UpdateRiskAssessment = (environment: Environment, values: {}) => {
  const variables = {
    input: values,
  };

  commitMutation(environment, {
    mutation,
    variables,
  });
};
