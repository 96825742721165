import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { IFieldProps } from './types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import timezones from 'data/timezones.json';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

type Props = FieldRenderProps<string, any> & IFieldProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 200,
    },
    lebel: {
      backgroundColor: theme.palette.common.white,
    },
  })
);

export const RegionSelectFieldStatic = (props: Props) => {
  const {
    input,
    meta,
    templateOptions: { label, name, value, required, disabled, fullWidth },
  } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    input.onChange(event.target.value);
  };

  const showError = meta.dirty && meta.invalid;
  const classes = useStyles();

  return (
    <FormControl
      fullWidth={fullWidth}
      variant="outlined"
      className={classes.formControl}
      required={required}
      disabled={disabled}
      error={Boolean(showError)}
    >
      <InputLabel className={classes.lebel} id={`${name}-label`}>
        {label}
      </InputLabel>
      <Select
        labelId={`${name}-label`}
        id={`${name}-id`}
        variant={'outlined'}
        error={Boolean(showError)}
        defaultValue={'Please select'}
        displayEmpty={true}
        onChange={handleChange}
        value={input.value || 'Please select'}
      >
        <MenuItem disabled value="Please select">
          Please select
        </MenuItem>
        {timezones.map((timezone: ITimeZone) => (
          <MenuItem key={timezone.text} value={timezone.utc[0]}>
            {timezone.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
