/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanArchiveQueryVariables = {
    status?: string | null;
    name?: string | null;
};
export type GetPlanArchiveQueryResponse = {
    readonly activityPlans: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
        readonly durationFrom: string;
        readonly durationTo: string;
        readonly participants: ReadonlyArray<{
            readonly id: string;
            readonly firstName: string | null;
            readonly lastName: string | null;
        } | null> | null;
    } | null> | null;
};
export type GetPlanArchiveQuery = {
    readonly response: GetPlanArchiveQueryResponse;
    readonly variables: GetPlanArchiveQueryVariables;
};



/*
query GetPlanArchiveQuery(
  $status: String
  $name: String
) {
  activityPlans(status: $status, name: $name) {
    id
    name
    durationFrom
    durationTo
    participants {
      id
      firstName
      lastName
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "status",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "name",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "activityPlans",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "name",
                    "variableName": "name"
                },
                {
                    "kind": "Variable",
                    "name": "status",
                    "variableName": "status"
                }
            ],
            "concreteType": "ActivityPlan",
            "plural": true,
            "selections": [
                (v1 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "durationFrom",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "durationTo",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participants",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanArchiveQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanArchiveQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanArchiveQuery",
            "id": null,
            "text": "query GetPlanArchiveQuery(\n  $status: String\n  $name: String\n) {\n  activityPlans(status: $status, name: $name) {\n    id\n    name\n    durationFrom\n    durationTo\n    participants {\n      id\n      firstName\n      lastName\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '095836292ef9ce0382500d1bd8a95fbb';
export default node;
