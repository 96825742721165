/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetCountriesQueryVariables = {
    regionName?: string | null;
};
export type GetCountriesQueryResponse = {
    readonly locations: ReadonlyArray<{
        readonly location: string;
    } | null> | null;
};
export type GetCountriesQuery = {
    readonly response: GetCountriesQueryResponse;
    readonly variables: GetCountriesQueryVariables;
};



/*
query GetCountriesQuery(
  $regionName: String
) {
  locations(region: $regionName) {
    location
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "regionName",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "region",
            "variableName": "regionName"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "location",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetCountriesQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "locations",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Location",
                    "plural": true,
                    "selections": [
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetCountriesQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "locations",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Location",
                    "plural": true,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetCountriesQuery",
            "id": null,
            "text": "query GetCountriesQuery(\n  $regionName: String\n) {\n  locations(region: $regionName) {\n    location\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '36c86a4d1cb9343cff1f61737dcf36fc';
export default node;
