import { graphql } from 'react-relay';

export const UpdateActivityLogStatusMutation = graphql`
  mutation UpdateActivityLogStatusMutation(
    $input: submitActivityLogActivityPlanInput!
  ) {
    submitActivityLogActivityPlan(input: $input) {
      activityPlan {
        id
        submittedStatusActivityLog
      }
    }
  }
`;
