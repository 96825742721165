/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type submitRiskDeterminationInput = {
    readonly id: string;
    readonly clientMutationId?: string | null;
};
export type UpdateRiskDeterminationStatusMutationVariables = {
    input: submitRiskDeterminationInput;
};
export type UpdateRiskDeterminationStatusMutationResponse = {
    readonly submitRiskDetermination: {
        readonly riskDetermination: {
            readonly submittedStatus: boolean | null;
        } | null;
    } | null;
};
export type UpdateRiskDeterminationStatusMutation = {
    readonly response: UpdateRiskDeterminationStatusMutationResponse;
    readonly variables: UpdateRiskDeterminationStatusMutationVariables;
};



/*
mutation UpdateRiskDeterminationStatusMutation(
  $input: submitRiskDeterminationInput!
) {
  submitRiskDetermination(input: $input) {
    riskDetermination {
      submittedStatus
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "submitRiskDeterminationInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "submittedStatus",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdateRiskDeterminationStatusMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "submitRiskDetermination",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "submitRiskDeterminationPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskDetermination",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskDetermination",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdateRiskDeterminationStatusMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "submitRiskDetermination",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "submitRiskDeterminationPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskDetermination",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskDetermination",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdateRiskDeterminationStatusMutation",
            "id": null,
            "text": "mutation UpdateRiskDeterminationStatusMutation(\n  $input: submitRiskDeterminationInput!\n) {\n  submitRiskDetermination(input: $input) {\n    riskDetermination {\n      submittedStatus\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '0e74864e0100128207a843a4a59745ee';
export default node;
