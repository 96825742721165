import React from 'react';
import importPlan from 'data/import-plan.json';
import { FormBuilder } from 'components';
import { modernEnvironment } from 'config/environment';
import { ImportActivityPlan } from 'graphql/ImportActivityPlanMutation';
import createDecorator from 'final-form-calculate';
import { sectionsSelectorDecorator } from 'data/form-decorators/import-plan-sections-selection';
import { useHistory } from 'react-router-dom';

interface IProps {
  createdPlanId: string;
  importedPlanId: string;
  CancelSectionsSelection(): void;
}

const selector = createDecorator(...sectionsSelectorDecorator);

export const ImportPlanSections = (props: IProps) => {
  const { createdPlanId, importedPlanId, CancelSectionsSelection } = props;
  const history = useHistory();

  return (
    <>
      <FormBuilder
        formObject={importPlan}
        decorators={[selector]}
        enableAutosave={false}
        initialValues={{
          selectAll: false,
          importBusinessIntent: false,
          importItineraryLegs: false,
          importIncidentAnalysis: false,
          importRiskDetermination: false,
          importRiskMitigation: false,
          importRiskAssessment: false,
        }}
        cancelButtonName={'Back'}
        onCancel={CancelSectionsSelection}
        submitButtonName={'Next'}
        onSubmit={(values) => {
          delete values.selectAll;
          const formattedImportedPlanId = importedPlanId.replace(
            '/api/activity_plans/',
            ''
          );
          const formattedCreatedPlanId = createdPlanId.replace(
            '/api/activity_plans/',
            'api/activity_plans/'
          );

          ImportActivityPlan(modernEnvironment, {
            id: formattedCreatedPlanId,
            activityPlanId: formattedImportedPlanId,
            ...values,
          });

          history.push(`/plan/${formattedImportedPlanId}`);
        }}
      />
    </>
  );
};
