import React, { useState, useCallback } from 'react';
import { IFieldProps, ICountry } from './types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { FieldRenderProps } from 'react-final-form';
import countries from 'data/countries.json';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import { Grid, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

type Props = FieldRenderProps<string, any> & IFieldProps;

const useStyles = makeStyles((theme) => ({
  prefix: {
    fontSize: '16px',
    width: 100,
    marginRight: '1px',
    minHeight: '58.7px',
  },
  phoneLabel: {
    fontSize: '13px',
    lineHeight: 1.2,
    verticalAlign: 'middle',
    marginBottom: '4px',
    display: 'block',
    transform: 'initial',
    padding: '0 8px',
  },
  formControl: {
    display: 'flex',
  },
  inputWrap: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '16px',
    alignItems: 'flex-start',
  },
}));

export const PhoneField = (props: Props) => {
  const classes = useStyles();
  const {
    input,
    meta,
    templateOptions: { label, elementType, placeholder, required, disabled, IMEI },
  } = props;

  const showError = meta.dirty && meta.invalid;

  const renderValue = (value: string) => {
    if (value === 'Country') {
      return value;
    }
    return `${value}`;
  };

  const [innerValue, setInnerValue] = useState({
    code: input.value.code || '',
    number: input.value.number || '',
    imei: input.value.imei || '',
  });

  const handleChange = useCallback(
    (property: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
      event.persist();
      if (elementType === 'satellite') {
        setInnerValue((state) => ({
          ...state,
          code: 'SAT',
          [property]: event.target.value,
        }));
      } else {
        setInnerValue((state) => ({
          ...state,
          [property]: event.target.value,
        }));
      }
      if (
        property === 'code' &&
        elementType === 'mobile' &&
        innerValue.number === ''
      ) {
        return;
      }
      input.onChange({ ...innerValue, [property]: event.target.value });
    },
    [innerValue, elementType, input]
  );

  return (
    <Grid container direction="column" alignItems="flex-start">
      <FormControl
        className={classes.formControl}
        required={required}
        disabled={disabled}
      >
        <InputLabel className={classes.phoneLabel}>{label}</InputLabel>
        <Box className={classes.inputWrap}>
          {elementType !== 'satellite' && (
            <Select
              disabled={disabled}
              labelId={`${input.name}-label`}
              id={`${input.name}-id`}
              variant={'outlined'}
              error={showError}
              defaultValue={'Country'}
              displayEmpty={true}
              renderValue={renderValue}
              className={classes.prefix}
              value={innerValue.code}
              onChange={handleChange('code')}
            >
              {countries &&
                countries.map((country: ICountry, index: number) => (
                  <MenuItem
                    key={`${country.dialCode + index}`}
                    value={country.dialCode}
                  >
                    {country.name} - {country.dialCode}
                  </MenuItem>
                ))}
            </Select>
          )}
          {elementType === 'satellite' && (
            <TextField
              value={'SAT'}
              variant={'outlined'}
              className={classes.prefix}
              disabled
            ></TextField>
          )}
          <TextField
            disabled={disabled}
            variant={'outlined'}
            error={showError}
            helperText={showError ? meta.error || meta.submitError : undefined}
            onChange={handleChange('number')}
            placeholder={placeholder}
            required={required}
            type={'number'}
            value={innerValue.number}
          />
        </Box>
      </FormControl>
      {IMEI && <Box pt={1} minWidth={300}>
        <FormControl
          className={classes.formControl}
          required={required}
          disabled={disabled}
        >
          <Tooltip title="IMEI Number. Your phone will show you the device's IMEI if you enter *#06# in the keypad.">
            <InputLabel className={classes.phoneLabel}>
              <Grid container justify="flex-start" alignItems="center">
                <Box pr={0.5}>IMEI (associated with each phone number): </Box>
                <InfoIcon fontSize="inherit" color="primary"></InfoIcon>
              </Grid>
            </InputLabel>
          </Tooltip>
          <Box className={classes.inputWrap}>
            <TextField
              disabled={disabled}
              variant={'outlined'}
              onChange={handleChange('imei')}
              placeholder={placeholder}
              required={required}
              type={'number'}
              value={innerValue.imei}
            />
          </Box>
        </FormControl>
      </Box>}
    </Grid>
  );
};
