import { graphql } from 'react-relay';

export const DeleteActivityPlanKeyBehaviourMutation = graphql`
  mutation DeleteActivityPlanKeyBehaviourMutation(
    $input: deleteActivityPlanKeyBehaviourInput!
  ) {
    deleteActivityPlanKeyBehaviour(input: $input) {
      activityPlanKeyBehaviour {
        id
      }
    }
  }
`;
