import { graphql } from 'react-relay';

export const GetPlanRiskDeterminationQuery = graphql`
  query GetPlanRiskDeterminationQuery($id: ID!) {
    activityPlan(id: $id) {
      statusItineraryLegs
      statusParticipants
      riskDetermination {
        id
        contextualInfo
        activitySpecificInfo
        staffSpecificInfo
        relevantIncidents
        keyThreats {
          id
          type
          notes
          probability
          impact
          commentary
        }
        environmentalFactors
        legalConsiderations
        overallRiskLevel
        assignedPhysicalRiskLevel
        assignedCyberRiskLevel
        assignedLegalRiskLevel
        assignedPhysicalRiskCommentary
        assignedInternalPhysicalRiskLevel
        assignedInternalPhysicalRiskCommentary
        submittedStatus
        calculatedRiskLevels
      }
    }
  }
`;
