import React from 'react';
import {
  Grid,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  AppBar,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { customTheme } from 'theme';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 1200,
    backgroundColor: customTheme.colors.slate,
  },
  dashboardHeader: {
    padding: theme.spacing(0.5, 1, 0.5, 3),
    color: theme.palette.primary.contrastText,
    backgroundColor: customTheme.colors.slate,
  },
}));

interface IDashboardHeaderProps {
  title: string;
}

export const DashboardHeader = (props: IDashboardHeaderProps) => {
  const { title } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className={classes.appBar}>
      <Box boxShadow={3}>
        <Grid
          className={classes.dashboardHeader}
          container
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h3">{title}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              color="inherit"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                Audit Trails and Reporting
              </MenuItem>
              <MenuItem onClick={handleClose}>Log Out</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Box>
    </AppBar>
  );
};
