import React from 'react';
import { Paper, Icon, IconButton, Grid, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface IComponentProps {
  to: string;
  children: ReactChildren;
}

export const SimpleTile = (props: IComponentProps) => {
  const { to, children } = props;
  return (
    <Box mb={1} width={'100%'}>
      <Link to={to}>
        <Paper
          component={Grid}
          container
          alignItems="center"
          justify="space-between"
        >
          <Box px={3} py={1.5} flex={1}>
            {children}
          </Box>
          <Box pr={1.5}>
            <IconButton size="small">
              <Icon>arrow_right</Icon>
            </IconButton>
          </Box>
        </Paper>
      </Link>
    </Box>
  );
};
