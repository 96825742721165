import React from 'react';
import { ContentWrapper } from 'components/ContentWrapper';
import { PlansSectionTitle } from 'components/Plans/PlansSectionTitle';
import queryString from 'query-string';
import { PlanTileList } from 'components/Plans/PlanTileList';
import planStates from 'data/planStates.json';

interface IPlansProps {
  match: {};
  location: { search: '' };
}

export const Plans = (props: IPlansProps) => {
  const { location } = props;
  const values = queryString.parse(location.search);
  const planState = planStates.find(
    (planState) => planState.slug === values.state
  );

  return (
    <>
      <ContentWrapper>
        <PlansSectionTitle planState={planState} />
        <PlanTileList
          filter={planState.slug}
          actionLabel={planState.actionArea && planState.actionArea.label}
        />
      </ContentWrapper>
    </>
  );
};
