import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  sublabel: {
    fontSize: 12,
  },
}));
export const InformationLabel = (props) => {
  const classes = useStyles();

  const {
    data: { label, sublabel },
    count,
  } = props;
  return (
    <Box display="flex" flexDirection="column">
      <Typography>
        {label}
        {count ? ` ${count}` : null}
      </Typography>
      {sublabel && (
        <Typography className={classes.sublabel} color={'textSecondary'}>
          {sublabel}
        </Typography>
      )}
    </Box>
  );
};
