import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { IFieldProps } from './types';
import { BaseCountrySelector } from './BaseCountrySelector';
import options from 'data/countriesNames.json';

type Props = FieldRenderProps<string, any> & IFieldProps;

export const CountrySelectFieldStatic = (props: Props) => {
  const {
    input,
    meta,
    templateOptions: { label, required, disabled, repeaterIndex },
  } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    input.onChange(event.target.value);
  };

  const showError = meta.dirty && meta.invalid;

  return (
    <BaseCountrySelector
      label={label}
      name={input.name}
      value={input.value}
      required={required}
      disabled={disabled}
      showError={showError}
      options={options}
      onChange={handleChange}
      repeaterIndex={repeaterIndex}
    />
  );
};
