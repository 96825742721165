import React from 'react';
import './App.css';
import { useStyles } from './styles';
import Box from '@material-ui/core/Box';
import { FormBuilder } from './components';
// TODO load those form objects async from different files
import * as formObject from './data/personal-details.json';

export const App: React.FC = () => {
  const classes = useStyles();

  // TODO load those async from GraphQL
  const initialValues = {
    firstName: 'Chemical',
    lastName: 'Brother',
    userAvatar: 'https://www.placecage.com/96/96',
    userAvatar2: '',
    vismoProfile: 'active',
    nationality2: ['american', 'british'],
    birthDetails2: new Date(),
  };

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <header>DJ HRT App</header>
      <Box>
        <FormBuilder
          title=""
          formObject={formObject}
          initialValues={initialValues}
        />
      </Box>
    </Box>
  );
};
