import React, { useEffect, useState } from 'react';
import { QueryRenderer } from 'react-relay';
import { GetPlanArchiveQuery } from '../../graphql/GetPlanArchiveQuery';
import { modernEnvironment } from '../../config/environment';
import { customTheme } from 'theme';
import {
  Box,
  Grid,
  Typography,
  InputBase,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import moment from 'moment';
import { PlanTile } from 'components/CreatePlan/PlanTile';

interface IProps {
  filter: string;
  planSelect(): void;
}

const useStyles = makeStyles((theme) => ({
  searchForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: customTheme.colors.brandGrey1,
    borderRadius: '50px',
    margin: '12px 0 24px 0',
    padding: '0 24px 0 0',
  },
  searchInput: {
    flexDirection: 'row',
    marginLeft: '12px',
  },
  resetSearchCTA: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: customTheme.colors.dark,
  },
}));

export const ImportPlan = (props: IProps) => {
  const classes = useStyles();
  const { filter, planSelect } = props;
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [searchPlanName, setSearchPlanName] = useState('');
  const [count, setCount] = useState(0);
  const [selectedPlanId, setSelectedPlanId] = useState('');

  useEffect(() => {
    setSearchPlanName('');
    setSearchFieldValue('');
    setCount(count + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSearchPlanName(searchFieldValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFieldValue(event.target.value);
  };

  const formatPlans = (plans) => {
    const formatted = [...plans].map((row, index) => ({
      ...row,
      date: `${moment(row.durationFrom).format('LL')} - ${moment(
        row.durationTo
      ).format('LL')}`,
    }));

    return formatted;
  };

  const handleClick = (plan) => {
    setSelectedPlanId(plan.id);
    planSelect(plan);
  };

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit} noValidate className={classes.searchForm}>
          <IconButton type="submit">
            <SearchIcon />
          </IconButton>
          <InputBase
            onChange={handleChange}
            value={searchFieldValue}
            fullWidth
            placeholder={'Search plan'}
            className={classes.searchInput}
          />
        </form>
      </Box>
      <QueryRenderer
        key={count}
        environment={modernEnvironment}
        query={GetPlanArchiveQuery}
        variables={{
          status: filter,
          name: searchPlanName,
        }}
        render={({ error, props }) => {
          if (error) {
            return <ValidationError>{error[0].message}</ValidationError>;
          } else if (props) {
            const { activityPlans: plans } = props;
            const formattedPlans = formatPlans(plans);
            return (
              <>
                {plans.length > 0 ? (
                  <Grid container>
                    {formattedPlans.map((plan, index) =>
                      <PlanTile
                        key={index}
                        name={plan.name}
                        date={plan.date}
                        selected={plan.id === selectedPlanId}
                        handleClick={() => handleClick(plan)}
                      />
                    )}
                  </Grid>
              ) : (
                  <Box pl={1.5}>
                    <Typography variant="h4">No results.</Typography>
                  </Box>
                )}
              </>
            );
          }
          return <Loader />;
        }}
      />
    </>
  );
};
