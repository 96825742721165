import { graphql } from 'react-relay';

export const CreateParticipantEmergencyContactMutation = graphql`
  mutation CreateParticipantEmergencyContactMutation(
    $input: createParticipantEmergencyContactInput!
  ) {
    createParticipantEmergencyContact(input: $input) {
      participantEmergencyContact {
        id
      }
    }
  }
`;
