import { commitMutation, graphql, Environment } from 'react-relay';

const mutation = graphql`
  mutation UpdatePlanClosingNotesMutation($input: updateActivityPlanClosingNotesInput!) {
    updateActivityPlanClosingNotes(input: $input) {
      activityPlanClosingNotes {
        id
        debriefNotes
        lessonsLearned
        submittedStatus
      }
    }
  }
`;

export const UpdatePlanClosingNotes = (environment: Environment, values: {}) => {
  const variables = {
    input: values,
  };

  commitMutation(environment, {
    mutation,
    variables,
  });
};
