import React, { useEffect, useState } from 'react';
import { QueryRenderer } from 'react-relay';
import { GetPlanArchiveQuery } from '../../graphql/GetPlanArchiveQuery';
import { modernEnvironment } from '../../config/environment';
import { customTheme } from 'theme';
import {
  Box,
  Typography,
  InputBase,
  IconButton,
  makeStyles,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { CustomisedTable } from 'components/CustomisedTable';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import moment from 'moment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Alert } from 'components/FormBuilder/AutoSave';

interface IProps {
  filter: string;
}

const HEADERS = [
  { key: 'name', label: 'Name' },
  { key: 'date', label: 'Date' },
  { key: 'participant', label: 'Participant' },
  { key: 'buttons', label: '' },
];

const formatPlans = (plans) => {
  const formatted = [...plans].map((row, index) => ({
    ...row,
    date: `${moment(row.durationFrom).format('LL')} - ${moment(
      row.durationTo
    ).format('LL')}`,
    participant: [...row.participants]
      .map((participant) => `${participant.firstName} ${participant.lastName}`)
      .join(', '),
  }));

  return formatted;
};

const useStyles = makeStyles((theme) => ({
  searchForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: customTheme.colors.brandGrey1,
    borderRadius: '50px',
    width: '952px',
    margin: '0 0 24px 12px',
    padding: '0 0 0 24px',
  },
  searchInput: {
    flexDirection: 'row',
  },
  resetSearchCTA: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: customTheme.colors.dark,
  },
}));

export const ArchivedPlans = (props: IProps) => {
  const classes = useStyles();
  const { filter } = props;
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [searchPlanName, setSearchPlanName] = useState('');
  const [count, setCount] = useState(0);
  const [snackBarShow, setSnackbarShow] = useState(false);

  useEffect(() => {
    setSearchPlanName('');
    setSearchFieldValue('');
    setCount(count + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSearchPlanName(searchFieldValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFieldValue(event.target.value);
  };

  const setRow = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: {}
  ) => {
    navigator.clipboard.writeText(row.name);
    setSnackbarShow(true);
    setTimeout(() => {
      setSnackbarShow(false);
    }, 1500);
  };

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit} noValidate className={classes.searchForm}>
          <InputBase
            onChange={handleChange}
            value={searchFieldValue}
            fullWidth
            placeholder={'Search plan'}
            className={classes.searchInput}
          />
          <IconButton type="submit">
            <SearchIcon />
          </IconButton>
        </form>
      </Box>
      <QueryRenderer
        key={count}
        environment={modernEnvironment}
        query={GetPlanArchiveQuery}
        variables={{
          status: filter,
          name: searchPlanName,
        }}
        render={({ error, props }) => {
          if (error) {
            return <ValidationError>{error[0].message}</ValidationError>;
          } else if (props) {
            const { activityPlans: plans } = props;
            const formattedPlans = formatPlans(plans);
            return (
              <>
                {plans.length > 0 ? (
                  <Box width={976} p={1.5}>
                    <CustomisedTable
                      headers={HEADERS}
                      sortBy={'date'}
                      data={formattedPlans}
                      rowHandler={setRow}
                      button={
                        <Tooltip title="Copy plan name">
                          <FileCopyIcon
                            fontSize="inherit"
                            variant="contained"
                            color="primary"
                          ></FileCopyIcon>
                        </Tooltip>
                      }
                    />
                    <Snackbar
                      open={snackBarShow}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                      <Alert>Plan name copied to clipboard.</Alert>
                    </Snackbar>
                  </Box>
                ) : (
                  <Box pl={1.5}>
                    <Typography variant="h4">No results.</Typography>
                  </Box>
                )}
              </>
            );
          }
          return <Loader />;
        }}
      />
    </>
  );
};
