import { graphql } from 'react-relay';

export const GetPlanItineraryContactQuery = graphql`
  query GetPlanItineraryContactQuery($id: ID!) {
    itineraryLeg(id: $id) {
      id
      itineraryContacts {
        id
        firstName
        middleName
        lastName
        mobilePhoneNumbers
        gender
        nationality
        email
        details
        activitiesLocationAssoc
      }
    }
  }
`;
