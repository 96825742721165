import React from 'react';
import { ContentWrapper } from 'components/ContentWrapper';
import { PlansSectionTitle } from 'components/Plans/PlansSectionTitle';
import { ArchivedPlans } from 'components/Plans/ArchivedPlans';
import queryString from 'query-string';
import planStates from 'data/planStates.json';

interface IPlansProps {
  match: {};
  location: { search: '' };
}

export const PlansArchive = (props: IPlansProps) => {
  const { location } = props;
  const values = queryString.parse(location.search);
  const planState = planStates.find(
    (planState) => planState.slug === values.state
  );

  return (
    <>
      <ContentWrapper>
        <PlansSectionTitle planState={planState} />
        <ArchivedPlans filter={planState.slug} />
      </ContentWrapper>
    </>
  );
};
