import { graphql } from 'react-relay';

export const AddEmployeesToActivityPlanMutation = graphql`
  mutation AddEmployeesToActivityPlanMutation(
    $input: addEmployeesToActivityPlanInput!
  ) {
    addEmployeesToActivityPlan(input: $input) {
      activityPlan {
        id
        name
        participants {
          id
          email
          firstName
          lastName
          mediaObjects(type: "avatar") {
            content
          }
        }
        supporters {
          role
          employee {
            id
            email
            firstName
            lastName
            mediaObjects(type: "avatar") {
              content
            }
          }
        }
      }
    }
  }
`;
