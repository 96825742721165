import { graphql } from 'react-relay';

export const DeletePlanParticipantMutation = graphql`
  mutation DeletePlanParticipantMutation($input: deleteParticipantInput!) {
    deleteParticipant(input: $input) {
      participant {
        id
      }
    }
  }
`;
