/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanLocationsQueryVariables = {
    id: string;
};
export type GetPlanLocationsQueryResponse = {
    readonly activityPlan: {
        readonly id: string;
        readonly countries: unknown;
    } | null;
};
export type GetPlanLocationsQuery = {
    readonly response: GetPlanLocationsQueryResponse;
    readonly variables: GetPlanLocationsQueryVariables;
};



/*
query GetPlanLocationsQuery(
  $id: ID!
) {
  activityPlan(id: $id) {
    id
    countries
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "activityPlan",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "ActivityPlan",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "countries",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanLocationsQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanLocationsQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanLocationsQuery",
            "id": null,
            "text": "query GetPlanLocationsQuery(\n  $id: ID!\n) {\n  activityPlan(id: $id) {\n    id\n    countries\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'd16f8f5ac86c52551fb5342521ff6bb6';
export default node;
