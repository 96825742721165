import { graphql } from 'react-relay';

export const UpdatePlanRiskDeterminationMutation = graphql`
  mutation UpdatePlanRiskDeterminationMutation(
    $input: updateRiskDeterminationInput!
  ) {
    updateRiskDetermination(input: $input) {
      riskDetermination {
        id
      }
    }
  }
`;
