import React from 'react';
import { Typography, Divider, Box } from '@material-ui/core';

interface IComponentProps {
  title: string;
  hasDivider: boolean;
  children: ReactNode;
}

export const Phase = ({ title, hasDivider, children }: IComponentProps) => (
  <>
    <Box pb={0.5}>
      <Typography variant="body1">{title}</Typography>
    </Box>
    {children}
    {hasDivider && (
      <Box pb={1.5} pt={1.5}>
        <Divider />
      </Box>
    )}
  </>
);
