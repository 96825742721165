import { graphql } from 'react-relay';

export const UpdateRiskDeterminationStatusMutation = graphql`
  mutation UpdateRiskDeterminationStatusMutation(
    $input: submitRiskDeterminationInput!
  ) {
    submitRiskDetermination(input: $input) {
      riskDetermination {
        submittedStatus
      }
    }
  }
`;
