import React from 'react';
import { IFieldProps } from './types';
import { FieldRenderProps } from 'react-final-form';
import { DynamicFormComponent } from 'components/DynamicFormComponent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

type Props = FieldRenderProps<string, any> & IFieldProps;

export const NestedField = (props: Props) => {
  const {
    fieldGroup,
    templateOptions: { label, heading, subheading, accent, accentDescription },
  } = props;

  return (
    <>
      {label && (
        <Box px={1} mb={2.5}>
          <Typography variant="body2" color="textSecondary">
            {label}
          </Typography>
        </Box>
      )}

      {heading && (
        <Box pt={3} pb={0.5}>
          <Typography variant="h3" color="textPrimary">
            {heading}
          </Typography>
        </Box>
      )}
      {subheading && (
        <Box pb={3.5}>
          <Typography variant="body2" color="textSecondary">
            {subheading}
          </Typography>
        </Box>
      )}

      {accent && (
        <Box pt={3} pb={0.5}>
          <Typography variant="h5" color="primary">
            {accent}
          </Typography>
        </Box>
      )}
      {accentDescription && (
        <Box pb={3.5}>
          <Typography variant="body2" color="textSecondary">
            {accentDescription}
          </Typography>
        </Box>
      )}

      {fieldGroup &&
        fieldGroup.map((field: any, index: number) => (
          <Box mr={2} mb={2} display="inline-flex" key={index}>
            <DynamicFormComponent
              {...field}
              name={field.key}
              type={field.type}
            />
          </Box>
        ))}
    </>
  );
};
