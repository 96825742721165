import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Box,
  Grid,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemSecondaryAction,
  ListItemText,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { QueryRenderer, commitMutation } from 'react-relay';
import { modernEnvironment } from 'config/environment';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import { GetKeyBehavioursQuery } from 'graphql/GetKeyBehavioursQuery';
import categories from 'data/plan/key-behaviours-categories.json';
import { CreateActivityPlanKeyBehaviourMutation } from 'graphql/CreateActivityPlanKeyBehaviourMutation';
import { DeleteActivityPlanKeyBehaviourMutation } from 'graphql/DeleteActivityPlanKeyBehaviourMutation';
import { FullscreenLoader } from 'components/FullscreenLoader';
import { CreateKeyBehaviourMutation } from 'graphql/CreateKeyBehaviourMutation';

interface IProps {
  category: string;
  riskMitigation: Object<any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 200,
    },
  })
);

export const KeyBehaviours = (props: IProps) => {
  const { category, riskMitigation } = props;
  const [categorySelected, setCategorySelected] = useState('');
  const [checked, setChecked] = useState([]);
  const [keyBehaviours, setKeyBehaviours] = useState([]);
  const [planKeyBehaviours, setPlanKeyBehaviours] = useState([]);
  const [recommendationText, setRecommendationText] = useState('');
  const [count, setCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const mounted = useRef(true);

  const handleToggle = (id: string, instruction: string) => () => {
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
      setIsLoading(true);

      createActivityPlanKeyBehaviour(id, instruction);
    } else {
      newChecked.splice(currentIndex, 1);
      const found = planKeyBehaviours.find(
        (planKeyBehaviour) => planKeyBehaviour.originalKeyBehaviour === id
      );
      if (found) {
        setIsLoading(true);

        commitMutation(modernEnvironment, {
          variables: {
            input: {
              id: found.id,
            },
          },
          mutation: DeleteActivityPlanKeyBehaviourMutation,
          optimisticResponse: {
            deleteActivityPlanKeyBehaviour: {
              activityPlanKeyBehaviour: {
                id: found.id,
              },
            },
          },
          configs: [
            {
              type: 'NODE_DELETE',
              deletedIDFieldName: found.id,
            },
          ],
          onCompleted: (response) => {
            mounted.current && setIsLoading(false);
          },
          onError: (response) => {
            mounted.current && setIsLoading(false);
          },
        });
      }
    }

    setChecked(newChecked);
  };

  const classes = useStyles();
  const inputLabel = useRef<HTMLElement | null>(null); // prettier-ignore
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
    if (category) {
      setCategorySelected(category);

      setPlanKeyBehaviours(
        [...riskMitigation.keyBehaviours].filter(
          (keyBehaviour) => keyBehaviour.category === category
        )
      );

      const newChecked = [];
      planKeyBehaviours.forEach((planKeyBehaviour) => {
        const found = keyBehaviours.find(
          (keyBehaviour) =>
            keyBehaviour.id === planKeyBehaviour.originalKeyBehaviour
        );
        if (found) {
          newChecked.push(found.id);
        }
      });
      setChecked(newChecked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, keyBehaviours]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategorySelected(event.target.value);
    event.stopPropagation();
  };

  const createKeyBehaviour = () => {
    setIsLoading(true);
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          instruction: recommendationText,
          category: categorySelected,
        },
      },
      mutation: CreateKeyBehaviourMutation,
      optimisticResponse: {
        createKeyBehaviour: {
          keyBehaviour: {
            id: '/api/key_behaviours/newKeyBehaviour',
            instruction: recommendationText,
            category: categorySelected,
          },
        },
      },
      onCompleted: (response) => {
        setRecommendationText('');
        setCount(count + 1);
        mounted.current && setIsLoading(false);
      },
      onError: (response) => {
        mounted.current && setIsLoading(false);
      },
    });
  };

  const createActivityPlanKeyBehaviour = (id: string, instruction: string) => {
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          riskMitigation: riskMitigation.id,
          instruction,
          category: categorySelected,
        },
      },
      mutation: CreateActivityPlanKeyBehaviourMutation,
      optimisticResponse: {
        createActivityPlanKeyBehaviour: {
          activityPlanKeyBehaviour: {
            id: `/api/activity_plan_key_behaviours/${riskMitigation.id}`,
            instruction,
            category: categorySelected,
          },
        },
      },
      onCompleted: (response) => {
        mounted.current && setIsLoading(false);
      },
      onError: (response) => {
        mounted.current && setIsLoading(false);
      },
    });
  };

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="category-label" ref={inputLabel}>
          Category
        </InputLabel>
        <Select
          labelId="category-label"
          id="category-id"
          variant="outlined"
          displayEmpty={true}
          value={categorySelected}
          disabled={categorySelected !== '' ? true : false}
          labelWidth={labelWidth}
          onChange={(e) => {
            handleChange(e);
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {categories.map((option: ISelectOptions) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {categorySelected && (
        <Box>
          <Box pt={3} pb={0.5}>
            <Typography variant="h3" color="textPrimary">
              Recommendations
            </Typography>
          </Box>

          <TextField
            variant={'outlined'}
            label="Add new recommendation or select from the list below"
            placeholder="Enter new recommendation"
            type="text"
            fullWidth={true}
            multiline={true}
            rows="3"
            value={recommendationText}
            onChange={(e) => setRecommendationText(e.target.value)}
          />
          <QueryRenderer
            key={count}
            width={'100%'}
            environment={modernEnvironment}
            query={GetKeyBehavioursQuery}
            variables={{
              category: categorySelected,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error[0].message}</ValidationError>;
              } else if (props) {
                const { keyBehaviours } = props;

                setKeyBehaviours(keyBehaviours);

                return (
                  <Grid container>
                    <Box py={1.5}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={createKeyBehaviour}
                        disabled={recommendationText === ''}
                      >
                        Save to list
                      </Button>
                    </Box>
                    <Paper
                      style={{
                        maxHeight: 240,
                        width: '100%',
                        overflow: 'auto',
                      }}
                    >
                      <List>
                        {[...keyBehaviours]
                          .sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          )
                          .map((keyBehaviour) => {
                            const labelId = `checkbox-list-label-${keyBehaviour.id}`;

                            return (
                              <ListItem
                                key={keyBehaviour.id}
                                role={undefined}
                                dense
                                button
                                onClick={handleToggle(
                                  keyBehaviour.id,
                                  keyBehaviour.instruction
                                )}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={
                                      checked.indexOf(keyBehaviour.id) !== -1
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={keyBehaviour.instruction}
                                />
                                <ListItemSecondaryAction></ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                      </List>
                    </Paper>
                  </Grid>
                );
              }
              return <Loader />;
            }}
          />
          <FullscreenLoader isLoading={isLoading} />
        </Box>
      )}
    </>
  );
};
