import { graphql } from 'react-relay';

export const GetKeyBehavioursQuery = graphql`
  query GetKeyBehavioursQuery($category: String) {
    keyBehaviours(category: $category) {
      id
      instruction
      category
      createdAt
    }
  }
`;
