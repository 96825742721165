import { createMuiTheme } from '@material-ui/core/styles';
import SimplonLightWoff2 from './assets/fonts/simplonnorm-light-webfont.woff2';
import SimplonRegularWoff2 from './assets/fonts/simplonnorm-regular-webfont.woff2';
import SimplonMediumWoff2 from './assets/fonts/simplonnorm-medium-webfont.woff2';
import SimplonBoldWoff2 from './assets/fonts/simplonnorm-bold-webfont.woff2';

const simplonLight = {
  fontFamily: 'Simplon Norm',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '300',
  src: `
    url(${SimplonLightWoff2}) format('woff2')
  `,
};
const simplonRegular = {
  fontFamily: 'Simplon Norm',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
    url(${SimplonRegularWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const simplonMedium = {
  fontFamily: 'Simplon Norm',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '500',
  src: `
    url(${SimplonMediumWoff2}) format('woff2')
  `,
};

const simplonBold = {
  fontFamily: 'Simplon Norm',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'bold',
  src: `
    url(${SimplonBoldWoff2}) format('woff2')
  `,
};

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'Simplon Norm',
    fontWeight: 300,
    fontSize: 16,
  },
  h2: {
    fontFamily: 'Simplon Norm',
  },
  h4: {
    fontFamily: 'Simplon Norm',
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: 34,
        lineHeight: 1.1,
        letterSpacing: '0.25px',
      },
      h2: {
        fontSize: 24,
        lineHeight: 1.2,
        letterSpacing: '0.25px',
      },
      h3: {
        fontSize: 20,
        letterSpacing: '0.25px',
        lineHeight: 1.3,
        fontWeight: 500,
      },
      h4: {
        fontSize: 18,
        lineHeight: 1.4,
      },
      h5: {
        fontSize: 16,
      },
      body1: {
        fontSize: 14,
      },
      body2: {
        fontSize: 12,
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          simplonLight,
          simplonRegular,
          simplonMedium,
          simplonBold,
        ],
      },
    },
  },
  palette: {
    primary: {
      main: '#02a3d5',
      contrastText: '#fff',
    },
    secondary: {
      main: '#2c3136',
    },
    background: {
      default: '#fafafa',
      slate: '#394859',
    },
    action: {
      selected: 'rgba(2, 163, 213, .12)',
    },
    success: {
      main: '#43a047',
    },
    info: {
      main: '#5699ff',
    },
    warning: {
      main: '#d50000',
    },
  },
  status: {
    danger: 'red',
  },
});

export const customTheme = {
  colors: {
    veryLightPinkThree: '#e0e0e0',
    slate: '#394859',
    greyBlue: '#657587',
    charcoalGrey: '#2c3136',
    oceanBlue: '#0072a2',
    black: '#000000',
    white: '#ffffff',
    brandGrey1: '#f4f4f4',
    brandGrey2: '#dee1e0',
    brandGrey3: '#8d9ca9',
    brownGrey: '#979797',
    secondary: '#3d6aa6',
    blackTwo: '#212121',
    dark: '#273341',
    secondaryMain: '#3d8cbc',
    success: '#43a047',
    error: '#d50000',
    softBlue: '#5699ff',
    frenchBlue: '#457ab8',
  },
};
