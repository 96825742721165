import { graphql } from 'react-relay';

export const CreatePlanMutation = graphql`
  mutation CreatePlanMutation($input: createActivityPlanInput!) {
    createActivityPlan(input: $input) {
      activityPlan {
        id
        name
        participants {
          id
          email
          firstName
          lastName
          mediaObjects(type: "avatar") {
            content
          }
        }
        supporters {
          role
          employee {
            id
            email
            firstName
            lastName
            mediaObjects(type: "avatar") {
              content
            }
          }
        }
      }
    }
  }
`;
