import React from 'react';
import { Button, Box, Divider, Typography } from '@material-ui/core';
import { FullscreenLoader } from 'components/FullscreenLoader';

interface IComponentProps {
  description?: string;
  isValid?: boolean;
  isSubmitted?: boolean;
  isLoading?: boolean;
  divider?: boolean;
  onClickSubmitHandler(): void;
  onClickUpdateHandler(): void;
}

export const SubmitSectionBtn = ({
  isValid = true,
  isLoading = false,
  isSubmitted = false,
  divider = true,
  description,
  onClickSubmitHandler,
  onClickUpdateHandler,
}: IComponentProps) => (
  <>
    {divider && (
      <Box px={1.15} py={1.5}>
        <Divider />
      </Box>
    )}
    {description && description !== '' && (
      <Box px={1.15} pb={1}>
        <Typography color="textSecondary" variant="body2">
          {description}
        </Typography>
      </Box>
    )}
    <Box px={divider ? 1.15 : 0}>
      {!isSubmitted && (
        <Button
          variant="outlined"
          color="primary"
          onClick={onClickSubmitHandler}
          disabled={!isValid}
        >
          Submit
        </Button>
      )}
      {isSubmitted && onClickUpdateHandler && (
        <Button
          variant="outlined"
          color="primary"
          onClick={onClickUpdateHandler}
        >
          Update
        </Button>
      )}
      <FullscreenLoader isLoading={isLoading} />
    </Box>
  </>
);
