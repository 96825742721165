import { graphql } from 'react-relay';

export const CreateActivityPlanKeyBehaviourMutation = graphql`
  mutation CreateActivityPlanKeyBehaviourMutation(
    $input: createActivityPlanKeyBehaviourInput!
  ) {
    createActivityPlanKeyBehaviour(input: $input) {
      activityPlanKeyBehaviour {
        instruction
        category
      }
    }
  }
`;
