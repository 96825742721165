// import 'date-fns';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { FieldRenderProps } from 'react-final-form';
import { IFieldProps } from './types';
import { ValidationError } from 'components/ValidationError';
import moment from 'moment';

type Props = FieldRenderProps<string, any> & IFieldProps;

export const DateField = (props: Props) => {
  const {
    input,
    meta,
    templateOptions: { label, required, disabled },
  } = props;

  const { value, name } = input;
  // prettier-ignore
  const [selectedDate, setSelectedDate] = useState<Date | any>(value ? value : null);

  const handleDateChange = (date: Date | null) => {
    const formattedDate = moment(date).format('YYYY-MM-DD hh:mm');
    setSelectedDate(formattedDate);
    input.onChange(formattedDate);
  };

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    (!meta.pristine || meta.touched);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="flex-start" direction="column">
        <KeyboardDatePicker
          autoOk
          disabled={disabled}
          variant="inline"
          required={required}
          inputVariant="outlined"
          label={label}
          name={name}
          format="MM-dd-yyyy"
          value={selectedDate}
          InputAdornmentProps={{ position: 'end' }}
          onChange={handleDateChange}
          error={showError}
        />
        {showError && (
          <ValidationError>{meta.error || meta.submitError}</ValidationError>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
