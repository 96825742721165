/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanRegionQueryVariables = {
    id: string;
};
export type GetPlanRegionQueryResponse = {
    readonly activityPlan: {
        readonly region: string;
        readonly countries: unknown;
    } | null;
};
export type GetPlanRegionQuery = {
    readonly response: GetPlanRegionQueryResponse;
    readonly variables: GetPlanRegionQueryVariables;
};



/*
query GetPlanRegionQuery(
  $id: ID!
) {
  activityPlan(id: $id) {
    region
    countries
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "region",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "countries",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanRegionQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanRegionQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanRegionQuery",
            "id": null,
            "text": "query GetPlanRegionQuery(\n  $id: ID!\n) {\n  activityPlan(id: $id) {\n    region\n    countries\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'fbb9333ad21a42b8a4d221e14203c894';
export default node;
