import React from 'react';
import { ITimeZone } from 'components/fields/types';
import timezones from 'data/timezones.json';
import { Box, Typography, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

interface IComponentProps {
  currentTime: any;
  localTime: any;
  showLocalInfo(): void;
}

const useStyles = makeStyles((theme) => ({
  timezoneLabel: {
    fontSize: '13px',
    lineHeight: 1.2,
    verticalAlign: 'middle',
    marginBottom: '4px',
    display: 'block',
    transform: 'initial',
    padding: '0 8px',
  },
  timezoneField: {
    minWidth: '150px',
    fontSize: '16px',
    marginRight: '1px',
    minHeight: '58.7px',
  },
}));

export const ActivityLogTimeZoneDeltas = (props: IComponentProps) => {
  const {
    currentTime,
    localTime = { timezone: '', timezoneDelta: '', formattedTime: '' },
    showLocalInfo,
  } = props;
  const classes = useStyles();

  return (
    <Box p={1.5}>
      <Box>
        <Typography variant="h4">Time Zone Deltas (from EST/EDT)</Typography>
      </Box>
      <Box pt={3.5}>
        <InputLabel className={classes.timezoneLabel}>
          Current time (EST/EDT)
        </InputLabel>
        <TextField
          value={currentTime.ESTTime}
          variant={'outlined'}
          className={classes.timezoneField}
          disabled
        ></TextField>
      </Box>
      <Grid container direction="row">
        <Box pt={3.5} pr={1.5}>
          <InputLabel className={classes.timezoneLabel}>
            BST/GMT (London) time zone delta
          </InputLabel>
          <TextField
            value={5}
            variant={'outlined'}
            className={classes.timezoneField}
            disabled
          ></TextField>
        </Box>
        <Box pt={3.5}>
          <InputLabel className={classes.timezoneLabel}>
            BST/GMT time
          </InputLabel>
          <TextField
            value={currentTime.GMTTime}
            variant={'outlined'}
            className={classes.timezoneField}
            disabled
          ></TextField>
        </Box>
      </Grid>
      <Box pt={3.5}>
        <InputLabel className={classes.timezoneLabel}>
          Local time zone
        </InputLabel>
        <Select
          variant={'outlined'}
          displayEmpty={true}
          defaultValue={'Please select'}
          className={classes.timezoneField}
          onChange={showLocalInfo}
        >
          <MenuItem disabled value="Please select">
            Please select
          </MenuItem>
          {timezones.map((timezone: ITimeZone) => (
            <MenuItem key={timezone.text} value={timezone.text}>
              {timezone.text}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Grid container direction="row">
        <Box pt={3.5} pr={1.5}>
          <InputLabel className={classes.timezoneLabel}>
            Local time zone delta
          </InputLabel>
          <TextField
            value={localTime.timezoneDelta}
            variant={'outlined'}
            className={classes.timezoneField}
            disabled
          ></TextField>
        </Box>
        <Box pt={3.5}>
          <InputLabel className={classes.timezoneLabel}>Local time</InputLabel>
          <TextField
            value={localTime.formattedTime}
            variant={'outlined'}
            className={classes.timezoneField}
            disabled
          ></TextField>
        </Box>
      </Grid>
    </Box>
  );
};
