import { graphql } from 'react-relay';

export const UpdateRiskAssessmentStatusMutation = graphql`
  mutation UpdateRiskAssessmentStatusMutation(
    $input: submitRiskAssessmentInput!
  ) {
    submitRiskAssessment(input: $input) {
      riskAssessment {
        submittedStatus
      }
    }
  }
`;
