/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type updateEmployeeEmergencyContactInput = {
    readonly id: string;
    readonly employee?: string | null;
    readonly firstName?: string | null;
    readonly middleName?: string | null;
    readonly lastName?: string | null;
    readonly mobilePhoneNumbers?: unknown | null;
    readonly gender?: string | null;
    readonly nationality?: unknown | null;
    readonly email?: string | null;
    readonly relationship?: string | null;
    readonly languagePreference?: string | null;
    readonly specialInstructions?: string | null;
    readonly type?: string | null;
    readonly clientMutationId?: string | null;
};
export type UpdateEmployeeEmergencyContactMutationVariables = {
    input: updateEmployeeEmergencyContactInput;
};
export type UpdateEmployeeEmergencyContactMutationResponse = {
    readonly updateEmployeeEmergencyContact: {
        readonly employeeEmergencyContact: {
            readonly id: string;
        } | null;
    } | null;
};
export type UpdateEmployeeEmergencyContactMutation = {
    readonly response: UpdateEmployeeEmergencyContactMutationResponse;
    readonly variables: UpdateEmployeeEmergencyContactMutationVariables;
};



/*
mutation UpdateEmployeeEmergencyContactMutation(
  $input: updateEmployeeEmergencyContactInput!
) {
  updateEmployeeEmergencyContact(input: $input) {
    employeeEmergencyContact {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "updateEmployeeEmergencyContactInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateEmployeeEmergencyContact",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "updateEmployeeEmergencyContactPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "employeeEmergencyContact",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EmployeeEmergencyContact",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdateEmployeeEmergencyContactMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdateEmployeeEmergencyContactMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdateEmployeeEmergencyContactMutation",
            "id": null,
            "text": "mutation UpdateEmployeeEmergencyContactMutation(\n  $input: updateEmployeeEmergencyContactInput!\n) {\n  updateEmployeeEmergencyContact(input: $input) {\n    employeeEmergencyContact {\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'b80f4eb672c9a50e3b30eccce808c92f';
export default node;
