import React from 'react';
import { Select, InputLabel, MenuItem, FormControl } from '@material-ui/core';
import { FieldRenderProps } from 'react-final-form';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ValidationError } from 'components/ValidationError';
import { IBaseCountrySelector, ISelectOptions } from './types';
import { arabicToRoman } from 'helper/arabicToRoman';

type Props = FieldRenderProps<string, any> & IBaseCountrySelector;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 200,
    },
    lebel: {
      backgroundColor: theme.palette.common.white,
    },
  })
);

export const BaseCountrySelector = (props: Props) => {
  const {
    label,
    name,
    value,
    required,
    disabled,
    showError,
    options,
    onChange,
    fullWidth,
    meta,
    repeaterIndex,
  } = props;

  const classes = useStyles();
  return (
    <FormControl
      fullWidth={fullWidth}
      variant="outlined"
      className={classes.formControl}
      required={required}
      disabled={disabled}
      error={Boolean(showError)}
    >
      <InputLabel className={classes.lebel} id={`${name}-label`}>
        {label} {repeaterIndex && arabicToRoman(repeaterIndex)}
      </InputLabel>
      <Select
        labelId={`${name}-label`}
        id={`${name}-id`}
        variant={'outlined'}
        error={Boolean(showError)}
        defaultValue={'Select'}
        displayEmpty={true}
        value={value}
        onChange={onChange}
      >
        {options &&
          options.map((option: ISelectOptions, index: number) => (
            <MenuItem key={index} value={option.location}>
              {option.location}
            </MenuItem>
          ))}
      </Select>

      {showError && (
        <ValidationError>{meta.error || meta.submitError}</ValidationError>
      )}
    </FormControl>
  );
};
