/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type createItineraryContactInput = {
    readonly firstName?: string | null;
    readonly middleName?: string | null;
    readonly lastName?: string | null;
    readonly gender?: string | null;
    readonly nationality?: unknown | null;
    readonly mobilePhoneNumbers?: unknown | null;
    readonly email?: string | null;
    readonly details?: string | null;
    readonly activitiesLocationAssoc?: string | null;
    readonly itineraryLegs?: ReadonlyArray<string | null> | null;
    readonly clientMutationId?: string | null;
};
export type CreateItineraryContactMutationVariables = {
    input: createItineraryContactInput;
};
export type CreateItineraryContactMutationResponse = {
    readonly createItineraryContact: {
        readonly itineraryContact: {
            readonly id: string;
        } | null;
    } | null;
};
export type CreateItineraryContactMutation = {
    readonly response: CreateItineraryContactMutationResponse;
    readonly variables: CreateItineraryContactMutationVariables;
};



/*
mutation CreateItineraryContactMutation(
  $input: createItineraryContactInput!
) {
  createItineraryContact(input: $input) {
    itineraryContact {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "createItineraryContactInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "createItineraryContact",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "createItineraryContactPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "itineraryContact",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ItineraryContact",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CreateItineraryContactMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "CreateItineraryContactMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "CreateItineraryContactMutation",
            "id": null,
            "text": "mutation CreateItineraryContactMutation(\n  $input: createItineraryContactInput!\n) {\n  createItineraryContact(input: $input) {\n    itineraryContact {\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '6944b85164103c9c6f9665ecf74d25a9';
export default node;
