import moment from 'moment';

export const HEADERS = [
  { key: 'dateTimeEST', label: 'EST time' },
  { key: 'localizedTime', label: 'Local time' },
  { key: 'gmtTime', label: 'GMT/BST time' },
  { key: 'remarks', label: 'Remarks', size: 'big' },
  { key: 'author', label: 'Author'},
  { key: 'actions', label: '' },
];

export const formatActivities = (activities) => {
  const formatted = [...activities].map((row) => ({
    ...row,
    dateTimeEST: moment(row.dateTimeEST).format('D MMM YY k:mm'),
    localizedTime: moment(row.localizedTime).format('D MMM YY k:mm'),
    gmtTime: moment(row.gmtTime).format('D MMM YY k:mm'),
  }));

  return formatted;
};
