/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type updateRiskDeterminationInput = {
    readonly id: string;
    readonly contextualInfo?: string | null;
    readonly activitySpecificInfo?: string | null;
    readonly staffSpecificInfo?: string | null;
    readonly relevantIncidents?: string | null;
    readonly environmentalFactors?: string | null;
    readonly legalConsiderations?: string | null;
    readonly assignedPhysicalRiskCommentary?: string | null;
    readonly assignedInternalPhysicalRiskLevel?: string | null;
    readonly assignedInternalPhysicalRiskCommentary?: string | null;
    readonly keyThreats?: ReadonlyArray<string | null> | null;
    readonly clientMutationId?: string | null;
};
export type UpdatePlanRiskDeterminationMutationVariables = {
    input: updateRiskDeterminationInput;
};
export type UpdatePlanRiskDeterminationMutationResponse = {
    readonly updateRiskDetermination: {
        readonly riskDetermination: {
            readonly id: string;
        } | null;
    } | null;
};
export type UpdatePlanRiskDeterminationMutation = {
    readonly response: UpdatePlanRiskDeterminationMutationResponse;
    readonly variables: UpdatePlanRiskDeterminationMutationVariables;
};



/*
mutation UpdatePlanRiskDeterminationMutation(
  $input: updateRiskDeterminationInput!
) {
  updateRiskDetermination(input: $input) {
    riskDetermination {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "updateRiskDeterminationInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateRiskDetermination",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "updateRiskDeterminationPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "riskDetermination",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "RiskDetermination",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdatePlanRiskDeterminationMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdatePlanRiskDeterminationMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdatePlanRiskDeterminationMutation",
            "id": null,
            "text": "mutation UpdatePlanRiskDeterminationMutation(\n  $input: updateRiskDeterminationInput!\n) {\n  updateRiskDetermination(input: $input) {\n    riskDetermination {\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '3011722e728e406fb5c99a2ec662f67f';
export default node;
