import React from 'react';

import {
  Typography,
  Divider,
  Box,
  makeStyles,
  CardContent,
  Card,
  CardActions,
  Button,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { theme } from 'theme';
import { stringToDatetimeShort } from 'helper/datetimeShort';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { AvatarWithBlob } from 'components/AvatarWithBlob';
import { getInitials } from 'helper/getInitials';
import { Link } from 'react-router-dom';
import { clearId } from 'helper/clearId';

interface IComponentProps {
  id: string;
  durationFrom?: string;
  durationTo?: string;
  name: string;
  actionLabel: string;
  actionFunction: Function;
  participants?: Node;
  children?: {};
}

const useStyles = makeStyles({
  card: {
    width: 475,
    minHeight: 292,
  },
  fixedBox: {
    backgroundColor: theme.palette.common.white,
  },
});

export const PlanTile = (props: IComponentProps) => {
  const classes = useStyles();
  const {
    id,
    durationFrom,
    durationTo,
    name,
    participants,
    actionLabel,
    actionFunction,
    children,
  } = props;

  const planId = clearId(id);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box display="flex" flexDirection="column" height={232}>
          <Grid container justify="space-between" alignItems="center">
            <Box pb={1.5}>
              <Typography variant="body1">
                {stringToDatetimeShort(durationFrom)}
                {' - '}
                {stringToDatetimeShort(durationTo)}
              </Typography>
            </Box>

            <Box pb={1.5} minHeight={45}>
              {participants.length > 0 && (
                <AvatarGroup>
                  {participants.map((participant) => (
                    <Tooltip
                      title={`${
                        participant.firstName !== null
                          ? participant.firstName
                          : ''
                      } ${
                        participant.firstName !== null
                          ? participant.lastName
                          : ''
                      }`}
                      placement="top"
                      key={participant.id}
                    >
                      <AvatarWithBlob
                        key={participant.email}
                        src={
                          participant.mediaObjects &&
                          participant.mediaObjects[0] &&
                          participant.mediaObjects[0].content
                        }
                      >
                        {getInitials(
                          participant.firstName,
                          participant.lastName
                        )}
                      </AvatarWithBlob>
                    </Tooltip>
                  ))}
                </AvatarGroup>
              )}
            </Box>
          </Grid>
          <Box pb={1.5}>
            <Link to={`/plan/${planId}`}>
              <Typography variant="body1">{name}</Typography>
            </Link>
          </Box>
          <Box flex={1}>{children}</Box>
        </Box>
      </CardContent>
      {actionLabel && (
        <>
          <Divider />
          <CardActions>
            <Grid container justify="flex-end">
              <Button variant="text" color="primary" onClick={actionFunction}>
                {actionLabel}
              </Button>
            </Grid>
          </CardActions>
        </>
      )}
    </Card>
  );
};
