import React from 'react';
import {
  Drawer,
  Divider,
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Box,
  Typography,
  Grid,
  Icon,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'theme';
import dashboardNav from 'data/dashboardNav';
import { DashboardCurrentUser } from 'components/Dashboard/DashboardCurrentUser';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { validateUser } from 'helper/validateUser';
import { CURRENT_USER } from 'config/globals';
import { ValidationError } from 'components/ValidationError';
import { GetEmployeeQuery } from 'graphql/GetEmployeeQuery';
import { Loader } from 'components/Loader';
import { modernEnvironment } from 'config/environment';

const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
}));

export const DashboardAside = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState();
  const [currentEl, setCurrentEl] = React.useState();
  const location = useLocation();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    name: String
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentEl(name);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentEl(null);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar} />

      <Box p={theme.spacing(2, 3)}>
        <DashboardCurrentUser />
      </Box>
      <Divider />
      <Box p={[4, 1]}>
        <List>
          {dashboardNav.map(
            (
              item: {
                id: number,
                icon: string,
                text: string,
                route: string,
                subnavigation: [],
              },
              index: number
            ) =>
              item.subnavigation ? (
                <ListItem
                  button
                  key={item.id}
                  selected={
                    matchPath(`${location.pathname}${location.search}`, {
                      path: item.subnavigation.map((o) => o.route),
                    }) != null
                  }
                >
                  <Grid
                    container
                    alignItems="center"
                    onClick={(e) => handleClick(e, `simple-menu${index}`)}
                    aria-controls={`simple-menu${index}`}
                    aria-haspopup="true"
                  >
                    <ListItemIcon>
                      <Icon fontSize="small">{item.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body1">{item.text}</Typography>
                      }
                    />
                    <Icon>arrow_right</Icon>
                  </Grid>
                  <Menu
                    id={`simple-menu${index}`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(currentEl === `simple-menu${index}`)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    {item.subnavigation.map(
                      (submenuItem: { text: string, route: string }, index) => (
                        <Link
                          key={index}
                          to={submenuItem.route}
                          color="textPrimary"
                        >
                          <MenuItem onClick={handleClose}>
                            <Typography variant="body1">
                              {submenuItem.text}
                            </Typography>
                          </MenuItem>
                        </Link>
                      )
                    )}
                  </Menu>
                </ListItem>
              ) : (
                <Link key={item.id} to={item.route} color="textPrimary">
                  <ListItem
                    button
                    selected={
                      matchPath(`${location.pathname}${location.search}`, {
                        path: item.route,
                      }) != null
                    }
                  >
                    <ListItemIcon>
                      <Icon fontSize="small">{item.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body1">{item.text}</Typography>
                      }
                    />

                    {item.text === 'Personal Details' && (
                      <QueryRenderer
                        environment={modernEnvironment}
                        query={GetEmployeeQuery}
                        variables={{
                          // TODO: The current user to be fetched from the HRT API, through the headers of the served Collaboration Dashboard App
                          id: CURRENT_USER.id,
                        }}
                        render={({ error, props }) => {
                          if (error) {
                            return (
                              <ValidationError>
                                {error[0].message}
                              </ValidationError>
                            );
                          } else if (props) {
                            const { employee } = props;
                            if (!employee || employee === null) {
                              return <></>;
                            }
                            const isValid = validateUser(employee);
                            return (
                              !isValid && (
                                <Tooltip
                                  title="Filling in Personal Profile, Physical/Medical Details and Proof of Life Information will expedite activity plan creation."
                                  placement="top"
                                >
                                  <Icon color="error" fontSize="small">
                                    warning
                                  </Icon>
                                </Tooltip>
                              )
                            );
                          }
                          return <Loader />;
                        }}
                      />
                    )}
                  </ListItem>
                </Link>
              )
          )}
        </List>
      </Box>
    </Drawer>
  );
};

// export default DashboardAside;
