import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Grid, Divider } from '@material-ui/core';
import { SectionStatus } from 'components/SectionStatus';
import { PlanName } from 'components/Plan/PlanName';
import { PlanContentWrapper } from 'components/Plan/PlanContentWrapper';
import timezones from 'data/timezones.json';
import { ActivityLogTimeZoneDeltas } from 'pages/plan/ActivityLogTimeZoneDeltas';
import { ActivitiesCustomisedTable } from 'pages/plan/activities/ActivitiesCustomisedTable';
import { QueryRenderer, commitMutation } from 'react-relay';
import { modernEnvironment } from 'config/environment';
import { GetPlanActivitiesQuery } from 'graphql/GetPlanActivitiesQuery';
import { ValidationError } from 'components/ValidationError';
import { formatActivities } from 'pages/plan/activities/ActivitiesCalculations';
import { Loader } from 'components/Loader';
import moment from 'moment';
import { CustomWarning } from 'components/Plan/CustomWarning';
import { SubmitSectionBtn } from 'components/Buttons/SubmitSectionBtn';
import { UpdateActivityLogStatusMutation } from 'graphql/UpdateActivityLogStatusMutation';

interface IComponentProps {
  planId: string;
}

export const ActivityLog = ({ planId }: IComponentProps) => {
  const [count, setCount] = useState(0);
  const mounted = useRef(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentTime, setCurrentTime] = useState({ ESTTime: '', GMTTime: '' });
  const [localTime, setLocalTime] = useState({
    timezone: '',
    timezoneDelta: '',
    formattedTime: '',
  });
  const [timezoneSelected, setTimezoneSelected] = useState(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const currentESTTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    const currentGMTTime = new Date().toLocaleString('en-GB', {
      timeZone: 'Europe/London',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    setCurrentTime({
      ESTTime: currentESTTime.toString(),
      GMTTime: currentGMTTime.toString(),
    });
  }, []);

  const showLocalInfo = (e) => {
    const selectedTimezone = timezones.find(
      (timezone) => timezone.text === e.target.value
    );
    const currentLocalTime = moment(currentTime.ESTTime, 'HH:mm').add(
      selectedTimezone.delta,
      'h'
    );

    setLocalTime({
      timezone: e.target.value,
      timezoneDelta: selectedTimezone.offset,
      formattedTime: currentLocalTime.format('HH:mm'),
    });
    setTimezoneSelected(true);
  };

  const changeSectionStatus = (newStatus: boolean, planId: string) => {
    setIsSubmitLoading(true);
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: `/api/activity_plans/${planId}`,
        },
      },
      mutation: UpdateActivityLogStatusMutation,
      updater: (store) => {
        const mutation = store.getRootField('submitActivityLogActivityPlan');
        const plan = mutation.getLinkedRecord('activityPlan');
        plan.setValue(newStatus, 'submittedStatusActivityLog');
      },
      onCompleted: (response) => {
        mounted.current && setIsSubmitted(newStatus);
        mounted.current && setIsSubmitLoading(false);
      },
      onError: (response) => {
        mounted.current && setIsSubmitLoading(false);
      },
    });
  };

  const showCustomWarning = () => (
    <CustomWarning>
      Risk determination needs to be submitted, in order to view/edit this
      section.
    </CustomWarning>
  );

  return (
    <PlanContentWrapper>
      <Grid container direction="column">
        <PlanName planId={planId}></PlanName>
        <Box p={1.5} pb={0.5}>
          <Typography variant="h1" component="h2">
            Activity Log
          </Typography>
        </Box>
        <SectionStatus completedBy="Global Security"></SectionStatus>
        <Box pb={3}>
          <Divider />
        </Box>
        <QueryRenderer
          key={count}
          environment={modernEnvironment}
          query={GetPlanActivitiesQuery}
          variables={{
            id: `/api/activity_plans/${planId}`,
          }}
          render={({ error, props }) => {
            if (error) {
              return <ValidationError>{error.message}</ValidationError>;
            } else if (props) {
              const { activityPlan } = props;
              if (
                activityPlan.activityLogs === null ||
                activityPlan.riskDetermination === null
              ) {
                return showCustomWarning();
              }

              const {
                activityLogs,
                submittedStatusActivityLog,
                riskDetermination: {
                  submittedStatus: riskDeterminationSubmittedStatus,
                },
              } = activityPlan;

              if (!riskDeterminationSubmittedStatus) {
                return showCustomWarning();
              }

              const formattedActivityLogs = formatActivities(activityLogs);
              setIsSubmitted(submittedStatusActivityLog);
              return (
                <>
                  <ActivityLogTimeZoneDeltas
                    currentTime={currentTime}
                    localTime={localTime}
                    showLocalInfo={showLocalInfo}
                  />
                  <ActivitiesCustomisedTable
                    isSubmitted={isSubmitted}
                    activities={formattedActivityLogs}
                    planId={planId}
                    countHandler={() => setCount(count + 1)}
                    currentTime={currentTime}
                    localTime={localTime}
                    timezoneSelected={timezoneSelected}
                  />
                  <Box p={1.5}>
                    <SubmitSectionBtn
                      divider={false}
                      isSubmitted={isSubmitted}
                      description={!isSubmitted ? '' : 'Section submitted.'}
                      isLoading={isSubmitLoading}
                      onClickSubmitHandler={() =>
                        changeSectionStatus(true, planId)
                      }
                    />
                  </Box>
                  <Box pb={3}></Box>
                </>
              );
            }
            return <Loader />;
          }}
        />
      </Grid>
    </PlanContentWrapper>
  );
};
