import React from 'react';
import { Box, Typography } from '@material-ui/core';

interface IComponentProps {
  completedBy?: string;
  requireLegalReview?: boolean;
}

export const SectionStatus = ({
  completedBy,
  requireLegalReview,
}: IComponentProps) => (
  <Box display="flex">
    {completedBy && (
      <Box p={1.5} width={'50%'}>
        <Typography variant="body2" color="textSecondary">
          To be completed by
        </Typography>
        <Typography variant="h4">{completedBy}</Typography>
      </Box>
    )}
    {typeof requireLegalReview !== 'undefined' && (
      <Box p={1.5} width={'50%'}>
        <Typography variant="body2" color="textSecondary">
          Require legal review
        </Typography>
        <Typography variant="h4">
          {requireLegalReview ? 'Yes' : 'No'}
        </Typography>
      </Box>
    )}
  </Box>
);
