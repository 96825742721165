import { graphql } from 'react-relay';

export const CreateKeyThreatMutation = graphql`
  mutation CreateKeyThreatMutation($input: createKeyThreatInput!) {
    createKeyThreat(input: $input) {
      keyThreat {
        id
        type
        notes
        probability
        impact
        commentary
      }
    }
  }
`;
