import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FieldRenderProps } from 'react-final-form';
import { IFieldProps } from './types';
import { Box, Typography } from '@material-ui/core';

type Props = FieldRenderProps<string, any> & IFieldProps;

const TextFieldWrapper = (props: Props) => {
  const {
    input,
    meta,
    templateOptions: {
      label,
      elementType,
      required,
      placeholder,
      disabled,
      width,
      fullWidth,
      multiline,
      rows,
      pattern,
      description,
      heading,
      subheading,
    },
  } = props;
  const showError = meta.dirty && meta.invalid;
  return (
    <>
      {heading && (
        <Box pt={3} pb={0.5}>
          <Typography variant="h3" color="textPrimary">
            {heading}
          </Typography>
        </Box>
      )}
      {subheading && (
        <Box pb={1.5}>
          <Typography variant="body2" color="textSecondary">
            {subheading}
          </Typography>
        </Box>
      )}

      <TextField
        variant={'outlined'}
        error={showError}
        helperText={showError ? meta.error || meta.submitError : undefined}
        onChange={input.onChange}
        label={label}
        placeholder={placeholder ? placeholder : label}
        required={required}
        type={elementType}
        value={input.value !== null ? input.value : ''}
        disabled={disabled}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
        inputProps={{
          style: { minWidth: `${width}px` },
          ...(pattern && pattern.rgx && { pattern: `${pattern.rgx}` }),
          step: 1,
        }}
        InputLabelProps={{ shrink: true }}
      />
      {description && (
        <Box pt={0.25}>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </Box>
      )}
    </>
  );
};

export { TextFieldWrapper as TextField };
