/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanHeaderQueryVariables = {
    id: string;
};
export type GetPlanHeaderQueryResponse = {
    readonly activityPlan: {
        readonly id: string;
        readonly name: string | null;
        readonly supporters: ReadonlyArray<{
            readonly role: string;
            readonly employee: {
                readonly id: string;
                readonly email: string;
                readonly firstName: string | null;
                readonly lastName: string | null;
                readonly mediaObjects: ReadonlyArray<{
                    readonly content: string | null;
                } | null> | null;
            };
        } | null> | null;
    } | null;
};
export type GetPlanHeaderQuery = {
    readonly response: GetPlanHeaderQueryResponse;
    readonly variables: GetPlanHeaderQueryVariables;
};



/*
query GetPlanHeaderQuery(
  $id: ID!
) {
  activityPlan(id: $id) {
    id
    name
    supporters(role_list: ["expert_legal", "expert_cyber", "expert_security"]) {
      role
      employee {
        id
        email
        firstName
        lastName
        mediaObjects(type: "avatar") {
          content
          id
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v4 = [
        ({
            "kind": "Literal",
            "name": "role_list",
            "value": [
                "expert_legal",
                "expert_cyber",
                "expert_security"
            ]
        } as any)
    ], v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "role",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v9 = [
        ({
            "kind": "Literal",
            "name": "type",
            "value": "avatar"
        } as any)
    ], v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "content",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanHeaderQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "supporters",
                            "storageKey": "supporters(role_list:[\"expert_legal\",\"expert_cyber\",\"expert_security\"])",
                            "args": (v4 /*: any*/),
                            "concreteType": "Supporter",
                            "plural": true,
                            "selections": [
                                (v5 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "employee",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Employee",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "mediaObjects",
                                            "storageKey": "mediaObjects(type:\"avatar\")",
                                            "args": (v9 /*: any*/),
                                            "concreteType": "EmployeeMediaObject",
                                            "plural": true,
                                            "selections": [
                                                (v10 /*: any*/)
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanHeaderQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "supporters",
                            "storageKey": "supporters(role_list:[\"expert_legal\",\"expert_cyber\",\"expert_security\"])",
                            "args": (v4 /*: any*/),
                            "concreteType": "Supporter",
                            "plural": true,
                            "selections": [
                                (v5 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "employee",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Employee",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "mediaObjects",
                                            "storageKey": "mediaObjects(type:\"avatar\")",
                                            "args": (v9 /*: any*/),
                                            "concreteType": "EmployeeMediaObject",
                                            "plural": true,
                                            "selections": [
                                                (v10 /*: any*/),
                                                (v2 /*: any*/)
                                            ]
                                        }
                                    ]
                                },
                                (v2 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanHeaderQuery",
            "id": null,
            "text": "query GetPlanHeaderQuery(\n  $id: ID!\n) {\n  activityPlan(id: $id) {\n    id\n    name\n    supporters(role_list: [\"expert_legal\", \"expert_cyber\", \"expert_security\"]) {\n      role\n      employee {\n        id\n        email\n        firstName\n        lastName\n        mediaObjects(type: \"avatar\") {\n          content\n          id\n        }\n      }\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '7ee35d4fcef6a71028c46da32aca1198';
export default node;
