import React, { useState, useRef, useEffect } from 'react';
import { IFieldProps } from './types';
import { FieldRenderProps } from 'react-final-form';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AvatarWithBlob } from 'components/AvatarWithBlob';
import { modernEnvironment } from 'config/environment';
import { CURRENT_USER } from 'config/globals';
import { commitMutation, QueryRenderer } from 'react-relay';
import { UpdateMediaObjectMutation } from 'graphql/UpdateMediaObjectMutation';
import { getInitials } from 'helper/getInitials';
import { DeleteEmployeeMediaObjectsMutation } from 'graphql/DeleteEmployeeMediaObjectsMutation';
import { GetCurrentEmployeeQuery } from 'graphql/GetCurrentEmployeeQuery';
import { ValidationError } from 'components/ValidationError';
import { Loader } from 'components/Loader';

type Props = FieldRenderProps<string, any> & IFieldProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileInputUpload: {
      opacity: '0',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: 96,
      height: 96,
    },
    fileInputUploadLabel: {
      position: 'absolute',
      bottom: '-6px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    bigAvatar: {
      width: 96,
      height: 96,
      color: theme.palette.primary.main,
      boxShadow: '0 0 3px #666',
    },
    iconButton: {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
);

export const AvatarField = (props: Props) => {
  const classes = useStyles();
  const {
    input: { name, value },
    templateOptions: { disabled },
  } = props;

  const [avatarImg, setAvatarImg] = useState(
    value.content ? value.content : ''
  );

  const [avatarId, setAvatarId] = useState(value.id ? value.id : '');
  const [count, updateCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleDelete = (idToDelete: string) => {
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: idToDelete,
        },
      },
      mutation: DeleteEmployeeMediaObjectsMutation,
    });
  };

  const handleUpload = (event: any) => {
    setIsDisabled(true);

    const files = [event.target.files[0]];
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: CURRENT_USER.id,
          files: files.map(() => null),
          type: 'avatar',
        },
      },
      mutation: UpdateMediaObjectMutation,
      uploadables: {
        files,
      },
      onCompleted: (response) => {
        const {
          uploadMediaEmployee: {
            employee: { mediaObjects },
          },
        } = response;
        if (avatarId !== '') {
          handleDelete(avatarId);
        }
        setAvatarImg(mediaObjects[0].content);
        setAvatarId(mediaObjects[0].id);
        updateCount(count + 1);
        mounted.current && setIsDisabled(false);
      },
      onError: (response) => {
        mounted.current && setIsDisabled(false);
      },
    });
  };
  return (
    <QueryRenderer
      key={count}
      environment={modernEnvironment}
      query={GetCurrentEmployeeQuery}
      variables={{
        // TODO: The current user to be fetched from the HRT API, through the headers of the served Collaboration Dashboard App
        id: CURRENT_USER.id,
      }}
      render={({ error, props }) => {
        if (error) {
          return <ValidationError>{error[0].message}</ValidationError>;
        } else if (props) {
          const { employee } = props;
          if (
            employee.mediaObjects &&
            employee.mediaObjects[0] &&
            employee.mediaObjects[0].content
          ) {
            setAvatarImg(employee.mediaObjects[0].content);
            setAvatarId(employee.mediaObjects[0].id);
          }

          return (
            <Box position="relative" display="flex" justifyContent="center">
              <AvatarWithBlob className={classes.bigAvatar} src={avatarImg}>
                {getInitials(employee.firstName, employee.lastName)}
              </AvatarWithBlob>
              {!!!disabled && (
                <>
                  <input
                    accept="image/*"
                    className={classes.fileInputUpload}
                    onChange={handleUpload}
                    id={`${name}-button-file`}
                    type="file"
                    disabled={isDisabled}
                  />

                  <label
                    htmlFor={`${name}-button-file`}
                    className={classes.fileInputUploadLabel}
                  >
                    <IconButton
                      className={classes.iconButton}
                      disableFocusRipple
                      disableRipple
                      aria-label="upload picture"
                      component="span"
                    >
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </>
              )}
            </Box>
          );
        }
        return <Loader />;
      }}
    />
  );
};
