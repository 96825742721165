import { graphql } from 'react-relay';

export const GetPlanRisksQuery = graphql`
  query GetPlanRisksQuery($region: String, $location_list: [String]) {
    locations(region: $region, location_list: $location_list) {
      region
      location
      securityRisk
      legalRisk
      cyberRisk
    }
  }
`;
