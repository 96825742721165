import { graphql } from 'react-relay';

export const UpdatePlanKeyThreatMutation = graphql`
  mutation UpdatePlanKeyThreatMutation($input: updateKeyThreatInput!) {
    updateKeyThreat(input: $input) {
      keyThreat {
        id
        type
        notes
      }
    }
  }
`;
