/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanTileQueryVariables = {
    status?: string | null;
};
export type GetPlanTileQueryResponse = {
    readonly activityPlans: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
        readonly durationFrom: string;
        readonly durationTo: string;
        readonly region: string;
        readonly submissionProgress: unknown;
        readonly itineraryLegs: ReadonlyArray<{
            readonly id: string;
            readonly travelDepartureTime: string | null;
            readonly travelArrivalTime: string | null;
        } | null> | null;
        readonly participants: ReadonlyArray<{
            readonly id: string;
            readonly firstName: string | null;
            readonly lastName: string | null;
            readonly role: string;
            readonly email: string | null;
            readonly mediaObjects: ReadonlyArray<{
                readonly id: string;
                readonly content: string | null;
            } | null> | null;
        } | null> | null;
        readonly supporters: ReadonlyArray<{
            readonly position: string;
            readonly approvedStatus: boolean | null;
            readonly employee: {
                readonly firstName: string | null;
                readonly lastName: string | null;
                readonly roleInTrip: string | null;
                readonly department: string | null;
            };
        } | null> | null;
    } | null> | null;
};
export type GetPlanTileQuery = {
    readonly response: GetPlanTileQueryResponse;
    readonly variables: GetPlanTileQueryVariables;
};



/*
query GetPlanTileQuery(
  $status: String
) {
  activityPlans(status: $status) {
    id
    name
    durationFrom
    durationTo
    region
    submissionProgress
    itineraryLegs {
      id
      travelDepartureTime
      travelArrivalTime
    }
    participants {
      id
      firstName
      lastName
      role
      email
      mediaObjects(type: "avatar") {
        id
        content
      }
    }
    supporters(role: "approver") {
      position
      approvedStatus
      employee {
        firstName
        lastName
        roleInTrip
        department
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "status",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "status",
            "variableName": "status"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "durationFrom",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "durationTo",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "region",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "submissionProgress",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "itineraryLegs",
        "storageKey": null,
        "args": null,
        "concreteType": "ItineraryLeg",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "travelDepartureTime",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "travelArrivalTime",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "participants",
        "storageKey": null,
        "args": null,
        "concreteType": "Participant",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            (v9 /*: any*/),
            (v10 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "role",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "mediaObjects",
                "storageKey": "mediaObjects(type:\"avatar\")",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "type",
                        "value": "avatar"
                    }
                ],
                "concreteType": "ParticipantMediaObject",
                "plural": true,
                "selections": [
                    (v2 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "content",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any), v12 = [
        ({
            "kind": "Literal",
            "name": "role",
            "value": "approver"
        } as any)
    ], v13 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "position",
        "args": null,
        "storageKey": null
    } as any), v14 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "approvedStatus",
        "args": null,
        "storageKey": null
    } as any), v15 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "roleInTrip",
        "args": null,
        "storageKey": null
    } as any), v16 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "department",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanTileQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlans",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": true,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v11 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "supporters",
                            "storageKey": "supporters(role:\"approver\")",
                            "args": (v12 /*: any*/),
                            "concreteType": "Supporter",
                            "plural": true,
                            "selections": [
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "employee",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Employee",
                                    "plural": false,
                                    "selections": [
                                        (v9 /*: any*/),
                                        (v10 /*: any*/),
                                        (v15 /*: any*/),
                                        (v16 /*: any*/)
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanTileQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlans",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": true,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v11 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "supporters",
                            "storageKey": "supporters(role:\"approver\")",
                            "args": (v12 /*: any*/),
                            "concreteType": "Supporter",
                            "plural": true,
                            "selections": [
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "employee",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Employee",
                                    "plural": false,
                                    "selections": [
                                        (v9 /*: any*/),
                                        (v10 /*: any*/),
                                        (v15 /*: any*/),
                                        (v16 /*: any*/),
                                        (v2 /*: any*/)
                                    ]
                                },
                                (v2 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanTileQuery",
            "id": null,
            "text": "query GetPlanTileQuery(\n  $status: String\n) {\n  activityPlans(status: $status) {\n    id\n    name\n    durationFrom\n    durationTo\n    region\n    submissionProgress\n    itineraryLegs {\n      id\n      travelDepartureTime\n      travelArrivalTime\n    }\n    participants {\n      id\n      firstName\n      lastName\n      role\n      email\n      mediaObjects(type: \"avatar\") {\n        id\n        content\n      }\n    }\n    supporters(role: \"approver\") {\n      position\n      approvedStatus\n      employee {\n        firstName\n        lastName\n        roleInTrip\n        department\n        id\n      }\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'ad7439126189760c4ba2d07dd8f2b63d';
export default node;
