/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanParticipantInfoQueryVariables = {
    id: string;
};
export type GetPlanParticipantInfoQueryResponse = {
    readonly activityPlan: {
        readonly statusParticipants: string | null;
        readonly statusItineraryLegs: string;
        readonly participants: ReadonlyArray<{
            readonly id: string;
            readonly firstName: string | null;
            readonly lastName: string | null;
            readonly status: string | null;
            readonly role: string;
            readonly origin: string;
            readonly email: string | null;
        } | null> | null;
    } | null;
};
export type GetPlanParticipantInfoQuery = {
    readonly response: GetPlanParticipantInfoQueryResponse;
    readonly variables: GetPlanParticipantInfoQueryVariables;
};



/*
query GetPlanParticipantInfoQuery(
  $id: ID!
) {
  activityPlan(id: $id) {
    statusParticipants
    statusItineraryLegs
    participants {
      id
      firstName
      lastName
      status
      role
      origin
      email
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "statusParticipants",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "statusItineraryLegs",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "participants",
        "storageKey": null,
        "args": null,
        "concreteType": "Participant",
        "plural": true,
        "selections": [
            (v4 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "role",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "origin",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanParticipantInfoQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v5 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanParticipantInfoQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v5 /*: any*/),
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanParticipantInfoQuery",
            "id": null,
            "text": "query GetPlanParticipantInfoQuery(\n  $id: ID!\n) {\n  activityPlan(id: $id) {\n    statusParticipants\n    statusItineraryLegs\n    participants {\n      id\n      firstName\n      lastName\n      status\n      role\n      origin\n      email\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '675d4922be1e90f3bb4c5a63561b8c29';
export default node;
