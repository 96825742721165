import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import departmentRoles from 'data/departmentRoles.json';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';

interface IComponentProps {
  approvers: {};
}

const getRoleLabel = (pos) => {
  let roleLabel = 'N/A';
  departmentRoles.forEach((department) => {
    Object.keys(department).forEach((level) => {
      if (department[level].position === pos) {
        roleLabel = department[level].label;
      }
    });
  });
  return roleLabel;
};

export const PendingApprovalTile = ({ approvers }: IComponentProps) => (
  <Grid
    container
    justify="flex-start"
    direction="column"
    alignItems="flex-start"
  >
    <Box p={0.5} pb={1}>
      <Typography variant="body1">Approval status</Typography>
      {approvers.map((approver, index) => (
        <Typography
          key={index}
          variant="body2"
          color={approver.approvedStatus ? 'textSecondary' : 'textPrimary'}
        >
          {`${approver.employee.firstName} ${
            approver.employee.lastName
          } - ${getRoleLabel(approver.position)}`}{' '}
          {approver.approvedStatus && (
            <CheckCircleIcon fontSize="inherit" style={{ color: green[500] }} />
          )}
        </Typography>
      ))}
    </Box>
  </Grid>
);
