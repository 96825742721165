import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Grid, Divider } from '@material-ui/core';
import { SectionStatus } from 'components/SectionStatus';
import { PlanName } from 'components/Plan/PlanName';
import { QueryRenderer, commitMutation } from 'react-relay';
import { Loader } from 'components/Loader';
import { FormBuilder } from 'components';
import { ValidationError } from 'components/ValidationError';
import { modernEnvironment } from 'config/environment';
import { GetPlanRiskDeterminationQuery } from 'graphql/GetPlanRiskDeterminationQuery';
import { UpdatePlanRiskDeterminationMutation } from 'graphql/UpdatePlanRiskDeterminationMutation';
import RiskDeterminationForm from 'data/plan/risk-determination.json';
import RiskDeterminationFormDisabled from 'data/plan/disabled/risk-determination.json';
import RiskDeterminationForm2 from 'data/plan/risk-determination2.json';
import RiskDeterminationForm2Disabled from 'data/plan/disabled/risk-determination2.json';
import RiskDeterminationForm3 from 'data/plan/risk-determination3.json';
import { commitMutationForm } from 'helper/commitMutationForm';
import { KeyThreats } from './risk-determination/KeyThreats';
import { PlanContentWrapper } from 'components/Plan/PlanContentWrapper';
import { CustomWarning } from 'components/Plan/CustomWarning';
import { SubmitSectionBtn } from 'components/Buttons/SubmitSectionBtn';
import { UpdateRiskDeterminationStatusMutation } from 'graphql/UpdateRiskDeterminationStatusMutation';
import { LocationRisks } from 'components/Plan/LocationRisks';

interface IComponentProps {
  planId: string;
}

export const RiskDetermination = ({ planId }: IComponentProps) => {
  const [count, updateCount] = useState(0);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const changeSectionStatus = (newStatus, riskId) => {
    setIsSubmitLoading(true);
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: riskId,
        },
      },
      mutation: UpdateRiskDeterminationStatusMutation,
      updater: (store) => {
        const mutation = store.getRootField('submitRiskDetermination');
        const section = mutation.getLinkedRecord('riskDetermination');
        section.setValue(newStatus, 'submittedStatus');
      },
      onCompleted: (response) => {
        mounted.current && setIsSubmitLoading(false);
      },
      onError: (response) => {
        mounted.current && setIsSubmitted(newStatus);
        mounted.current && setIsSubmitLoading(false);
      },
    });
  };

  return (
    <PlanContentWrapper>
      <Grid container direction="column">
        <PlanName planId={planId}></PlanName>
        <Box p={1.5} pb={0.5}>
          <Typography variant="h1" component="h2">
            Risk Determination
          </Typography>
        </Box>
        <Box p={1.5} pb={3.5}>
          <Typography variant="body1">
            Critical information, relevant incidents, key threats, environmental
            factors, risk levels
          </Typography>
        </Box>
        <SectionStatus
          completedBy="Global Security"
          requireLegalReview={true}
        ></SectionStatus>
        <Box pb={3}>
          <Divider />
        </Box>
        <Box>
          <QueryRenderer
            environment={modernEnvironment}
            query={GetPlanRiskDeterminationQuery}
            variables={{
              id: `/api/activity_plans/${planId}`,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error[0].message}</ValidationError>;
              } else if (props) {
                const { activityPlan } = props;
                const {
                  riskDetermination,
                  statusItineraryLegs,
                  statusParticipants,
                } = activityPlan;

                let keyThreats;
                if (riskDetermination !== null) {
                  keyThreats = riskDetermination.keyThreats;
                }

                if (
                  statusItineraryLegs === 'development' ||
                  statusParticipants === 'development'
                ) {
                  return (
                    <CustomWarning>
                      Participant Info and Itinerary sections need to be
                      submitted, in order to view/edit this section.
                    </CustomWarning>
                  );
                }

                setIsSubmitted(riskDetermination.submittedStatus);
                return (
                  <>
                    <FormBuilder
                      formObject={
                        !isSubmitted
                          ? RiskDeterminationForm
                          : RiskDeterminationFormDisabled
                      }
                      initialValues={riskDetermination}
                      onSubmit={(values) => {
                        if (isSubmitted) {
                          return;
                        }
                        commitMutationForm({
                          values: {
                            id: riskDetermination.id,
                            ...values,
                          },
                          mutation: UpdatePlanRiskDeterminationMutation,
                        });
                      }}
                    />
                    {keyThreats && (
                      <KeyThreats
                        keyThreats={keyThreats}
                        riskDeterminationId={riskDetermination.id}
                        isSubmitted={isSubmitted}
                      />
                    )}
                    <FormBuilder
                      formObject={
                        !isSubmitted
                          ? RiskDeterminationForm2
                          : RiskDeterminationForm2Disabled
                      }
                      initialValues={riskDetermination}
                      onSubmit={(values) => {
                        if (isSubmitted) {
                          return;
                        }
                        commitMutationForm({
                          values: {
                            id: riskDetermination.id,
                            ...values,
                          },
                          mutation: UpdatePlanRiskDeterminationMutation,
                          onCompleteCallback: (response, errors) => {
                            if (
                              mounted.current &&
                              values.assignedInternalPhysicalRiskLevel
                            ) {
                              updateCount(count + 1);
                            }
                          },
                        });
                      }}
                    />
                    <Box pt={3} pb={3} pl={1.5}>
                      <Typography variant="h3" color="textPrimary">
                        Location Driven Risk Levels
                      </Typography>
                    </Box>
                    <Box>
                      <LocationRisks planId={planId} />
                    </Box>
                    <Box pt={3} pb={3} pl={1.5}>
                      <Typography variant="h3" color="textPrimary">
                        Summary Risk Level
                      </Typography>
                    </Box>
                  </>
                );
              }
              return <Loader />;
            }}
          />
          <QueryRenderer
            key={count}
            environment={modernEnvironment}
            query={GetPlanRiskDeterminationQuery}
            variables={{
              id: `/api/activity_plans/${planId}`,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error[0].message}</ValidationError>;
              } else if (props) {
                const { activityPlan } = props;
                const {
                  riskDetermination,
                  statusItineraryLegs,
                  statusParticipants,
                } = activityPlan;
                if (
                  statusItineraryLegs === 'development' ||
                  statusParticipants === 'development'
                ) {
                  return <></>;
                }
                setIsSubmitted(riskDetermination.submittedStatus);
                return (
                  <>
                    <FormBuilder
                      formObject={RiskDeterminationForm3}
                      initialValues={riskDetermination}
                      onSubmit={(values) => {
                        // No actions needed, just visualising overall risk level
                      }}
                    />
                    <SubmitSectionBtn
                      isSubmitted={isSubmitted}
                      description={!isSubmitted ? '' : 'Section submitted.'}
                      isLoading={isSubmitLoading}
                      onClickSubmitHandler={() =>
                        changeSectionStatus(true, riskDetermination.id)
                      }
                    />
                  </>
                );
              }
              return <Loader />;
            }}
          />
        </Box>
      </Grid>
    </PlanContentWrapper>
  );
};
