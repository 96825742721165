import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { QueryRenderer, commitMutation } from 'react-relay';
import { GetPlanItineraryLegsQuery } from 'graphql/GetPlanItineraryLegsQuery';
import { modernEnvironment } from 'config/environment';
import { LegTile } from 'components/Plan/Legs/LegTile';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import { clearId } from 'helper/clearId';
import { AddLegBtn } from 'components/Buttons/AddLegBtn';
import * as moment from 'moment';
import { SectionStatus } from 'components/SectionStatus';
import { PlanName } from 'components/Plan/PlanName';
import { PlanContentWrapper } from 'components/Plan/PlanContentWrapper';
import { CustomWarning } from 'components/Plan/CustomWarning';
import { SubmitSectionBtn } from 'components/Buttons/SubmitSectionBtn';
import { UpdateItineraryStatusMutation } from 'graphql/UpdateItineraryStatusMutation';
import { LocationRisks } from 'components/Plan/LocationRisks';

interface IComponentProps {
  planId: string;
}

export const Itinerary = ({ planId }: IComponentProps) => {
  const basePlanRoute = `/plan/${planId}/itinerary`;

  const mounted = useRef(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const changeSectionStatus = (newStatus, planId) => {
    setIsSubmitLoading(true);
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: `/api/activity_plans/${planId}`,
        },
      },
      mutation: UpdateItineraryStatusMutation,
      updater: (store) => {
        const mutation = store.getRootField('submitItineratyLegsActivityPlan');
        const plan = mutation.getLinkedRecord('activityPlan');
        plan.setValue(newStatus, 'statusItineraryLegs');
      },
      onCompleted: (response) => {
        mounted.current && setIsSubmitted(newStatus);
        mounted.current && setIsSubmitLoading(false);
      },
      onError: (response) => {
        mounted.current && setIsSubmitLoading(false);
      },
    });
  };

  return (
    <PlanContentWrapper>
      <Grid container direction="column">
        <PlanName planId={planId}></PlanName>
        <Box p={1.5} pb={0.5}>
          <Typography variant="h1" component="h2">
            Itinerary
          </Typography>
        </Box>
        <Box p={1.5} pb={3.5}>
          <Typography variant="body1">
            Itinerary To be completed for each section of the trip. Periods
            documented may cover extended periods of time or single days
            depending on needed flexibility, consistency of activity from day to
            day or complexity and risk. Global Security is available to consult
            on itinerary completion. In all situations, the itinerarty should
            outline intent and qualify when and how flexibility is needed.
            Approved adjustments should be captured on a live basis with
            specificity and timeliness directly proportionate to level of risk.
          </Typography>
        </Box>
        <SectionStatus
          completedBy="Security Expert"
          requireLegalReview={true}
        ></SectionStatus>
        <LocationRisks planId={planId} />
        <Box p={1.5}>
          <QueryRenderer
            environment={modernEnvironment}
            query={GetPlanItineraryLegsQuery}
            variables={{
              id: `/api/activity_plans/${planId}`,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error.message}</ValidationError>;
              } else if (props) {
                const {
                  activityPlan: {
                    itineraryLegs,
                    statusParticipants,
                    statusItineraryLegs,
                  },
                } = props;
                const itineraryLegsSorted = [...itineraryLegs].sort((a, b) =>
                  a !== null &&
                  b !== null &&
                  moment(a.travelDepartureTime).isBefore(b.travelDepartureTime)
                    ? -1
                    : 1
                );
                if (statusParticipants === 'development') {
                  return (
                    <CustomWarning>
                      Participant Info section needs to be submitted, in order
                      to view/edit the Itinerary.
                    </CustomWarning>
                  );
                }
                setIsSubmitted(
                  statusItineraryLegs !== 'development' ? true : false
                );
                return (
                  <>
                    <Grid container>
                      {itineraryLegsSorted.map((leg, index) => {
                        if (!leg || leg === null) {
                          return <Fragment key={index}></Fragment>;
                        }
                        return (
                          <LegTile
                            key={leg.id}
                            index={index + 1}
                            departureTime={leg.travelDepartureTime}
                            arrivalTime={leg.travelArrivalTime}
                            to={`${basePlanRoute}/${index + 1}/${clearId(
                              leg.id
                            )}`}
                          />
                        );
                      })}
                    </Grid>
                    {!isSubmitted && (
                      <Box py={1.5}>
                        <AddLegBtn
                          basePlanRoute={basePlanRoute}
                          planId={planId}
                          legNumber={parseInt(itineraryLegs.length, 10) + 1}
                        />
                      </Box>
                    )}
                    {itineraryLegsSorted.length > 0 && (
                      <Box py={1.5}>
                        <SubmitSectionBtn
                          divider={false}
                          isSubmitted={isSubmitted}
                          description={!isSubmitted ? '' : 'Section submitted.'}
                          isLoading={isSubmitLoading}
                          onClickSubmitHandler={() =>
                            changeSectionStatus(true, planId)
                          }
                        />
                      </Box>
                    )}
                  </>
                );
              }
              return <Loader />;
            }}
          />
        </Box>
      </Grid>
    </PlanContentWrapper>
  );
};
