import React, { useState, Fragment } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { Loader } from 'components/Loader';
import { QueryRenderer } from 'react-relay';
import { ValidationError } from 'components/ValidationError';
import { PlanName } from 'components/Plan/PlanName';
import { SimpleTile } from 'components/SimpleTile';
import { SectionStatus } from 'components/SectionStatus';
import { modernEnvironment } from 'config/environment';
import { GetPlanParticipantInfoQuery } from 'graphql/GetPlanParticipantInfoQuery';
import { clearId } from 'helper/clearId';
import { AddParticipantDialog } from 'pages/plan/participants/AddParticipantDialog';
import { PlanContentWrapper } from 'components/Plan/PlanContentWrapper';

interface IComponentProps {
  planId: string;
}

export const ParticipantInfo = ({ planId }: IComponentProps) => {
  const [count, setCount] = useState(0);

  const basePlanRoute = `/plan/${planId}/participant-info`;
  return (
    <PlanContentWrapper>
      <Grid container direction="column">
        <PlanName planId={planId}></PlanName>
        <Box p={1.5} pb={0.5}>
          <Typography variant="h1" component="h2">
            Personal information
          </Typography>
        </Box>
        <Box p={1.5} pb={3.5}>
          <Typography variant="body1">
            For each individual involved regardless of employment.
          </Typography>
        </Box>
        <SectionStatus
          completedBy="Participants"
          requireLegalReview={true}
        ></SectionStatus>
        <Box p={1.5}>
          <QueryRenderer
            key={count}
            environment={modernEnvironment}
            query={GetPlanParticipantInfoQuery}
            variables={{
              id: `/api/activity_plans/${planId}`,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error.message}</ValidationError>;
              } else if (props) {
                const {
                  activityPlan: {
                    participants,
                    statusParticipants,
                    statusItineraryLegs,
                  },
                } = props;

                const participantsSorted = [...participants].sort((a, b) =>
                  a !== null && a.role && a.role === 'primary_participant'
                    ? -1
                    : 1
                );

                return (
                  <>
                    <Grid container>
                      {participantsSorted.map((participant, index) => {
                        if (!participant || participant === null) {
                          return <Fragment key={index}></Fragment>;
                        }
                        return (
                          <SimpleTile
                            key={participant.id}
                            to={`${basePlanRoute}/${index + 1}/${clearId(
                              participant.id
                            )}`}
                          >
                            <Box display="flex" flex={1}>
                              <Box flexBasis={'140px'}>
                                <Typography variant="body1">
                                  {participant.role === 'primary_participant'
                                    ? 'Primary participant'
                                    : `Participant ${index + 1}`}
                                </Typography>
                              </Box>
                              <Box flex={1}>
                                {participant.firstName} {participant.lastName}
                                {participant.origin === 'freelancer' &&
                                  ` (${participant.origin})`}
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {participant.status === 'development'
                                    ? 'In Process'
                                    : 'Complete'}
                                </Typography>
                              </Box>
                            </Box>
                          </SimpleTile>
                        );
                      })}
                      <Box pb={3}>
                        {statusItineraryLegs === 'development' && (
                          <AddParticipantDialog
                            planId={planId}
                            countHandler={() => setCount(count + 1)}
                            addedParticipants={participants}
                          />
                        )}
                      </Box>
                      <Box width={'100%'} pb={3}>
                        {statusParticipants !== 'development' && (
                          <Typography color="textSecondary" variant="body2">
                            All participant information is complete.
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  </>
                );
              }
              return <Loader />;
            }}
          />
        </Box>
      </Grid>
    </PlanContentWrapper>
  );
};
