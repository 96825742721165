import { graphql } from 'react-relay';

export const GetPlanBusinessIntentQuery = graphql`
  query GetPlanBusinessIntentQuery($id: ID!) {
    activityPlan(id: $id) {
      id
      businessIntent
      statusBusinessIntent
    }
  }
`;
