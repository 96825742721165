import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Divider,
  Card,
  CardActions,
  Button,
  makeStyles,
} from '@material-ui/core';
import { ContentWrapper } from 'components/ContentWrapper';
import { QueryRenderer } from 'react-relay';
import { Loader } from '../../components/Loader';
import { modernEnvironment } from 'config/environment';
import { GetEmployeeQuery } from '../../graphql/GetEmployeeQuery';
import { ValidationError } from 'components/ValidationError';
import { CURRENT_USER } from 'config/globals';
import { InformationDetails } from 'components/PersonalDetails/InformationDetails';
import { ModalWithFormBuilder } from 'components/ModalWithFormBuilder/ModalWithFormBuilder';
import {
  PERSONAL_DETAILS_PROFILE,
  PERSONAL_DETAILS_PHYSICAL,
  PERSONAL_DETAILS_PROOFOFLIFE,
  PERSONAL_DETAILS_EMERGENCYCONTACTS,
  PERSONAL_DETAILS_TRANSFORMERS,
} from 'data/personal-details';

const useStyles = makeStyles((theme) => ({
  bigAvatar: {
    zIndex: 1200,
  },
  dashboardHeader: {
    padding: theme.spacing(0.5, 1, 0.5, 3),
    color: theme.palette.primary.contrastText,
  },
  grid: {
    margin: theme.spacing(2, 0, 0),
    columns: '2 200px',
    display: 'block',
    width: '100%',
  },
  infoCard: {
    padding: theme.spacing(2, 0, 0),
    margin: theme.spacing(0, 1, 1, 1),
    display: 'inline-block',
    width: 'calc(100% - 16px)',
  },
  infoCardTitle: {
    fontSize: '26px',
    fontWeight: 400,
    margin: theme.spacing(0, 1, 2, 0),
    padding: theme.spacing(1, 3),
  },
  infoCardContent: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3, 3, 1),
  },
  col2x: {
    gridRowEnd: 'span 2',
  },
  col3x: {
    gridRowEnd: 'span 3',
  },
}));

export const PersonalDetails = () => {
  const classes = useStyles();
  const [editId, setEditId] = useState();
  const [modalTitle, setModalTitle] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [count, setCount] = useState(0);

  return (
    <ContentWrapper>
      <Box>
        <Box p={1.5}>
          <Typography variant="h1" component="h2">
            Personal details
          </Typography>
          <Grid
            container
            justify="flex-start"
            alignItems="flex-start"
            className={classes.grid}
          >
            <QueryRenderer
              key={count}
              environment={modernEnvironment}
              query={GetEmployeeQuery}
              variables={{
                // TODO: The current user to be fetched from the HRT API, through the headers of the served Collaboration Dashboard App
                id: CURRENT_USER.id,
              }}
              render={({ error, props }) => {
                const handleEditClick = (id: string, title: string) => {
                  setEditId(id);
                  setModalTitle(title);
                  setOpenModal(true);
                };
                const handelCloseModal = () => {
                  setOpenModal(false);
                  if (
                    editId === 'emergencyContactsAlternate' ||
                    editId === 'emergencyContactsPrimary'
                  ) {
                    setCount(count + 1);
                  }
                };

                if (error) {
                  return <ValidationError>{error[0].message}</ValidationError>;
                } else if (props) {
                  const { employee } = props;
                  const { employeeEmergencyContacts } = employee;
                  const primaryContact = employeeEmergencyContacts.find(
                    (o) => o.type === 'primary'
                  );
                  const alternateContact = employeeEmergencyContacts.find(
                    (o) => o.type === 'alternate'
                  );
                  return (
                    <>
                      <Card className={classes.infoCard}>
                        <Box>
                          <Typography
                            variant="h3"
                            className={classes.infoCardTitle}
                          >
                            Profile
                          </Typography>
                        </Box>
                        <Divider />
                        <Box className={classes.infoCardContent}>
                          <InformationDetails
                            items={PERSONAL_DETAILS_PROFILE}
                            data={employee}
                            transformers={PERSONAL_DETAILS_TRANSFORMERS}
                          />
                        </Box>
                        <Divider />
                        <Box>
                          <CardActions>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() =>
                                handleEditClick('personalDetails', 'Profile')
                              }
                            >
                              Edit
                            </Button>
                          </CardActions>
                        </Box>
                      </Card>
                      <Card className={classes.infoCard}>
                        <Box>
                          <Typography
                            variant="h3"
                            className={classes.infoCardTitle}
                          >
                            Proof of Life Information
                          </Typography>
                        </Box>
                        <Divider />
                        <Box className={classes.infoCardContent}>
                          <InformationDetails
                            items={PERSONAL_DETAILS_PROOFOFLIFE}
                            data={employee}
                            transformers={PERSONAL_DETAILS_TRANSFORMERS}
                          />
                        </Box>
                        <Divider />
                        <Box>
                          <CardActions>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() =>
                                handleEditClick(
                                  'proofOfLife',
                                  'Proof of Life Information'
                                )
                              }
                            >
                              Edit
                            </Button>
                          </CardActions>
                        </Box>
                      </Card>
                      {openModal && (
                        <ModalWithFormBuilder
                          title={modalTitle}
                          openModal={openModal}
                          editId={editId ? editId : ''}
                          closeModal={handelCloseModal}
                        />
                      )}
                      <Card className={classes.infoCard}>
                        <Box>
                          <Typography
                            variant="h3"
                            className={classes.infoCardTitle}
                          >
                            Physical/Medical Details
                          </Typography>
                        </Box>
                        <Divider />
                        <Box className={classes.infoCardContent}>
                          <InformationDetails
                            items={PERSONAL_DETAILS_PHYSICAL}
                            data={employee}
                            transformers={PERSONAL_DETAILS_TRANSFORMERS}
                          />
                        </Box>
                        <Divider />
                        <Box>
                          <CardActions>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() =>
                                handleEditClick(
                                  'physicalDetails',
                                  'Physical/Medical Details'
                                )
                              }
                            >
                              Edit
                            </Button>
                          </CardActions>
                        </Box>
                      </Card>
                      <Card className={classes.infoCard}>
                        <Box>
                          <Typography
                            variant="h3"
                            className={classes.infoCardTitle}
                          >
                            Primary Emergency Contact
                          </Typography>
                        </Box>
                        <Divider />
                        <Box className={classes.infoCardContent}>
                          <InformationDetails
                            items={PERSONAL_DETAILS_EMERGENCYCONTACTS}
                            data={primaryContact}
                            transformers={PERSONAL_DETAILS_TRANSFORMERS}
                          />
                        </Box>
                        <Divider />
                        <Box>
                          <CardActions>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() =>
                                handleEditClick(
                                  'emergencyContactsPrimary',
                                  'Primary Emergency Contact'
                                )
                              }
                            >
                              Edit
                            </Button>
                          </CardActions>
                        </Box>
                      </Card>
                      <Card className={classes.infoCard}>
                        <Box>
                          <Typography
                            variant="h3"
                            className={classes.infoCardTitle}
                          >
                            Alternate Emergency Contact
                          </Typography>
                        </Box>
                        <Divider />
                        <Box className={classes.infoCardContent}>
                          <InformationDetails
                            items={PERSONAL_DETAILS_EMERGENCYCONTACTS}
                            data={alternateContact}
                            transformers={PERSONAL_DETAILS_TRANSFORMERS}
                          />
                        </Box>
                        <Divider />
                        <Box>
                          <CardActions>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() =>
                                handleEditClick(
                                  'emergencyContactsAlternate',
                                  'Alternate Emergency Contact'
                                )
                              }
                            >
                              Edit
                            </Button>
                          </CardActions>
                        </Box>
                      </Card>
                    </>
                  );
                }
                return <Loader />;
              }}
            />
          </Grid>
        </Box>
      </Box>
    </ContentWrapper>
  );
};
