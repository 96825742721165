import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import BusinessIntentForm from 'data/plan/business-intent.json';
import BusinessIntentFormDisabled from 'data/plan//disabled/business-intent.json';
import { FormBuilder } from 'components';
import { modernEnvironment } from 'config/environment';
import { UpdateActivityPlan } from 'graphql/UpdateActivityPlanMutation';
import { GetPlanBusinessIntentQuery } from 'graphql/GetPlanBusinessIntentQuery';
import { QueryRenderer, commitMutation } from 'react-relay';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import { PlanName } from 'components/Plan/PlanName';
import { SectionStatus } from 'components/SectionStatus';
import { PlanContentWrapper } from 'components/Plan/PlanContentWrapper';
import { SubmitSectionBtn } from 'components/Buttons/SubmitSectionBtn';
import { UpdateBusinessIntentStatusMutation } from 'graphql/UpdateBusinessIntentStatusMutation';

interface IComponentProps {
  planId: string;
}
export const BusinessIntent = ({ planId }: IComponentProps) => {
  const mounted = useRef(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const changeSectionStatus = (newStatus) => {
    setIsSubmitLoading(true);
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: `/api/activity_plans/${planId}`,
          status: newStatus,
        },
      },
      mutation: UpdateBusinessIntentStatusMutation,
      updater: (store) => {
        const mutation = store.getRootField('submitBusinessIntentActivityPlan');
        const plan = mutation.getLinkedRecord('activityPlan');
        plan.setValue(newStatus, 'statusBusinessIntent');
      },
      onCompleted: (response) => {
        mounted.current &&
          setIsSubmitted(newStatus === 'pending_approval' ? true : false);
        mounted.current && setIsSubmitLoading(false);
      },
      onError: (response) => {
        mounted.current && setIsSubmitLoading(false);
      },
    });
  };

  return (
    <PlanContentWrapper>
      <Grid container direction="column">
        <PlanName planId={planId}></PlanName>
        <Box p={1.5} pb={0.5}>
          <Typography variant="h1" component="h2">
            Business Intent / Editorial Proposal
          </Typography>
        </Box>
        <Box p={1.5} pb={3.5}>
          <Typography variant="body1">
            Overview of business or reporting intent to provide context and
            enable additional insights which may be relevant to security
            planning. At a minimum, this should contain the same information
            used to justify the travel or reporting effort.
          </Typography>
        </Box>
        <SectionStatus
          completedBy="Participants"
          requireLegalReview={false}
        ></SectionStatus>
        <Box>
          <QueryRenderer
            environment={modernEnvironment}
            query={GetPlanBusinessIntentQuery}
            variables={{
              id: `/api/activity_plans/${planId}`,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error[0].message}</ValidationError>;
              } else if (props) {
                const { activityPlan } = props;
                const {
                  statusBusinessIntent,
                  ...activityPlanWithoutStatus
                } = activityPlan;
                setIsSubmitted(
                  statusBusinessIntent !== 'development' ? true : false
                );
                return (
                  <>
                    <FormBuilder
                      formObject={
                        !isSubmitted
                          ? BusinessIntentForm
                          : BusinessIntentFormDisabled
                      }
                      initialValues={activityPlanWithoutStatus}
                      onValidate={(values: {}) => {
                        const errors = {};
                        if (values.businessIntent === null) {
                          errors.businessIntent = 'Required';
                          setIsButtonEnabled(false);
                        } else {
                          setIsButtonEnabled(true);
                        }
                        return errors;
                      }}
                      onSubmit={(values) => {
                        UpdateActivityPlan(modernEnvironment, {
                          id: `/api/activity_plans/${planId}`,
                          ...values,
                        });
                      }}
                    />
                    <SubmitSectionBtn
                      isValid={isButtonEnabled}
                      isSubmitted={isSubmitted}
                      description={
                        isSubmitted
                          ? 'Section submitted, click "Update" to change.'
                          : ''
                      }
                      isLoading={isSubmitLoading}
                      onClickSubmitHandler={() =>
                        changeSectionStatus('pending_approval')
                      }
                      onClickUpdateHandler={() =>
                        changeSectionStatus('development')
                      }
                    />
                  </>
                );
              }
              return <Loader />;
            }}
          />
        </Box>
      </Grid>
    </PlanContentWrapper>
  );
};
