import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Icon, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface ILinkProps {
  to: string;
  children: ReactNode;
  disabled?: boolean;
  markcompleted?: boolean;
  selected?: boolean;
  disableGutters?: boolean;
  onClick(): void;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    position: 'relative',
    top: 3,
    left: 6,
  },
}));

export const PlanNavigationLink = React.forwardRef((props: ILinkProps, ref) => {
  const classes = useStyles();
  const {
    to,
    children,
    disabled,
    markcompleted,
    selected,
    disableGutters,
    onClick
  } = props;
  return (
    <Link to={!disabled ? to : '#'} ref={ref} onClick={onClick}>
      <MenuItem
        disableGutters={disableGutters}
        style={disableGutters && { backgroundColor: 'transparent' }}
      >
        <Typography
          color={
            !disabled ? (selected ? 'primary' : 'textPrimary') : 'textSecondary'
          }
          variant="h5"
        >
          {children}
          {markcompleted && (
            <Icon fontSize="inherit" className={classes.icon}>
              check_circle
            </Icon>
          )}
        </Typography>
      </MenuItem>
    </Link>
  );
});
