/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type updateEmployeeInput = {
    readonly id: string;
    readonly mediaObjects?: ReadonlyArray<string | null> | null;
    readonly employeeEmergencyContacts?: ReadonlyArray<string | null> | null;
    readonly firstName?: string | null;
    readonly middleName?: string | null;
    readonly lastName?: string | null;
    readonly mobilePhoneNumbers?: unknown | null;
    readonly satellitePhoneNumbers?: unknown | null;
    readonly vismoProfile?: boolean | null;
    readonly birthDetails?: string | null;
    readonly nationality?: unknown | null;
    readonly employer?: string | null;
    readonly employmentStatus?: string | null;
    readonly insuranceProvider?: string | null;
    readonly policyNumber?: string | null;
    readonly department?: string | null;
    readonly position?: string | null;
    readonly roleInTrip?: string | null;
    readonly bureauAssigned?: unknown | null;
    readonly domicileCity?: unknown | null;
    readonly lastHefatReceived?: string | null;
    readonly biologicalSex?: string | null;
    readonly bloodType?: string | null;
    readonly height?: number | null;
    readonly eyeColor?: string | null;
    readonly correctedVision?: boolean | null;
    readonly hairColorLength?: string | null;
    readonly identifyingMarksDescription?: string | null;
    readonly primaryPhysicianName?: string | null;
    readonly physicianContactNumber?: unknown | null;
    readonly disabilitiesOrOtherMedicalConditions?: string | null;
    readonly medicationsAndDosage?: string | null;
    readonly drugOrOtherAllergies?: string | null;
    readonly pollQuestionsAnswers?: unknown | null;
    readonly level1Approver?: string | null;
    readonly level2Approver?: string | null;
    readonly level3Approver?: string | null;
    readonly clientMutationId?: string | null;
};
export type UpdateEmployeeMutationVariables = {
    input: updateEmployeeInput;
};
export type UpdateEmployeeMutationResponse = {
    readonly updateEmployee: {
        readonly employee: {
            readonly id: string;
            readonly mediaObjects: ReadonlyArray<{
                readonly type: string;
                readonly content: string | null;
            } | null> | null;
            readonly firstName: string | null;
            readonly middleName: string | null;
            readonly lastName: string | null;
            readonly mobilePhoneNumbers: unknown;
            readonly satellitePhoneNumbers: unknown;
            readonly vismoProfile: boolean | null;
            readonly email: string;
            readonly birthDetails: string | null;
            readonly nationality: unknown;
            readonly employer: string | null;
            readonly employmentStatus: string | null;
            readonly insuranceProvider: string | null;
            readonly policyNumber: string | null;
            readonly department: string | null;
            readonly position: string | null;
            readonly roleInTrip: string | null;
            readonly bureauAssigned: unknown;
            readonly domicileCity: unknown;
            readonly lastHefatReceived: string | null;
            readonly level1Approver: {
                readonly firstName: string | null;
                readonly lastName: string | null;
            } | null;
            readonly level2Approver: {
                readonly firstName: string | null;
                readonly lastName: string | null;
            } | null;
            readonly level3Approver: {
                readonly firstName: string | null;
                readonly lastName: string | null;
            } | null;
            readonly employeeEmergencyContacts: ReadonlyArray<{
                readonly id: string;
                readonly firstName: string | null;
                readonly middleName: string | null;
                readonly lastName: string | null;
                readonly mobilePhoneNumbers: unknown;
                readonly gender: string | null;
                readonly nationality: unknown;
                readonly email: string | null;
                readonly relationship: string | null;
                readonly languagePreference: string | null;
                readonly specialInstructions: string | null;
                readonly type: string;
            } | null> | null;
            readonly biologicalSex: string | null;
            readonly bloodType: string | null;
            readonly height: number | null;
            readonly eyeColor: string | null;
            readonly correctedVision: boolean | null;
            readonly hairColorLength: string | null;
            readonly identifyingMarksDescription: string | null;
            readonly primaryPhysicianName: string | null;
            readonly physicianContactNumber: unknown;
            readonly disabilitiesOrOtherMedicalConditions: string | null;
            readonly medicationsAndDosage: string | null;
            readonly drugOrOtherAllergies: string | null;
            readonly pollQuestionsAnswers: unknown;
        } | null;
    } | null;
};
export type UpdateEmployeeMutation = {
    readonly response: UpdateEmployeeMutationResponse;
    readonly variables: UpdateEmployeeMutationVariables;
};



/*
mutation UpdateEmployeeMutation(
  $input: updateEmployeeInput!
) {
  updateEmployee(input: $input) {
    employee {
      id
      mediaObjects {
        type
        content
        id
      }
      firstName
      middleName
      lastName
      mobilePhoneNumbers
      satellitePhoneNumbers
      vismoProfile
      email
      birthDetails
      nationality
      employer
      employmentStatus
      insuranceProvider
      policyNumber
      department
      position
      roleInTrip
      bureauAssigned
      domicileCity
      lastHefatReceived
      level1Approver {
        firstName
        lastName
        id
      }
      level2Approver {
        firstName
        lastName
        id
      }
      level3Approver {
        firstName
        lastName
        id
      }
      employeeEmergencyContacts {
        id
        firstName
        middleName
        lastName
        mobilePhoneNumbers
        gender
        nationality
        email
        relationship
        languagePreference
        specialInstructions
        type
      }
      biologicalSex
      bloodType
      height
      eyeColor
      correctedVision
      hairColorLength
      identifyingMarksDescription
      primaryPhysicianName
      physicianContactNumber
      disabilitiesOrOtherMedicalConditions
      medicationsAndDosage
      drugOrOtherAllergies
      pollQuestionsAnswers
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "updateEmployeeInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "content",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "middleName",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "mobilePhoneNumbers",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "satellitePhoneNumbers",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "vismoProfile",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v12 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "birthDetails",
        "args": null,
        "storageKey": null
    } as any), v13 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "nationality",
        "args": null,
        "storageKey": null
    } as any), v14 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "employer",
        "args": null,
        "storageKey": null
    } as any), v15 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "employmentStatus",
        "args": null,
        "storageKey": null
    } as any), v16 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "insuranceProvider",
        "args": null,
        "storageKey": null
    } as any), v17 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "policyNumber",
        "args": null,
        "storageKey": null
    } as any), v18 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "department",
        "args": null,
        "storageKey": null
    } as any), v19 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "position",
        "args": null,
        "storageKey": null
    } as any), v20 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "roleInTrip",
        "args": null,
        "storageKey": null
    } as any), v21 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "bureauAssigned",
        "args": null,
        "storageKey": null
    } as any), v22 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "domicileCity",
        "args": null,
        "storageKey": null
    } as any), v23 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastHefatReceived",
        "args": null,
        "storageKey": null
    } as any), v24 = [
        (v5 /*: any*/),
        (v7 /*: any*/)
    ], v25 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "employeeEmergencyContacts",
        "storageKey": null,
        "args": null,
        "concreteType": "EmployeeEmergencyContact",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            (v5 /*: any*/),
            (v6 /*: any*/),
            (v7 /*: any*/),
            (v8 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "gender",
                "args": null,
                "storageKey": null
            },
            (v13 /*: any*/),
            (v11 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "relationship",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "languagePreference",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "specialInstructions",
                "args": null,
                "storageKey": null
            },
            (v3 /*: any*/)
        ]
    } as any), v26 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "biologicalSex",
        "args": null,
        "storageKey": null
    } as any), v27 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "bloodType",
        "args": null,
        "storageKey": null
    } as any), v28 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "height",
        "args": null,
        "storageKey": null
    } as any), v29 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "eyeColor",
        "args": null,
        "storageKey": null
    } as any), v30 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "correctedVision",
        "args": null,
        "storageKey": null
    } as any), v31 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "hairColorLength",
        "args": null,
        "storageKey": null
    } as any), v32 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "identifyingMarksDescription",
        "args": null,
        "storageKey": null
    } as any), v33 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "primaryPhysicianName",
        "args": null,
        "storageKey": null
    } as any), v34 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "physicianContactNumber",
        "args": null,
        "storageKey": null
    } as any), v35 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "disabilitiesOrOtherMedicalConditions",
        "args": null,
        "storageKey": null
    } as any), v36 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "medicationsAndDosage",
        "args": null,
        "storageKey": null
    } as any), v37 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "drugOrOtherAllergies",
        "args": null,
        "storageKey": null
    } as any), v38 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "pollQuestionsAnswers",
        "args": null,
        "storageKey": null
    } as any), v39 = [
        (v5 /*: any*/),
        (v7 /*: any*/),
        (v2 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdateEmployeeMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "updateEmployee",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "updateEmployeePayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "employee",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Employee",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "mediaObjects",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "EmployeeMediaObject",
                                    "plural": true,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/)
                                    ]
                                },
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                (v15 /*: any*/),
                                (v16 /*: any*/),
                                (v17 /*: any*/),
                                (v18 /*: any*/),
                                (v19 /*: any*/),
                                (v20 /*: any*/),
                                (v21 /*: any*/),
                                (v22 /*: any*/),
                                (v23 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "level1Approver",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Employee",
                                    "plural": false,
                                    "selections": (v24 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "level2Approver",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Employee",
                                    "plural": false,
                                    "selections": (v24 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "level3Approver",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Employee",
                                    "plural": false,
                                    "selections": (v24 /*: any*/)
                                },
                                (v25 /*: any*/),
                                (v26 /*: any*/),
                                (v27 /*: any*/),
                                (v28 /*: any*/),
                                (v29 /*: any*/),
                                (v30 /*: any*/),
                                (v31 /*: any*/),
                                (v32 /*: any*/),
                                (v33 /*: any*/),
                                (v34 /*: any*/),
                                (v35 /*: any*/),
                                (v36 /*: any*/),
                                (v37 /*: any*/),
                                (v38 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdateEmployeeMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "updateEmployee",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "updateEmployeePayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "employee",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Employee",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "mediaObjects",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "EmployeeMediaObject",
                                    "plural": true,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v2 /*: any*/)
                                    ]
                                },
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                (v15 /*: any*/),
                                (v16 /*: any*/),
                                (v17 /*: any*/),
                                (v18 /*: any*/),
                                (v19 /*: any*/),
                                (v20 /*: any*/),
                                (v21 /*: any*/),
                                (v22 /*: any*/),
                                (v23 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "level1Approver",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Employee",
                                    "plural": false,
                                    "selections": (v39 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "level2Approver",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Employee",
                                    "plural": false,
                                    "selections": (v39 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "level3Approver",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Employee",
                                    "plural": false,
                                    "selections": (v39 /*: any*/)
                                },
                                (v25 /*: any*/),
                                (v26 /*: any*/),
                                (v27 /*: any*/),
                                (v28 /*: any*/),
                                (v29 /*: any*/),
                                (v30 /*: any*/),
                                (v31 /*: any*/),
                                (v32 /*: any*/),
                                (v33 /*: any*/),
                                (v34 /*: any*/),
                                (v35 /*: any*/),
                                (v36 /*: any*/),
                                (v37 /*: any*/),
                                (v38 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdateEmployeeMutation",
            "id": null,
            "text": "mutation UpdateEmployeeMutation(\n  $input: updateEmployeeInput!\n) {\n  updateEmployee(input: $input) {\n    employee {\n      id\n      mediaObjects {\n        type\n        content\n        id\n      }\n      firstName\n      middleName\n      lastName\n      mobilePhoneNumbers\n      satellitePhoneNumbers\n      vismoProfile\n      email\n      birthDetails\n      nationality\n      employer\n      employmentStatus\n      insuranceProvider\n      policyNumber\n      department\n      position\n      roleInTrip\n      bureauAssigned\n      domicileCity\n      lastHefatReceived\n      level1Approver {\n        firstName\n        lastName\n        id\n      }\n      level2Approver {\n        firstName\n        lastName\n        id\n      }\n      level3Approver {\n        firstName\n        lastName\n        id\n      }\n      employeeEmergencyContacts {\n        id\n        firstName\n        middleName\n        lastName\n        mobilePhoneNumbers\n        gender\n        nationality\n        email\n        relationship\n        languagePreference\n        specialInstructions\n        type\n      }\n      biologicalSex\n      bloodType\n      height\n      eyeColor\n      correctedVision\n      hairColorLength\n      identifyingMarksDescription\n      primaryPhysicianName\n      physicianContactNumber\n      disabilitiesOrOtherMedicalConditions\n      medicationsAndDosage\n      drugOrOtherAllergies\n      pollQuestionsAnswers\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '0da4e953d6aef70e3f543ae1d104afe9';
export default node;
