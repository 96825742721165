import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Loader } from 'components/Loader';
import { QueryRenderer } from 'react-relay';
import { modernEnvironment } from 'config/environment';
import { ValidationError } from 'components/ValidationError';
import { FormBuilder } from 'components';
import emergencyContacts from 'data/emergency-contacts.json';
import emergencyContactsDisabled from 'data/disabled/emergency-contacts.json';
import { commitMutationForm } from 'helper/commitMutationForm';
import { GetParticipantEmergencyContactsQuery } from 'graphql/GetParticipantEmergencyContactsQuery';
import { UpdateParticipantEmergencyContactMutation } from 'graphql/UpdateParticipantEmergencyContactMutation';
import { CreateParticipantEmergencyContactMutation } from 'graphql/CreateParticipantEmergencyContactMutation';

interface IComponentProps {
  participantId: string;
  isSubmitted: boolean;
}

export const ParticipantEmergencyContacts = ({
  participantId,
  isSubmitted,
}: IComponentProps) => (
  <QueryRenderer
    environment={modernEnvironment}
    query={GetParticipantEmergencyContactsQuery}
    variables={{
      id: `/api/participants/${participantId}`,
    }}
    render={({ error, props }) => {
      if (error) {
        return <ValidationError>{error[0].message}</ValidationError>;
      } else if (props) {
        const {
          participant: { participantEmergencyContacts },
        } = props;

        const primaryContact =
          participantEmergencyContacts.find((o) => o.type === 'primary') || {};
        const alternateContact =
          participantEmergencyContacts.find((o) => o.type === 'alternate') ||
          {};
        if (!primaryContact.id) {
          commitMutationForm({
            values: {
              participant: `/api/participants/${participantId}`,
              type: 'primary',
              mobilePhoneNumbers: [''],
              nationality: [''],
            },
            mutation: CreateParticipantEmergencyContactMutation,
            onCompleteCallback: (response) => {
              primaryContact.id =
                response.createParticipantEmergencyContact.participantEmergencyContact.id;
            },
          });
        }
        if (!alternateContact.id) {
          commitMutationForm({
            values: {
              participant: `/api/participants/${participantId}`,
              type: 'alternate',
              mobilePhoneNumbers: [''],
              nationality: [''],
            },
            mutation: CreateParticipantEmergencyContactMutation,
            onCompleteCallback: (response) => {
              alternateContact.id =
                response.createParticipantEmergencyContact.participantEmergencyContact.id;
            },
          });
        }

        return (
          <Box>
            <Box py={1.5}>
              <Typography variant="h5" color="primary">
                Primary contact
              </Typography>
            </Box>
            <FormBuilder
              title=""
              enabledInnerHeadings={false}
              formObject={
                !isSubmitted ? emergencyContacts : emergencyContactsDisabled
              }
              initialValues={primaryContact}
              onSubmit={(values) => {
                commitMutationForm({
                  values: {
                    id: primaryContact.id,
                    ...values,
                  },
                  mutation: UpdateParticipantEmergencyContactMutation,
                });
              }}
            />
            <Box pt={6} pb={1.5}>
              <Typography variant="h5" color="primary">
                Alternate contact
              </Typography>
            </Box>
            <FormBuilder
              title=""
              enabledInnerHeadings={false}
              formObject={
                !isSubmitted ? emergencyContacts : emergencyContactsDisabled
              }
              initialValues={alternateContact}
              onSubmit={(values) => {
                commitMutationForm({
                  values: {
                    id: alternateContact.id,
                    ...values,
                  },
                  mutation: UpdateParticipantEmergencyContactMutation,
                });
              }}
            />
          </Box>
        );
      }
      return <Loader />;
    }}
  />
);
