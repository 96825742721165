import React from 'react';
import { Grid, Box, Typography, LinearProgress } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { customTheme } from 'theme';
import { arabicToRoman } from 'helper/arabicToRoman';
import * as moment from 'moment';

export const useStyles = makeStyles((theme) => ({
  track: {
    borderRadius: 20,
  },
  trackProgress: {
    color: customTheme.colors.oceanBlue,
    position: 'absolute',
    bottom: 30,
    transform: 'translateX(-50%)',
  },
}));

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: customTheme.colors.brandGrey2,
  },
  bar: {
    borderRadius: 20,
    backgroundColor: customTheme.colors.oceanBlue,
  },
})(LinearProgress);

interface IComponentProps {
  legs: {};
}

const calculateCurrentLeg = (legs) => {
  let legIndex = 0;
  legs.forEach((leg, index) => {
    const nowDate = moment();
    const startDate = moment(leg.travelDepartureTime);
    const endDate = moment(leg.travelArrivalTime);

    // omitting the optional third parameter, 'units'
    const isBet = nowDate.isBetween(startDate, endDate, null, '[]');

    if (isBet) {
      legIndex = index;
    }
  });
  return legIndex;
};

export const ActiveTile = ({ legs }: IComponentProps) => {
  const classes = useStyles();
  const currentLeg = calculateCurrentLeg(legs);
  const totalLegs = legs.length;

  const progressValue =
    currentLeg === 1
      ? 2
      : (currentLeg / totalLegs) * 100 - (1 / totalLegs) * 100;

  return (
    <Box
      pb={1}
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
    >
      <Box p={1} pt={3} position="relative">
        {currentLeg !== 0 && (
          <Typography
            variant="body1"
            className={classes.trackProgress}
            style={{ left: `${progressValue}%` }}
          >
            Leg {arabicToRoman(currentLeg)}
          </Typography>
        )}
        <BorderLinearProgress
          className={classes.track}
          variant="determinate"
          value={progressValue}
        />
      </Box>

      <Grid container justify="space-between">
        <Box>
          <Typography variant="body1">Leg {arabicToRoman(1)}</Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            Leg {arabicToRoman(totalLegs)}
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};
