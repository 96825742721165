import { graphql } from 'react-relay';

export const GetPlanIncidentsQuery = graphql`
  query GetPlanIncidentsQuery(
    $id: ID!
    $incidentDate: IncidentFilter_incidentDate
  ) {
    activityPlan(id: $id) {
      statusItineraryLegs
      submittedStatusIncidents
      incidents(incidentDate: $incidentDate) {
        quantity
        incidentDate
        gap
        location
        type
        target
        details
        id
      }
    }
  }
`;
