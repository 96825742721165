import { graphql } from 'react-relay';

export const DeleteIncidentMutation = graphql`
  mutation DeleteIncidentMutation($input: deleteIncidentInput!) {
    deleteIncident(input: $input) {
      incident {
        id
      }
    }
  }
`;
