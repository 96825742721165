/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetEmployeeProofOfLifeQueryVariables = {
    id: string;
};
export type GetEmployeeProofOfLifeQueryResponse = {
    readonly employee: {
        readonly pollQuestionsAnswers: unknown;
    } | null;
};
export type GetEmployeeProofOfLifeQuery = {
    readonly response: GetEmployeeProofOfLifeQueryResponse;
    readonly variables: GetEmployeeProofOfLifeQueryVariables;
};



/*
query GetEmployeeProofOfLifeQuery(
  $id: ID!
) {
  employee(id: $id) {
    pollQuestionsAnswers
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "pollQuestionsAnswers",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetEmployeeProofOfLifeQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "employee",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Employee",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetEmployeeProofOfLifeQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "employee",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Employee",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetEmployeeProofOfLifeQuery",
            "id": null,
            "text": "query GetEmployeeProofOfLifeQuery(\n  $id: ID!\n) {\n  employee(id: $id) {\n    pollQuestionsAnswers\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'bcaf96b6f35c759be4db99f57166bd48';
export default node;
