/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetKeyBehavioursQueryVariables = {
    category?: string | null;
};
export type GetKeyBehavioursQueryResponse = {
    readonly keyBehaviours: ReadonlyArray<{
        readonly id: string;
        readonly instruction: string;
        readonly category: string;
        readonly createdAt: string;
    } | null> | null;
};
export type GetKeyBehavioursQuery = {
    readonly response: GetKeyBehavioursQueryResponse;
    readonly variables: GetKeyBehavioursQueryVariables;
};



/*
query GetKeyBehavioursQuery(
  $category: String
) {
  keyBehaviours(category: $category) {
    id
    instruction
    category
    createdAt
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "category",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "keyBehaviours",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "category",
                    "variableName": "category"
                }
            ],
            "concreteType": "KeyBehaviour",
            "plural": true,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "instruction",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "category",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetKeyBehavioursQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "GetKeyBehavioursQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "GetKeyBehavioursQuery",
            "id": null,
            "text": "query GetKeyBehavioursQuery(\n  $category: String\n) {\n  keyBehaviours(category: $category) {\n    id\n    instruction\n    category\n    createdAt\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '15fa010aeb8bd12715d6d5dfcd93b575';
export default node;
