import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import { FieldRenderProps } from 'react-final-form';
import { IFieldProps, ISelectOptions } from './types';

type Props = FieldRenderProps<string, any> & IFieldProps;

export const RadioGroupField = (props: Props) => {
  const {
    input,
    meta,
    templateOptions: { label, options, disabled, required },
  } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val =
      event.target.value === 'true'
        ? true
        : event.target.value === 'false'
        ? false
        : event.target.value;
    input.onChange(val);
  };

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      required={required}
      variant="outlined"
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        aria-label={input.name}
        name={input.name}
        defaultValue={String(input.value) || options[0].value}
        onChange={handleChange}
        row
      >
        {options &&
          options.map((option: ISelectOptions) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio color="primary" disabled={option.disabled} />}
              label={option.name}
              labelPlacement="end"
            />
          ))}
      </RadioGroup>
      {showError && (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      )}
    </FormControl>
  );
};
