import React, { useState, useCallback } from 'react';
import { IFieldProps } from './types';
import {
  Typography,
  TextField,
  makeStyles,
  Box,
  InputLabel,
  FormControl,
} from '@material-ui/core';

import { FieldRenderProps } from 'react-final-form';
import { ValidationError } from 'components/ValidationError';

type Props = FieldRenderProps<string, any> & IFieldProps;

const useStyles = makeStyles((theme) => ({
  prefix: {
    fontSize: '16px',
    width: 200,
    marginRight: '1px',
  },
  officeLabel: {
    fontSize: '13px',
    marginBottom: '4px',
    display: 'block',
    transform: 'initial',
    padding: '0 8px',
  },
  formControl: {
    display: 'flex',
  },
  inputWrap: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '16px',
  },
}));

export const BaseOfficeField = (props: Props) => {
  const classes = useStyles();
  const {
    input,
    meta,
    templateOptions: {
      label,
      elementType,
      placeholder,
      required,
      disabled,
      description,
    },
    render,
  } = props;

  const showError = meta.dirty && meta.invalid;

  const [innerValue, setInnerValue] = useState({
    country: input.value.country || '',
    location: input.value.location || '',
  });

  const handleChange = useCallback(
    (property: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
      const val = event.target.value;

      setInnerValue((state) => ({
        ...state,
        [property]: val,
      }));

      input.onChange({ ...innerValue, [property]: val });
    },
    [innerValue, input]
  );

  return (
    <FormControl
      className={classes.formControl}
      required={required}
      disabled={disabled}
      error={showError}
    >
      <InputLabel className={classes.officeLabel}>{label}</InputLabel>
      <Box className={classes.inputWrap}>
        <Box display="inline" pr={1.5}>
          {render(innerValue, handleChange, showError)}
        </Box>

        {showError && (
          <ValidationError>{meta.error || meta.submitError}</ValidationError>
        )}
        <TextField
          variant={'outlined'}
          error={showError}
          helperText={showError ? meta.error || meta.submitError : undefined}
          onChange={handleChange('location')}
          placeholder={placeholder}
          required={required}
          type={elementType}
          value={innerValue.location}
          disabled={disabled}
        />
      </Box>
      {description && (
        <Box pt={0.25}>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </Box>
      )}
    </FormControl>
  );
};
