import React, { useEffect } from 'react';
import { FormSpy } from 'react-final-form';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useAutoSave } from 'hooks/useAutoSave';

export const Alert = (props: AlertProps) => (
  <MuiAlert elevation={6} variant="filled" {...props} />
);

export const AutoSave = ({ debounce, isSaving, onSave }) => {
  const [result, handleChange] = useAutoSave(debounce);

  useEffect(() => {
    onSave(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <>
      <Snackbar
        open={isSaving}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert>Form is saving</Alert>
      </Snackbar>

      <FormSpy
        subscription={{
          values: true,
          initialValues: true,
          errors: false,
          hasValidationErrors: true,
        }}
        onChange={handleChange}
      />
    </>
  );
};
