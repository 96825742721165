import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { KeyThreat } from 'pages/plan/risk-determination/KeyThreat';
import { AddKeyThreatPopup } from 'pages/plan/risk-determination/AddKeyThreatPopup';

interface IComponentProps {
  keyThreats: Array<{}>;
  riskDeterminationId: string;
  isSubmitted: boolean;
}

export const KeyThreats = ({
  keyThreats,
  riskDeterminationId,
  isSubmitted,
}: IComponentProps) => {
  const [keyThreatsLocal, setKeyThreatsLocal] = useState([]);

  useEffect(() => {
    setKeyThreatsLocal(keyThreats);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDone = (id) => {
    setKeyThreatsLocal(
      keyThreatsLocal.filter((keyThreat) => keyThreat.id !== id)
    );
  };

  const handleAdd = (keyThreat) => {
    const newKeyThreats = [...keyThreatsLocal];
    newKeyThreats.push(keyThreat);
    setKeyThreatsLocal(newKeyThreats);
  };
  return (
    <>
      <Box pt={6} pb={0.5} pl={1.15}>
        <Typography variant="h3" color="textPrimary">
          Key Threats
        </Typography>
      </Box>
      <Box pb={3} pl={1.15}>
        <Typography variant="body2" color="textSecondary">
          Relevant incidents, which convey the most applicable threats to inform
          planning.
        </Typography>
      </Box>
      <Box>
        {keyThreatsLocal.map((keyThreat, index) => (
          <KeyThreat
            keyThreat={keyThreat}
            key={keyThreat.id}
            index={index}
            doneHandler={handleDone}
            isSubmitted={isSubmitted}
          />
        ))}
      </Box>
      {!isSubmitted && (
        <Box mt={-2.5}>
          <AddKeyThreatPopup
            riskDeterminationId={riskDeterminationId}
            addHandler={handleAdd}
          />
        </Box>
      )}
    </>
  );
};
