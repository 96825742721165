/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetSettingsQueryVariables = {
    type?: string | null;
};
export type GetSettingsQueryResponse = {
    readonly settings: ReadonlyArray<{
        readonly category: string;
        readonly value: string;
    } | null> | null;
};
export type GetSettingsQuery = {
    readonly response: GetSettingsQueryResponse;
    readonly variables: GetSettingsQueryVariables;
};



/*
query GetSettingsQuery(
  $type: String
) {
  settings(category: $type) {
    category
    value
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "type",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "category",
            "variableName": "type"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "category",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "value",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetSettingsQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Setting",
                    "plural": true,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetSettingsQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "settings",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Setting",
                    "plural": true,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetSettingsQuery",
            "id": null,
            "text": "query GetSettingsQuery(\n  $type: String\n) {\n  settings(category: $type) {\n    category\n    value\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '9cfde96f3af0f0666c003f65bb96aac5';
export default node;
