import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FullscreenLoader } from 'components/FullscreenLoader';

import { commitMutationForm } from 'helper/commitMutationForm';
import { clearId } from 'helper/clearId';
import { CreatePlanItineraryLegMutation } from 'graphql/CreatePlanItineraryLegMutation';
import { CreateItineraryContactMutation } from 'graphql/CreateItineraryContactMutation';

interface IComponentProps {
  legNumber: number;
  basePlanRoute: string;
  planId: string;
}

export const AddLegBtn = ({
  legNumber,
  basePlanRoute,
  planId,
}: IComponentProps) => {
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const createItineraryContact = useCallback(
    (legId: string) => {
      commitMutationForm({
        values: {
          itineraryLegs: [legId],
          nationality: [''],
          mobilePhoneNumbers: [''],
        },
        mutation: CreateItineraryContactMutation,
        optimisticResponse: {
          createItineraryContact: {
            itineraryContact: {
              id: '/api/itinerary_contacts/newContact',
            },
          },
        },
        onCompleteCallback: (response, errors) => {
          if (window.location.href.indexOf('/newLeg') !== -1) {
            history.replace(`${basePlanRoute}/${legNumber}/${clearId(legId)}`);
          }
          mounted.current && setIsDisabled(false);
        },
        onErrorCallback: (error) => {
          mounted.current && setIsDisabled(false);
        },
      });
    },
    [history, legNumber, basePlanRoute]
  );

  const createLeg = useCallback(() => {
    setIsDisabled(true);

    history.push(`${basePlanRoute}/${legNumber}/newLeg`);

    commitMutationForm({
      values: {
        activityPlan: `/api/activity_plans/${planId}`,
        fromLocation: { country: '', location: '' },
        toLocation: { country: '', location: '' },
      },
      mutation: CreatePlanItineraryLegMutation,
      optimisticResponse: {
        createItineraryLeg: {
          itineraryLeg: {
            id: '/api/itinerary_legs/newLeg',
            meansOfTravel: '',
            fromLocation: {},
            toLocation: {},
            travelDepartureTime: null,
            travelArrivalTime: null,
          },
        },
      },
      onCompleteCallback: (response, errors) => {
        createItineraryContact(response.createItineraryLeg.itineraryLeg.id);
      },
      onErrorCallback: (error) => {
        mounted.current && setIsDisabled(false);
      },
    });
  }, [history, legNumber, basePlanRoute, planId, createItineraryContact]);

  return (
    <>
      <Button
        onClick={createLeg}
        variant="text"
        color="primary"
        disabled={isDisabled}
      >
        ADD LEG
      </Button>
      <FullscreenLoader isLoading={isDisabled} />
    </>
  );
};
