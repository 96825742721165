import React from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  fullHeight: {
    minHeight: '100vh',
  },
  paper: {
    minWidth: 612,
    padding: theme.spacing(6, 8),
  },
}));

interface IComponentProps {
  history: { push(path: string): void };
  push(path: string): void;
}

export const SelectMode = (props: IComponentProps) => {
  const { history } = props;
  const classes = useStyles();

  return (
    <Grid
      className={classes.fullHeight}
      container
      justify="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Paper className={classes.paper}>
          <Box mb={2}>
            <Typography variant="h3">
              Are you supporting or participating in a high risk activity?
            </Typography>
          </Box>
          <Grid container justify="center" alignItems="center">
            <Box ml={2} mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push('/dashboard/home');
                }}
              >
                Supporting
              </Button>
            </Box>

            <Box ml={2} mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push('/login-personal-details');
                }}
              >
                Participating
              </Button>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
