/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanRiskDeterminationQueryVariables = {
    id: string;
};
export type GetPlanRiskDeterminationQueryResponse = {
    readonly activityPlan: {
        readonly statusItineraryLegs: string;
        readonly statusParticipants: string | null;
        readonly riskDetermination: {
            readonly id: string;
            readonly contextualInfo: string | null;
            readonly activitySpecificInfo: string | null;
            readonly staffSpecificInfo: string | null;
            readonly relevantIncidents: string | null;
            readonly keyThreats: ReadonlyArray<{
                readonly id: string;
                readonly type: string | null;
                readonly notes: string | null;
                readonly probability: string | null;
                readonly impact: string | null;
                readonly commentary: string | null;
            } | null> | null;
            readonly environmentalFactors: string | null;
            readonly legalConsiderations: string | null;
            readonly overallRiskLevel: string | null;
            readonly assignedPhysicalRiskLevel: string;
            readonly assignedCyberRiskLevel: string;
            readonly assignedLegalRiskLevel: string;
            readonly assignedPhysicalRiskCommentary: string | null;
            readonly assignedInternalPhysicalRiskLevel: string;
            readonly assignedInternalPhysicalRiskCommentary: string | null;
            readonly submittedStatus: boolean | null;
            readonly calculatedRiskLevels: unknown;
        } | null;
    } | null;
};
export type GetPlanRiskDeterminationQuery = {
    readonly response: GetPlanRiskDeterminationQueryResponse;
    readonly variables: GetPlanRiskDeterminationQueryVariables;
};



/*
query GetPlanRiskDeterminationQuery(
  $id: ID!
) {
  activityPlan(id: $id) {
    statusItineraryLegs
    statusParticipants
    riskDetermination {
      id
      contextualInfo
      activitySpecificInfo
      staffSpecificInfo
      relevantIncidents
      keyThreats {
        id
        type
        notes
        probability
        impact
        commentary
      }
      environmentalFactors
      legalConsiderations
      overallRiskLevel
      assignedPhysicalRiskLevel
      assignedCyberRiskLevel
      assignedLegalRiskLevel
      assignedPhysicalRiskCommentary
      assignedInternalPhysicalRiskLevel
      assignedInternalPhysicalRiskCommentary
      submittedStatus
      calculatedRiskLevels
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "statusItineraryLegs",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "statusParticipants",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "riskDetermination",
        "storageKey": null,
        "args": null,
        "concreteType": "RiskDetermination",
        "plural": false,
        "selections": [
            (v4 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "contextualInfo",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "activitySpecificInfo",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "staffSpecificInfo",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "relevantIncidents",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "keyThreats",
                "storageKey": null,
                "args": null,
                "concreteType": "KeyThreat",
                "plural": true,
                "selections": [
                    (v4 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "notes",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "probability",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "impact",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "commentary",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "environmentalFactors",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "legalConsiderations",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "overallRiskLevel",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "assignedPhysicalRiskLevel",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "assignedCyberRiskLevel",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "assignedLegalRiskLevel",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "assignedPhysicalRiskCommentary",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "assignedInternalPhysicalRiskLevel",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "assignedInternalPhysicalRiskCommentary",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "submittedStatus",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "calculatedRiskLevels",
                "args": null,
                "storageKey": null
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanRiskDeterminationQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v5 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanRiskDeterminationQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v5 /*: any*/),
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanRiskDeterminationQuery",
            "id": null,
            "text": "query GetPlanRiskDeterminationQuery(\n  $id: ID!\n) {\n  activityPlan(id: $id) {\n    statusItineraryLegs\n    statusParticipants\n    riskDetermination {\n      id\n      contextualInfo\n      activitySpecificInfo\n      staffSpecificInfo\n      relevantIncidents\n      keyThreats {\n        id\n        type\n        notes\n        probability\n        impact\n        commentary\n      }\n      environmentalFactors\n      legalConsiderations\n      overallRiskLevel\n      assignedPhysicalRiskLevel\n      assignedCyberRiskLevel\n      assignedLegalRiskLevel\n      assignedPhysicalRiskCommentary\n      assignedInternalPhysicalRiskLevel\n      assignedInternalPhysicalRiskCommentary\n      submittedStatus\n      calculatedRiskLevels\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '9b8e59e49d78d82a5817807a870b641f';
export default node;
