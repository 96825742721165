/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanRiskMitigationQueryVariables = {
    id: string;
};
export type GetPlanRiskMitigationQueryResponse = {
    readonly activityPlan: {
        readonly riskDetermination: {
            readonly submittedStatus: boolean | null;
        } | null;
        readonly riskMitigation: {
            readonly id: string;
            readonly submittedStatus: boolean;
            readonly keyBehaviours: ReadonlyArray<{
                readonly id: string;
                readonly instruction: string;
                readonly category: string;
                readonly originalKeyBehaviour: string | null;
            } | null> | null;
        } | null;
    } | null;
};
export type GetPlanRiskMitigationQuery = {
    readonly response: GetPlanRiskMitigationQueryResponse;
    readonly variables: GetPlanRiskMitigationQueryVariables;
};



/*
query GetPlanRiskMitigationQuery(
  $id: ID!
) {
  activityPlan(id: $id) {
    riskDetermination {
      submittedStatus
      id
    }
    riskMitigation {
      id
      submittedStatus
      keyBehaviours {
        id
        instruction
        category
        originalKeyBehaviour
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "submittedStatus",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "riskMitigation",
        "storageKey": null,
        "args": null,
        "concreteType": "RiskMitigation",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            (v2 /*: any*/),
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "keyBehaviours",
                "storageKey": null,
                "args": null,
                "concreteType": "ActivityPlanKeyBehaviour",
                "plural": true,
                "selections": [
                    (v3 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "instruction",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "category",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "originalKeyBehaviour",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanRiskMitigationQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskDetermination",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskDetermination",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ]
                        },
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanRiskMitigationQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskDetermination",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskDetermination",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/)
                            ]
                        },
                        (v4 /*: any*/),
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanRiskMitigationQuery",
            "id": null,
            "text": "query GetPlanRiskMitigationQuery(\n  $id: ID!\n) {\n  activityPlan(id: $id) {\n    riskDetermination {\n      submittedStatus\n      id\n    }\n    riskMitigation {\n      id\n      submittedStatus\n      keyBehaviours {\n        id\n        instruction\n        category\n        originalKeyBehaviour\n      }\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '90a7735180ad539c637e112d3de925dc';
export default node;
