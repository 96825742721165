/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetParticipantEmergencyContactsQueryVariables = {
    id: string;
};
export type GetParticipantEmergencyContactsQueryResponse = {
    readonly participant: {
        readonly participantEmergencyContacts: ReadonlyArray<{
            readonly id: string;
            readonly type: string;
            readonly firstName: string | null;
            readonly middleName: string | null;
            readonly lastName: string | null;
            readonly mobilePhoneNumbers: unknown;
            readonly gender: string | null;
            readonly nationality: unknown;
            readonly email: string | null;
            readonly relationship: string | null;
            readonly languagePreference: string | null;
            readonly specialInstructions: string | null;
        } | null> | null;
    } | null;
};
export type GetParticipantEmergencyContactsQuery = {
    readonly response: GetParticipantEmergencyContactsQueryResponse;
    readonly variables: GetParticipantEmergencyContactsQueryVariables;
};



/*
query GetParticipantEmergencyContactsQuery(
  $id: ID!
) {
  participant(id: $id) {
    participantEmergencyContacts {
      id
      type
      firstName
      middleName
      lastName
      mobilePhoneNumbers
      gender
      nationality
      email
      relationship
      languagePreference
      specialInstructions
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "participantEmergencyContacts",
        "storageKey": null,
        "args": null,
        "concreteType": "ParticipantEmergencyContact",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "type",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "middleName",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "mobilePhoneNumbers",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "gender",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "nationality",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "relationship",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "languagePreference",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "specialInstructions",
                "args": null,
                "storageKey": null
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetParticipantEmergencyContactsQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Participant",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetParticipantEmergencyContactsQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Participant",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v2 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetParticipantEmergencyContactsQuery",
            "id": null,
            "text": "query GetParticipantEmergencyContactsQuery(\n  $id: ID!\n) {\n  participant(id: $id) {\n    participantEmergencyContacts {\n      id\n      type\n      firstName\n      middleName\n      lastName\n      mobilePhoneNumbers\n      gender\n      nationality\n      email\n      relationship\n      languagePreference\n      specialInstructions\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'fb2209aa7c0181855c8bb7995f362f58';
export default node;
