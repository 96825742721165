import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Grid } from '@material-ui/core';
import KeyThreatForm from 'data/plan/key-threat.json';
import KeyThreatFormDisabled from 'data/plan/disabled/key-threat.json';
import { FormBuilder } from 'components';
import { arabicToRoman } from 'helper/arabicToRoman';
import CloseIcon from '@material-ui/icons/Close';
import { UpdateKeyThreatMutation } from 'graphql/UpdateKeyThreatMutation';
import { commitMutationForm } from 'helper/commitMutationForm';
import { DeleteKeyThreatMutation } from 'graphql/DeleteKeyThreatMutation';
import { FullscreenLoader } from 'components/FullscreenLoader';

interface IComponentProps {
  keyThreat: Object;
  index: number;
  isSubmitted: boolean;
  doneHandler(): void;
}

export const KeyThreat = ({
  keyThreat,
  index,
  isSubmitted,
  doneHandler,
}: IComponentProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const deleteThreat = () => {
    setIsDisabled(true);

    commitMutationForm({
      values: {
        id: keyThreat.id,
      },
      mutation: DeleteKeyThreatMutation,
      optimisticResponse: {
        deleteKeyThreat: {
          keyThreat: {
            id: keyThreat.id,
          },
        },
      },
      configs: [
        {
          type: 'NODE_DELETE',
          deletedIDFieldName: keyThreat.id,
        },
      ],
      onCompleteCallback: (response, errors) => {
        mounted.current && doneHandler(keyThreat.id);
        mounted.current && setIsDisabled(false);
      },
      onErrorCallback: (error) => {
        mounted.current && doneHandler(keyThreat.id);
        mounted.current && setIsDisabled(false);
      },
    });
  };
  return (
    <Box pb={3}>
      <Grid container justify="space-between" alignItems="center">
        <Box mb={1} pl={1.15}>
          <Typography variant="h5" color="primary">
            Risk/Threat Mitigation {arabicToRoman(index + 1)}
          </Typography>
        </Box>
        {!isSubmitted && (
          <Button
            variant="text"
            color="primary"
            size="small"
            startIcon={<CloseIcon />}
            onClick={deleteThreat}
          >
            Remove Key Threat
          </Button>
        )}
      </Grid>

      <FormBuilder
        formObject={!isSubmitted ? KeyThreatForm : KeyThreatFormDisabled}
        initialValues={keyThreat}
        onSubmit={(values) => {
          commitMutationForm({
            values: {
              id: keyThreat.id,
              ...values,
            },
            mutation: UpdateKeyThreatMutation,
          });
        }}
      />
      <FullscreenLoader isLoading={isDisabled} />
    </Box>
  );
};
