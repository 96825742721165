import { graphql } from 'react-relay';

export const GetIncidentQuery = graphql`
  query GetIncidentQuery($id: ID!) {
    incident(id: $id) {
      gap
      incidentDate
      target
      quantity
      location
      details
      type
    }
  }
`;
