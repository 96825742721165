import React from 'react';
import { Box } from '@material-ui/core';
import { modernEnvironment } from 'config/environment';
import { GetPlanRisksQuery } from 'graphql/GetPlanRisksQuery';
import { ValidationError } from 'components/ValidationError';
import { GetPlanRegionQuery } from 'graphql/GetPlanRegionQuery';
import { QueryRenderer } from 'react-relay';
import { Loader } from 'components/Loader';
import { LocationRisk } from 'components/Plan/LocationRisk';

interface IComponentProps {
  planId: string;
}

export const LocationRisks = ({ planId }: IComponentProps) => (
  <QueryRenderer
    environment={modernEnvironment}
    query={GetPlanRegionQuery}
    variables={{
      id: `/api/activity_plans/${planId}`,
    }}
    render={({ error, props }) => {
      if (error) {
        return <ValidationError>{error.message}</ValidationError>;
      } else if (props) {
        const {
          activityPlan: { region, countries },
        } = props;
        return (
          <QueryRenderer
            environment={modernEnvironment}
            query={GetPlanRisksQuery}
            variables={{
              region,
              location_list: countries,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error.message}</ValidationError>;
              } else if (props) {
                const { locations } = props;

                return (
                  <Box mb={1}>
                    {locations.map((location) => (
                      <LocationRisk
                        key={`${location.region}-${location.location}`}
                        region={location.region}
                        location={location.location}
                        securityRisk={location.securityRisk}
                        legalRisk={location.legalRisk}
                        cyberRisk={location.cyberRisk}
                      />
                    ))}
                  </Box>
                );
              }
              return <Loader />;
            }}
          />
        );
      }
      return <Loader />;
    }}
  />
);
