import React, { useState, useRef, useEffect } from 'react';
import { Button, Grid, Box, IconButton } from '@material-ui/core';
import { CustomisedDialog } from 'components/CustomisedDialog';
import { FullscreenLoader } from 'components/FullscreenLoader';
import { KeyBehaviours } from './КeyBehaviours';
import EditIcon from '@material-ui/icons/Edit';

interface IComponentProps {
  category?: string;
  riskMitigation: string;
  addHandler(): void;
}

export const AddEditCategoryPopup = ({
  category,
  riskMitigation,
  addHandler,
}: IComponentProps) => {
  const [open, setOpen] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpen(false);
    addHandler();
    e.stopPropagation();
  };

  const handleAdd = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpen(true);
    e.stopPropagation();
  };
  return (
    <>
      {!category && (
        <Button
          variant="text"
          color="primary"
          onClick={(e) => {
            handleAdd(e);
          }}
        >
          + Add category
        </Button>
      )}

      {category && (
        <IconButton
          size="small"
          onClick={(e) => {
            handleAdd(e);
          }}
        >
          <EditIcon fontSize="inherit"></EditIcon>
        </IconButton>
      )}

      <CustomisedDialog
        title={category ? 'Edit category' : 'Add category'}
        subtitle="Select a category and add mitigation recommendations and directives"
        open={open}
        onClose={(e) => {
          handleClose(e);
        }}
      >
        <KeyBehaviours
          category={category}
          riskMitigation={riskMitigation}
        ></KeyBehaviours>
        <Grid container justify="flex-end">
          <Box pt={1.5} pb={0.75}>
            <Button
              color="primary"
              variant="contained"
              onClick={(e) => {
                handleClose(e);
              }}
            >
              Done
            </Button>
          </Box>
        </Grid>
        <FullscreenLoader isLoading={false} />
      </CustomisedDialog>
    </>
  );
};
