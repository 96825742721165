import React, { useState } from 'react';
import { Grid, Typography, AppBar, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { customTheme } from 'theme';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { CustomisedDialog } from 'components/CustomisedDialog';
import { QueryRenderer } from 'react-relay';
import { Loader } from 'components/Loader';
import { AvatarWithBlob } from 'components/AvatarWithBlob';
import { modernEnvironment } from 'config/environment';
import { GetPlanHeaderQuery } from 'graphql/GetPlanHeaderQuery';
import { ValidationError } from 'components/ValidationError';
import { getInitials } from 'helper/getInitials';
import { SelectedUsersWithRoles } from 'components/SelectedUsersWithRoles';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 1200,
    backgroundColor: customTheme.colors.slate,
  },
  dashboardHeader: {
    height: 60,
    padding: theme.spacing(0.5, 1, 0.5, 3),
    color: theme.palette.primary.contrastText,
    backgroundColor: customTheme.colors.slate,
  },
  smallAvatar: {
    width: 32,
    height: 32,
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  headerText: {
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

interface IHeaderProps {
  planId: string;
}

export const PlanHeader = (props: IHeaderProps) => {
  const { planId } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AppBar className={classes.appBar}>
      <Box boxShadow={3}>
        <Grid
          className={classes.dashboardHeader}
          container
          justify="space-between"
          alignItems="center"
        >
          <QueryRenderer
            environment={modernEnvironment}
            query={GetPlanHeaderQuery}
            variables={{
              id: `/api/activity_plans/${planId}`,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error[0].message}</ValidationError>;
              } else if (props) {
                const {
                  activityPlan: { name: planName, supporters },
                } = props;
                return (
                  <>
                    <Box maxWidth={'60%'}>
                      <Typography
                        title={planName}
                        className={classes.headerText}
                        variant="h3"
                      >
                        {planName}
                      </Typography>
                    </Box>
                    <Box maxWidth={'40%'}>
                      <Grid container onClick={handleOpen} alignItems="center">
                        <Box pr={1.5}>Experts</Box>
                        <Box pr={3}>
                          <AvatarGroup>
                            {supporters.map(({ employee }) => (
                              <AvatarWithBlob
                                key={employee.email}
                                className={classes.smallAvatar}
                                src={
                                  employee !== null &&
                                  employee.mediaObjects &&
                                  employee.mediaObjects[0] &&
                                  employee.mediaObjects[0].content
                                }
                              >
                                {getInitials(
                                  employee.firstName,
                                  employee.lastName
                                )}
                              </AvatarWithBlob>
                            ))}
                          </AvatarGroup>
                        </Box>
                      </Grid>
                      <CustomisedDialog
                        title="View experts"
                        subtitle="Review assigned experts"
                        open={open}
                        onClose={handleClose}
                      >
                        <SelectedUsersWithRoles users={supporters} />
                      </CustomisedDialog>
                    </Box>
                  </>
                );
              }
              return <Loader />;
            }}
          />
        </Grid>
      </Box>
    </AppBar>
  );
};
