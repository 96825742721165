/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanActivitiesQueryVariables = {
    id: string;
};
export type GetPlanActivitiesQueryResponse = {
    readonly activityPlan: {
        readonly riskDetermination: {
            readonly submittedStatus: boolean | null;
        } | null;
        readonly submittedStatusActivityLog: boolean;
        readonly activityLogs: ReadonlyArray<{
            readonly dateTimeEST: string;
            readonly localizedTime: string;
            readonly gmtTime: string;
            readonly remarks: string;
            readonly author: string;
            readonly id: string;
        } | null> | null;
    } | null;
};
export type GetPlanActivitiesQuery = {
    readonly response: GetPlanActivitiesQueryResponse;
    readonly variables: GetPlanActivitiesQueryVariables;
};



/*
query GetPlanActivitiesQuery(
  $id: ID!
) {
  activityPlan(id: $id) {
    riskDetermination {
      submittedStatus
      id
    }
    submittedStatusActivityLog
    activityLogs {
      dateTimeEST
      localizedTime
      gmtTime
      remarks
      author
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "submittedStatus",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "submittedStatusActivityLog",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "activityLogs",
        "storageKey": null,
        "args": null,
        "concreteType": "ActivityLog",
        "plural": true,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "dateTimeEST",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "localizedTime",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "gmtTime",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "remarks",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "author",
                "args": null,
                "storageKey": null
            },
            (v4 /*: any*/)
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanActivitiesQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskDetermination",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskDetermination",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ]
                        },
                        (v3 /*: any*/),
                        (v5 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanActivitiesQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskDetermination",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskDetermination",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v4 /*: any*/)
                            ]
                        },
                        (v3 /*: any*/),
                        (v5 /*: any*/),
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanActivitiesQuery",
            "id": null,
            "text": "query GetPlanActivitiesQuery(\n  $id: ID!\n) {\n  activityPlan(id: $id) {\n    riskDetermination {\n      submittedStatus\n      id\n    }\n    submittedStatusActivityLog\n    activityLogs {\n      dateTimeEST\n      localizedTime\n      gmtTime\n      remarks\n      author\n      id\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'a647d6988c34a227942909d2eb77ddd8';
export default node;
