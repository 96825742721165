import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Icon,
  IconButton,
  withStyles
} from '@material-ui/core';

import TableSortLabel from '@material-ui/core/TableSortLabel';

const descendingComparator = <T extends unknown>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

type Order = 'asc' | 'desc';

const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key,
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }) =>
  (order === 'desc')
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const stableSort = <T extends unknown>(array: ArrayT<>, comparator: (a: T, b: T) => number) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  td: {
    padding: '10px',
  },
}));

interface IComponentProps {
  order: Order;
  orderBy: string;
  data: Array<{}>;
  headers: Array<{}>;
  sortBy: String;
  actionMenu: ReactNode;
  button: ReactNode;
  rowHandler(): void;
}

export const CustomisedTable = ({
  data,
  headers,
  sortBy,
  actionMenu,
  button,
  rowHandler,
}: IComponentProps) => {

  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('calories');

  let sortedData = [...data];
  if (sortBy === 'date') {
    sortedData = sortedData.sort(
      (a, b) => new Date(b.incidentDate) - new Date(a.incidentDate)
    );
  }
  if (sortBy === 'number') {
    sortedData = sortedData.sort((a, b) => b - a);
  }

  const EnhancedTableHead = () => {
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

    const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const StyledTableSortLabel = withStyles((theme) => ({
      icon: {
        fontSize: '16px',
      }
    }))(TableSortLabel);

    return (
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={header.key} className={classes.td}
              sortDirection={orderBy === header.key ? order : false}>
              {header.key !== 'actions' && (
                <Box width={header.size === 'big' ? 160 : 'auto'}>
                  <Typography variant="body2" color="primary">
                    <StyledTableSortLabel
                      active={orderBy === header.key }
                      direction={orderBy === header.key ? order : 'asc'}
                      onClick={createSortHandler(header.key)}
                    >
                      {header.label}
                      {orderBy === header.key ? (
                        <span className={classes.visuallyHidden}>
                        </span>
                      ) : null}
                    </StyledTableSortLabel>
                  </Typography>
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      {sortedData.length > 0 && (
        <Paper className={classes.wrapper}>
          <Table aria-label="simple table">
            <EnhancedTableHead />
            <TableBody>
              {stableSort(sortedData, getComparator(order, orderBy)).map((row, index) =>
                  <TableRow key={index}>
                    {headers.map((header) => (
                      <TableCell key={header.key} className={classes.td}>
                        {header.key === 'index' && (
                          <Typography variant="body2">{index + 1}</Typography>
                        )}
                        {header.key !== 'actions' && (
                          <Typography variant="body2">
                            {row[header.key]}
                          </Typography>
                        )}
                        {header.key === 'actions' && (
                          <IconButton
                            size="small"
                            onClick={(e) => rowHandler(e, row)}
                            aria-haspopup="true"
                          >
                            <Icon>more_vert</Icon>
                          </IconButton>
                        )}
                        {header.key === 'buttons' && (
                          <IconButton
                          size="small"
                          onClick={(e) => rowHandler(e, row)}
                          aria-haspopup="true"
                        >
                          {button}
                        </IconButton>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
              )}
            </TableBody>
          </Table>
          {actionMenu}
        </Paper>
      )}
    </>
  );
};
