/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type updateKeyThreatInput = {
    readonly id: string;
    readonly type?: string | null;
    readonly probability?: string | null;
    readonly impact?: string | null;
    readonly commentary?: string | null;
    readonly notes?: string | null;
    readonly riskDetermination?: string | null;
    readonly clientMutationId?: string | null;
};
export type UpdateKeyThreatMutationVariables = {
    input: updateKeyThreatInput;
};
export type UpdateKeyThreatMutationResponse = {
    readonly updateKeyThreat: {
        readonly keyThreat: {
            readonly id: string;
        } | null;
    } | null;
};
export type UpdateKeyThreatMutation = {
    readonly response: UpdateKeyThreatMutationResponse;
    readonly variables: UpdateKeyThreatMutationVariables;
};



/*
mutation UpdateKeyThreatMutation(
  $input: updateKeyThreatInput!
) {
  updateKeyThreat(input: $input) {
    keyThreat {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "updateKeyThreatInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateKeyThreat",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "updateKeyThreatPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "keyThreat",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "KeyThreat",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdateKeyThreatMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdateKeyThreatMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdateKeyThreatMutation",
            "id": null,
            "text": "mutation UpdateKeyThreatMutation(\n  $input: updateKeyThreatInput!\n) {\n  updateKeyThreat(input: $input) {\n    keyThreat {\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '64faf4cf6bb1f9bc139ed58f47adafb0';
export default node;
