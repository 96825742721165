export const sectionsSelectorDecorator = [
  {
    field: 'selectAll',
    updates: {
      importBusinessIntent: (selectAllValue) => selectAllValue,
      importItineraryLegs: (selectAllValue) => selectAllValue,
      importIncidentAnalysis: (selectAllValue) => selectAllValue,
      importRiskDetermination: (selectAllValue) => selectAllValue,
      importRiskMitigation: (selectAllValue) => selectAllValue,
      importRiskAssessment: (selectAllValue) => selectAllValue
    }
  },
];
