import { graphql } from 'react-relay';

export const UpdateParticipantMutation = graphql`
  mutation UpdateParticipantMutation($input: updateParticipantInput!) {
    updateParticipant(input: $input) {
      participant {
        id
      }
    }
  }
`;
