import React from 'react';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';
import { history } from 'store';

import { ObtainFromWorkday } from './pages/login/ObtainFromWorkday';
import { SelectMode } from './pages/login/SelectMode';
import { LoginPersonalDetails } from './pages/login/LoginPersonalDetails';

import { Dashboard } from './pages/Dashboard';

import { Plan } from './pages/Plan';

import { NotFound } from './pages/NotFound';
import { App } from './App';

const ExternalRedirect = ({ to, push = false }) => {
  if (push) {
    window.location.href = to;
  } else {
    window.location.replace(to);
  }
  return null;
};

export const PrivateRoute = (props) => {
  const { user } = useAuthData();
  return user ? (
    <Route {...props} />
  ) : (
    <ExternalRedirect push to={'http://octalogin.com'} />
  );
};

const useAuthData = () => {
  console.log('TODO: Check here, if user is authenticated');
  return { user: 'John' };
};

export const Routes = () => (
  <ThemeProvider theme={theme}>
    <ConnectedRouter history={history}>
      <Switch>
        <PrivateRoute path={`/dashboard`} component={Dashboard} />
        <PrivateRoute path={`/plan/:planId`} component={Plan} />

        <PrivateRoute exact path={`/app`} component={App} />

        <PrivateRoute path={`/select-mode`} component={SelectMode} />
        <PrivateRoute
          path={`/login-personal-details`}
          component={LoginPersonalDetails}
        />

        <PrivateRoute path={`/`} component={ObtainFromWorkday} />
        <Route path={`/*`} render={NotFound} />
      </Switch>
    </ConnectedRouter>
  </ThemeProvider>
);
