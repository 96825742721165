import { graphql } from 'react-relay';

export const UpdateParticipantEmergencyContactMutation = graphql`
  mutation UpdateParticipantEmergencyContactMutation(
    $input: updateParticipantEmergencyContactInput!
  ) {
    updateParticipantEmergencyContact(input: $input) {
      participantEmergencyContact {
        id
      }
    }
  }
`;
