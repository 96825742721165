import {
  Environment,
  Network,
  RecordSource,
  Store,
  Variables,
  RequestParameters,
  UploadableMap,
  Observable,
  GraphQLResponse,
  CacheConfig,
} from 'relay-runtime';

const HRT_API_URL = process.env.REACT_APP_HRT_API
  ? process.env.REACT_APP_HRT_API
  : 'https://localhost:8443/api/graphql';

const fetchQuery = (
  operation: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig,
  uploadables?: UploadableMap | null
) =>
  Observable.create <
  GraphQLResponse >
  ((sink) => {

    let headers  = {
      'Content-Type': 'application/json',
    };
    let body: FormData | string = JSON.stringify({
      query: operation.text,
      variables,
    });

    if (uploadables != null && Object.keys(uploadables).length) {
      body = new FormData();
      body.append('operations', JSON.stringify({
        query: operation.text,
        variables,
      }));
      body.append('map', JSON.stringify(
        Object.keys(uploadables).reduce((list, key) => {
          let variablesMap = [[`variables.input.${key}`]];
          if (Array.isArray(uploadables[key])) {
            variablesMap = uploadables[key].map((_, i) => [`variables.input.${key}.${i}`]);
          }
          return [
            ...list,
            ...variablesMap,
          ];
        }, []).reduce((map, value, index) => ({...map, [index]: value}), {})
      ));

      Object.keys(uploadables).forEach((key, i) => {
        if (Array.isArray(uploadables[key])) {
          uploadables[key].forEach((file, index) => {
            (body as FormData).append(index, file);
          });
        } else {
          (body as FormData).append(i, uploadables[key]);
        }
      });

      headers = {};
    }

    // tslint:disable-next-line: no-floating-promises
    fetch(HRT_API_URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        // Authorization: `bearer ${TOKEN}`,
        ...headers
      },
      body,
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.errors) {
          if (json.data === null) {
            sink.error(json.errors);
            return;
          }
          console.error(json.errors);
        }
        sink.next(json);

        sink.complete();
      });
  });

export const modernEnvironment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});
