const readyMinFunctions: any = {};
const readyMaxFunctions: any = {};
const readyPatternFunctions: any = {};

export const requiredValidator = (value: any) =>
  !value && typeof value !== 'boolean' ? 'Required' : undefined;

export const mustBeNumberValidator = (value: any) =>
  isNaN(value) ? 'Must be a number' : undefined;

export const maxLengthValidator = (max: number) => {
  if (!readyMaxFunctions[max]) {
    readyMaxFunctions[max] = (value: any) =>
      value && value.length > max
        ? `Must be ${max} characters or less`
        : undefined;
    return readyMaxFunctions[max];
  }
  return readyMaxFunctions[max];
};

export const minLengthValidator = (min: any) => {
  if (!readyMinFunctions[min]) {
    readyMinFunctions[min] = (value: any) =>
      value && value.length < min
        ? `Must be more than ${min} characters`
        : undefined;
    return readyMinFunctions[min];
  }
  return readyMinFunctions[min];
};

export const patternValidator = (pattern: { key: string, rgx: string }) => {
  if (!readyPatternFunctions[pattern.key]) {
    readyPatternFunctions[pattern.key] = (value: string) =>
      value && new RegExp(pattern.rgx).test(value)
        ? 'Invalid format'
        : undefined;
    return readyPatternFunctions[pattern.key];
  }
  return readyPatternFunctions[pattern.key];
};

export const emailValidator = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const phoneValidator = (value: any) =>
  value && (!/^[0-9]+$/i.test(value.number) || value.code === '') ? 'Invalid phone number or missing country code' : undefined;
