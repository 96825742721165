import React from 'react';
import moment from 'moment';
import countries from 'data/countries.json';
import countriesNames from 'data/countriesNames.json';
import departmentRoles from 'data/departmentRoles.json';

const office = (value) => `${value.country.toUpperCase()} ${value.location}`;
const date = (value) => moment(value).format('DD MMMM, YYYY');
const approver = (value) => `${value.firstName} ${value.lastName}`;
export const approverLabel = (value, level) => {
  const role = departmentRoles.filter((role) => role.department === value)[0];
  return { label: `${role[level].label}` };
};
const phone = (value) =>
  countries
    .filter((country) => country.dialCode === value.code)[0]
    .iso2.toUpperCase() + ` ${value.code} ${value.number}`;

export const PERSONAL_DETAILS_PROFILE = [
  'avatar',
  'firstName',
  'middleName',
  'lastName',
  'mobilePhoneNumbers',
  'satellitePhoneNumbers',
  'vismoProfile',
  'email',
  'birthDetails',
  'nationality',
  'employer',
  'employmentStatus',
  'insuranceProvider',
  'policyNumber',
  'department',
  'position',
  'roleInTrip',
  'bureauAssigned',
  'domicileCity',
  'lastHefatReceived',
  'level1Approver',
  'level2Approver',
  'level3Approver',
];

export const PERSONAL_DETAILS_PHYSICAL = [
  'biologicalSex',
  'bloodType',
  'height',
  'eyeColor',
  'correctedVision',
  'hairColorLength',
  'identifyingMarksDescription',
  'identifyingMarksImages',
  'primaryPhysicianName',
  'physicianContactNumber',
  'disabilitiesOrOtherMedicalConditions',
  'medicationsAndDosage',
  'drugOrOtherAllergies',
];

export const PERSONAL_DETAILS_PROOFOFLIFE = [
  'pollQuestionsAnswers',
  'privacy-act-form',
  'passport-scans',
];

export const PERSONAL_DETAILS_EMERGENCYCONTACTS = [
  'firstName',
  'middleName',
  'lastName',
  'mobilePhoneNumbers',
  'gender',
  'nationality',
  'email',
  'languagePreference',
  'specialInstructions',
];

export const PERSONAL_DETAILS_PROFILE_LABELS = {
  avatar: {
    label: 'Photo',
    sublabel: '',
  },
  firstName: {
    label: 'Name',
    sublabel: '(First, Middle, LAST)',
  },
  mobilePhoneNumbers: {
    label: 'Mobile Phone Number',
    sublabel: '(Country, Country Code, Local Number, IMEI)',
  },
  satellitePhoneNumbers: {
    label: 'Satellite Phone Number',
    sublabel: '(Country Code, Local Number, IMEI)',
  },
  vismoProfile: {
    label: 'Active Vismo Profile',
    sublabel: '',
  },
  email: {
    label: 'Email',
    sublabel: '',
  },
  birthDetails: {
    label: 'Date of Birth (DoB)',
    sublabel: '',
  },
  nationality: {
    label: 'Nationality',
    sublabel: '',
  },
  employer: {
    label: 'Employer',
    sublabel: '',
  },
  employmentStatus: {
    label: 'Employment Status',
    sublabel: '',
  },
  insuranceProvider: {
    label: 'Insurance Provider',
    sublabel: '',
  },
  policyNumber: {
    label: 'Insurance Policy No.',
    sublabel: '',
  },
  department: {
    label: 'Department',
    sublabel: '',
  },
  position: {
    label: 'Position/Title',
    sublabel: '',
  },
  roleInTrip: {
    label: 'Role in Trip',
    sublabel: '',
  },
  bureauAssigned: {
    label: 'Bureau/Office Assigned',
    sublabel: '',
  },
  domicileCity: {
    label: 'Domicile Country/City',
    sublabel: '',
  },
  lastHefatReceived: {
    label: 'Last HEFAT Received',
    sublabel: '(Hostile Environment Training)',
  },
  level1Approver: {
    label: 'Line Manager',
  },
  level2Approver: {
    label: 'Bureau Chief (editorial)',
  },
  level3Approver: {
    label: 'Senior Regional Editor (editorial)',
  },
  biologicalSex: {
    label: 'Biological sex',
  },
  bloodType: {
    label: 'Blood Type',
  },
  height: {
    label: 'Height',
  },
  eyeColor: {
    label: 'Eye Color',
  },
  correctedVision: {
    label: 'Corrected Vision',
  },
  hairColorLength: {
    label: 'hair Color & Length',
  },
  identifyingMarksDescription: {
    label: 'Identifying Marks Description',
  },
  identifyingMarksImages: {
    label: 'Identifying Marks Images',
  },
  primaryPhysicianName: {
    label: "Primary Physicain's Full Name",
  },
  physicianContactNumber: {
    label: "Physician's Contact Number",
  },
  disabilitiesOrOtherMedicalConditions: {
    label: 'Disabilities or other Medical Conditions',
  },
  medicationsAndDosage: {
    label: 'Medications and Dosage',
  },
  drugOrOtherAllergies: {
    label: 'Drug or Other Allergies',
  },
  pollQuestionsAnswers: {
    question: {
      label: 'Question',
    },
    answer: {
      label: 'Answer',
    },
  },
  'privacy-act-form': {
    label: 'Privacy Act Form',
  },
  'passport-scans': {
    label: 'Passport Scan(s)',
  },
  gender: {
    label: 'Gender',
  },
  languagePreference: {
    label: 'Language Preferance',
  },
  specialInstructions: {
    label: 'Special instructions',
  },
  type: {
    label: 'Relationship type',
  },
};

const departments = [
  {
    name: 'News(Photo)',
    value: 'news_photo',
  },
  {
    name: 'News(Editorial)',
    value: 'news_editorial',
  },
  {
    name: 'News(Video)',
    value: 'news_video',
  },
  {
    name: 'News(Editorial Opinion)',
    value: 'news_editorial_opinion',
  },
  {
    name: 'Non-News',
    value: 'non_news',
  },
];
export const PERSONAL_DETAILS_TRANSFORMERS = {
  avatar: (value) => <strong>{value}</strong>,
  firstName: (first, middle, last) => {
    const lastName = last !== null ? last.toUpperCase() : '';
    return (
      <>
        {first} {middle} {lastName}
      </>
    );
  },
  vismoProfile: (active) => (active ? 'Active' : 'Not active'),
  correctedVision: (yes) => (yes ? 'Yes' : 'No'),
  birthDetails: (value) => date(value),
  department: (value) =>
    departments.filter((dep) => value === dep.value)[0]['name'],
  bureauAssigned: (value) => office(value),
  domicileCity: (value) => office(value),
  lastHefatReceived: (value) => date(value),
  level1Approver: (value) => approver(value),
  level1ApproverLabel: (value, level) => approverLabel(value, level),
  level2Approver: (value) => approver(value, 'level1Approver'),
  level2ApproverLabel: (value, level) => approverLabel(value, level),
  level3Approver: (value) => approver(value),
  level3ApproverLabel: (value, level) => approverLabel(value, level),
  mobilePhoneNumbers: (value) => {
    const found = countries.filter(
      (country) => country.dialCode === value.code
    )[0];
    const iso2found = found ? `${found.iso2.toUpperCase()} ` : '';
    return `${iso2found}${value.code} ${value.number} / IMEI: ${
      value.imei ? value.imei : '-'
    }`;
  },
  satellitePhoneNumbers: (value) =>
    `${value.code} ${value.number} / IMEI: ${value.imei ? value.imei : '-'}`,
  nationality: (value) => {
    const country = countriesNames.filter(
      (country) => country.location === value
    )[0];
    return country ? country.code : 'US';
  },
  physicianContactNumber: (value) => phone(value),
};
