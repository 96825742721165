/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetActivityPlanClosingNotesQueryVariables = {
    id: string;
};
export type GetActivityPlanClosingNotesQueryResponse = {
    readonly activityPlan: {
        readonly id: string;
        readonly riskDetermination: {
            readonly submittedStatus: boolean | null;
        } | null;
        readonly riskAssessment: {
            readonly submittedStatus: boolean | null;
        } | null;
        readonly activityPlanClosingNotes: {
            readonly id: string;
            readonly debriefNotes: string | null;
            readonly lessonsLearned: unknown;
            readonly submittedStatus: boolean | null;
        } | null;
    } | null;
};
export type GetActivityPlanClosingNotesQuery = {
    readonly response: GetActivityPlanClosingNotesQueryResponse;
    readonly variables: GetActivityPlanClosingNotesQueryVariables;
};



/*
query GetActivityPlanClosingNotesQuery(
  $id: ID!
) {
  activityPlan(id: $id) {
    id
    riskDetermination {
      submittedStatus
      id
    }
    riskAssessment {
      submittedStatus
      id
    }
    activityPlanClosingNotes {
      id
      debriefNotes
      lessonsLearned
      submittedStatus
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "submittedStatus",
        "args": null,
        "storageKey": null
    } as any), v4 = [
        (v3 /*: any*/)
    ], v5 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "activityPlanClosingNotes",
        "storageKey": null,
        "args": null,
        "concreteType": "ActivityPlanClosingNotes",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "debriefNotes",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "lessonsLearned",
                "args": null,
                "storageKey": null
            },
            (v3 /*: any*/)
        ]
    } as any), v6 = [
        (v3 /*: any*/),
        (v2 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetActivityPlanClosingNotesQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskDetermination",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskDetermination",
                            "plural": false,
                            "selections": (v4 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskAssessment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskAssessment",
                            "plural": false,
                            "selections": (v4 /*: any*/)
                        },
                        (v5 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetActivityPlanClosingNotesQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskDetermination",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskDetermination",
                            "plural": false,
                            "selections": (v6 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskAssessment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskAssessment",
                            "plural": false,
                            "selections": (v6 /*: any*/)
                        },
                        (v5 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetActivityPlanClosingNotesQuery",
            "id": null,
            "text": "query GetActivityPlanClosingNotesQuery(\n  $id: ID!\n) {\n  activityPlan(id: $id) {\n    id\n    riskDetermination {\n      submittedStatus\n      id\n    }\n    riskAssessment {\n      submittedStatus\n      id\n    }\n    activityPlanClosingNotes {\n      id\n      debriefNotes\n      lessonsLearned\n      submittedStatus\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'f83514823c5b1581e4829a66f0b8353e';
export default node;
