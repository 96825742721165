import { graphql } from 'react-relay';

export const CreateItineraryContactMutation = graphql`
  mutation CreateItineraryContactMutation(
    $input: createItineraryContactInput!
  ) {
    createItineraryContact(input: $input) {
      itineraryContact {
        id
      }
    }
  }
`;
