import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { arabicToRoman } from 'helper/arabicToRoman';
import { RemoveLegBtn } from 'components/Buttons/RemoveLegBtn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { customTheme } from 'theme';
import { QueryRenderer } from 'react-relay';
import { modernEnvironment } from 'config/environment';
import { GetPlanItineraryLegQuery } from 'graphql/GetPlanItineraryLegQuery';
import { GetPlanItineraryContactQuery } from 'graphql/GetPlanItineraryContactQuery';
import { ValidationError } from 'components/ValidationError';
import { SectionHeader } from 'components/Plan/SectionHeader';
import { Loader } from 'components/Loader';
import ItineraryLegForm from 'data/plan/itinerary-leg.json';
import ItineraryLegFormDisabled from 'data/plan/disabled/itinerary-leg.json';
import ItineraryContactsForm from 'data/plan/itinerary-contacts.json';
import ItineraryContactsFormDisabled from 'data/plan/disabled/itinerary-contacts.json';
import { FormBuilder } from 'components';
import { UpdatePlanItineraryLegMutation } from 'graphql/UpdatePlanItineraryLegMutation';
import { UpdatePlanItineraryContactMutation } from 'graphql/UpdatePlanItineraryContactMutation';
import { commitMutationForm } from 'helper/commitMutationForm';
import createDecorator from 'final-form-calculate';
import * as moment from 'moment';
import { AutocompletePeopleContext } from 'contexts/AutocompletePeopleContext';
import { PlanContext } from 'contexts/PlanContext';
import timezones from 'data/timezones.json';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  panelSummary: {
    backgroundColor: customTheme.colors.veryLightPinkThree,
  },
}));

const calculator = createDecorator(
  {
    field: 'travelDepartureTime', // when minimum changes...
    updates: {
      // ...update maximum to the result of this function
      estTravelTime: (departureDateValue, allValues) =>
        calculateTravelTime(
          departureDateValue,
          allValues.travelArrivalTime,
          allValues.travelDepartureTimezone,
          allValues.travelArrivalTimezone
        ),
    },
  },
  {
    field: 'travelArrivalTime', // when minimum changes...
    updates: {
      // ...update maximum to the result of this function
      estTravelTime: (travelArrivalTimeValue, allValues) =>
        calculateTravelTime(
          allValues.travelDepartureTime,
          travelArrivalTimeValue,
          allValues.travelDepartureTimezone,
          allValues.travelArrivalTimezone
        ),
    },
  },
  {
    field: 'travelDepartureTimezone', // when minimum changes...
    updates: {
      // ...update maximum to the result of this function
      estTravelTime: (travelDepartureTimezoneValue, allValues) =>
        calculateTravelTime(
          allValues.travelDepartureTime,
          allValues.travelArrivalTime,
          travelDepartureTimezoneValue,
          allValues.travelArrivalTimezone
        ),
    },
  },
  {
    field: 'travelArrivalTimezone', // when minimum changes...
    updates: {
      // ...update maximum to the result of this function
      estTravelTime: (travelArrivalTimezoneValue, allValues) =>
        calculateTravelTime(
          allValues.travelDepartureTime,
          allValues.travelArrivalTime,
          allValues.travelDepartureTimezone,
          travelArrivalTimezoneValue
        ),
    },
  }
);

const calculateTravelTime = (
  departureDate,
  arrivalDate,
  departureTimezone,
  arrivalTimezone
) => {
  const duration = moment.duration(
    moment(arrivalDate).diff(moment(departureDate))
  );
  const selectedDepartureTimezone = timezones.find(
    (zone) => zone.utc[0] === departureTimezone
  );
  const selectedArrivalTimezone = timezones.find(
    (zone) => zone.utc[0] === arrivalTimezone
  );
  if (!duration || !selectedDepartureTimezone || !selectedArrivalTimezone) {
    return;
  }
  const diff = Math.abs(
    selectedDepartureTimezone.offset - selectedArrivalTimezone.offset
  );
  let calculatedDuration = duration;
  if (selectedDepartureTimezone.offset > selectedArrivalTimezone.offset) {
    calculatedDuration = duration.subtract(diff, 'hours');
  } else if (
    selectedDepartureTimezone.offset < selectedArrivalTimezone.offset
  ) {
    calculatedDuration = duration.add(diff, 'hours');
  }

  // Below is option, with showing up to thirty days periods of duration
  // const formatted = moment.utc(moment.duration(calculatedDuration).asMilliseconds()).format("DD:HH:mm")
  return moment.duration(calculatedDuration).asMilliseconds() < 0
    ? `minus ${calculatedDuration.humanize()}`
    : calculatedDuration.humanize();
};

export const Leg = () => {
  const { legId, index, planId } = useParams();
  const classes = useStyles();
  const [expanded, setExpanded] = useState('panel1');
  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [isSubmitted, setIsSubmitted] = useState(false);
  return (
    <>
      <SectionHeader
        title={`Leg ${arabicToRoman(parseInt(index, 10))}`}
        backUrl={`/plan/${planId}/itinerary`}
      />
      <Box width={760} pb={13}>
        <Grid container justify="space-between" alignItems="flex-start">
          <Box p={1.5} pb={3.5} flex={1}>
            <Typography variant="h1" component="h2">
              Leg <b>{arabicToRoman(parseInt(index, 10))}</b>
            </Typography>
          </Box>
          {!isSubmitted && (
            <Box p={1.5} pb={3.5}>
              <RemoveLegBtn legId={legId} planId={planId} />
            </Box>
          )}
          <ExpansionPanel
            className={classes.root}
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.panelSummary}
            >
              <Typography variant="h4">Travel</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.root}>
              {legId === 'newLeg' ? (
                <Loader />
              ) : (
                <QueryRenderer
                  environment={modernEnvironment}
                  query={GetPlanItineraryLegQuery}
                  variables={{
                    legId: `/api/itinerary_legs/${legId}`,
                    planId: `/api/activity_plans/${planId}`,
                  }}
                  render={({ error, props }) => {
                    if (error) {
                      return (
                        <ValidationError>{error[0].message}</ValidationError>
                      );
                    } else if (props) {
                      const {
                        itineraryLeg,
                        activityPlan: {
                          participants: planParticipants,
                          statusItineraryLegs,
                          durationFrom,
                          durationTo,
                        },
                      } = props;
                      const { participants: legParticipants } = itineraryLeg;
                      const data = [
                        {
                          id: 'participants',
                          values: legParticipants,
                          options: planParticipants,
                        },
                      ];
                      data.forEach((dataObject) => {
                        dataObject.options = dataObject.options.map(
                          (option) => ({
                            fullName: `${option.firstName} ${option.lastName}`,
                            ...option,
                          })
                        );
                        dataObject.values = dataObject.values.map((value) => ({
                          fullName: `${value.firstName} ${value.lastName}`,
                          ...value,
                        }));
                      });

                      setIsSubmitted(
                        statusItineraryLegs !== 'development' ? true : false
                      );

                      return (
                        <AutocompletePeopleContext.Provider value={data}>
                          <PlanContext.Provider
                            value={{ id: `/api/activity_plans/${planId}` }}
                          >
                            <FormBuilder
                              formObject={
                                !isSubmitted
                                  ? ItineraryLegForm
                                  : ItineraryLegFormDisabled
                              }
                              initialValues={itineraryLeg}
                              decorators={[calculator]}
                              onSubmit={(values) => {
                                const {
                                  estTravelTime,
                                  ...filteredValues
                                } = values;

                                commitMutationForm({
                                  values: {
                                    id: `/api/itinerary_legs/${legId}`,
                                    ...filteredValues,
                                  },
                                  mutation: UpdatePlanItineraryLegMutation,
                                });
                              }}
                              onValidate={(values) => {
                                const errors = {};
                                if (
                                  values.travelDepartureTime &&
                                  values.travelArrivalTime &&
                                  moment(values.travelDepartureTime) >=
                                    moment(values.travelArrivalTime)
                                ) {
                                  errors.travelArrivalTime = `Choose a date after ${moment(
                                    values.travelDepartureTime
                                  ).format('l HH:mm')}`;
                                }

                                if (
                                  values.travelDepartureTime &&
                                  values.travelArrivalTime &&
                                  moment(values.travelArrivalTime) <=
                                    moment(values.travelDepartureTime)
                                ) {
                                  errors.travelDepartureTime = `Choose a date before ${moment(
                                    values.travelArrivalTime
                                  ).format('l HH:mm')}`;
                                }

                                if (
                                  values.travelDepartureTime &&
                                  moment(values.travelDepartureTime) <=
                                    moment(durationFrom)
                                ) {
                                  errors.travelDepartureTime = `Choose a date after the plan's start date - ${moment(
                                    durationFrom
                                  ).format('l HH:mm')}`;
                                }

                                if (
                                  values.travelDepartureTime &&
                                  moment(values.travelDepartureTime) >=
                                    moment(durationTo)
                                ) {
                                  errors.travelDepartureTime = `Choose a date before the plan's end date - ${moment(
                                    durationTo
                                  ).format('l HH:mm')}`;
                                }

                                if (
                                  values.travelArrivalTime &&
                                  moment(values.travelArrivalTime) >=
                                    moment(durationTo)
                                ) {
                                  errors.travelArrivalTime = `Choose a date before the plan's end date - ${moment(
                                    durationTo
                                  ).format('l HH:mm')}`;
                                }

                                if (
                                  values.travelArrivalTime &&
                                  moment(values.travelArrivalTime) <=
                                    moment(durationFrom)
                                ) {
                                  errors.travelArrivalTime = `Choose a date after the plan's start date - ${moment(
                                    durationFrom
                                  ).format('l HH:mm')}`;
                                }

                                return errors;
                              }}
                            />
                          </PlanContext.Provider>
                        </AutocompletePeopleContext.Provider>
                      );
                    }
                    return <Loader />;
                  }}
                />
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.root}
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className={classes.panelSummary}
            >
              <Typography variant="h4">Relative contact</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.root}>
              {legId === 'newLeg' ? (
                <Loader />
              ) : (
                <QueryRenderer
                  environment={modernEnvironment}
                  query={GetPlanItineraryContactQuery}
                  variables={{
                    id: `/api/itinerary_legs/${legId}`,
                  }}
                  render={({ error, props }) => {
                    if (error) {
                      return (
                        <ValidationError>{error[0].message}</ValidationError>
                      );
                    } else if (props) {
                      const {
                        itineraryLeg: { itineraryContacts },
                      } = props;

                      return (
                        <FormBuilder
                          formObject={
                            !isSubmitted
                              ? ItineraryContactsForm
                              : ItineraryContactsFormDisabled
                          }
                          initialValues={itineraryContacts[0]}
                          onSubmit={(values) => {
                            commitMutationForm({
                              values: {
                                id: itineraryContacts[0].id,
                                ...values,
                              },
                              mutation: UpdatePlanItineraryContactMutation,
                            });
                          }}
                        />
                      );
                    }
                    return <Loader />;
                  }}
                />
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Box>
    </>
  );
};
