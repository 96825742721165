import { graphql } from 'react-relay';

export const UpdateKeyThreatMutation = graphql`
  mutation UpdateKeyThreatMutation($input: updateKeyThreatInput!) {
    updateKeyThreat(input: $input) {
      keyThreat {
        id
      }
    }
  }
`;
