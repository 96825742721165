import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Card, Button, Grid } from '@material-ui/core';
import { usePlanSections } from 'hooks/usePlanSections';

export const PlanFooter = (props: {}) => {
  const location = useLocation();
  const [nextSectionUrl] = usePlanSections(location.pathname);

  return (
    <Box position="fixed" bottom={0} left={0} width={'100%'} zIndex="drawer">
      <Card>
        <Grid container justify="flex-end">
          <Box p={3} pr={1.5}>
            <Button
              component={Link}
              to="/dashboard"
              variant="outlined"
              color="primary"
            >
              Exit
            </Button>
          </Box>
          <Box p={3} pl={0}>
            <Button
              component={Link}
              to={nextSectionUrl}
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Card>
    </Box>
  );
};
