import { graphql } from 'react-relay';

export const GetCurrentEmployeeQuery = graphql`
  query GetCurrentEmployeeQuery($id: ID!) {
    employee(id: $id) {
      id
      email
      firstName
      lastName
      mediaObjects(type: "avatar") {
        id
        content
      }
    }
  }
`;
