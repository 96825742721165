/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetEmployeeEmergencyContactsQueryVariables = {
    id: string;
};
export type GetEmployeeEmergencyContactsQueryResponse = {
    readonly employee: {
        readonly employeeEmergencyContacts: ReadonlyArray<{
            readonly id: string;
            readonly type: string;
            readonly firstName: string | null;
            readonly middleName: string | null;
            readonly lastName: string | null;
            readonly mobilePhoneNumbers: unknown;
            readonly gender: string | null;
            readonly nationality: unknown;
            readonly email: string | null;
            readonly relationship: string | null;
            readonly languagePreference: string | null;
            readonly specialInstructions: string | null;
        } | null> | null;
    } | null;
    readonly employees: ReadonlyArray<{
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly id: string;
        readonly email: string;
    } | null> | null;
};
export type GetEmployeeEmergencyContactsQuery = {
    readonly response: GetEmployeeEmergencyContactsQueryResponse;
    readonly variables: GetEmployeeEmergencyContactsQueryVariables;
};



/*
query GetEmployeeEmergencyContactsQuery(
  $id: ID!
) {
  employee(id: $id) {
    employeeEmergencyContacts {
      id
      type
      firstName
      middleName
      lastName
      mobilePhoneNumbers
      gender
      nationality
      email
      relationship
      languagePreference
      specialInstructions
    }
    id
  }
  employees {
    firstName
    lastName
    id
    email
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "employeeEmergencyContacts",
        "storageKey": null,
        "args": null,
        "concreteType": "EmployeeEmergencyContact",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "type",
                "args": null,
                "storageKey": null
            },
            (v3 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "middleName",
                "args": null,
                "storageKey": null
            },
            (v4 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "mobilePhoneNumbers",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "gender",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "nationality",
                "args": null,
                "storageKey": null
            },
            (v5 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "relationship",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "languagePreference",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "specialInstructions",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v7 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "employees",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": true,
        "selections": [
            (v3 /*: any*/),
            (v4 /*: any*/),
            (v2 /*: any*/),
            (v5 /*: any*/)
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetEmployeeEmergencyContactsQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "employee",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Employee",
                    "plural": false,
                    "selections": [
                        (v6 /*: any*/)
                    ]
                },
                (v7 /*: any*/)
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetEmployeeEmergencyContactsQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "employee",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Employee",
                    "plural": false,
                    "selections": [
                        (v6 /*: any*/),
                        (v2 /*: any*/)
                    ]
                },
                (v7 /*: any*/)
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetEmployeeEmergencyContactsQuery",
            "id": null,
            "text": "query GetEmployeeEmergencyContactsQuery(\n  $id: ID!\n) {\n  employee(id: $id) {\n    employeeEmergencyContacts {\n      id\n      type\n      firstName\n      middleName\n      lastName\n      mobilePhoneNumbers\n      gender\n      nationality\n      email\n      relationship\n      languagePreference\n      specialInstructions\n    }\n    id\n  }\n  employees {\n    firstName\n    lastName\n    id\n    email\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '9aa6b0e731cf829ca400a07df2d80ef7';
export default node;
