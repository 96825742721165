import { graphql } from 'react-relay';

export const UpdateActivityLogMutation = graphql`
  mutation UpdateActivityLogMutation($input: updateActivityLogInput!) {
    updateActivityLog(input: $input) {
      activityLog {
        dateTimeEST
        localizedTime
        gmtTime
        remarks
        author
      }
    }
  }
`;
