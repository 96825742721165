import { commitMutation, graphql, Environment } from 'react-relay';

const mutation = graphql`
  mutation UpdateActivityPlanMutation($input: updateActivityPlanInput!) {
    updateActivityPlan(input: $input) {
      activityPlan {
        id
        name
      }
    }
  }
`;

export const UpdateActivityPlan = (environment: Environment, values: {}) => {
  const variables = {
    input: values,
  };

  commitMutation(environment, {
    mutation,
    variables,
  });
};
