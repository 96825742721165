import { graphql } from 'react-relay';

export const GetEmployeeMediaObjectsQuery = graphql`
  query GetEmployeeMediaObjectsQuery($employee: ID!, $type: String) {
    employee(id: $employee) {
      id
      mediaObjects(type: $type) {
        id
        type
        content
      }
    }
  }
`;
