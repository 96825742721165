import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { IFieldProps } from './types';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ValidationError } from 'components/ValidationError';
import { AutocompletePeopleContext } from 'contexts/AutocompletePeopleContext';

type Props = FieldRenderProps<string, any> & IFieldProps;

const useStyles = makeStyles((_) => ({
  chipInputRoot: {
    minWidth: 300,
  },
}));

export const AutocompleteField = (props: Props) => {
  const {
    input,
    meta,
    templateOptions: {
      label,
      placeholder,
      disabled,
      fullWidth,
      freeSolo,
      dataId,
      multiple,
    },
  } = props;
  const [founds, setFounds] = useState([]);

  const classes = useStyles();
  const showError = meta.dirty || meta.invalid;

  return (
    <AutocompletePeopleContext.Consumer>
      {(data) => {
        const currentData = data.filter((o) => o.id === dataId)[0];
        const { options, values } = currentData;

        if (
          input.value &&
          input.value.length > 0 &&
          typeof input.value[0] === 'object'
        ) {
          if (founds.length === 0) {
            setFounds(input.value.map((o) => o.id));
          }
          setFounds(founds);
          input.onChange(founds);
        }

        const handleChange = (
          event: React.ChangeEvent<{ value: unknown }>,
          value: string,
          options
        ) => {
          let found;
          if (multiple) {
            found = value.map((val) => {
              let result = val;
              const matchName = options.find(
                (option) => option.fullName === val
              );
              if (matchName) {
                result = matchName.id;
              }
              return result;
            });
          } else {
            const matchName = options.find(
              (option) => option.fullName === value
            );
            if (matchName) {
              found = matchName.id;
            }
          }
          setFounds(found);
          input.onChange(found);
        };
        return (
          <>
            <Autocomplete
              multiple={multiple}
              id={input.name}
              disabled={disabled}
              options={options.map((option) => option.fullName)}
              defaultValue={values.map((value) => value.fullName)}
              freeSolo={freeSolo}
              renderTags={(value: Array<string> | string, getTagProps) =>
                value.map((option: {}, index: number) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              onChange={(e, value) => handleChange(e, value, options)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={'outlined'}
                  label={label}
                  fullWidth={fullWidth}
                  helperText={
                    showError ? meta.error || meta.submitError : undefined
                  }
                  disabled={disabled}
                  onChange={input.onChange}
                  placeholder={placeholder ? placeholder : label}
                  classes={{
                    root: classes.chipInputRoot,
                  }}
                />
              )}
            />
            {showError && (
              <ValidationError>
                {meta.error || meta.submitError}
              </ValidationError>
            )}
          </>
        );
      }}
    </AutocompletePeopleContext.Consumer>
  );
};
