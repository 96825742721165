import React, { useEffect, useRef, useState } from 'react';
import IncidentForm from 'data/incident.json';
import { FormBuilder } from 'components';
import { commitMutationForm } from 'helper/commitMutationForm';
import { CreateIncidentMutation } from 'graphql/CreateIncidentMutation';
import { FullscreenLoader } from 'components/FullscreenLoader';
import moment from 'moment';

interface IComponentProps {
  planId: string;
  doneHandler(): void;
}

export const AddIncidentDialog = (props: IComponentProps) => {
  const { planId, doneHandler } = props;
  const mounted = useRef(true);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);
  return (
    <>
      <FormBuilder
        enableAutosave={false}
        formObject={IncidentForm}
        submitButtonName="Add"
        onSubmit={(values) => {
          setIsDisabled(true);
          commitMutationForm({
            values: {
              activityPlan: `/api/activity_plans/${planId}`,
              ...values,
            },
            mutation: CreateIncidentMutation,
            onCompleteCallback: (response, errors) => {
              mounted.current && doneHandler();
              mounted.current && setIsDisabled(false);
            },
            onErrorCallback: (error) => {
              mounted.current && setIsDisabled(false);
            },
          });
        }}
        onValidate={(values: {}) => {
          const errors = {};
          if (values.incidentDate) {
            const formattedDate = moment(new Date(values.incidentDate)).format(
              'YYYY-MM-DD hh:mm'
            );
            if (formattedDate === 'Invalid date') {
              errors.incidentDate = 'Format should be MM-DD-YYYY';
            }
          }

          return errors;
        }}
      />
      <FullscreenLoader isLoading={isDisabled} />
    </>
  );
};
