import React, { ReactNode, Fragment } from 'react';
import { QueryRenderer } from 'react-relay';
import { modernEnvironment } from 'config/environment';
import { ISubNavigationItem } from './PlanNavigation';
import { clearId } from 'helper/clearId';
import { GetPlanParticipantInfoQuery } from 'graphql/GetPlanParticipantInfoQuery';

type Renderer = (
  error: Error | null,
  navigations: Array<ISubNavigationItem> | null
) => ReactNode;

interface ILegsNavigationQuery {
  planId: string;
  render: Renderer;
}

export const ParticipantsNavigationQuery = ({
  planId,
  render,
}: ILegsNavigationQuery) => (
  <QueryRenderer
    environment={modernEnvironment}
    query={GetPlanParticipantInfoQuery}
    variables={{
      id: `/api/activity_plans/${planId}`,
    }}
    render={({ error, props }) => {
      if (error != null) {
        return render(error, null);
      }
      if (props != null) {
        const {
          activityPlan: { participants },
        } = props;

        const participantsSorted = [...participants].sort((a, b) =>
          a !== null && b !== null && a.role === 'primary_participant' ? -1 : 1
        );

        const navigationItems = participantsSorted.map((participant, index) => {
          if (!participant || participant === null) {
            return <Fragment key={index}></Fragment>;
          }
          return {
            text:
              participant.role === 'primary_participant'
                ? 'Primary Participant'
                : `Participant ${index + 1}`,
            route: `/participant-info/${index + 1}/${clearId(participant.id)}`,
          };
        });

        return render(null, navigationItems);
      }
      return render(null, null);
    }}
  />
);
