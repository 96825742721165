/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetEmployeeMediaObjectsQueryVariables = {
    employee: string;
    type?: string | null;
};
export type GetEmployeeMediaObjectsQueryResponse = {
    readonly employee: {
        readonly id: string;
        readonly mediaObjects: ReadonlyArray<{
            readonly id: string;
            readonly type: string;
            readonly content: string | null;
        } | null> | null;
    } | null;
};
export type GetEmployeeMediaObjectsQuery = {
    readonly response: GetEmployeeMediaObjectsQueryResponse;
    readonly variables: GetEmployeeMediaObjectsQueryVariables;
};



/*
query GetEmployeeMediaObjectsQuery(
  $employee: ID!
  $type: String
) {
  employee(id: $employee) {
    id
    mediaObjects(type: $type) {
      id
      type
      content
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "employee",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "type",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "employee",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "employee"
                }
            ],
            "concreteType": "Employee",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "mediaObjects",
                    "storageKey": null,
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "type",
                            "variableName": "type"
                        }
                    ],
                    "concreteType": "EmployeeMediaObject",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "type",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "content",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetEmployeeMediaObjectsQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "GetEmployeeMediaObjectsQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "GetEmployeeMediaObjectsQuery",
            "id": null,
            "text": "query GetEmployeeMediaObjectsQuery(\n  $employee: ID!\n  $type: String\n) {\n  employee(id: $employee) {\n    id\n    mediaObjects(type: $type) {\n      id\n      type\n      content\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'c26b60600e7226af382f1fee447b871e';
export default node;
