import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import * as moment from 'moment';

interface IComponentProps {
  durationfrom: string;
}

export const ApprovedTile = ({ durationfrom }: IComponentProps) => (
  <Grid container justify="center" direction="column" alignItems="center">
    <Box p={0.5} pt={'35px'}>
      <Typography variant="h4">Active on</Typography>
    </Box>
    <Box p={0.5} pb={1}>
      <Typography variant="h2">{moment(durationfrom).format('LLL')}</Typography>
    </Box>
  </Grid>
);
