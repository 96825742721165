/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetActivityLogQueryVariables = {
    id: string;
};
export type GetActivityLogQueryResponse = {
    readonly activityLog: {
        readonly dateTimeEST: string;
        readonly localizedTime: string;
        readonly gmtTime: string;
        readonly remarks: string;
        readonly author: string;
    } | null;
};
export type GetActivityLogQuery = {
    readonly response: GetActivityLogQueryResponse;
    readonly variables: GetActivityLogQueryVariables;
};



/*
query GetActivityLogQuery(
  $id: ID!
) {
  activityLog(id: $id) {
    dateTimeEST
    localizedTime
    gmtTime
    remarks
    author
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "dateTimeEST",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "localizedTime",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "gmtTime",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "remarks",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "author",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetActivityLogQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityLog",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityLog",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetActivityLogQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityLog",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityLog",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetActivityLogQuery",
            "id": null,
            "text": "query GetActivityLogQuery(\n  $id: ID!\n) {\n  activityLog(id: $id) {\n    dateTimeEST\n    localizedTime\n    gmtTime\n    remarks\n    author\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '8b37306eabeee155df67a5ef3238c7bd';
export default node;
