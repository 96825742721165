import { graphql } from 'react-relay';

export const DeleteKeyThreatMutation = graphql`
  mutation DeleteKeyThreatMutation($input: deleteKeyThreatInput!) {
    deleteKeyThreat(input: $input) {
      keyThreat {
        id
      }
    }
  }
`;
