import { graphql } from 'react-relay';

export const CreateKeyBehaviourMutation = graphql`
  mutation CreateKeyBehaviourMutation($input: createKeyBehaviourInput!) {
    createKeyBehaviour(input: $input) {
      keyBehaviour {
        id
        instruction
        category
      }
    }
  }
`;
