/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanItineraryLegsQueryVariables = {
    id: string;
};
export type GetPlanItineraryLegsQueryResponse = {
    readonly activityPlan: {
        readonly id: string;
        readonly statusParticipants: string | null;
        readonly statusItineraryLegs: string;
        readonly itineraryLegs: ReadonlyArray<{
            readonly id: string;
            readonly travelDepartureTime: string | null;
            readonly travelArrivalTime: string | null;
        } | null> | null;
    } | null;
};
export type GetPlanItineraryLegsQuery = {
    readonly response: GetPlanItineraryLegsQueryResponse;
    readonly variables: GetPlanItineraryLegsQueryVariables;
};



/*
query GetPlanItineraryLegsQuery(
  $id: ID!
) {
  activityPlan(id: $id) {
    id
    statusParticipants
    statusItineraryLegs
    itineraryLegs {
      id
      travelDepartureTime
      travelArrivalTime
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "activityPlan",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "ActivityPlan",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "statusParticipants",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "statusItineraryLegs",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "itineraryLegs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ItineraryLeg",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "travelDepartureTime",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "travelArrivalTime",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanItineraryLegsQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanItineraryLegsQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanItineraryLegsQuery",
            "id": null,
            "text": "query GetPlanItineraryLegsQuery(\n  $id: ID!\n) {\n  activityPlan(id: $id) {\n    id\n    statusParticipants\n    statusItineraryLegs\n    itineraryLegs {\n      id\n      travelDepartureTime\n      travelArrivalTime\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '33c1748cefde4b7357b318cdf3ee43ac';
export default node;
