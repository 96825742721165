import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { SectionStatus } from 'components/SectionStatus';

export const IncidentAnalysisIntro = () => (
  <>
    <Box p={1.5} pb={0.5}>
      <Typography variant="h1" component="h2">
        Incident / SIGACT Analysis
      </Typography>
    </Box>
    <Box p={1.5} pb={3.5}>
      <Typography variant="body1">
        A summary of relevant incidents to inform threats.
      </Typography>
    </Box>
    <SectionStatus
      completedBy="Security Expert"
      requireLegalReview={true}
    ></SectionStatus>
  </>
);
