/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetEmployeePhysicalDetailsQueryVariables = {
    id: string;
};
export type GetEmployeePhysicalDetailsQueryResponse = {
    readonly employee: {
        readonly biologicalSex: string | null;
        readonly bloodType: string | null;
        readonly height: number | null;
        readonly eyeColor: string | null;
        readonly correctedVision: boolean | null;
        readonly hairColorLength: string | null;
        readonly identifyingMarksDescription: string | null;
        readonly primaryPhysicianName: string | null;
        readonly physicianContactNumber: unknown;
        readonly disabilitiesOrOtherMedicalConditions: string | null;
        readonly medicationsAndDosage: string | null;
        readonly drugOrOtherAllergies: string | null;
    } | null;
};
export type GetEmployeePhysicalDetailsQuery = {
    readonly response: GetEmployeePhysicalDetailsQueryResponse;
    readonly variables: GetEmployeePhysicalDetailsQueryVariables;
};



/*
query GetEmployeePhysicalDetailsQuery(
  $id: ID!
) {
  employee(id: $id) {
    biologicalSex
    bloodType
    height
    eyeColor
    correctedVision
    hairColorLength
    identifyingMarksDescription
    primaryPhysicianName
    physicianContactNumber
    disabilitiesOrOtherMedicalConditions
    medicationsAndDosage
    drugOrOtherAllergies
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "biologicalSex",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "bloodType",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "height",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "eyeColor",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "correctedVision",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "hairColorLength",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "identifyingMarksDescription",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "primaryPhysicianName",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "physicianContactNumber",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "disabilitiesOrOtherMedicalConditions",
        "args": null,
        "storageKey": null
    } as any), v12 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "medicationsAndDosage",
        "args": null,
        "storageKey": null
    } as any), v13 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "drugOrOtherAllergies",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetEmployeePhysicalDetailsQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "employee",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Employee",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        (v12 /*: any*/),
                        (v13 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetEmployeePhysicalDetailsQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "employee",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Employee",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        (v12 /*: any*/),
                        (v13 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetEmployeePhysicalDetailsQuery",
            "id": null,
            "text": "query GetEmployeePhysicalDetailsQuery(\n  $id: ID!\n) {\n  employee(id: $id) {\n    biologicalSex\n    bloodType\n    height\n    eyeColor\n    correctedVision\n    hairColorLength\n    identifyingMarksDescription\n    primaryPhysicianName\n    physicianContactNumber\n    disabilitiesOrOtherMedicalConditions\n    medicationsAndDosage\n    drugOrOtherAllergies\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '284d5fa236b7a22faeb43fa9533fcd2a';
export default node;
