import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DeletePlanItineraryLegMutation } from 'graphql/DeletePlanItineraryLegMutation';
import { commitMutationForm } from 'helper/commitMutationForm';

interface IComponentProps {
  legId: string;
  planId: string;
}

export const RemoveLegBtn = ({ legId, planId }: IComponentProps) => {
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const deleteLeg = useCallback(() => {
    setIsDisabled(true);

    commitMutationForm({
      values: {
        id: `/api/itinerary_legs/${legId}`,
      },
      mutation: DeletePlanItineraryLegMutation,
      optimisticResponse: {
        deleteItineraryLeg: {
          itineraryLeg: {
            id: `/api/itinerary_legs/${legId}`,
          },
        },
      },

      configs: [
        {
          type: 'NODE_DELETE',
          deletedIDFieldName: `/api/itinerary_legs/${legId}`,
        },
      ],
      onUpdate: (store) => {
        const legs = store.getRootField('deleteItineraryLeg');
        const leg = legs.getLinkedRecord('itineraryLeg');
        store.delete(leg.getDataID());
      },
      onCompleteCallback: (response) => {
        mounted.current && setIsDisabled(false);
      },
      onErrorCallback: () => {
        mounted.current && setIsDisabled(false);
      },
    });
    history.replace(`/plan/${planId}/itinerary`);
  }, [planId, legId, history]);

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={deleteLeg}
      disabled={isDisabled}
    >
      Remove leg
    </Button>
  );
};
