/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetIncidentQueryVariables = {
    id: string;
};
export type GetIncidentQueryResponse = {
    readonly incident: {
        readonly gap: number | null;
        readonly incidentDate: string | null;
        readonly target: string | null;
        readonly quantity: number | null;
        readonly location: string | null;
        readonly details: string | null;
        readonly type: string | null;
    } | null;
};
export type GetIncidentQuery = {
    readonly response: GetIncidentQueryResponse;
    readonly variables: GetIncidentQueryVariables;
};



/*
query GetIncidentQuery(
  $id: ID!
) {
  incident(id: $id) {
    gap
    incidentDate
    target
    quantity
    location
    details
    type
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "gap",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "incidentDate",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "target",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "quantity",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "location",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "details",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetIncidentQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "incident",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Incident",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetIncidentQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "incident",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Incident",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetIncidentQuery",
            "id": null,
            "text": "query GetIncidentQuery(\n  $id: ID!\n) {\n  incident(id: $id) {\n    gap\n    incidentDate\n    target\n    quantity\n    location\n    details\n    type\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '337026ab844b49f4bc6e9d2bc9a7ee4b';
export default node;
