import React, { useState, useEffect, useRef } from 'react';
import { CustomisedDialog } from 'components/CustomisedDialog';
import addParticipants from 'data/plan/add-participants.json';
import { FormBuilder } from 'components';
import { AutocompletePeopleContext } from 'contexts/AutocompletePeopleContext';
import { QueryRenderer, commitMutation } from 'react-relay';
import { CreatePlanQuery } from 'graphql/CreatePlanQuery';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import { modernEnvironment } from 'config/environment';
import { FullscreenLoader } from 'components/FullscreenLoader';
import { AddEmployeesToActivityPlanMutation } from 'graphql/AddEmployeesToActivityPlanMutation';
import { AddFreelancersToActivityPlanMutation } from 'graphql/AddFreelancersToActivityPlanMutation';
import { Button } from '@material-ui/core';

interface IComponentProps {
  planId: string;
  addedParticipants: Array<{}>;
  countHandler(): void;
}

export const AddParticipantDialog = ({
  planId,
  countHandler,
  addedParticipants,
}: IComponentProps) => {
  const [open, setOpen] = useState(false);
  const [isDisabledCreate, setIsDisabledCreate] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const findById = (id: string, dataset: []) => {
    const found = dataset.find((o) => o.id === id);
    return found ? found.email : id;
  };

  return (
    <>
      <Button variant="text" color="primary" onClick={() => setOpen(true)}>
        + Add participant
      </Button>
      <CustomisedDialog
        title="Add new participants"
        subtitle="Select users and import personal info"
        open={open}
        onClose={handleClose}
      >
        <QueryRenderer
          environment={modernEnvironment}
          query={CreatePlanQuery}
          render={({ error, props }) => {
            if (error) {
              return <ValidationError>{error[0].message}</ValidationError>;
            } else if (props) {
              const { employees, freelancers } = props;
              const filteredEmployees = [...employees].filter(
                (el) =>
                  addedParticipants.filter(
                    (anotherEl) => anotherEl.email === el.email
                  ).length === 0
              );
              const filteredfreelancers = [...freelancers].filter(
                (el) =>
                  addedParticipants.filter(
                    (anotherEl) => anotherEl.email === el.email
                  ).length === 0
              );

              const data = [
                { id: 'participants', values: [], options: filteredEmployees },
                { id: 'freelancers', values: [], options: filteredfreelancers },
              ];
              data.forEach((dataObject) => {
                dataObject.options = dataObject.options.map((option) => ({
                  fullName: `${option.firstName} ${option.lastName}`,
                  ...option,
                }));
              });

              return (
                <AutocompletePeopleContext.Provider value={data}>
                  <FormBuilder
                    initialValues={{ employeeType: 'dj' }}
                    enableAutosave={false}
                    formObject={addParticipants}
                    submitting={isDisabledCreate}
                    submitButtonName="Add"
                    onSubmit={(values) => {
                      const {
                        participants: newPlanParticipants,
                        freelancers: newPlanFreelancers,
                      } = values;

                      if (isDisabledCreate) {
                        return;
                      }
                      setIsDisabledCreate(true);

                      // TODO: Refactor the below mutations to something more reusable and beautiful
                      if (
                        // Add both freelancers and employees
                        newPlanParticipants &&
                        newPlanParticipants.length &&
                        newPlanFreelancers &&
                        newPlanFreelancers.length
                      ) {
                        const newPlanParticipantsMails = newPlanParticipants.map(
                          (el) => findById(el, data[0].options)
                        );

                        commitMutation(modernEnvironment, {
                          variables: {
                            input: {
                              id: `/api/activity_plans/${planId}`,
                              emails: newPlanParticipantsMails,
                            },
                          },
                          mutation: AddEmployeesToActivityPlanMutation,
                          onCompleted: (response) => {
                            if (response.addEmployeesToActivityPlan === null) {
                              return;
                            }

                            const newPlanFreelancersMails = newPlanFreelancers.map(
                              (el) => findById(el, data[1].options)
                            );

                            commitMutation(modernEnvironment, {
                              variables: {
                                input: {
                                  id: `/api/activity_plans/${planId}`,
                                  emails: newPlanFreelancersMails,
                                },
                              },
                              mutation: AddFreelancersToActivityPlanMutation,
                              onCompleted: (response) => {
                                mounted.current && setIsDisabledCreate(false);
                                mounted.current && countHandler();
                                mounted.current && handleClose();
                              },
                            });
                          },
                        });
                      } else if (
                        // Add DJ employees mutation
                        newPlanParticipants &&
                        newPlanParticipants.length
                      ) {
                        const newPlanParticipantsMails = newPlanParticipants.map(
                          (el) => findById(el, data[0].options)
                        );

                        commitMutation(modernEnvironment, {
                          variables: {
                            input: {
                              id: `/api/activity_plans/${planId}`,
                              emails: newPlanParticipantsMails,
                            },
                          },
                          mutation: AddEmployeesToActivityPlanMutation,
                          onCompleted: (response) => {
                            mounted.current && setIsDisabledCreate(false);
                            mounted.current && countHandler();
                            mounted.current && handleClose();
                          },
                        });
                      } else if (
                        // Add freelancers mutation
                        newPlanFreelancers &&
                        newPlanFreelancers.length
                      ) {
                        const newPlanFreelancersMails = newPlanFreelancers.map(
                          (el) => findById(el, data[1].options)
                        );

                        commitMutation(modernEnvironment, {
                          variables: {
                            input: {
                              id: `/api/activity_plans/${planId}`,
                              emails: newPlanFreelancersMails,
                            },
                          },
                          mutation: AddFreelancersToActivityPlanMutation,
                          onCompleted: (response) => {
                            mounted.current && setIsDisabledCreate(false);
                            mounted.current && countHandler();
                            mounted.current && handleClose();
                          },
                        });
                      }
                    }}
                    cancelButtonName="Cancel"
                    onCancel={handleClose}
                  />
                </AutocompletePeopleContext.Provider>
              );
            }
            return <Loader />;
          }}
        />
        <FullscreenLoader isLoading={isDisabledCreate} />
      </CustomisedDialog>
    </>
  );
};
