import { graphql } from 'react-relay';

export const DeleteActivityLogMutation = graphql`
  mutation DeleteActivityLogMutation($input: deleteActivityLogInput!) {
    deleteActivityLog(input: $input) {
      activityLog {
        id
      }
    }
  }
`;
