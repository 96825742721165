import React from 'react';
import {
  Typography,
  Paper,
  Icon,
  IconButton,
  Grid,
  Box,
} from '@material-ui/core';
import { arabicToRoman } from 'helper/arabicToRoman';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface IComponentProps {
  departureTime: Date;
  arrivalTime: Date;
  index: number;
  to: string;
}

export const LegTile = (props: IComponentProps) => {
  const { index, departureTime, arrivalTime, to } = props;
  return (
    <Box mb={1} width={'100%'}>
      <Link to={to}>
        <Paper
          component={Grid}
          container
          alignItems="center"
          justify="space-between"
        >
          <Box display="flex" px={3} py={1.5} flex={1}>
            <Box flexBasis={'90px'}>
              <Typography variant="body1">
                Leg {arabicToRoman(index)}
              </Typography>
            </Box>
            <Box>
              {departureTime && moment(departureTime).format('l HH:mm')} &mdash;{' '}
              {arrivalTime && moment(arrivalTime).format('l HH:mm')}
            </Box>
          </Box>
          <Box pr={1.5}>
            <IconButton size="small">
              <Icon>arrow_right</Icon>
            </IconButton>
          </Box>
        </Paper>
      </Link>
    </Box>
  );
};
