/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetParticipantPersonalDetailsQueryVariables = {
    id: string;
};
export type GetParticipantPersonalDetailsQueryResponse = {
    readonly participant: {
        readonly id: string;
        readonly status: string | null;
        readonly role: string;
        readonly email: string | null;
        readonly firstName: string | null;
        readonly middleName: string | null;
        readonly lastName: string | null;
        readonly mobilePhoneNumbers: unknown;
        readonly satellitePhoneNumbers: unknown;
        readonly vismoProfile: boolean | null;
        readonly birthDetails: string | null;
        readonly nationality: unknown;
        readonly employer: string | null;
        readonly employmentStatus: string | null;
        readonly insuranceProvider: string | null;
        readonly policyNumber: string | null;
        readonly department: string | null;
        readonly position: string | null;
        readonly roleInTrip: string | null;
        readonly bureauAssigned: unknown;
        readonly domicileCity: unknown;
        readonly lastHefatReceived: string | null;
        readonly level1Approver: {
            readonly id: string;
            readonly email: string;
        } | null;
        readonly level2Approver: {
            readonly id: string;
            readonly email: string;
        } | null;
        readonly level3Approver: {
            readonly id: string;
            readonly email: string;
        } | null;
        readonly biologicalSex: string | null;
        readonly bloodType: string | null;
        readonly height: number | null;
        readonly eyeColor: string | null;
        readonly correctedVision: boolean | null;
        readonly hairColorLength: string | null;
        readonly identifyingMarksDescription: string | null;
        readonly primaryPhysicianName: string | null;
        readonly physicianContactNumber: unknown;
        readonly disabilitiesOrOtherMedicalConditions: string | null;
        readonly medicationsAndDosage: string | null;
        readonly drugOrOtherAllergies: string | null;
        readonly pollQuestionsAnswers: unknown;
    } | null;
    readonly employees: ReadonlyArray<{
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly id: string;
        readonly email: string;
    } | null> | null;
};
export type GetParticipantPersonalDetailsQuery = {
    readonly response: GetParticipantPersonalDetailsQueryResponse;
    readonly variables: GetParticipantPersonalDetailsQueryVariables;
};



/*
query GetParticipantPersonalDetailsQuery(
  $id: ID!
) {
  participant(id: $id) {
    id
    status
    role
    email
    firstName
    middleName
    lastName
    mobilePhoneNumbers
    satellitePhoneNumbers
    vismoProfile
    birthDetails
    nationality
    employer
    employmentStatus
    insuranceProvider
    policyNumber
    department
    position
    roleInTrip
    bureauAssigned
    domicileCity
    lastHefatReceived
    level1Approver {
      id
      email
    }
    level2Approver {
      id
      email
    }
    level3Approver {
      id
      email
    }
    biologicalSex
    bloodType
    height
    eyeColor
    correctedVision
    hairColorLength
    identifyingMarksDescription
    primaryPhysicianName
    physicianContactNumber
    disabilitiesOrOtherMedicalConditions
    medicationsAndDosage
    drugOrOtherAllergies
    pollQuestionsAnswers
  }
  employees {
    firstName
    lastName
    id
    email
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v5 = [
        (v1 /*: any*/),
        (v2 /*: any*/)
    ], v6 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "participant",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "Participant",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "role",
                    "args": null,
                    "storageKey": null
                },
                (v2 /*: any*/),
                (v3 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "middleName",
                    "args": null,
                    "storageKey": null
                },
                (v4 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "mobilePhoneNumbers",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "satellitePhoneNumbers",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "vismoProfile",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "birthDetails",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "nationality",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "employer",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "employmentStatus",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "insuranceProvider",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "policyNumber",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "department",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "position",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "roleInTrip",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "bureauAssigned",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "domicileCity",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastHefatReceived",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "level1Approver",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Employee",
                    "plural": false,
                    "selections": (v5 /*: any*/)
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "level2Approver",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Employee",
                    "plural": false,
                    "selections": (v5 /*: any*/)
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "level3Approver",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Employee",
                    "plural": false,
                    "selections": (v5 /*: any*/)
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "biologicalSex",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "bloodType",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "height",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "eyeColor",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "correctedVision",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hairColorLength",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "identifyingMarksDescription",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "primaryPhysicianName",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "physicianContactNumber",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "disabilitiesOrOtherMedicalConditions",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "medicationsAndDosage",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "drugOrOtherAllergies",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "pollQuestionsAnswers",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "employees",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": [
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetParticipantPersonalDetailsQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v6 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "GetParticipantPersonalDetailsQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v6 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "GetParticipantPersonalDetailsQuery",
            "id": null,
            "text": "query GetParticipantPersonalDetailsQuery(\n  $id: ID!\n) {\n  participant(id: $id) {\n    id\n    status\n    role\n    email\n    firstName\n    middleName\n    lastName\n    mobilePhoneNumbers\n    satellitePhoneNumbers\n    vismoProfile\n    birthDetails\n    nationality\n    employer\n    employmentStatus\n    insuranceProvider\n    policyNumber\n    department\n    position\n    roleInTrip\n    bureauAssigned\n    domicileCity\n    lastHefatReceived\n    level1Approver {\n      id\n      email\n    }\n    level2Approver {\n      id\n      email\n    }\n    level3Approver {\n      id\n      email\n    }\n    biologicalSex\n    bloodType\n    height\n    eyeColor\n    correctedVision\n    hairColorLength\n    identifyingMarksDescription\n    primaryPhysicianName\n    physicianContactNumber\n    disabilitiesOrOtherMedicalConditions\n    medicationsAndDosage\n    drugOrOtherAllergies\n    pollQuestionsAnswers\n  }\n  employees {\n    firstName\n    lastName\n    id\n    email\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '7f88bae69c120f1fbf22d6c74a76997c';
export default node;
