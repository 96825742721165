/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanNavQueryVariables = {
    id: string;
};
export type GetPlanNavQueryResponse = {
    readonly activityPlan: {
        readonly id: string;
        readonly status: string;
        readonly statusBusinessIntent: string;
        readonly statusParticipants: string | null;
        readonly statusItineraryLegs: string;
        readonly riskDetermination: {
            readonly submittedStatus: boolean | null;
        } | null;
        readonly riskMitigation: {
            readonly submittedStatus: boolean;
        } | null;
        readonly submittedStatusIncidents: boolean;
        readonly riskAssessment: {
            readonly submittedStatus: boolean | null;
        } | null;
        readonly submittedStatusActivityLog: boolean;
        readonly activityPlanClosingNotes: {
            readonly submittedStatus: boolean | null;
        } | null;
    } | null;
};
export type GetPlanNavQuery = {
    readonly response: GetPlanNavQueryResponse;
    readonly variables: GetPlanNavQueryVariables;
};



/*
query GetPlanNavQuery(
  $id: ID!
) {
  activityPlan(id: $id) {
    id
    status
    statusBusinessIntent
    statusParticipants
    statusItineraryLegs
    riskDetermination {
      submittedStatus
      id
    }
    riskMitigation {
      submittedStatus
      id
    }
    submittedStatusIncidents
    riskAssessment {
      submittedStatus
      id
    }
    submittedStatusActivityLog
    activityPlanClosingNotes {
      submittedStatus
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "statusBusinessIntent",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "statusParticipants",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "statusItineraryLegs",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "submittedStatus",
        "args": null,
        "storageKey": null
    } as any), v8 = [
        (v7 /*: any*/)
    ], v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "submittedStatusIncidents",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "submittedStatusActivityLog",
        "args": null,
        "storageKey": null
    } as any), v11 = [
        (v7 /*: any*/),
        (v2 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanNavQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskDetermination",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskDetermination",
                            "plural": false,
                            "selections": (v8 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskMitigation",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskMitigation",
                            "plural": false,
                            "selections": (v8 /*: any*/)
                        },
                        (v9 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskAssessment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskAssessment",
                            "plural": false,
                            "selections": (v8 /*: any*/)
                        },
                        (v10 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "activityPlanClosingNotes",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ActivityPlanClosingNotes",
                            "plural": false,
                            "selections": (v8 /*: any*/)
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanNavQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskDetermination",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskDetermination",
                            "plural": false,
                            "selections": (v11 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskMitigation",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskMitigation",
                            "plural": false,
                            "selections": (v11 /*: any*/)
                        },
                        (v9 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskAssessment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskAssessment",
                            "plural": false,
                            "selections": (v11 /*: any*/)
                        },
                        (v10 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "activityPlanClosingNotes",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ActivityPlanClosingNotes",
                            "plural": false,
                            "selections": (v11 /*: any*/)
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanNavQuery",
            "id": null,
            "text": "query GetPlanNavQuery(\n  $id: ID!\n) {\n  activityPlan(id: $id) {\n    id\n    status\n    statusBusinessIntent\n    statusParticipants\n    statusItineraryLegs\n    riskDetermination {\n      submittedStatus\n      id\n    }\n    riskMitigation {\n      submittedStatus\n      id\n    }\n    submittedStatusIncidents\n    riskAssessment {\n      submittedStatus\n      id\n    }\n    submittedStatusActivityLog\n    activityPlanClosingNotes {\n      submittedStatus\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '137028d48c11ce51077e7b0443fcc5c9';
export default node;
