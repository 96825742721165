import arrayMutators from 'final-form-arrays';
import Box from '@material-ui/core/Box';
import { AutoSave } from './AutoSave';
import { DynamicFormComponent } from 'components/DynamicFormComponent';
import { Field, Form } from 'react-final-form';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  ICondition,
  IFormSection,
  IFormBuilderProps,
} from 'components/fields/types';
import { Button, Grid, Divider } from '@material-ui/core';

const Condition = ({ when, is, children }: ICondition) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const AutoSaveForm = ({ onSubmit }) => {
  const [isSaving, updateIsSaving] = useState(false);

  const timeout = useRef();

  const save = useCallback(
    (values: {}) => {
      if (!values || !Object.keys(values).length) {
        return;
      }

      updateIsSaving(true);
      onSubmit && onSubmit(values);
      timeout.current = setTimeout(() => {
        updateIsSaving(false);
      }, 2000);
    },
    [timeout, onSubmit, updateIsSaving]
  );

  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    },
    [timeout]
  );

  return <AutoSave isSaving={isSaving} debounce={2000} onSave={save} />;
};

export const FormBuilder = ({
  title,
  enabledInnerHeadings = true,
  formObject,
  initialValues,
  step,
  submitting,
  onSubmit,
  submitButtonName,
  cancelButtonName,
  onValidate,
  onCancel,
  decorators,
  enableAutosave = true,
  children,
}: IFormBuilderProps) => (
  <Box width={'100%'}>
    <Typography variant="h2">{title}</Typography>
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={initialValues}
      subscription={{ values: true, errors: true, hasValidationErrors: true }}
      mutators={{
        // potentially other mutators could be merged here
        ...arrayMutators,
      }}
      decorators={decorators}
      render={({
        handleSubmit,
        // form: {
        //   mutators: { push, pop },
        // },
        pristine,
        // form,
        submitting,
        // values,
      }) => (
        <form onSubmit={handleSubmit}>
          {enableAutosave && <AutoSaveForm onSubmit={onSubmit} />}

          {children}
          <Box display="flex" flexDirection="column">
            {formObject &&
              // tslint:disable-next-line: strict-boolean-expressions
              formObject.map((section: IFormSection, i) => {
                const { title, subtitle, form } = section;

                return (
                  <Box key={i} position="relative">
                    {enabledInnerHeadings && title && (
                      <Box pl={1.15}>
                        <Typography variant="h3">{title}</Typography>
                      </Box>
                    )}
                    {enabledInnerHeadings && subtitle && (
                      <Box mb={2} pl={1.15}>
                        <Typography variant="body1" color="textSecondary">
                          {subtitle}
                        </Typography>
                      </Box>
                    )}

                    {step && (
                      <Box position="absolute" top="-10px" right="0">
                        <Typography variant="overline">Step {step}</Typography>
                      </Box>
                    )}
                    {form &&
                      form.map((el, index) => {
                        const { type, fieldGroup, templateOptions } = el;
                        if (type === 'conditional') {
                          return (
                            <Condition
                              when={templateOptions.when}
                              is={templateOptions.is}
                              key={index}
                            >
                              <Box p={1} key={index}>
                                {fieldGroup &&
                                  fieldGroup.map(
                                    (field: any, index: number) => (
                                      <Box my={2} key={index}>
                                        <DynamicFormComponent
                                          {...field}
                                          name={field.key}
                                          type={field.type}
                                        />
                                      </Box>
                                    )
                                  )}
                              </Box>
                            </Condition>
                          );
                        }
                        return (
                          <Box p={1} key={index}>
                            <DynamicFormComponent
                              {...el}
                              name={el.key}
                              type={el.type}
                              key={index}
                            />
                          </Box>
                        );
                      })}
                  </Box>
                );
              })}
            {onSubmit && submitButtonName && (
              <>
                <Box pt={1.5}>
                  <Divider />
                </Box>
                <Grid container justify="flex-end">
                  {cancelButtonName && (
                    <Box p={1.5}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={onCancel}
                      >
                        {cancelButtonName || 'Exit'}
                      </Button>
                    </Box>
                  )}
                  <Box p={1.5} pl={0}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={submitting || pristine}
                    >
                      {submitButtonName || 'Submit'}
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
          </Box>
        </form>
      )}
    />
  </Box>
);
