// tslint:disable:max-file-line-count
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Button, Typography, Grid, Divider } from '@material-ui/core';
import { CustomisedDialog } from 'components/CustomisedDialog';
import createPlan from 'data/create-plan.json';
import { FormBuilder } from 'components';
import { Link } from 'react-router-dom';
import { AutocompletePeopleContext } from 'contexts/AutocompletePeopleContext';
import { QueryRenderer, commitMutation } from 'react-relay';
import { CreatePlanQuery } from 'graphql/CreatePlanQuery';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import { modernEnvironment } from 'config/environment';
import { CreatePlanMutation } from 'graphql/CreatePlanMutation';
import { commitMutationForm } from 'helper/commitMutationForm';
import { SelectedUsersWithRoles } from 'components/SelectedUsersWithRoles';
import { FullscreenLoader } from 'components/FullscreenLoader';
import { AddEmployeesToActivityPlanMutation } from 'graphql/AddEmployeesToActivityPlanMutation';
import { AddFreelancersToActivityPlanMutation } from 'graphql/AddFreelancersToActivityPlanMutation';
import moment from 'moment';
import { ImportPlan } from 'components/CreatePlan/ImportPlan';
import { ImportPlanSections } from 'components/CreatePlan/ImportPlanSections';

export const CreatePlanDialog = () => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [isDisabledCreate, setIsDisabledCreate] = useState(false);
  const mounted = useRef(true);

  const [planName, setPlanName] = useState('New Activity Plan');
  const [planId, setPlanId] = useState('');
  const [formattedPlanId, setFormattedPlanId] = useState('');
  const [planParticipantsWithRoles, setPlanParticipants] = useState([]);
  const [planFreelancersWithRoles, setPlanFreelancers] = useState([]);
  const [planApproversWithRoles, setPlanApprovers] = useState([]);
  const [isDisabledNext, setIsDisabledNext] = useState(true);
  const [importedPlan, setImportedPlan] = useState();

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
      setPlanParticipants([]);
      setPlanFreelancers([]);
      setPlanApprovers([]);
    };
  }, []);

  const participantsCalculated = useMemo(
    () => [
      ...planFreelancersWithRoles,
      ...planParticipantsWithRoles,
      ...planApproversWithRoles,
    ],
    [
      planFreelancersWithRoles,
      planParticipantsWithRoles,
      planApproversWithRoles,
    ]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStep(1);
  };

  const findById = (id: string, dataset: []) => {
    const found = dataset.find((o) => o.id === id);
    return found ? found.email : id;
  };

  const planSelect = (plan) => {
    setIsDisabledNext(false);
    setImportedPlan(plan);
  };

  const CancelSectionsSelection = () => {
    setStep(step - 1);
    setIsDisabledNext(true);
    setImportedPlan('');
  };

  return (
    <Box mb={1.5}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleOpen}
      >
        Create new plan
      </Button>
      {step === 1 && (
        <CustomisedDialog
          title="Create new activity plan"
          subtitle="Plan name will be automatically generated"
          open={open}
          onClose={handleClose}
        >
          <QueryRenderer
            environment={modernEnvironment}
            query={CreatePlanQuery}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error[0].message}</ValidationError>;
              } else if (props) {
                const { employees, freelancers } = props;
                const data = [
                  { id: 'participants', values: [], options: employees },
                  { id: 'freelancers', values: [], options: freelancers },
                ];
                data.forEach((dataObject) => {
                  dataObject.options = dataObject.options.map((option) => ({
                    fullName: `${option.firstName} ${option.lastName}`,
                    ...option,
                  }));
                });

                return (
                  <AutocompletePeopleContext.Provider value={data}>
                    <FormBuilder
                      initialValues={{ employeeType: 'dj' }}
                      enableAutosave={false}
                      formObject={createPlan}
                      submitting={isDisabledCreate}
                      submitButtonName="Create"
                      onSubmit={(values) => {
                        if (isDisabledCreate) {
                          return;
                        }
                        setIsDisabledCreate(true);
                        const {
                          countries_apac,
                          countries_emea,
                          countries_americas,
                          ...restValues
                        } = values;

                        const allowed = [`countries_${values.region}`];

                        const filtered = Object.keys(values)
                          .filter((key) => allowed.includes(key))
                          .reduce((obj, key) => {
                            obj[key] = values[key];
                            return { ...obj };
                          }, {});

                        const valuesFiltered = {
                          countries: filtered[`countries_${values.region}`],
                          ...restValues,
                        };

                        const {
                          employeeType,
                          participants: newPlanParticipants,
                          freelancers: newPlanFreelancers,
                          ...valuesFilteredToSend
                        } = valuesFiltered;

                        commitMutationForm({
                          values: valuesFilteredToSend,
                          mutation: CreatePlanMutation,
                          onCompleteCallback: (response, errors) => {
                            const {
                              createActivityPlan: { activityPlan },
                            } = response;

                            // TODO: Refactor the below mutations to something more reusable and beautiful
                            if (
                              // Add both freelancers and employees
                              newPlanParticipants &&
                              newPlanParticipants.length &&
                              newPlanFreelancers &&
                              newPlanFreelancers.length
                            ) {
                              const newPlanParticipantsMails = newPlanParticipants.map(
                                (el) => findById(el, data[0].options)
                              );

                              commitMutation(modernEnvironment, {
                                variables: {
                                  input: {
                                    id: activityPlan.id,
                                    emails: newPlanParticipantsMails,
                                  },
                                },
                                mutation: AddEmployeesToActivityPlanMutation,
                                onCompleted: (response) => {
                                  if (
                                    response.addEmployeesToActivityPlan === null
                                  ) {
                                    return;
                                  }
                                  const {
                                    addEmployeesToActivityPlan: {
                                      activityPlan: { participants },
                                    },
                                  } = response;

                                  const participantsWithRoles = participants.map(
                                    (participant) => ({
                                      role: 'participants',
                                      employee: { ...participant },
                                    })
                                  );
                                  setPlanParticipants(participantsWithRoles);
                                  const newPlanFreelancersMails = newPlanFreelancers.map(
                                    (el) => findById(el, data[1].options)
                                  );

                                  commitMutation(modernEnvironment, {
                                    variables: {
                                      input: {
                                        id: activityPlan.id,
                                        emails: newPlanFreelancersMails,
                                      },
                                    },
                                    mutation: AddFreelancersToActivityPlanMutation,
                                    onCompleted: (response) => {
                                      if (
                                        response.addFreelancersToActivityPlan ===
                                        null
                                      ) {
                                        return;
                                      }
                                      const {
                                        addFreelancersToActivityPlan: {
                                          activityPlan: {
                                            participants: freelancers,
                                          },
                                        },
                                      } = response;
                                      const freelancersWithRoles = freelancers.map(
                                        (participant) => ({
                                          role: 'participants',
                                          employee: { ...participant },
                                        })
                                      );
                                      setPlanParticipants([]);
                                      setPlanFreelancers(freelancersWithRoles);
                                    },
                                  });
                                },
                              });
                            } else if (
                              // Add DJ employees mutation
                              newPlanParticipants &&
                              newPlanParticipants.length
                            ) {
                              const newPlanParticipantsMails = newPlanParticipants.map(
                                (el) => findById(el, data[0].options)
                              );

                              commitMutation(modernEnvironment, {
                                variables: {
                                  input: {
                                    id: activityPlan.id,
                                    emails: newPlanParticipantsMails,
                                  },
                                },
                                mutation: AddEmployeesToActivityPlanMutation,
                                onCompleted: (response) => {
                                  if (
                                    response.addEmployeesToActivityPlan === null
                                  ) {
                                    return;
                                  }
                                  const {
                                    addEmployeesToActivityPlan: {
                                      activityPlan: { participants },
                                    },
                                  } = response;
                                  const participantsWithRoles = participants.map(
                                    (participant) => ({
                                      role: 'participants',
                                      employee: { ...participant },
                                    })
                                  );

                                  setPlanParticipants(participantsWithRoles);
                                },
                              });
                            } else if (
                              // Add freelancers mutation
                              newPlanFreelancers &&
                              newPlanFreelancers.length
                            ) {
                              const newPlanFreelancersMails = newPlanFreelancers.map(
                                (el) => findById(el, data[1].options)
                              );

                              commitMutation(modernEnvironment, {
                                variables: {
                                  input: {
                                    id: activityPlan.id,
                                    emails: newPlanFreelancersMails,
                                  },
                                },
                                mutation: AddFreelancersToActivityPlanMutation,
                                onCompleted: (response) => {
                                  if (
                                    response.addFreelancersToActivityPlan ===
                                    null
                                  ) {
                                    return;
                                  }
                                  const {
                                    addFreelancersToActivityPlan: {
                                      activityPlan: { participants },
                                    },
                                  } = response;
                                  const participantsWithRoles = participants.map(
                                    (participant) => ({
                                      role: 'participants',
                                      employee: { ...participant },
                                    })
                                  );
                                  setPlanFreelancers(participantsWithRoles);
                                },
                              });
                            }

                            setPlanName(activityPlan.name);
                            setPlanId(activityPlan.id);
                            setFormattedPlanId(
                              activityPlan.id.replace(
                                'api/activity_plans',
                                'plan'
                              )
                            );
                            setPlanApprovers(activityPlan.supporters);
                            setStep(step + 1);
                            mounted.current && setIsDisabledCreate(false);
                          },
                          onErrorCallback: () => {
                            mounted.current && setIsDisabledCreate(false);
                          },
                        });
                      }}
                      cancelButtonName="Cancel"
                      onCancel={handleClose}
                      onValidate={(values: {}) => {
                        const errors = {};
                        if (!values.durationFrom) {
                          errors.durationFrom = 'Required';
                        }
                        if (!values.durationTo) {
                          errors.durationTo = 'Required';
                        } else if (
                          values.durationFrom &&
                          values.durationTo &&
                          values.durationFrom >= values.durationTo
                        ) {
                          errors.durationTo = `Choose a date after ${moment(
                            values.durationFrom
                          ).format('l')}`;
                        }
                        if (!values.region) {
                          errors.region = 'Required';
                        }

                        return errors;
                      }}
                    />
                  </AutocompletePeopleContext.Provider>
                );
              }
              return <Loader />;
            }}
          />
          <FullscreenLoader isLoading={isDisabledCreate} />
        </CustomisedDialog>
      )}
      {step === 2 && (
        <CustomisedDialog
          title="Activity plan created"
          subtitle={planName}
          open={open}
          onClose={handleClose}
        >
          <Box mb={1.5}>
            <Typography variant="body1">
              The following members have been notified:
            </Typography>
          </Box>
          <SelectedUsersWithRoles users={participantsCalculated} />
          <Box pt={1.5}>
            <Divider />
          </Box>
          <Grid container justify="flex-end">
            <Box p={1.5}>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Close
              </Button>
            </Box>
            <Box p={1.5} pl={0}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setStep(step + 1)}
              >
                Import plan
              </Button>
            </Box>
            <Box p={1.5} pl={0}>
              <Button
                component={Link}
                to={formattedPlanId}
                color="primary"
                variant="contained"
              >
                Edit plan
              </Button>
            </Box>
          </Grid>
        </CustomisedDialog>
      )}
      {step === 3 && (
        <CustomisedDialog
          title="Import from existing plans"
          open={open}
          onClose={handleClose}
        >
          <Box mb={1.5}>
            <ImportPlan filter="complete" planSelect={planSelect} />
          </Box>
          <Box pt={1.5}>
            <Divider />
          </Box>
          <Grid container justify="flex-end">
            <Box p={1.5}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setStep(step - 1)}
              >
                Back
              </Button>
            </Box>
            <Box p={1.5} pl={0}>
              <Button
                disabled={isDisabledNext}
                variant="contained"
                color="primary"
                onClick={() => setStep(step + 1)}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </CustomisedDialog>
      )}
      {step === 4 && (
        <CustomisedDialog
          title={importedPlan.name}
          open={open}
          onClose={handleClose}
        >
          <Box mb={1.5}>
            <Box my={1.5}>
              <Typography>Select sections you want to import</Typography>
            </Box>
            <ImportPlanSections
              createdPlanId={planId}
              importedPlanId={importedPlan.id}
              CancelSectionsSelection={CancelSectionsSelection}
            />
          </Box>
        </CustomisedDialog>
      )}
    </Box>
  );
};
