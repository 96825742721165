import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { QueryRenderer } from 'react-relay';
import { GetCurrentEmployeeQuery } from '../../graphql/GetCurrentEmployeeQuery';
import { modernEnvironment } from '../../config/environment';
import { Box, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getInitials } from 'helper/getInitials';
import { Loader } from 'components/Loader';
import { CURRENT_USER } from 'config/globals';
import { ValidationError } from 'components/ValidationError';
import { AvatarWithBlob } from 'components/AvatarWithBlob';

const useStyles = makeStyles((theme) => ({
  input: {
    color: '#fff',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#fff',
  },
  textField: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(1),
    width: 200,
    color: '#fff',
  },
  smallAvatar: {
    width: 48,
    height: 48,
    color: theme.palette.primary.main,
  },
  email: {
    fontSize: 14,
    color: theme.palette.grey[600],
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}));

export const DashboardCurrentUser = () => {
  const classes = useStyles();

  return (
    <QueryRenderer
      environment={modernEnvironment}
      query={GetCurrentEmployeeQuery}
      variables={{
        // TODO: The current user to be fetched from the HRT API, through the headers of the served Collaboration Dashboard App
        id: CURRENT_USER.id,
      }}
      render={({ error, props }) => {
        if (error) {
          return <ValidationError>{error[0].message}</ValidationError>;
        } else if (props) {
          const { employee } = props;
          if (!employee || employee === null) {
            return <></>;
          }
          return (
            <>
              <Box mb={1.5}>
                <Link to="/dashboard/personal-details">
                  <AvatarWithBlob
                    className={classes.smallAvatar}
                    src={
                      employee.mediaObjects &&
                      employee.mediaObjects[0] &&
                      employee.mediaObjects[0].content
                    }
                  >
                    {getInitials(employee.firstName, employee.lastName)}
                  </AvatarWithBlob>
                </Link>
              </Box>

              <Grid container justify="flex-start" alignItems="center">
                <Typography variant="h3">{employee.firstName}</Typography>
                <Box mr={0.5}></Box>
                <Typography variant="h3" className={classes.uppercase}>
                  {employee.lastName}
                </Typography>
              </Grid>
              <Typography className={classes.email}>
                {employee.email}
              </Typography>
            </>
          );
        }
        return <Loader />;
      }}
    />
  );
};
