import React from 'react';
import { IFieldProps } from './types';

import { FieldRenderProps } from 'react-final-form';
import { BaseCountrySelector } from './BaseCountrySelector';
import { QueryRenderer } from 'react-relay';
import { modernEnvironment } from 'config/environment';
import { Loader } from 'components/Loader';
import { BaseOfficeField } from 'components/fields/BaseOfficeField';
import { GetPlanLocationsQuery } from 'graphql/GetPlanLocationsQuery';
import { PlanContext } from 'contexts/PlanContext';

type Props = FieldRenderProps<string, any> & IFieldProps;

export const PlanLocationsQuery = (props: Props) => {
  const {
    input,
    templateOptions: { required, disabled },
  } = props;

  return (
    <PlanContext.Consumer>
      {(data) => (
        <BaseOfficeField
          {...props}
          render={(innerValue, handleChange, showError) => (
            <QueryRenderer
              environment={modernEnvironment}
              query={GetPlanLocationsQuery}
              variables={{
                id: data.id,
              }}
              render={({ props }) => {
                if (props) {
                  const {
                    activityPlan: { countries },
                  } = props;
                  const locations = countries.map((country) => ({
                    location: country,
                  }));
                  return (
                    <BaseCountrySelector
                      fullWidth={true}
                      name={input.name}
                      value={innerValue.country}
                      required={required}
                      disabled={disabled}
                      showError={showError}
                      options={locations}
                      onChange={handleChange('country')}
                    />
                  );
                }
                return <Loader />;
              }}
            />
          )}
        ></BaseOfficeField>
      )}
    </PlanContext.Consumer>
  );
};
