import React, { useState } from 'react';
import {
  FormLabel,
  FormControl,
  Typography,
  makeStyles,
  Theme,
  Box,
  IconButton,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { FieldRenderProps } from 'react-final-form';
import { IFieldProps, IUploadFieldProps } from './types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { UpdateMediaObjectMutation } from 'graphql/UpdateMediaObjectMutation';
import { CURRENT_USER } from 'config/globals';

import { modernEnvironment } from 'config/environment';
import { commitMutation, QueryRenderer } from 'react-relay';
import { GetEmployeeMediaObjectsQuery } from 'graphql/GetEmployeeMediaObjectsQuery';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import { DeleteEmployeeMediaObjectsMutation } from 'graphql/DeleteEmployeeMediaObjectsMutation';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

type Props = FieldRenderProps<string, any> & IFieldProps & IUploadFieldProps;

const useStyles = makeStyles((theme: Theme) => ({
  dropZone: {
    minwidth: '60%',
    minHeight: '100%',
    marginBottom: '10px',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  dropzoneParagraph: {
    fontSize: '1rem',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const UploadField = (props: Props) => {
  const {
    input,
    meta,
    templateOptions: {
      label,
      placeholder,
      filesLimit,
      maxFileSize,
      acceptedFiles,
      required,
      disabled = false,
      type,
      fullWidth,
    },
  } = props;
  const classes = useStyles();
  const [count, updateCount] = useState(0);

  // prettier-ignore
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const handleDelete = (idToDelete: string) => {
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: idToDelete,
        },
      },
      mutation: DeleteEmployeeMediaObjectsMutation,
      onCompleted: (response) => {
        updateCount(count + 1);
      },
    });
  };

  const handleDrop = (file: Object) => {
    const files = [file];

    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: CURRENT_USER.id,
          files: files.map(() => null),
          type,
        },
      },
      mutation: UpdateMediaObjectMutation,
      uploadables: {
        files,
      },
      onCompleted: (response) => {
        updateCount(count + 1);
      },
    });
  };

  const openPreview = (file: string) => {
    const image = new Image();
    image.src = file.content;

    const w = window.open('');
    if (file.content.indexOf('application/pdf' !== -1)) {
      w.document.write(
        `<iframe width='100%' height='100%' src='${encodeURI(
          file.content
        )}'></iframe>`
      );
    } else {
      w.document.write(image.outerHTML);
    }
  };

  return (
    <FormControl fullWidth={fullWidth} variant="standard" width={'100%'}>
      <FormLabel required={required} id={`${input.name}-label`}>
        <Box pb={0.5}>{label}</Box>
      </FormLabel>
      <QueryRenderer
        width={'100%'}
        key={count}
        environment={modernEnvironment}
        query={GetEmployeeMediaObjectsQuery}
        variables={{
          employee: CURRENT_USER.id,
          type,
        }}
        render={({ error, props }) => {
          if (error) {
            return <ValidationError>{error[0].message}</ValidationError>;
          } else if (props) {
            const {
              employee: { mediaObjects },
            } = props;

            return (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                width={'100%'}
              >
                {!disabled && (
                  <Box width={'50%'}>
                    <DropzoneArea
                      classes={classes}
                      onDrop={handleDrop}
                      onDelete={handleDelete}
                      acceptedFiles={acceptedFiles}
                      filesLimit={filesLimit}
                      maxFileSize={maxFileSize}
                      dropzoneText={placeholder}
                      initialFiles={[]}
                      showPreviewsInDropzone={false}
                      showFileNames={false}
                      showPreviews={false}
                      useChipsForPreview={false}
                    />
                  </Box>
                )}
                <Box width={'50%'} px={1.5}>
                  {mediaObjects &&
                    mediaObjects[0] &&
                    mediaObjects.map((file) => (
                      <Box
                        key={file.id}
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                      >
                        <InsertDriveFileOutlinedIcon color="secondary" />
                        <Box flex={1} px={1} py={0.5}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            className={classes.dropzoneParagraph}
                            onClick={() => openPreview(file)}
                          >
                            {file.id}
                          </Typography>
                        </Box>
                        {!disabled && (
                          <IconButton
                            aria-label="close"
                            onClick={() => handleDelete(file.id)}
                            size="small"
                          >
                            <CloseIcon color="primary" />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                </Box>
              </Box>
            );
          }
          return <Loader />;
        }}
      />

      {showError && (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      )}
    </FormControl>
  );
};
