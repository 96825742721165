import { graphql } from 'react-relay';

export const UpdateIncidentAnalysisStatusMutation = graphql`
  mutation UpdateIncidentAnalysisStatusMutation(
    $input: submitIncidentsActivityPlanInput!
  ) {
    submitIncidentsActivityPlan(input: $input) {
      activityPlan {
        id
        submittedStatusIncidents
      }
    }
  }
`;
