import React, { Fragment } from 'react';
import { Box } from '@material-ui/core';
import { IInformationDetailsProps, IMediaObject } from './types';
import { makeStyles } from '@material-ui/core/styles';
import { PERSONAL_DETAILS_PROFILE_LABELS } from 'data/personal-details';
import { InformationLabel } from './InformationLabel';
import { arabicToRoman } from 'helper/arabicToRoman';
import { AvatarWithBlob } from 'components/AvatarWithBlob';
import Flags from 'country-flag-icons/react/3x2';

export const useStyles = makeStyles((theme) => ({
  smallAvatar: {
    width: 82,
    height: 82,
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  itemsList: {
    display: 'flex',
    width: '100%',
  },
  infoItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 0, 2),
  },
  itemLabel: {
    width: '240px',
  },
  itemValue: {
    margin: theme.spacing(0, 1),
    flex: '1',
    maxWidth: '50%',
  },
  markImage: {
    maxWidth: '60px',
    margin: '0 6px 0 0',
  },
  flag: {
    width: '24px',
    margin: theme.spacing(0, 0.5),
  },
}));

export const InformationDetails = (props: IInformationDetailsProps) => {
  const { items, data, transformers } = props;
  const classes = useStyles();

  return (
    <Box flexDirection="column" className={classes.itemsList}>
      {items.map((key: string, index: number) => {
        if (key === 'avatar') {
          // tslint:disable
          const avatar =
            data['mediaObjects'] &&
            data['mediaObjects'].filter(
              (media: IMediaObject) => media.type === 'avatar'
            )[0];
          // tslint:enable
          return (
            <Box key={index} display="flex" className={classes.infoItem}>
              <Box className={classes.itemLabel}>
                <InformationLabel data={PERSONAL_DETAILS_PROFILE_LABELS[key]} />
              </Box>
              <Box className={classes.itemValue}>
                <AvatarWithBlob
                  src={avatar && avatar.content}
                  className={classes.smallAvatar}
                />
              </Box>
            </Box>
          );
        }
        if (key === 'firstName') {
          return (
            <Box key={index} className={classes.infoItem}>
              <Box key={index} display="flex">
                <Box className={classes.itemLabel}>
                  <InformationLabel
                    data={PERSONAL_DETAILS_PROFILE_LABELS[key]}
                  />
                </Box>
                <Box className={classes.itemValue}>
                  {transformers[key](
                    data.firstName,
                    data.middleName,
                    data.lastName
                  )}
                </Box>
              </Box>
            </Box>
          );
        }
        if (key === 'middleName' || key === 'lastName') {
          return null;
        }
        if (key === 'vismoProfile') {
          return (
            <Box key={index} className={classes.infoItem}>
              <Box key={index} display="flex">
                <Box className={classes.itemLabel}>
                  <InformationLabel
                    data={PERSONAL_DETAILS_PROFILE_LABELS[key]}
                  />
                </Box>
                <Box className={classes.itemValue}>
                  {transformers[key](
                    data.firstName,
                    data.middleName,
                    data.lastName
                  )}
                </Box>
              </Box>
            </Box>
          );
        }
        if (key === 'passport-scans' || key === 'privacy-act-form') {
          // tslint:disable-next-line
          const scans =
            // tslint:disable-next-line
            data['mediaObjects'] &&
            // tslint:disable-next-line
            data['mediaObjects'].filter(
              (media: IMediaObject) => media.type === key
            );
          return (
            <Box key={index} className={classes.infoItem}>
              <Box key={index} display="flex">
                <Box className={classes.itemLabel}>
                  <InformationLabel
                    data={PERSONAL_DETAILS_PROFILE_LABELS[key]}
                  />
                </Box>
                <Box className={classes.itemValue}>
                  {scans && scans.length ? 'Complete / On File' : 'None'}
                </Box>
              </Box>
            </Box>
          );
        }

        if (key === 'identifyingMarksImages') {
          // tslint:disable
          const marks =
            data['mediaObjects'] &&
            data['mediaObjects'].filter(
              (media: IMediaObject) => media.type === 'identifying-marks'
            );
          // tslint:enable
          return (
            <Box key={index} className={classes.infoItem}>
              <Box key={index} display="flex">
                <Box className={classes.itemLabel}>
                  <InformationLabel
                    data={PERSONAL_DETAILS_PROFILE_LABELS[key]}
                  />
                </Box>
                <Box className={classes.itemValue}>
                  {marks &&
                    marks[0] &&
                    marks.map((mark: IMediaObject, index: number) => (
                      <img
                        src={mark.content}
                        alt={''}
                        className={classes.markImage}
                        key={index}
                      />
                    ))}
                </Box>
              </Box>
            </Box>
          );
        }

        if (key === 'mobilePhoneNumbers' || key === 'satellitePhoneNumbers') {
          return (
            <Fragment key={index}>
              {data[key].map((item: any, index: number) => (
                <Box key={index} className={classes.infoItem}>
                  <Box key={index} display="flex">
                    <Box className={classes.itemLabel}>
                      <InformationLabel
                        data={PERSONAL_DETAILS_PROFILE_LABELS[key]}
                        count={arabicToRoman(index + 1)}
                      />
                    </Box>
                    <Box className={classes.itemValue}>
                      {transformers[key](item)}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Fragment>
          );
        }

        if (key === 'nationality') {
          return (
            <Fragment key={index}>
              {data[key].map((item: any, index: number) => {
                const cc = transformers[key](item);
                const Flag = Flags[cc];
                return (
                  <Box key={index} className={classes.infoItem}>
                    <Box key={index} display="flex">
                      <Box className={classes.itemLabel}>
                        <InformationLabel
                          data={PERSONAL_DETAILS_PROFILE_LABELS[key]}
                          count={arabicToRoman(index + 1)}
                        />
                      </Box>
                      <Box className={classes.itemValue}>
                        <Flag className={classes.flag} />
                        {item}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Fragment>
          );
        }

        if (key === 'pollQuestionsAnswers') {
          return (
            <Fragment key={index}>
              {data[key] &&
                data[key].map((item: any, index: number) => (
                  <Fragment key={index}>
                    <Box className={classes.infoItem}>
                      <Box key={index} display="flex">
                        <Box className={classes.itemLabel}>
                          <InformationLabel
                            data={PERSONAL_DETAILS_PROFILE_LABELS[key].question}
                            count={arabicToRoman(index + 1)}
                          />
                        </Box>
                        <Box className={classes.itemValue}>{item.question}</Box>
                      </Box>
                    </Box>

                    <Box className={classes.infoItem}>
                      <Box key={index} display="flex">
                        <Box className={classes.itemLabel}>
                          <InformationLabel
                            data={PERSONAL_DETAILS_PROFILE_LABELS[key].answer}
                            count={arabicToRoman(index + 1)}
                          />
                        </Box>
                        <Box className={classes.itemValue}>{item.answer}</Box>
                      </Box>
                    </Box>
                  </Fragment>
                ))}
            </Fragment>
          );
        }

        if (
          key === 'level1Approver' ||
          key === 'level2Approver' ||
          key === 'level3Approver'
        ) {
          return (
            <Box key={index} className={classes.infoItem}>
              <Box key={index} display="flex">
                <Box className={classes.itemLabel}>
                  <InformationLabel
                    data={transformers[`${key}Label`](data.department, key)}
                  />
                </Box>
                <Box className={classes.itemValue}>
                  {transformers[key] ? (
                    transformers[key](data[key]) ? (
                      transformers[key](data[key])
                    ) : null
                  ) : (
                    <Box>{data[key] && data[key].toString()}</Box>
                  )}
                </Box>
              </Box>
            </Box>
          );
        }

        return (
          <Box key={index} className={classes.infoItem}>
            <Box key={index} display="flex">
              <Box className={classes.itemLabel}>
                <InformationLabel data={PERSONAL_DETAILS_PROFILE_LABELS[key]} />
              </Box>
              <Box className={classes.itemValue}>
                {transformers[key] ? (
                  transformers[key](data[key]) ? (
                    transformers[key](data[key])
                  ) : null
                ) : (
                  <Box>{data[key] && data[key].toString()}</Box>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
