/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type createKeyThreatInput = {
    readonly type?: string | null;
    readonly probability?: string | null;
    readonly impact?: string | null;
    readonly commentary?: string | null;
    readonly notes?: string | null;
    readonly riskDetermination?: string | null;
    readonly clientMutationId?: string | null;
};
export type CreateKeyThreatMutationVariables = {
    input: createKeyThreatInput;
};
export type CreateKeyThreatMutationResponse = {
    readonly createKeyThreat: {
        readonly keyThreat: {
            readonly id: string;
            readonly type: string | null;
            readonly notes: string | null;
            readonly probability: string | null;
            readonly impact: string | null;
            readonly commentary: string | null;
        } | null;
    } | null;
};
export type CreateKeyThreatMutation = {
    readonly response: CreateKeyThreatMutationResponse;
    readonly variables: CreateKeyThreatMutationVariables;
};



/*
mutation CreateKeyThreatMutation(
  $input: createKeyThreatInput!
) {
  createKeyThreat(input: $input) {
    keyThreat {
      id
      type
      notes
      probability
      impact
      commentary
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "createKeyThreatInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "createKeyThreat",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "createKeyThreatPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "keyThreat",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "KeyThreat",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "type",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "notes",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "probability",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "impact",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "commentary",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CreateKeyThreatMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "CreateKeyThreatMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "CreateKeyThreatMutation",
            "id": null,
            "text": "mutation CreateKeyThreatMutation(\n  $input: createKeyThreatInput!\n) {\n  createKeyThreat(input: $input) {\n    keyThreat {\n      id\n      type\n      notes\n      probability\n      impact\n      commentary\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '43ed78e8147208bce0248502b8f7c6bb';
export default node;
