/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanItineraryContactQueryVariables = {
    id: string;
};
export type GetPlanItineraryContactQueryResponse = {
    readonly itineraryLeg: {
        readonly id: string;
        readonly itineraryContacts: ReadonlyArray<{
            readonly id: string;
            readonly firstName: string | null;
            readonly middleName: string | null;
            readonly lastName: string | null;
            readonly mobilePhoneNumbers: unknown | null;
            readonly gender: string | null;
            readonly nationality: unknown | null;
            readonly email: string | null;
            readonly details: string | null;
            readonly activitiesLocationAssoc: string | null;
        } | null> | null;
    } | null;
};
export type GetPlanItineraryContactQuery = {
    readonly response: GetPlanItineraryContactQueryResponse;
    readonly variables: GetPlanItineraryContactQueryVariables;
};



/*
query GetPlanItineraryContactQuery(
  $id: ID!
) {
  itineraryLeg(id: $id) {
    id
    itineraryContacts {
      id
      firstName
      middleName
      lastName
      mobilePhoneNumbers
      gender
      nationality
      email
      details
      activitiesLocationAssoc
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "itineraryLeg",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "ItineraryLeg",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "itineraryContacts",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ItineraryContact",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "middleName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "mobilePhoneNumbers",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "gender",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "nationality",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "details",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "activitiesLocationAssoc",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanItineraryContactQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanItineraryContactQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanItineraryContactQuery",
            "id": null,
            "text": "query GetPlanItineraryContactQuery(\n  $id: ID!\n) {\n  itineraryLeg(id: $id) {\n    id\n    itineraryContacts {\n      id\n      firstName\n      middleName\n      lastName\n      mobilePhoneNumbers\n      gender\n      nationality\n      email\n      details\n      activitiesLocationAssoc\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '74e49c3ae4d25bb4e992e144cc4a7f98';
export default node;
