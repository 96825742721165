import { graphql } from 'react-relay';

export const UpdateRiskMitigationStatusMutation = graphql`
  mutation UpdateRiskMitigationStatusMutation(
    $input: submitRiskMitigationInput!
  ) {
    submitRiskMitigation(input: $input) {
      riskMitigation {
        submittedStatus
      }
    }
  }
`;
