import React from 'react';
import { Divider, Paper, Box, Typography } from '@material-ui/core';
import { commitMutationForm } from 'helper/commitMutationForm';
import { UpdateParticipantMutation } from 'graphql/UpdateParticipantMutation';
import { FormBuilder } from 'components';
import filesSummary from 'data/files-summary.json';
import filesSummaryDisabled from 'data/disabled/files-summary.json';

interface IComponentProps {
  participantId: string;
  participant: Object;
  isSubmitted: boolean;
}

export const ParticipantFiles = ({
  participantId,
  participant,
  isSubmitted,
}: IComponentProps) => (
  <Box pb={3.5} width={'100%'}>
    <Paper>
      <Box p={2.5} pl={3}>
        <Typography variant="h4">Files</Typography>
      </Box>
      <Divider />
      <Box p={1.5}>
        <FormBuilder
          title=""
          enabledInnerHeadings={false}
          formObject={!isSubmitted ? filesSummary : filesSummaryDisabled}
          initialValues={participant}
          onSubmit={(values) => {
            commitMutationForm({
              values: {
                id: `/api/participants/${participantId}`,
                ...values,
              },
              mutation: UpdateParticipantMutation,
            });
          }}
        />
      </Box>
    </Paper>
  </Box>
);
