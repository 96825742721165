import React, { ReactNode, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import {
  Box,
  Card,
  Grid,
  Icon,
  Menu,
  IconButton,
  Typography,
} from '@material-ui/core';
import planNav from 'data/planNav.json';
import { Phase } from 'components/Plan/Phase';
import { PlanNavigationLink } from 'components/Plan/PlanNavigationLink';
import { ValidationError } from 'components/ValidationError';
import { LegsNavigationQuery } from 'components/Plan/LegsNavigationQuery';
import { ParticipantsNavigationQuery } from 'components/Plan/ParticipantsNavigationQuery';
import { QueryRenderer } from 'react-relay';
import { Loader } from 'components/Loader';
import { modernEnvironment } from 'config/environment';
import { GetPlanNavQuery } from 'graphql/GetPlanNavQuery';

interface IComponentProps {
  planId: string;
}

export interface ISubNavigationItem {
  text: string;
  route: string;
}

type SubNavigationHandler = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  subnavigation: Array<{ text: string, route: string }>
) => any;

type SubNavigationRenderCallback = (
  id: string,
  openSubnavigation: SubNavigationHandler
) => ReactNode;

const renderSubNavigation = (
  planId: string,
  subnavigation: string | Array<ISubNavigationItem>,
  openSubnavigation: SubNavigationHandler
): ReactNode => {
  if (Array.isArray(subnavigation)) {
    return (
      <IconButton
        size="small"
        onClick={(e) => openSubnavigation(e, subnavigation)}
        aria-haspopup="true"
      >
        <Icon>arrow_right</Icon>
      </IconButton>
    );
  }
  return subNavigationRenders[subnavigation](planId, openSubnavigation);
};

const subNavigationRenders: { [key: string]: SubNavigationRenderCallback } = {
  participants: (id: string, openSubnavigation: SubNavigationHandler) => (
    <ParticipantsNavigationQuery
      planId={id}
      render={(error, subnavigation) => {
        if (error !== null) {
          return <ValidationError>{error.message}</ValidationError>;
        }
        if (subnavigation !== null && subnavigation.length > 0) {
          return (
            <IconButton
              size="small"
              onClick={(e) => openSubnavigation(e, subnavigation)}
              aria-haspopup="true"
            >
              <Icon>arrow_right</Icon>
            </IconButton>
          );
        }
        return (
          <IconButton disabled size="small" aria-haspopup="true">
            <Icon>arrow_right</Icon>
          </IconButton>
        );
      }}
    />
  ),
  itinerary: (id: string, openSubnavigation: SubNavigationHandler) => (
    <LegsNavigationQuery
      planId={id}
      render={(error, subnavigation) => {
        if (error !== null) {
          return <ValidationError>{error.message}</ValidationError>;
        }
        if (subnavigation !== null && subnavigation.length > 0) {
          return (
            <IconButton
              size="small"
              onClick={(e) => openSubnavigation(e, subnavigation)}
              aria-haspopup="true"
            >
              <Icon>arrow_right</Icon>
            </IconButton>
          );
        }
        return (
          <IconButton disabled size="small" aria-haspopup="true">
            <Icon>arrow_right</Icon>
          </IconButton>
        );
      }}
    />
  ),
};

export const PlanNavigation = ({ planId }: IComponentProps) => {
  const [anchorEl, setAnchorEl] = useState();
  const [currentSubnavigation, setCurrentSubnavigation] = useState([
    { text: '', route: '' },
  ]);
  const location = useLocation();
  const basePlanRoute = `/plan/${planId}`;

  const openSubnavigation = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    subnavigation: Array<{ text: string, route: string }>
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentSubnavigation(subnavigation);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isCompleteSection = (sectionKey: string, statuses: Object) => {
    if (
      statuses[sectionKey] &&
      typeof statuses[sectionKey].submittedStatus !== 'undefined'
    ) {
      return statuses[sectionKey].submittedStatus;
    }

    return statuses[sectionKey] && statuses[sectionKey] !== 'development'
      ? true
      : false;
  };

  return (
    <Box
      position="fixed"
      p={3}
      top={60}
      left={0}
      width={'322px'}
      zIndex="drawer"
    >
      <Card>
        <QueryRenderer
          environment={modernEnvironment}
          query={GetPlanNavQuery}
          variables={{
            id: `/api/activity_plans/${planId}`,
          }}
          render={({ error, props }) => {
            if (error) {
              return <ValidationError>{error[0].message}</ValidationError>;
            } else if (props) {
              const { activityPlan: activityPlanStatuses } = props;
              return (
                <Box p={3}>
                  {planNav.map((phase, index) => (
                    <Phase
                      key={index}
                      title={phase.title}
                      hasDivider={index !== planNav.length - 1}
                    >
                      {phase.sections.map((section) =>
                        section.subnavigation ? (
                          <div key={section.id}>
                            <Grid
                              container
                              alignItems="center"
                              justify="space-between"
                            >
                              <Box flex={1}>
                                <PlanNavigationLink
                                  to={`${basePlanRoute}${section.route}`}
                                  disableGutters={true}
                                  markcompleted={isCompleteSection(
                                    section.key,
                                    activityPlanStatuses
                                  )}
                                  selected={
                                    matchPath(location.pathname, {
                                      path: `/plan/:id${section.route}`,
                                    }) != null
                                  }
                                >
                                  {section.text}
                                </PlanNavigationLink>
                              </Box>
                              {renderSubNavigation(
                                planId,
                                section.subnavigation,
                                openSubnavigation
                              )}
                            </Grid>
                          </div>
                        ) : (
                          <Box key={section.id} pt={0.5} pb={0.5}>
                            <PlanNavigationLink
                              markcompleted={isCompleteSection(
                                section.key,
                                activityPlanStatuses
                              )}
                              to={`${basePlanRoute}${section.route}`}
                              disableGutters={true}
                              selected={
                                location.pathname ===
                                `${basePlanRoute}${section.route}`
                              }
                            >
                              {section.text}
                            </PlanNavigationLink>
                          </Box>
                        )
                      )}
                    </Phase>
                  ))}
                </Box>
              );
            }
            return <Loader />;
          }}
        />
      </Card>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {currentSubnavigation.map(
          (submenuItem: { text: string, route: string }, index) => (
            <PlanNavigationLink
              key={index}
              to={`${basePlanRoute}${submenuItem.route}`}
              selected={
                location.pathname === `${basePlanRoute}${submenuItem.route}`
              }
              onClick={handleClose}
            >
              <Typography variant="body1">{submenuItem.text}</Typography>
            </PlanNavigationLink>
          )
        )}
      </Menu>
    </Box>
  );
};
