import { graphql } from 'react-relay';

export const UpdatePlanItineraryLegMutation = graphql`
  mutation UpdatePlanItineraryLegMutation($input: updateItineraryLegInput!) {
    updateItineraryLeg(input: $input) {
      itineraryLeg {
        id
        meansOfTravel
        fromLocation
        toLocation
        details
        travelDepartureTime
        travelArrivalTime
        notes
        visaStatus
        accreditationStatus
      }
    }
  }
`;
