import React from 'react';
import { QueryRenderer } from 'react-relay';
import { GetPlanTileQuery } from '../../graphql/GetPlanTileQuery';
import { modernEnvironment } from '../../config/environment';
import { Grid, Box } from '@material-ui/core';
import { PlanTile } from 'components/Plans/PlanTile';
import { Loader } from 'components/Loader';
import { ActiveTile } from 'components/Plans/ActiveTile';
import { ApprovedTile } from 'components/Plans/ApprovedTile';
import { ValidationError } from 'components/ValidationError';
import { InDeveloppmentTile } from './InDevelopmentTile';
import { PendingApprovalTile } from './PendingApprovalTile';

interface IProps {
  filter: string;
  actionLabel: string;
  actionFunction(): void;
}

export const PlanTileList = (props: IProps) => {
  const { filter, actionLabel, actionFunction } = props;

  return (
    <QueryRenderer
      environment={modernEnvironment}
      query={GetPlanTileQuery}
      variables={{
        status: filter,
      }}
      render={({ error, props }) => {
        if (error) {
          return <ValidationError>{error[0].message}</ValidationError>;
        } else if (props) {
          const { activityPlans: plans } = props;

          return (
            <Grid container>
              {plans &&
                plans.map((plan, index: number) => (
                  <Box p={1.5} key={index}>
                    <PlanTile
                      id={plan.id}
                      name={plan.name}
                      durationFrom={plan.durationFrom}
                      durationTo={plan.durationTo}
                      actionLabel={actionLabel}
                      actionFunction={actionFunction}
                      participants={plan.participants}
                    >
                      {(() => {
                        switch (filter) {
                          case 'approved':
                            return (
                              <ApprovedTile durationfrom={plan.durationFrom} />
                            );
                          case 'active':
                            return <ActiveTile legs={plan.itineraryLegs} />;
                          case 'development':
                            return (
                              <InDeveloppmentTile
                                submissionProgress={plan.submissionProgress}
                              />
                            );
                          case 'pending_approval':
                            return (
                              <PendingApprovalTile
                                approvers={plan.supporters}
                              />
                            );
                          default:
                            return <></>;
                        }
                      })()}
                    </PlanTile>
                  </Box>
                ))}
            </Grid>
          );
        }
        return <Loader />;
      }}
    />
  );
};
