import { graphql } from 'react-relay';

export const GetActivityLogQuery = graphql`
  query GetActivityLogQuery($id: ID!) {
    activityLog(id: $id) {
      dateTimeEST
      localizedTime
      gmtTime
      remarks
      author
    }
  }
`;
