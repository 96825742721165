import { graphql } from 'react-relay';

export const CreateIncidentMutation = graphql`
  mutation CreateIncidentMutation($input: createIncidentInput!) {
    createIncident(input: $input) {
      incident {
        gap
        incidentDate
        target
        quantity
        location
        details
        type
      }
    }
  }
`;
