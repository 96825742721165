import React from 'react';
import { DashboardHeader } from 'components/Dashboard/DashboardHeader';
import { DashboardAside } from 'components/Dashboard/DashboardAside';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import { theme } from 'theme';

import { Switch, Redirect } from 'react-router';
import { PrivateRoute } from 'Routes';
import { Home } from 'pages/dashboard/Home';
import { PersonalDetails } from 'pages/dashboard/PersonalDetails';
import { Plans } from 'pages/dashboard/Plans';
import { PlansArchive } from 'pages/dashboard/PlansArchive';
import { CreatePlanDialog } from 'components/CreatePlan/CreatePlanDialog';

const useStyles = makeStyles({
  card: {
    width: 475,
    minHeight: 292,
  },
  fixedBox: {
    backgroundColor: theme.palette.common.white,
  },
});

export const Dashboard = () => {
  const classes = useStyles();
  return (
    <>
      <DashboardAside />
      <DashboardHeader title="High Risk Traveler" />

      <Grid container>
        <Box
          position="fixed"
          p={1.5}
          bottom={0}
          left={0}
          width="255px"
          zIndex="drawer"
          className={classes.fixedBox}
        >
          <CreatePlanDialog />
        </Box>
      </Grid>

      <Box pl={'268px'} pt={10}>
        <Switch>
          <PrivateRoute path="/dashboard/home" component={Home} />
          <PrivateRoute
            path="/dashboard/personal-details"
            component={PersonalDetails}
          />
          <PrivateRoute path="/dashboard/plans" component={Plans} />
          <PrivateRoute
            path="/dashboard/plansArchive"
            component={PlansArchive}
          />
          <Redirect from="/dashboard" to="/dashboard/home" />
        </Switch>
      </Box>
    </>
  );
};
