import { graphql } from 'react-relay';

export const UpdateEmployeeMutation = graphql`
  mutation UpdateEmployeeMutation($input: updateEmployeeInput!) {
    updateEmployee(input: $input) {
      employee {
        id
        mediaObjects {
          type
          content
        }
        firstName
        middleName
        lastName
        mobilePhoneNumbers
        satellitePhoneNumbers
        vismoProfile
        email
        birthDetails
        nationality
        employer
        employmentStatus
        insuranceProvider
        policyNumber
        department
        position
        roleInTrip
        bureauAssigned
        domicileCity
        lastHefatReceived
        level1Approver {
          firstName
          lastName
        }
        level2Approver {
          firstName
          lastName
        }
        level3Approver {
          firstName
          lastName
        }
        employeeEmergencyContacts {
          id
          firstName
          middleName
          lastName
          mobilePhoneNumbers
          gender
          nationality
          email
          relationship
          languagePreference
          specialInstructions
          type
        }
        biologicalSex
        bloodType
        height
        eyeColor
        correctedVision
        hairColorLength
        identifyingMarksDescription
        primaryPhysicianName
        physicianContactNumber
        disabilitiesOrOtherMedicalConditions
        medicationsAndDosage
        drugOrOtherAllergies
        pollQuestionsAnswers
      }
    }
  }
`;
