import React from 'react';
import { QueryRenderer } from 'react-relay';
import { Loader } from 'components/Loader';
import { modernEnvironment } from 'config/environment';
import { FormBuilder } from 'components';
import ActivityForm from 'data/activity.json';
import ActivityFormDisabled from 'data/disabled/activity.json';
import { GetActivityLogQuery } from 'graphql/GetActivityLogQuery';
import { commitMutationForm } from 'helper/commitMutationForm';
import { UpdateActivityLogMutation } from 'graphql/UpdateActivityLogMutation';
import { ValidationError } from 'components/ValidationError';
import { Box, Button, Grid } from '@material-ui/core';
import { useAutoSave } from 'hooks/useAutoSave';
import { FormSpy } from 'react-final-form';

interface IComponentProps {
  activityId: string;
  isSubmitted: boolean;
  handleClose(): void;
}

export const EditActivityDialog = (props: IComponentProps) => {
  const { activityId, isSubmitted, handleClose } = props;
  const [result, handleChange] = useAutoSave(30);

  const onSubmitHandler = (values, activityId) => {
    commitMutationForm({
      values: {
        id: activityId,
        ...values,
      },
      mutation: UpdateActivityLogMutation,
    });
  };
  return (
    <QueryRenderer
      environment={modernEnvironment}
      query={GetActivityLogQuery}
      variables={{
        id: activityId,
      }}
      render={({ error, props }) => {
        if (error) {
          return <ValidationError>{error.message}</ValidationError>;
        } else if (props) {
          const { activityLog } = props;
          return (
            <>
              <FormBuilder
                formObject={!isSubmitted ? ActivityForm : ActivityFormDisabled}
                initialValues={activityLog}
                onSubmit={(values) => {
                  onSubmitHandler(values, activityId);
                }}
              >
                <FormSpy
                  subscription={{
                    values: true,
                    initialValues: true,
                    errors: false,
                    hasValidationErrors: true,
                  }}
                  onChange={({ values }) => {
                    handleChange({ values });
                  }}
                />
              </FormBuilder>
              <Grid container justify="flex-end">
                <Box p={0.75}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      onSubmitHandler(result, activityId);
                      handleClose();
                    }}
                  >
                    Done
                  </Button>
                </Box>
              </Grid>
            </>
          );
        }
        return <Loader />;
      }}
    />
  );
};
