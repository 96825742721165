import React from 'react';
import { PlanHeader } from 'components/Plan/PlanHeader';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Switch, Redirect, useRouteMatch } from 'react-router';
import { PrivateRoute } from 'Routes';
import { BusinessIntent } from './plan/BusinessIntent';
import { ParticipantInfo } from './plan/ParticipantInfo';
import { Participant } from './plan/participants/Participant';
import { Itinerary } from './plan/Itinerary';
import { Leg } from './plan/legs/Leg';
import { IncidentAnalysis } from './plan/IncidentAnalysis';
import { RiskDetermination } from './plan/RiskDetermination';
import { RiskMitigation } from './plan/RiskMitigation';
import { RiskSection } from './plan/risk-mitigation/RiskSection';
import { RiskAssessment } from './plan/RiskAssessment';
import { ActivityLog } from './plan/ActivityLog';
import { Close } from './plan/Close';
import { PlanNavigation } from 'components/Plan/PlanNavigation';
import { PlanFooter } from 'components/Plan/PlanFooter';

export const Plan = () => {
  const { planId } = useParams<{planId: string}>();

  const matchParticipant = useRouteMatch('/plan/:planId/participant-info/:index/:participantId?');
  const matchLeg = useRouteMatch('/plan/:planId/itinerary/:index/:legId?');
  const matchRiskSection = useRouteMatch('/plan/:planId/risk-mitigation/:index/:riskSectionId?');
  return (
    <>
      <PlanNavigation planId={planId} />

      <PlanHeader planId={planId} />

      <Box pl={'325px'} pt={10}>
        <Switch>
          <PrivateRoute
            path="/plan/:planId/business-intent"
            render={() => <BusinessIntent planId={planId} />}
          />
          <PrivateRoute
            exact
            path="/plan/:planId/participant-info"
            render={() => <ParticipantInfo planId={planId} />}
          />
          <PrivateRoute
            path="/plan/:planId/participant-info/:index/:participantId?"
            render={() => <Participant planId={planId} />}

          />
          <PrivateRoute
            exact
            path="/plan/:planId/itinerary"
            render={() => <Itinerary planId={planId} />}
          />
          <PrivateRoute
            path="/plan/:planId/itinerary/:index/:legId?"
            component={Leg}
          />
          <PrivateRoute
            exact
            path="/plan/:planId/incident-analysis"
            render={() => <IncidentAnalysis planId={planId} />}
          />
          <PrivateRoute
            path="/plan/:planId/risk-determination"
            render={() => <RiskDetermination planId={planId} />}
          />
          <PrivateRoute
            exact
            path="/plan/:planId/risk-mitigation"
            render={() => <RiskMitigation planId={planId} />}
          />
          <PrivateRoute
            path="/plan/:planId/risk-mitigation/:riskSectionId?"
            render={() => <RiskSection planId={planId} />}
          />
          <PrivateRoute
            path="/plan/:planId/risk-assessment"
            render={() => <RiskAssessment planId={planId} />}
          />
          <PrivateRoute
            path="/plan/:planId/activity-log"
            render={() => <ActivityLog planId={planId} />}
          />
          <PrivateRoute
            path="/plan/:planId/close"
            render={() => <Close planId={planId} />}
          />
          <Redirect from="/plan/:planId" to="/plan/:planId/business-intent" />
        </Switch>
      </Box>

      {!matchParticipant && !matchLeg && !matchRiskSection && <PlanFooter planId={planId} />}
    </>
  );
};
