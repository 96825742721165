import moment from 'moment';

export const incidentDurationDecorators = [
  {
    field: 'durationFrom',
    updates: {
      days: (durationFromValue, allValues) =>
        allValues.durationTo &&
        moment(durationFromValue).format('YYYY-MM-DD hh:mm') !== 'Invalid date'
          ? Math.abs(
              moment.duration(
                moment(moment(durationFromValue).format('YYYY-MM-DD')).diff(
                  moment(moment(allValues.durationTo).format('YYYY-MM-DD')),
                  'days'
                ) - 1
              )
            )
          : 'N/A',
      months: (durationFromValue, allValues) =>
        allValues.durationTo &&
        moment(durationFromValue).format('YYYY-MM-DD hh:mm') !== 'Invalid date'
          ? Math.abs(
              moment.duration(
                moment(durationFromValue).diff(
                  moment(allValues.durationTo),
                  'months'
                )
              )
            )
          : 'N/A',
    },
  },
  {
    field: 'durationTo',
    updates: {
      days: (durationToValue, allValues) =>
        allValues.durationFrom &&
        moment(durationToValue).format('YYYY-MM-DD hh:mm') !== 'Invalid date'
          ? Math.abs(
              moment.duration(
                moment(moment(durationToValue).format('YYYY-MM-DD')).diff(
                  moment(moment(allValues.durationFrom).format('YYYY-MM-DD')),
                  'days'
                ) + 1
              )
            )
          : 'N/A',
      months: (durationToValue, allValues) =>
        allValues.durationFrom &&
        moment(durationToValue).format('YYYY-MM-DD hh:mm') !== 'Invalid date'
          ? Math.abs(
              moment.duration(
                moment(moment(durationToValue).format('YYYY-MM-DD')).diff(
                  moment(moment(allValues.durationFrom).format('YYYY-MM-DD')),
                  'months'
                )
              )
            )
          : 'N/A',
    },
  },
];
