/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type IncidentFilter_incidentDate = {
    readonly before?: string | null;
    readonly strictly_before?: string | null;
    readonly after?: string | null;
    readonly strictly_after?: string | null;
};
export type GetPlanIncidentsQueryVariables = {
    id: string;
    incidentDate?: IncidentFilter_incidentDate | null;
};
export type GetPlanIncidentsQueryResponse = {
    readonly activityPlan: {
        readonly statusItineraryLegs: string;
        readonly submittedStatusIncidents: boolean;
        readonly incidents: ReadonlyArray<{
            readonly quantity: number | null;
            readonly incidentDate: string | null;
            readonly gap: number | null;
            readonly location: string | null;
            readonly type: string | null;
            readonly target: string | null;
            readonly details: string | null;
            readonly id: string;
        } | null> | null;
    } | null;
};
export type GetPlanIncidentsQuery = {
    readonly response: GetPlanIncidentsQueryResponse;
    readonly variables: GetPlanIncidentsQueryVariables;
};



/*
query GetPlanIncidentsQuery(
  $id: ID!
  $incidentDate: IncidentFilter_incidentDate
) {
  activityPlan(id: $id) {
    statusItineraryLegs
    submittedStatusIncidents
    incidents(incidentDate: $incidentDate) {
      quantity
      incidentDate
      gap
      location
      type
      target
      details
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "incidentDate",
            "type": "IncidentFilter_incidentDate",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "statusItineraryLegs",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "submittedStatusIncidents",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "incidents",
        "storageKey": null,
        "args": [
            {
                "kind": "Variable",
                "name": "incidentDate",
                "variableName": "incidentDate"
            }
        ],
        "concreteType": "Incident",
        "plural": true,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "quantity",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "incidentDate",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "gap",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "location",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "type",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "target",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "details",
                "args": null,
                "storageKey": null
            },
            (v4 /*: any*/)
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanIncidentsQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v5 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanIncidentsQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v5 /*: any*/),
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanIncidentsQuery",
            "id": null,
            "text": "query GetPlanIncidentsQuery(\n  $id: ID!\n  $incidentDate: IncidentFilter_incidentDate\n) {\n  activityPlan(id: $id) {\n    statusItineraryLegs\n    submittedStatusIncidents\n    incidents(incidentDate: $incidentDate) {\n      quantity\n      incidentDate\n      gap\n      location\n      type\n      target\n      details\n      id\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '4c325d35356b6b80bc698606a03c412e';
export default node;
