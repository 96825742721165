import React from 'react';
import { Typography, Grid, Box, Icon } from '@material-ui/core';

interface IComponentProps {
  children: ReactNode;
}

export const CustomWarning = ({ children }: IComponentProps) => (
  <Grid container alignItems="center">
    <Box mr={1}>
      <Icon color="error" fontSize="default">
        warning
      </Icon>
    </Box>
    <Typography variant="body1">{children}</Typography>
  </Grid>
);
