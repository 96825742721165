/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type deleteActivityLogInput = {
    readonly id: string;
    readonly clientMutationId?: string | null;
};
export type DeleteActivityLogMutationVariables = {
    input: deleteActivityLogInput;
};
export type DeleteActivityLogMutationResponse = {
    readonly deleteActivityLog: {
        readonly activityLog: {
            readonly id: string;
        } | null;
    } | null;
};
export type DeleteActivityLogMutation = {
    readonly response: DeleteActivityLogMutationResponse;
    readonly variables: DeleteActivityLogMutationVariables;
};



/*
mutation DeleteActivityLogMutation(
  $input: deleteActivityLogInput!
) {
  deleteActivityLog(input: $input) {
    activityLog {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "deleteActivityLogInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "deleteActivityLog",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "deleteActivityLogPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityLog",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActivityLog",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "DeleteActivityLogMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "DeleteActivityLogMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "DeleteActivityLogMutation",
            "id": null,
            "text": "mutation DeleteActivityLogMutation(\n  $input: deleteActivityLogInput!\n) {\n  deleteActivityLog(input: $input) {\n    activityLog {\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'e1cd0efb13b2b8983ac43b2aed6445e0';
export default node;
