import { graphql } from 'react-relay';

export const CreatePlanQuery = graphql`
  query CreatePlanQuery {
    employees {
      firstName
      lastName
      id
      email
    }
    freelancers {
      firstName
      lastName
      id
      email
    }
  }
`;
