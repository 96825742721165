import { graphql } from 'react-relay';

export const UpdateItineraryStatusMutation = graphql`
  mutation UpdateItineraryStatusMutation(
    $input: submitItineratyLegsActivityPlanInput!
  ) {
    submitItineratyLegsActivityPlan(input: $input) {
      activityPlan {
        id
        statusItineraryLegs
      }
    }
  }
`;
