import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

interface IComponentProps {
  children?: ReactNode;
}

const useStyles = makeStyles({
  contentWrapper: {
    width: 1024,
    overflowX: 'scroll',
  },
});

export const ContentWrapper = (props: IComponentProps) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <>
      <Box className={classes.contentWrapper}>
        {children}
      </Box>
    </>
  );
};
