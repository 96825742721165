/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type updateActivityPlanInput = {
    readonly id: string;
    readonly businessIntent?: string | null;
    readonly clientMutationId?: string | null;
};
export type UpdateActivityPlanMutationVariables = {
    input: updateActivityPlanInput;
};
export type UpdateActivityPlanMutationResponse = {
    readonly updateActivityPlan: {
        readonly activityPlan: {
            readonly id: string;
            readonly name: string | null;
        } | null;
    } | null;
};
export type UpdateActivityPlanMutation = {
    readonly response: UpdateActivityPlanMutationResponse;
    readonly variables: UpdateActivityPlanMutationVariables;
};



/*
mutation UpdateActivityPlanMutation(
  $input: updateActivityPlanInput!
) {
  updateActivityPlan(input: $input) {
    activityPlan {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "updateActivityPlanInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateActivityPlan",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "updateActivityPlanPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdateActivityPlanMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdateActivityPlanMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdateActivityPlanMutation",
            "id": null,
            "text": "mutation UpdateActivityPlanMutation(\n  $input: updateActivityPlanInput!\n) {\n  updateActivityPlan(input: $input) {\n    activityPlan {\n      id\n      name\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '964d48c4281652cde9f07bd37848e24c';
export default node;
