import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { FieldRenderProps } from 'react-final-form';
import { IFieldProps } from './types';
import { ValidationError } from 'components/ValidationError';

type Props = FieldRenderProps<string, any> & IFieldProps;

export const DateTimeField = (props: Props) => {
  const {
    input,
    meta,
    templateOptions: { label, required, disabled },
  } = props;

  const { value, name } = input;
  // prettier-ignore
  const [selectedDate, setSelectedDate] = useState<Date | any>(value ? value : null);

  const handleChange = (date: Date | null) => {
    setSelectedDate(date);
    input.onChange(date);
  };

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    (!meta.pristine || meta.touched);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="flex-start" direction="column">
        <KeyboardDateTimePicker
          required={required}
          disabled={disabled}
          inputVariant="outlined"
          label={label}
          name={name}
          format="MM/dd/yyyy HH:mm"
          value={selectedDate}
          onChange={handleChange}
          error={showError}
        />

        {showError && (
          <ValidationError>{meta.error || meta.submitError}</ValidationError>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
