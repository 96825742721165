import { graphql } from 'react-relay';

export const GetPlanLocationsQuery = graphql`
  query GetPlanLocationsQuery($id: ID!) {
    activityPlan(id: $id) {
      id
      countries
    }
  }
`;
