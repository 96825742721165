/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type addEmployeesToActivityPlanInput = {
    readonly id: string;
    readonly emails?: ReadonlyArray<string | null> | null;
    readonly clientMutationId?: string | null;
};
export type AddEmployeesToActivityPlanMutationVariables = {
    input: addEmployeesToActivityPlanInput;
};
export type AddEmployeesToActivityPlanMutationResponse = {
    readonly addEmployeesToActivityPlan: {
        readonly activityPlan: {
            readonly id: string;
            readonly name: string | null;
            readonly participants: ReadonlyArray<{
                readonly id: string;
                readonly email: string | null;
                readonly firstName: string | null;
                readonly lastName: string | null;
                readonly mediaObjects: ReadonlyArray<{
                    readonly content: string | null;
                } | null> | null;
            } | null> | null;
            readonly supporters: ReadonlyArray<{
                readonly role: string;
                readonly employee: {
                    readonly id: string;
                    readonly email: string;
                    readonly firstName: string | null;
                    readonly lastName: string | null;
                    readonly mediaObjects: ReadonlyArray<{
                        readonly content: string | null;
                    } | null> | null;
                };
            } | null> | null;
        } | null;
    } | null;
};
export type AddEmployeesToActivityPlanMutation = {
    readonly response: AddEmployeesToActivityPlanMutationResponse;
    readonly variables: AddEmployeesToActivityPlanMutationVariables;
};



/*
mutation AddEmployeesToActivityPlanMutation(
  $input: addEmployeesToActivityPlanInput!
) {
  addEmployeesToActivityPlan(input: $input) {
    activityPlan {
      id
      name
      participants {
        id
        email
        firstName
        lastName
        mediaObjects(type: "avatar") {
          content
          id
        }
      }
      supporters {
        role
        employee {
          id
          email
          firstName
          lastName
          mediaObjects(type: "avatar") {
            content
            id
          }
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "addEmployeesToActivityPlanInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v7 = [
        ({
            "kind": "Literal",
            "name": "type",
            "value": "avatar"
        } as any)
    ], v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "content",
        "args": null,
        "storageKey": null
    } as any), v9 = [
        (v8 /*: any*/)
    ], v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "role",
        "args": null,
        "storageKey": null
    } as any), v11 = [
        (v8 /*: any*/),
        (v2 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "AddEmployeesToActivityPlanMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "addEmployeesToActivityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "addEmployeesToActivityPlanPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "activityPlan",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ActivityPlan",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "participants",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Participant",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "mediaObjects",
                                            "storageKey": "mediaObjects(type:\"avatar\")",
                                            "args": (v7 /*: any*/),
                                            "concreteType": "ParticipantMediaObject",
                                            "plural": true,
                                            "selections": (v9 /*: any*/)
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "supporters",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Supporter",
                                    "plural": true,
                                    "selections": [
                                        (v10 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "employee",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Employee",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v4 /*: any*/),
                                                (v5 /*: any*/),
                                                (v6 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "mediaObjects",
                                                    "storageKey": "mediaObjects(type:\"avatar\")",
                                                    "args": (v7 /*: any*/),
                                                    "concreteType": "EmployeeMediaObject",
                                                    "plural": true,
                                                    "selections": (v9 /*: any*/)
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "AddEmployeesToActivityPlanMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "addEmployeesToActivityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "addEmployeesToActivityPlanPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "activityPlan",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ActivityPlan",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "participants",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Participant",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "mediaObjects",
                                            "storageKey": "mediaObjects(type:\"avatar\")",
                                            "args": (v7 /*: any*/),
                                            "concreteType": "ParticipantMediaObject",
                                            "plural": true,
                                            "selections": (v11 /*: any*/)
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "supporters",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Supporter",
                                    "plural": true,
                                    "selections": [
                                        (v10 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "employee",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Employee",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v4 /*: any*/),
                                                (v5 /*: any*/),
                                                (v6 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "mediaObjects",
                                                    "storageKey": "mediaObjects(type:\"avatar\")",
                                                    "args": (v7 /*: any*/),
                                                    "concreteType": "EmployeeMediaObject",
                                                    "plural": true,
                                                    "selections": (v11 /*: any*/)
                                                }
                                            ]
                                        },
                                        (v2 /*: any*/)
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "AddEmployeesToActivityPlanMutation",
            "id": null,
            "text": "mutation AddEmployeesToActivityPlanMutation(\n  $input: addEmployeesToActivityPlanInput!\n) {\n  addEmployeesToActivityPlan(input: $input) {\n    activityPlan {\n      id\n      name\n      participants {\n        id\n        email\n        firstName\n        lastName\n        mediaObjects(type: \"avatar\") {\n          content\n          id\n        }\n      }\n      supporters {\n        role\n        employee {\n          id\n          email\n          firstName\n          lastName\n          mediaObjects(type: \"avatar\") {\n            content\n            id\n          }\n        }\n        id\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '7b3a93a0462e054cdb62957fbce847e7';
export default node;
