import React, { useState, useEffect, useRef } from 'react';
import { commitMutationForm } from 'helper/commitMutationForm';
import { Box, Typography, Menu, MenuItem, Button } from '@material-ui/core';
import { HEADERS } from 'pages/plan/activities/ActivitiesCalculations';
import { CustomisedTable } from 'components/CustomisedTable';
import { CustomisedDialog } from 'components/CustomisedDialog';
import { AddActivityDialog } from 'pages/plan/activities/AddActivityDialog';
import { EditActivityDialog } from 'pages/plan/activities/EditActivityDialog';
import { DeleteActivityLogMutation } from 'graphql/DeleteActivityLogMutation';
import { FullscreenLoader } from 'components/FullscreenLoader';

interface IComponentProps {
  activities: Array<any>;
  planId: string;
  currentTime: any;
  localTime: any;
  timezoneSelected: boolean;
  isSubmitted: boolean;
  countHandler(): void;
}

export const ActivitiesCustomisedTable = (props: IComponentProps) => {
  const { activities, planId, currentTime, localTime, timezoneSelected, isSubmitted, countHandler } = props;
  const [popupContent, setPopupContent] = useState('add');
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [rowId, setRowId] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleDelete = (activityId) => {
    setIsDisabled(true);

    commitMutationForm({
      values: {
        id: activityId,
      },
      mutation: DeleteActivityLogMutation,
      onCompleteCallback: (response, errors) => {
        mounted.current && countHandler();
        mounted.current && setIsDisabled(false);
      },
      onErrorCallback: (error) => {
        mounted.current && countHandler();
        mounted.current && setIsDisabled(false);
      },
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    setOpen(true);
    setPopupContent('edit');
  };

  const handleAdd = () => {
    setOpen(true);
    setPopupContent('add');
  };

  const setRow = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: {}
  ) => {
    setAnchorEl(event.currentTarget);
    setRowId(row.id);
  };

  return (
    <Box p={1.5} pt={3.5}>
    {activities.length === 0 && (
      <Box pb={1.5}>
        <Typography variant="body1">No activities for this plan</Typography>
      </Box>
    )}
      <Box pb={3}>
        <CustomisedTable
          headers={HEADERS}
          sortBy={'DateEST'}
          data={activities}
          rowHandler={setRow}
          actionMenu={
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={handleEdit}>
                <Typography variant="body1">
                  {!isSubmitted ? 'Edit' : 'View'}
                </Typography>
              </MenuItem>
              {!isSubmitted && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleDelete(rowId);
                  }}
                >
                  <Typography variant="body1">Delete</Typography>
                </MenuItem>
              )}
            </Menu>
          }
        />
      </Box>
      {!isSubmitted && timezoneSelected && (
        <Box pb={1.5}>
          <Button variant="text" color="primary" onClick={handleAdd}>
            + Add activity
          </Button>
        </Box>
      )}
      <CustomisedDialog
        title={
          popupContent === 'add'
            ? 'Add activity'
            : !isSubmitted
            ? 'Edit activity'
            : 'View activity'
        }
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth={true}
      >
        {popupContent === 'add' ? (
          <AddActivityDialog
            doneHandler={() => {
              handleClose();
              countHandler();
            }}
            planId={planId}
            currentTime={currentTime}
            localTime={localTime}
          />
        ) : (
          <EditActivityDialog
            activityId={rowId}
            handleClose={handleClose}
            isSubmitted={isSubmitted}
          />
        )}
      </CustomisedDialog>
      <FullscreenLoader isLoading={isDisabled} />
    </Box>
  );
};
