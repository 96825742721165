import { graphql } from 'react-relay';

export const GetPlanRiskMitigationQuery = graphql`
  query GetPlanRiskMitigationQuery($id: ID!) {
    activityPlan(id: $id) {
      riskDetermination {
        submittedStatus
      }
      riskMitigation {
        id
        submittedStatus
        keyBehaviours {
          id
          instruction
          category
          originalKeyBehaviour
        }
      }
    }
  }
`;
