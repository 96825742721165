import React from 'react';
import { IFieldProps } from './types';
import { FieldRenderProps } from 'react-final-form';
import staticCountries from 'data/countriesNames.json';
import { BaseCountrySelector } from './BaseCountrySelector';
import { BaseOfficeField } from 'components/fields/BaseOfficeField';

type Props = FieldRenderProps<string, any> & IFieldProps;

export const OfficeFieldStatic = (props: Props) => {
  const {
    input,
    templateOptions: { required, disabled },
  } = props;

  return (
    <BaseOfficeField
      {...props}
      render={(innerValue, handleChange, showError) => (
        <BaseCountrySelector
          name={input.name}
          value={input.value.country}
          required={required}
          disabled={disabled}
          showError={showError}
          options={staticCountries}
          onChange={handleChange('country')}
        />
      )}
    />
  );
};
