import { useMemo } from 'react';
import planNav from 'data/planNav.json';

export const usePlanSections = (currentRoute) => {
  const sections = useMemo(
    () => planNav.reduce((acc, phase) => acc.concat(phase.sections), []),
    []
  );

  const currentSectionIndex = useMemo(
    () =>
      sections.findIndex(
        (section) => currentRoute.indexOf(section.route) !== -1
      ),
    [currentRoute, sections]
  );

  const recalculatedCurrentIndex =
    currentSectionIndex === -1 ? 0 : currentSectionIndex;

  const nextSectionRoute = useMemo(() => {
    return sections[recalculatedCurrentIndex + 1]
      ? currentRoute.replace(
          sections[recalculatedCurrentIndex].route,
          sections[recalculatedCurrentIndex + 1].route
        )
      : currentRoute.replace(
          sections[recalculatedCurrentIndex].route,
          sections[0].route
        );
  }, [sections, recalculatedCurrentIndex, currentRoute]);

  return [nextSectionRoute, sections];
};
