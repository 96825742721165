import { graphql } from 'react-relay';

export const GetEmployeePersonalDetailsQuery = graphql`
  query GetEmployeePersonalDetailsQuery($id: ID!) {
    employee(id: $id) {
      id
      email
      firstName
      middleName
      lastName
      mobilePhoneNumbers
      satellitePhoneNumbers
      vismoProfile
      birthDetails
      nationality
      employer
      employmentStatus
      insuranceProvider
      policyNumber
      department
      position
      roleInTrip
      bureauAssigned
      domicileCity
      lastHefatReceived
    }
    employees {
      firstName
      lastName
      id
      email
    }
  }
`;
