import React, { ReactNode } from 'react';
import { QueryRenderer } from 'react-relay';
import { GetPlanItineraryLegsQuery } from 'graphql/GetPlanItineraryLegsQuery';
import { modernEnvironment } from 'config/environment';
import { ISubNavigationItem } from './PlanNavigation';
import { arabicToRoman } from 'helper/arabicToRoman';
import { clearId } from 'helper/clearId';

type Renderer = (
  error: Error | null,
  navigations: Array<ISubNavigationItem> | null
) => ReactNode;

interface ILegsNavigationQuery {
  planId: string;
  render: Renderer;
}

export const LegsNavigationQuery = ({
  planId,
  render,
}: ILegsNavigationQuery) => (
  <QueryRenderer
    environment={modernEnvironment}
    query={GetPlanItineraryLegsQuery}
    variables={{
      id: `/api/activity_plans/${planId}`,
    }}
    render={({ error, props }) => {
      if (error != null) {
        return render(error, null);
      }
      if (props != null) {
        const {
          activityPlan: { itineraryLegs },
        } = props;

        const navigationItems = itineraryLegs.map(
          (leg, index) =>
            leg &&
            leg !== null && {
              text: `Leg ${arabicToRoman(index + 1)}`,
              route: `/itinerary/${index + 1}/${clearId(leg.id)}`,
            }
        );

        return render(null, navigationItems);
      }
      return render(null, null);
    }}
  />
);
