import { graphql } from 'react-relay';

export const GetPlanParticipantInfoQuery = graphql`
  query GetPlanParticipantInfoQuery($id: ID!) {
    activityPlan(id: $id) {
      statusParticipants
      statusItineraryLegs
      participants {
        id
        firstName
        lastName
        status
        role
        origin
        email
      }
    }
  }
`;
