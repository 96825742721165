/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type importActivityPlanInput = {
    readonly id: string;
    readonly activityPlanId: string;
    readonly importBusinessIntent?: boolean | null;
    readonly importItineraryLegs?: boolean | null;
    readonly importIncidentAnalysis?: boolean | null;
    readonly importRiskDetermination?: boolean | null;
    readonly importRiskMitigation?: boolean | null;
    readonly importRiskAssessment?: boolean | null;
    readonly clientMutationId?: string | null;
};
export type ImportActivityPlanMutationVariables = {
    input: importActivityPlanInput;
};
export type ImportActivityPlanMutationResponse = {
    readonly importActivityPlan: {
        readonly activityPlan: {
            readonly id: string;
        } | null;
    } | null;
};
export type ImportActivityPlanMutation = {
    readonly response: ImportActivityPlanMutationResponse;
    readonly variables: ImportActivityPlanMutationVariables;
};



/*
mutation ImportActivityPlanMutation(
  $input: importActivityPlanInput!
) {
  importActivityPlan(input: $input) {
    activityPlan {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "importActivityPlanInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "importActivityPlan",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "importActivityPlanPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ImportActivityPlanMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "ImportActivityPlanMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "ImportActivityPlanMutation",
            "id": null,
            "text": "mutation ImportActivityPlanMutation(\n  $input: importActivityPlanInput!\n) {\n  importActivityPlan(input: $input) {\n    activityPlan {\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'db0472ddf2e9b0ee8688726eef7c6efa';
export default node;
