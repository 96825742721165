import { graphql } from 'react-relay';

export const GetPlanItineraryLegsQuery = graphql`
  query GetPlanItineraryLegsQuery($id: ID!) {
    activityPlan(id: $id) {
      id
      statusParticipants
      statusItineraryLegs
      itineraryLegs {
        id
        travelDepartureTime
        travelArrivalTime
      }
    }
  }
`;
