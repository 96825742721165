import { graphql } from 'react-relay';

export const GetActivityPlanClosingNotesQuery = graphql`
  query GetActivityPlanClosingNotesQuery($id: ID!) {
    activityPlan(id: $id) {
      id
      riskDetermination {
        submittedStatus
      }
      riskAssessment {
        submittedStatus
      }
      activityPlanClosingNotes {
        id
        debriefNotes
        lessonsLearned
        submittedStatus
      }
    }
  }
`;
