import { graphql } from 'react-relay';

export const GetPlanNavQuery = graphql`
  query GetPlanNavQuery($id: ID!) {
    activityPlan(id: $id) {
      id
      status
      statusBusinessIntent
      statusParticipants
      statusItineraryLegs
      riskDetermination {
        submittedStatus
      }
      riskMitigation {
        submittedStatus
      }
      submittedStatusIncidents
      riskAssessment {
        submittedStatus
      }
      submittedStatusActivityLog
      activityPlanClosingNotes {
        submittedStatus
      }
    }
  }
`;
