import React from 'react';
import {
  Grid,
  Box,
  Typography,
  LinearProgress,
  Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  phaseProgress: {
    width: 'calc(100% - 60px)',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,
  },
  phaseLabel: {
    margin: '0 10px 0 0',
    textTransform: 'capitalize',
  },
  phaseProgressLine: {
    width: '372px',
    margin: 3,
  },
  phaseProgressPercent: {
    flexBasis: 80,
    marginLeft: '10px',
  },
}));

interface IComponentProps {
  submissionProgress: {};
}

export const InDeveloppmentTile = ({ submissionProgress }: IComponentProps) => {
  const classes = useStyles();

  return (
    <Grid container justify="center" direction="column" alignItems="flex-start">
      <Box pt={2}>
        {Object.keys(submissionProgress).map((key, index: number) => (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mb={1.5}
            key={index}
          >
            <Box className={classes.phaseLabel}>
              <Typography>{key}</Typography>
            </Box>
            <Container className={classes.phaseProgress}>
              <LinearProgress
                variant="determinate"
                color="primary"
                value={submissionProgress[key]}
                className={classes.phaseProgressLine}
              />
              <Typography className={classes.phaseProgressPercent}>
                {submissionProgress[key]}%
              </Typography>
            </Container>
          </Box>
        ))}
      </Box>
    </Grid>
  );
};
