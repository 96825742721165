import { TextField } from './TextField';
import { NestedField } from './NestedField';
import { SelectField } from './SelectField';
import { AvatarField } from './AvatarField';
import { PhoneField } from './PhoneField';
import { RepeaterField } from './RepeaterField';
import { DateField } from './DateField';
import { DateTimeField } from './DateTimeField';
import { OfficeFieldStatic } from './OfficeFieldStatic';
import { OfficeFieldQuery } from './OfficeFieldQuery';
import { PlanLocationsQuery } from './PlanLocationsQuery';
import { AutocompleteField } from './AutocompleteField';
import { UploadField } from './UploadField';
import { RadioGroupField } from './RadioGroupField';
import { CheckboxField } from './CheckboxField';
import { CountrySelectFieldQuery } from './CountrySelectFieldQuery';
import { CountrySelectFieldStatic } from './CountrySelectFieldStatic';
import { RegionSelectFieldStatic } from './RegionSelectFieldStatic';
import { KeyRiskField } from './KeyRiskField';
import { SettingsSelectFieldQuery } from './SettingsSelectFieldQuery';

export const input = TextField;
export const nested = NestedField;
export const select = SelectField;
export const settingsselectquery = SettingsSelectFieldQuery;
export const avatar = AvatarField;
export const phone = PhoneField;
export const repeater = RepeaterField;
export const date = DateField;
export const datetime = DateTimeField;
export const officestatic = OfficeFieldStatic;
export const officequery = OfficeFieldQuery;
export const planlocationsquery = PlanLocationsQuery;
export const autocomplete = AutocompleteField;
export const upload = UploadField;
export const radio = RadioGroupField;
export const checkbox = CheckboxField;
export const countryselectquery = CountrySelectFieldQuery;
export const countryselectstatic = CountrySelectFieldStatic;
export const regionselectstatic = RegionSelectFieldStatic;
export const keyRisk = KeyRiskField;
