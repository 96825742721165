import React from 'react';
import { QueryRenderer } from 'react-relay';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import { modernEnvironment } from 'config/environment';
import { FormBuilder } from 'components';
import IncidentForm from 'data/incident.json';
import IncidentFormDisabled from 'data/disabled/incident.json';
import { GetIncidentQuery } from 'graphql/GetIncidentQuery';
import { commitMutationForm } from 'helper/commitMutationForm';
import { UpdateIncidentMutation } from 'graphql/UpdateIncidentMutation';
import { Box, Button, Grid } from '@material-ui/core';
import moment from 'moment';
import { useAutoSave } from 'hooks/useAutoSave';
import { FormSpy } from 'react-final-form';

interface IComponentProps {
  incidentId: string;
  isSubmitted: boolean;
  handleClose(): void;
}

export const EditIncidentDialog = (props: IComponentProps) => {
  const { incidentId, isSubmitted, handleClose } = props;
  const [result, handleChange] = useAutoSave(30);

  const onSubmitHandler = (values, incidentId) => {
    commitMutationForm({
      values: {
        id: incidentId,
        ...values,
      },
      mutation: UpdateIncidentMutation,
    });
  };

  return (
    <QueryRenderer
      environment={modernEnvironment}
      query={GetIncidentQuery}
      variables={{
        id: incidentId,
      }}
      render={({ error, props }) => {
        if (error) {
          return <ValidationError>{error[0].message}</ValidationError>;
        } else if (props) {
          const { incident } = props;
          return (
            <>
              <FormBuilder
                formObject={!isSubmitted ? IncidentForm : IncidentFormDisabled}
                initialValues={incident}
                onSubmit={(values) => {
                  onSubmitHandler(values, incidentId);
                }}
                onValidate={(values: {}) => {
                  const errors = {};
                  if (values.incidentDate) {
                    const formattedDate = moment(
                      new Date(values.incidentDate)
                    ).format('YYYY-MM-DD hh:mm');
                    if (formattedDate === 'Invalid date') {
                      errors.incidentDate = 'Format should be MM-DD-YYYY';
                    }
                  }

                  return errors;
                }}
              >
                <FormSpy
                  subscription={{
                    values: true,
                    initialValues: true,
                    errors: false,
                    hasValidationErrors: true,
                  }}
                  onChange={({ values }) => {
                    handleChange({ values });
                  }}
                />
              </FormBuilder>
              <Grid container justify="flex-end">
                <Box p={0.75}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      onSubmitHandler(result, incidentId);
                      handleClose();
                    }}
                  >
                    Done
                  </Button>
                </Box>
              </Grid>
            </>
          );
        }
        return <Loader />;
      }}
    />
  );
};
