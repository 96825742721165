import React, { useState, useRef, useEffect } from 'react';
import { Button, Grid, Box, IconButton, Typography } from '@material-ui/core';
import { CustomisedDialog } from 'components/CustomisedDialog';
import { FullscreenLoader } from 'components/FullscreenLoader';
import CancelIcon from '@material-ui/icons/Cancel';
import { commitMutation } from 'react-relay';
import { DeleteActivityPlanKeyBehaviourMutation } from 'graphql/DeleteActivityPlanKeyBehaviourMutation';
import { modernEnvironment } from 'config/environment';

interface IComponentProps {
  category?: string;
  riskMitigation: string;
  addHandler(): void;
}

export const DeleteCategoryPopup = ({
  category,
  riskMitigation,
  addHandler,
}: IComponentProps) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [planKeyBehaviours, setPlanKeyBehaviours] = useState([]);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    setPlanKeyBehaviours(
      [...riskMitigation.keyBehaviours]
        .filter((keyBehaviour) => keyBehaviour.category === category)
        .map((keyBehaviour) => keyBehaviour.id)
    );

    return () => {
      mounted.current = false;
      setPlanKeyBehaviours([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpen(false);
    e.stopPropagation();
  };

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsLoading(true);
    deleteKeyBehaviour(planKeyBehaviours);
  };

  const deleteKeyBehaviour = (keyBehaviours) => {
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: keyBehaviours[0],
        },
      },
      mutation: DeleteActivityPlanKeyBehaviourMutation,
      // updater: (store) => {
      //   const mutation = store.getRootField(
      //     'submitBusinessIntentActivityPlan'
      //   );
      //   const plan = mutation.getLinkedRecord('activityPlan');
      //   plan.setValue(newStatus, 'statusBusinessIntent');
      // },
      onCompleted: (response) => {
        setPlanKeyBehaviours(planKeyBehaviours.splice(0, 1));

        if (planKeyBehaviours.length === 0) {
          mounted.current && setIsLoading(false);
          setOpen(false);
          addHandler();
        } else {
          deleteKeyBehaviour(planKeyBehaviours);
        }
      },
      onError: (response) => {
        mounted.current && setIsLoading(false);
      },
    });
  };
  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => {
          handleOpen(e);
        }}
      >
        <CancelIcon fontSize="inherit"></CancelIcon>
      </IconButton>
      <CustomisedDialog
        title={`Remove ${category}`}
        subtitle=""
        open={open}
        onClose={(e) => {
          handleClose(e);
        }}
      >
        <Typography variant="h4">
          Are you sure you want to remove the category <b>{category}</b> from
          the Key Behaviours?
        </Typography>
        <Grid container justify="flex-end">
          <Box pt={1.5} pb={0.75}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={(e) => {
                handleClose(e);
              }}
            >
              No
            </Button>
          </Box>
          <Box pl={1.5} pt={1.5} pb={0.75}>
            <Button
              color="primary"
              variant="contained"
              onClick={(e) => {
                handleRemove(e);
              }}
            >
              Yes
            </Button>
          </Box>
        </Grid>
        <FullscreenLoader isLoading={isLoading} />
      </CustomisedDialog>
    </>
  );
};
