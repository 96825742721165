/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type updateItineraryLegInput = {
    readonly id: string;
    readonly activityPlan?: string | null;
    readonly meansOfTravel?: string | null;
    readonly fromLocation?: unknown | null;
    readonly toLocation?: unknown | null;
    readonly details?: string | null;
    readonly travelDepartureTime?: string | null;
    readonly travelArrivalTime?: string | null;
    readonly travelDepartureTimezone?: string | null;
    readonly travelArrivalTimezone?: string | null;
    readonly notes?: string | null;
    readonly visaStatus?: string | null;
    readonly accreditationStatus?: string | null;
    readonly locationDepartureTime?: string | null;
    readonly locationArrivalTime?: string | null;
    readonly locationAccommodationNotes?: string | null;
    readonly locationActivityNotes?: string | null;
    readonly itineraryContacts?: ReadonlyArray<string | null> | null;
    readonly participants?: ReadonlyArray<string | null> | null;
    readonly clientMutationId?: string | null;
};
export type UpdatePlanItineraryLegMutationVariables = {
    input: updateItineraryLegInput;
};
export type UpdatePlanItineraryLegMutationResponse = {
    readonly updateItineraryLeg: {
        readonly itineraryLeg: {
            readonly id: string;
            readonly meansOfTravel: string | null;
            readonly fromLocation: unknown;
            readonly toLocation: unknown;
            readonly details: string | null;
            readonly travelDepartureTime: string | null;
            readonly travelArrivalTime: string | null;
            readonly notes: string | null;
            readonly visaStatus: string | null;
            readonly accreditationStatus: string | null;
        } | null;
    } | null;
};
export type UpdatePlanItineraryLegMutation = {
    readonly response: UpdatePlanItineraryLegMutationResponse;
    readonly variables: UpdatePlanItineraryLegMutationVariables;
};



/*
mutation UpdatePlanItineraryLegMutation(
  $input: updateItineraryLegInput!
) {
  updateItineraryLeg(input: $input) {
    itineraryLeg {
      id
      meansOfTravel
      fromLocation
      toLocation
      details
      travelDepartureTime
      travelArrivalTime
      notes
      visaStatus
      accreditationStatus
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "updateItineraryLegInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateItineraryLeg",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "updateItineraryLegPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "itineraryLeg",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ItineraryLeg",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "meansOfTravel",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fromLocation",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "toLocation",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "details",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "travelDepartureTime",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "travelArrivalTime",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "notes",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "visaStatus",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "accreditationStatus",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdatePlanItineraryLegMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdatePlanItineraryLegMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdatePlanItineraryLegMutation",
            "id": null,
            "text": "mutation UpdatePlanItineraryLegMutation(\n  $input: updateItineraryLegInput!\n) {\n  updateItineraryLeg(input: $input) {\n    itineraryLeg {\n      id\n      meansOfTravel\n      fromLocation\n      toLocation\n      details\n      travelDepartureTime\n      travelArrivalTime\n      notes\n      visaStatus\n      accreditationStatus\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '052fc38749ae94096b3e7f8a7fb8460d';
export default node;
