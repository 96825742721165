import { graphql } from 'react-relay';

export const GetPlanItineraryLegQuery = graphql`
  query GetPlanItineraryLegQuery($legId: ID!, $planId: ID!) {
    itineraryLeg(id: $legId) {
      id
      meansOfTravel
      fromLocation
      toLocation
      details
      travelDepartureTime
      travelArrivalTime
      travelDepartureTimezone
      travelArrivalTimezone
      notes
      visaStatus
      accreditationStatus
      participants {
        id
        firstName
        lastName
      }
    }
    activityPlan(id: $planId) {
      id
      name
      statusItineraryLegs
      participants {
        id
        firstName
        lastName
      }
      durationFrom
      durationTo
    }
  }
`;
