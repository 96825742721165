import React, { ReactNode } from 'react';
import { Avatar } from '@material-ui/core';

interface IComponentProps {
  src: string;
  children: ReactNode;
}

const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const base64MimeType = (encoded: string) => {
  let result = null;

  if (typeof encoded !== 'string') {
    return result;
  }

  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
};

export const AvatarWithBlob = React.forwardRef(
  (props: IComponentProps, ref) => {
    const { src = '', children } = props;
    const srcUpdated = src === null ? '' : src;
    const base64result = srcUpdated.substr(srcUpdated.indexOf(',') + 1);

    const blob = b64toBlob(base64result, base64MimeType(srcUpdated));
    const blobUrl = URL.createObjectURL(blob);
    return (
      <Avatar src={blobUrl} {...props} ref={ref}>
        {children}
      </Avatar>
    );
  }
);
