import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

interface IComponentProps {
  children?: ReactNode;
}

const useStyles = makeStyles({
  planContentWrapper: {
    width: 870,
    paddingBottom: 104,
    overflowX: 'scroll',
  },
});

export const PlanContentWrapper = (props: IComponentProps) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <>
      <Box className={classes.planContentWrapper}>
        {children}
      </Box>
    </>
  );
};
