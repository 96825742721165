import { graphql } from 'react-relay';

export const GetEmployeeEmergencyContactsQuery = graphql`
  query GetEmployeeEmergencyContactsQuery($id: ID!) {
    employee(id: $id) {
      employeeEmergencyContacts {
        id
        type
        firstName
        middleName
        lastName
        mobilePhoneNumbers
        gender
        nationality
        email
        relationship
        languagePreference
        specialInstructions
      }
    }
    employees {
      firstName
      lastName
      id
      email
    }
  }
`;
