/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type updateParticipantEmergencyContactInput = {
    readonly id: string;
    readonly participant?: string | null;
    readonly firstName?: string | null;
    readonly middleName?: string | null;
    readonly lastName?: string | null;
    readonly mobilePhoneNumbers?: unknown | null;
    readonly gender?: string | null;
    readonly nationality?: unknown | null;
    readonly email?: string | null;
    readonly relationship?: string | null;
    readonly languagePreference?: string | null;
    readonly specialInstructions?: string | null;
    readonly type?: string | null;
    readonly clientMutationId?: string | null;
};
export type UpdateParticipantEmergencyContactMutationVariables = {
    input: updateParticipantEmergencyContactInput;
};
export type UpdateParticipantEmergencyContactMutationResponse = {
    readonly updateParticipantEmergencyContact: {
        readonly participantEmergencyContact: {
            readonly id: string;
        } | null;
    } | null;
};
export type UpdateParticipantEmergencyContactMutation = {
    readonly response: UpdateParticipantEmergencyContactMutationResponse;
    readonly variables: UpdateParticipantEmergencyContactMutationVariables;
};



/*
mutation UpdateParticipantEmergencyContactMutation(
  $input: updateParticipantEmergencyContactInput!
) {
  updateParticipantEmergencyContact(input: $input) {
    participantEmergencyContact {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "updateParticipantEmergencyContactInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateParticipantEmergencyContact",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "updateParticipantEmergencyContactPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participantEmergencyContact",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ParticipantEmergencyContact",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdateParticipantEmergencyContactMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdateParticipantEmergencyContactMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdateParticipantEmergencyContactMutation",
            "id": null,
            "text": "mutation UpdateParticipantEmergencyContactMutation(\n  $input: updateParticipantEmergencyContactInput!\n) {\n  updateParticipantEmergencyContact(input: $input) {\n    participantEmergencyContact {\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '8e528992c676d8f1a20f49e49cc12625';
export default node;
