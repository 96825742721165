import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';

interface IComponentProps {
  planState: {};
}

export const PlansSectionTitle = ({ planState }: IComponentProps) => (
  <>
    <Grid container alignItems="center">
      <Box p={1.5} pb={3.5}>
        <Typography variant="h1" component="h2">
          {planState.label}
        </Typography>
      </Box>
      <Box p={1.5} pb={3.5}>
        <Typography variant="body1" color="textSecondary">
          {planState.description}
        </Typography>
      </Box>

    </Grid>
  </>
);
