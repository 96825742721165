import { graphql } from 'react-relay';

export const GetPlanRiskAssessmentQuery = graphql`
  query GetPlanRiskAssessmentQuery($id: ID!) {
    activityPlan(id: $id) {
      riskDetermination {
        submittedStatus
      }
      riskAssessment {
        id
        submittedStatus
        mitigationSummary
        extantUntreatedRisk
      }
    }
  }
`;
