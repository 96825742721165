/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type createActivityPlanKeyBehaviourInput = {
    readonly instruction: string;
    readonly category: string;
    readonly riskMitigation: string;
    readonly clientMutationId?: string | null;
};
export type CreateActivityPlanKeyBehaviourMutationVariables = {
    input: createActivityPlanKeyBehaviourInput;
};
export type CreateActivityPlanKeyBehaviourMutationResponse = {
    readonly createActivityPlanKeyBehaviour: {
        readonly activityPlanKeyBehaviour: {
            readonly instruction: string;
            readonly category: string;
        } | null;
    } | null;
};
export type CreateActivityPlanKeyBehaviourMutation = {
    readonly response: CreateActivityPlanKeyBehaviourMutationResponse;
    readonly variables: CreateActivityPlanKeyBehaviourMutationVariables;
};



/*
mutation CreateActivityPlanKeyBehaviourMutation(
  $input: createActivityPlanKeyBehaviourInput!
) {
  createActivityPlanKeyBehaviour(input: $input) {
    activityPlanKeyBehaviour {
      instruction
      category
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "createActivityPlanKeyBehaviourInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "instruction",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "category",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CreateActivityPlanKeyBehaviourMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "createActivityPlanKeyBehaviour",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "createActivityPlanKeyBehaviourPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "activityPlanKeyBehaviour",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ActivityPlanKeyBehaviour",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "CreateActivityPlanKeyBehaviourMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "createActivityPlanKeyBehaviour",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "createActivityPlanKeyBehaviourPayload",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "activityPlanKeyBehaviour",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ActivityPlanKeyBehaviour",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "CreateActivityPlanKeyBehaviourMutation",
            "id": null,
            "text": "mutation CreateActivityPlanKeyBehaviourMutation(\n  $input: createActivityPlanKeyBehaviourInput!\n) {\n  createActivityPlanKeyBehaviour(input: $input) {\n    activityPlanKeyBehaviour {\n      instruction\n      category\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'ccc4c624cf20d506b5965e4078748218';
export default node;
