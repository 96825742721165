import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createRootReducer } from './reducers';
export const history = createBrowserHistory();

const rootReducer = createRootReducer(history);

export const configureStore = (preloadedState?: any) => {
  const composeEnhancer: typeof compose = ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
      ),
    ),
  );

  // Hot reloading
  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept('./reducers', () => {
  //     store.replaceReducer(createRootReducer(history));
  //   });
  // }

  return store;
};

export type AppState = ReturnType<typeof rootReducer>;
