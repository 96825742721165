import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FieldRenderProps } from 'react-final-form';
import { IFieldProps } from './types';
import { Box, Typography, Grid, InputLabel } from '@material-ui/core';
import { arabicToRoman } from 'helper/arabicToRoman';

type Props = FieldRenderProps<string, any> & IFieldProps;

const KeyRiskFieldWrapper = (props: Props) => {
  const {
    input,
    meta,
    templateOptions: {
      label,
      elementType,
      required,
      placeholder,
      disabled,
      width,
      fullWidth,
      multiline,
      rows,
      pattern,
      description,
      repeaterIndex,
    },
  } = props;

  const showError = meta.dirty && meta.invalid;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const changeInput = {
      id: input.value.id,
      type: input.value.type,
      notes: event.target.value,
    };

    input.onChange(changeInput);
  };

  return (
    <Grid container direction="column">
      {repeaterIndex && (
        <Box mb={1}>
          <InputLabel>
            Risk/Treat Mitigation {arabicToRoman(repeaterIndex + 1)}
          </InputLabel>
        </Box>
      )}
      <Box mb={2} width={'100%'}>
        <TextField
          variant={'outlined'}
          error={showError}
          helperText={showError ? meta.error || meta.submitError : undefined}
          type={elementType}
          value={
            input.value !== null && input.value.type ? input.value.type : ''
          }
          disabled={true}
          fullWidth={fullWidth}
          multiline={multiline}
          inputProps={{
            style: { minWidth: `${width}px` },
            pattern: `${pattern && pattern.rgx}`,
            step: 1,
          }}
        />
      </Box>
      <Box mb={3}>
        <TextField
          variant={'outlined'}
          error={showError}
          helperText={showError ? meta.error || meta.submitError : undefined}
          onChange={handleChange}
          label={label}
          placeholder={placeholder ? placeholder : label}
          required={required}
          type={elementType}
          value={
            input.value !== null && input.value.notes ? input.value.notes : ''
          }
          disabled={disabled}
          fullWidth={fullWidth}
          multiline={true}
          rows={rows}
          inputProps={{
            style: { minWidth: `${width}px` },
            pattern: `${pattern && pattern.rgx}`,
            step: 1,
          }}
        />
        {description && (
          <Box pt={0.25}>
            <Typography variant="body2" color="textSecondary">
              {description}
            </Typography>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export { KeyRiskFieldWrapper as KeyRiskField };
