import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { IFieldProps } from './types';
import { QueryRenderer } from 'react-relay';
import { modernEnvironment } from 'config/environment';
import { GetCountriesQuery } from 'graphql/GetCountriesQuery';
import { BaseCountrySelector } from './BaseCountrySelector';
import { Loader } from 'components/Loader';

type Props = FieldRenderProps<string, any> & IFieldProps;

export const CountrySelectFieldQuery = (props: Props) => {
  const {
    input,
    meta,
    templateOptions: { label, required, disabled, regionName },
  } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    input.onChange(event.target.value);
  };

  const showError = meta.dirty && meta.invalid;

  return (
    <QueryRenderer
      environment={modernEnvironment}
      query={GetCountriesQuery}
      variables={{
        regionName,
      }}
      render={({ props }) =>
        props ? (
          <BaseCountrySelector
            label={label}
            name={input.name}
            value={input.value}
            required={required}
            disabled={disabled}
            showError={showError}
            options={props.locations}
            onChange={handleChange}
          />
        ) : (
          <Loader />
        )
      }
    />
  );
};
