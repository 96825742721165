import { graphql } from 'react-relay';

export const GetPlanTileQuery = graphql`
  query GetPlanTileQuery($status: String) {
    activityPlans(status: $status) {
      id
      name
      durationFrom
      durationTo
      region
      submissionProgress
      itineraryLegs {
        id
        travelDepartureTime
        travelArrivalTime
      }
      participants {
        id
        firstName
        lastName
        role
        email
        mediaObjects(type: "avatar") {
          id
          content
        }
      }
      supporters(role: "approver") {
        position
        approvedStatus
        employee {
          firstName
          lastName
          roleInTrip
          department
        }
      }
    }
  }
`;
