import { graphql } from 'react-relay';

export const DeletePlanItineraryLegMutation = graphql`
  mutation DeletePlanItineraryLegMutation($input: deleteItineraryLegInput!) {
    deleteItineraryLeg(input: $input) {
      itineraryLeg {
        id
      }
    }
  }
`;
