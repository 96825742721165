import React, { Fragment } from 'react';
import { getInitials } from 'helper/getInitials';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { AvatarWithBlob } from 'components/AvatarWithBlob';
import supporterRoles from 'data/supporter-roles.json';

interface IComponentProps {
  users: Object;
}

export const useStyles = makeStyles((theme) => ({
  smallAvatar: {
    width: 32,
    height: 32,
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  bigAvatar: {
    width: 48,
    height: 48,
    color: theme.palette.primary.main,
    fontSize: 20,
  },
}));

export const SelectedUsersWithRoles = ({ users }: IComponentProps) => {
  const classes = useStyles();
  return (
    <Fragment>
      {supporterRoles.map((role, index) => {
        const selectedUsers = users.filter((user) => user.role === role.key);
        if (!selectedUsers.length) {
          return <Fragment key={`${role.key}-${index}`}></Fragment>;
        }
        return (
          <Box key={`${role.key}-${index}`} pb={4.5}>
            <Typography variant="h3">{role.name}</Typography>
            <Typography variant="body1">{role.description}</Typography>
            <Box pt={1.5}>
              {selectedUsers.map(({ employee }) => (
                <Grid container key={employee.email}>
                  <Box pb={1.5} pr={1.5}>
                    <AvatarWithBlob
                      className={classes.bigAvatar}
                      src={
                        employee !== null &&
                        employee.mediaObjects &&
                        employee.mediaObjects[0] &&
                        employee.mediaObjects[0].content
                      }
                    >
                      {getInitials(employee.firstName, employee.lastName)}
                    </AvatarWithBlob>
                  </Box>
                  <Box pb={1.5}>
                    <Typography variant="h4">
                      {employee.firstName} {employee.lastName}
                    </Typography>
                    <Typography variant="body1">{employee.email}</Typography>
                  </Box>
                </Grid>
              ))}
            </Box>
          </Box>
        );
      })}
    </Fragment>
  );
};
