import React, { useState } from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  fullHeight: {
    minHeight: '100vh',
  },
  paper: {
    minWidth: 612,
    padding: theme.spacing(6, 8),
  },
}));

interface IComponentProps {
  history: { push(path: string): void };
  push(path: string): void;
}

export const ObtainFromWorkday = (props: IComponentProps) => {
  const { history } = props;
  const classes = useStyles();
  const [step, setStep] = useState(1);
  return (
    <Grid
      className={classes.fullHeight}
      container
      justify="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        {step === 1 && (
          <Paper className={classes.paper}>
            <Box mb={2}>
              <Typography variant="h3">High Risk Traveler wants to:</Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="h3">
                Obtain your personal data from WorkDay
              </Typography>
            </Box>
            <Grid container justify="flex-end" alignItems="center">
              <Box ml={2} mt={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    history.push('/select-mode');
                  }}
                >
                  No
                </Button>
              </Box>

              <Box ml={2} mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  Allow
                </Button>
              </Box>
            </Grid>
          </Paper>
        )}
        {step === 2 && (
          <Paper className={classes.paper}>
            <Box mb={2}>
              <Typography variant="h3">
                Your personal data has been populated from WorkDay
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="h3">
                Please finish all rest part of your personal details.
              </Typography>
            </Box>
            <Grid container justify="flex-end" alignItems="center">
              <Box ml={2} mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push('/select-mode');
                  }}
                >
                  Next
                </Button>
              </Box>
            </Grid>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};
