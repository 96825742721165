/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanRiskAssessmentQueryVariables = {
    id: string;
};
export type GetPlanRiskAssessmentQueryResponse = {
    readonly activityPlan: {
        readonly riskDetermination: {
            readonly submittedStatus: boolean | null;
        } | null;
        readonly riskAssessment: {
            readonly id: string;
            readonly submittedStatus: boolean | null;
            readonly mitigationSummary: string | null;
            readonly extantUntreatedRisk: string | null;
        } | null;
    } | null;
};
export type GetPlanRiskAssessmentQuery = {
    readonly response: GetPlanRiskAssessmentQueryResponse;
    readonly variables: GetPlanRiskAssessmentQueryVariables;
};



/*
query GetPlanRiskAssessmentQuery(
  $id: ID!
) {
  activityPlan(id: $id) {
    riskDetermination {
      submittedStatus
      id
    }
    riskAssessment {
      id
      submittedStatus
      mitigationSummary
      extantUntreatedRisk
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "submittedStatus",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "riskAssessment",
        "storageKey": null,
        "args": null,
        "concreteType": "RiskAssessment",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            (v2 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "mitigationSummary",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "extantUntreatedRisk",
                "args": null,
                "storageKey": null
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanRiskAssessmentQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskDetermination",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskDetermination",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ]
                        },
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanRiskAssessmentQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "activityPlan",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ActivityPlan",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "riskDetermination",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskDetermination",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/)
                            ]
                        },
                        (v4 /*: any*/),
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanRiskAssessmentQuery",
            "id": null,
            "text": "query GetPlanRiskAssessmentQuery(\n  $id: ID!\n) {\n  activityPlan(id: $id) {\n    riskDetermination {\n      submittedStatus\n      id\n    }\n    riskAssessment {\n      id\n      submittedStatus\n      mitigationSummary\n      extantUntreatedRisk\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'd9660ae34ef3b0cfb929a1104ffb1750';
export default node;
