import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import * as components from './fields';
import { IFormComponent } from './fields/types';
import {
  requiredValidator,
  maxLengthValidator,
  minLengthValidator,
  patternValidator,
  emailValidator,
  phoneValidator,
} from 'helper/fieldValidatators';

export const DynamicFormComponent: React.ComponentType<IFormComponent> = (
  props: IFormComponent
) => {
  const { type } = props;
  // @ts-ignore
  // tslint:disable-next-line: strict-boolean-expressions
  const COMPONENT = components[type] || components.input;
  const { fieldGroup, templateOptions, template } = props;
  const {
    elementType,
    required,
    width,
    fullWidth,
    maxlength,
    minlength,
    pattern,
  } = templateOptions;

  const validators = useMemo(() => {
    const validators = [];
    if (type !== 'repeater') {
      // console.log(type);
      if (type === 'email' && !pattern) {
        validators.push(emailValidator);
      }

      if (type === 'phone') {
        validators.push(phoneValidator);
      }
      if (pattern) {
        validators.push(patternValidator(pattern));
      }
      if (required) {
        validators.push(requiredValidator);
      }
      if (maxlength) {
        validators.push(maxLengthValidator(maxlength));
      }
      if (minlength) {
        validators.push(minLengthValidator(minlength));
      }
    }
    return validators;
  }, [type, required, maxlength, minlength, pattern]);

  const doValidate = useMemo(
    () => (value: any) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      ),
    [validators]
  );

  return (
    <>
      <Field
        name={props.name}
        type={elementType}
        templateOptions={templateOptions}
        required={required}
        fieldGroup={fieldGroup}
        template={template}
        component={COMPONENT}
        width={width}
        fullWidth={fullWidth}
        validate={doValidate}
        allowNull
        parse={(value) => (value === '' ? null : value)}
      />
    </>
  );
};
