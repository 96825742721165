import { graphql } from 'react-relay';

export const UpdateEmployeeEmergencyContactMutation = graphql`
  mutation UpdateEmployeeEmergencyContactMutation(
    $input: updateEmployeeEmergencyContactInput!
  ) {
    updateEmployeeEmergencyContact(input: $input) {
      employeeEmergencyContact {
        id
      }
    }
  }
`;
