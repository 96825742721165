import { graphql } from 'react-relay';

export const GetParticipantEmergencyContactsQuery = graphql`
  query GetParticipantEmergencyContactsQuery($id: ID!) {
    participant(id: $id) {
      participantEmergencyContacts {
        id
        type
        firstName
        middleName
        lastName
        mobilePhoneNumbers
        gender
        nationality
        email
        relationship
        languagePreference
        specialInstructions
      }
    }
  }
`;
