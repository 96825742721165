import { graphql } from 'react-relay';

export const DeleteEmployeeMediaObjectsMutation = graphql`
  mutation DeleteEmployeeMediaObjectsMutation(
    $input: deleteEmployeeMediaObjectInput!
  ) {
    deleteEmployeeMediaObject(input: $input) {
      employeeMediaObject {
        id
      }
    }
  }
`;
