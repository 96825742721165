/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type deleteKeyThreatInput = {
    readonly id: string;
    readonly clientMutationId?: string | null;
};
export type DeleteKeyThreatMutationVariables = {
    input: deleteKeyThreatInput;
};
export type DeleteKeyThreatMutationResponse = {
    readonly deleteKeyThreat: {
        readonly keyThreat: {
            readonly id: string;
        } | null;
    } | null;
};
export type DeleteKeyThreatMutation = {
    readonly response: DeleteKeyThreatMutationResponse;
    readonly variables: DeleteKeyThreatMutationVariables;
};



/*
mutation DeleteKeyThreatMutation(
  $input: deleteKeyThreatInput!
) {
  deleteKeyThreat(input: $input) {
    keyThreat {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "deleteKeyThreatInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "deleteKeyThreat",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "deleteKeyThreatPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "keyThreat",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "KeyThreat",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "DeleteKeyThreatMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "DeleteKeyThreatMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "DeleteKeyThreatMutation",
            "id": null,
            "text": "mutation DeleteKeyThreatMutation(\n  $input: deleteKeyThreatInput!\n) {\n  deleteKeyThreat(input: $input) {\n    keyThreat {\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '4d0501576737e29f76fd632f6e3f28fc';
export default node;
