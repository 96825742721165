/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type createParticipantEmergencyContactInput = {
    readonly participant?: string | null;
    readonly firstName?: string | null;
    readonly middleName?: string | null;
    readonly lastName?: string | null;
    readonly mobilePhoneNumbers: unknown;
    readonly gender?: string | null;
    readonly nationality: unknown;
    readonly email?: string | null;
    readonly relationship?: string | null;
    readonly languagePreference?: string | null;
    readonly specialInstructions?: string | null;
    readonly type: string;
    readonly clientMutationId?: string | null;
};
export type CreateParticipantEmergencyContactMutationVariables = {
    input: createParticipantEmergencyContactInput;
};
export type CreateParticipantEmergencyContactMutationResponse = {
    readonly createParticipantEmergencyContact: {
        readonly participantEmergencyContact: {
            readonly id: string;
        } | null;
    } | null;
};
export type CreateParticipantEmergencyContactMutation = {
    readonly response: CreateParticipantEmergencyContactMutationResponse;
    readonly variables: CreateParticipantEmergencyContactMutationVariables;
};



/*
mutation CreateParticipantEmergencyContactMutation(
  $input: createParticipantEmergencyContactInput!
) {
  createParticipantEmergencyContact(input: $input) {
    participantEmergencyContact {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "createParticipantEmergencyContactInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "createParticipantEmergencyContact",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "createParticipantEmergencyContactPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participantEmergencyContact",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ParticipantEmergencyContact",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CreateParticipantEmergencyContactMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "CreateParticipantEmergencyContactMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "CreateParticipantEmergencyContactMutation",
            "id": null,
            "text": "mutation CreateParticipantEmergencyContactMutation(\n  $input: createParticipantEmergencyContactInput!\n) {\n  createParticipantEmergencyContact(input: $input) {\n    participantEmergencyContact {\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '7b714f78975d8842b1369077b0958882';
export default node;
