/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanRisksQueryVariables = {
    region?: string | null;
    location_list?: ReadonlyArray<string | null> | null;
};
export type GetPlanRisksQueryResponse = {
    readonly locations: ReadonlyArray<{
        readonly region: string;
        readonly location: string;
        readonly securityRisk: string;
        readonly legalRisk: string;
        readonly cyberRisk: string;
    } | null> | null;
};
export type GetPlanRisksQuery = {
    readonly response: GetPlanRisksQueryResponse;
    readonly variables: GetPlanRisksQueryVariables;
};



/*
query GetPlanRisksQuery(
  $region: String
  $location_list: [String]
) {
  locations(region: $region, location_list: $location_list) {
    region
    location
    securityRisk
    legalRisk
    cyberRisk
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "region",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "location_list",
            "type": "[String]",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "location_list",
            "variableName": "location_list"
        } as any),
        ({
            "kind": "Variable",
            "name": "region",
            "variableName": "region"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "region",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "location",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "securityRisk",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "legalRisk",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "cyberRisk",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanRisksQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "locations",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Location",
                    "plural": true,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanRisksQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "locations",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Location",
                    "plural": true,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanRisksQuery",
            "id": null,
            "text": "query GetPlanRisksQuery(\n  $region: String\n  $location_list: [String]\n) {\n  locations(region: $region, location_list: $location_list) {\n    region\n    location\n    securityRisk\n    legalRisk\n    cyberRisk\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '254aeb3b190e7db2dba9112f4cff5ae6';
export default node;
