import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Grid,
  Divider,
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from '@material-ui/core';
import { customTheme } from 'theme';
import { QueryRenderer, commitMutation } from 'react-relay';
import { modernEnvironment } from 'config/environment';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GetPlanIncidentsQuery } from 'graphql/GetPlanIncidentsQuery';
import { CustomisedTable } from 'components/CustomisedTable';
import { IncidentAnalysisIntro } from 'pages/plan/IncidentAnalysisIntro';
import IncidentsDurationForm from 'data/incidents-duration.json';
import { FormBuilder } from 'components';
import createDecorator from 'final-form-calculate';
import { incidentDurationDecorators } from 'data/form-decorators/incident-analysis-duration';
import { IncidentsCustomisedTable } from 'pages/plan/incidents/IncidentsCustomisedTable';
import {
  formatIncidents,
  getAllIncidentsQuantity,
  HEADERS_BY,
  formattedBy,
  formattedCombinedBy,
  getAllIncidentsDays,
} from 'pages/plan/incidents/AnalysisCalculations';
import moment from 'moment';
import { PlanName } from 'components/Plan/PlanName';
import { PlanContentWrapper } from 'components/Plan/PlanContentWrapper';
import { CustomWarning } from 'components/Plan/CustomWarning';
import { SubmitSectionBtn } from 'components/Buttons/SubmitSectionBtn';
import { UpdateIncidentAnalysisStatusMutation } from 'graphql/UpdateIncidentAnalysisStatusMutation';

interface IComponentProps {
  planId: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  panelSummary: {
    backgroundColor: customTheme.colors.veryLightPinkThree,
  },
}));

const calculator = createDecorator(...incidentDurationDecorators);

export const IncidentAnalysis = ({ planId }: IComponentProps) => {
  const [filterDate, setFilterDate] = useState({ before: null, after: null });
  const [incidentDays, setIncidentDays] = useState(0);
  const [count, setCount] = useState(0);

  const classes = useStyles();

  const mounted = useRef(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const changeSectionStatus = (newStatus: boolean, planId: string) => {
    setIsSubmitLoading(true);
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: `/api/activity_plans/${planId}`,
        },
      },
      mutation: UpdateIncidentAnalysisStatusMutation,
      updater: (store) => {
        const mutation = store.getRootField('submitIncidentsActivityPlan');
        const plan = mutation.getLinkedRecord('activityPlan');
        plan.setValue(newStatus, 'submittedStatusIncidents');
      },
      onCompleted: (response) => {
        mounted.current && setIsSubmitted(newStatus);
        mounted.current && setIsSubmitLoading(false);
      },
      onError: (response) => {
        mounted.current && setIsSubmitLoading(false);
      },
    });
  };

  return (
    <PlanContentWrapper>
      <Grid container direction="column">
        <PlanName planId={planId}></PlanName>
        <IncidentAnalysisIntro />
        <Box pb={3}>
          <FormBuilder
            enableAutosave={false}
            decorators={[calculator]}
            formObject={IncidentsDurationForm}
            initialValues={{}}
            submitButtonName="Filter"
            onSubmit={(values) => {
              setFilterDate({
                before: values.durationTo,
                after: values.durationFrom,
              });
              setIncidentDays(values.days);
            }}
            onValidate={(values: {}) => {
              const errors = {};
              if (!values.durationFrom) {
                errors.durationFrom = 'Required';
              }
              if (!values.durationTo) {
                errors.durationTo = 'Required';
              } else if (
                values.durationFrom &&
                values.durationTo &&
                values.durationFrom >= values.durationTo
              ) {
                errors.durationTo = `Choose a date after ${values.durationFrom}`;
              }
              if (values.durationFrom) {
                const formattedDateFrom = moment(values.durationFrom).format(
                  'YYYY-MM-DD hh:mm'
                );
                if (formattedDateFrom === 'Invalid date') {
                  errors.durationFrom = 'Format should be MM-DD-YYYY';
                }
              }
              if (values.durationTo) {
                const formattedDateTo = moment(values.durationTo).format(
                  'YYYY-MM-DD hh:mm'
                );
                if (formattedDateTo === 'Invalid date') {
                  errors.durationTo = 'Format should be MM-DD-YYYY';
                }
              }

              return errors;
            }}
          />
        </Box>
        <Box p={1.5}>
          <QueryRenderer
            key={count}
            environment={modernEnvironment}
            query={GetPlanIncidentsQuery}
            variables={{
              id: `/api/activity_plans/${planId}`,
              incidentDate: filterDate,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error.message}</ValidationError>;
              } else if (props) {
                const { activityPlan } = props;
                const {
                  incidents,
                  statusItineraryLegs,
                  submittedStatusIncidents,
                } = activityPlan;
                const formattedIncidents = formatIncidents(incidents);
                if (statusItineraryLegs === 'development') {
                  return (
                    <CustomWarning>
                      Participant Info and Itinerary sections need to be
                      submitted, in order to view/edit this section.
                    </CustomWarning>
                  );
                }
                setIsSubmitted(submittedStatusIncidents);
                return (
                  <Grid container direction="column">
                    <IncidentsCustomisedTable
                      isSubmitted={isSubmitted}
                      incidents={formattedIncidents}
                      planId={planId}
                      countHandler={() => setCount(count + 1)}
                    />
                    {formattedIncidents.length > 0 && (
                      <>
                        <Box pb={3}>
                          <Divider />
                        </Box>
                        <Box>
                          <Typography variant="h3" component="h3">
                            Review
                          </Typography>
                        </Box>
                      </>
                    )}
                    {filterDate.before === null && filterDate.after === null && (
                      <Box pb={1.5}>
                        <Typography variant="body1">
                          Please, select period above, in order to see summary.
                        </Typography>
                      </Box>
                    )}
                    {formattedIncidents.length > 0 &&
                      filterDate.before !== null &&
                      filterDate.after !== null && (
                        <>
                          <Box pb={3}>
                            <Typography variant="body1">
                              Relevant incidents which convey the most
                              applicable threats to inform planning.
                            </Typography>
                          </Box>
                          <Box pb={0.5}>
                            <Typography variant="h4" component="h4">
                              Incident rates
                            </Typography>
                          </Box>
                          <Box pb={3}>
                            <Grid container>
                              <Box pr={0.5} width={165}>
                                <TextField
                                  size="small"
                                  disabled
                                  label="Number of incidents"
                                  value={
                                    formattedIncidents.length > 0 &&
                                    formattedIncidents[0].quantity !== null
                                      ? getAllIncidentsQuantity(
                                          formattedIncidents
                                        )
                                      : 0
                                  }
                                />
                              </Box>
                              <Box pr={0.5} flex={1}>
                                <TextField
                                  size="small"
                                  disabled
                                  label="Days per incident"
                                  value={
                                    incidentDays &&
                                    (
                                      incidentDays /
                                      getAllIncidentsQuantity(
                                        formattedIncidents
                                      )
                                    ).toFixed(2)
                                  }
                                />
                              </Box>
                              <Box pr={0.5} flex={1}>
                                <TextField
                                  size="small"
                                  disabled
                                  label="Incidents per day"
                                  value={
                                    incidentDays &&
                                    (
                                      getAllIncidentsQuantity(
                                        formattedIncidents
                                      ) / incidentDays
                                    ).toFixed(2)
                                  }
                                />
                              </Box>
                              <Box width={265}>
                                <TextField
                                  size="small"
                                  fullWidth
                                  disabled
                                  label="Proportion of days with incidents"
                                  value={`${incidentDays &&
                                    (
                                      (getAllIncidentsDays(formattedIncidents) /
                                        incidentDays) *
                                      100
                                    ).toFixed(2)}%`}
                                />
                              </Box>
                            </Grid>
                          </Box>
                          <Box pb={3}>
                            <ExpansionPanel className={classes.root}>
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.panelSummary}
                              >
                                <Typography variant="h4">
                                  Single-factor sigact summary
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails className={classes.root}>
                                <Grid container direction="column">
                                  <Box pb={3}>
                                    <Typography variant="body2" color="primary">
                                      SIGACTs by Method
                                    </Typography>
                                  </Box>
                                  <Box pb={4.5}>
                                    <CustomisedTable
                                      headers={HEADERS_BY}
                                      data={formattedBy(
                                        incidents,
                                        incidentDays,
                                        'type'
                                      )}
                                    />
                                  </Box>
                                  <Box pb={3}>
                                    <Typography variant="body2" color="primary">
                                      SIGACTs by Target
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <CustomisedTable
                                      headers={HEADERS_BY}
                                      data={formattedBy(
                                        incidents,
                                        incidentDays,
                                        'target'
                                      )}
                                    />
                                  </Box>
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel className={classes.root}>
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                className={classes.panelSummary}
                              >
                                <Typography variant="h4">
                                  Combined-factor sigact summary
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails className={classes.root}>
                                <CustomisedTable
                                  headers={HEADERS_BY}
                                  data={formattedCombinedBy(
                                    incidents,
                                    incidentDays
                                  )}
                                />
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Box>
                        </>
                      )}
                    <Box py={1.5}>
                      <SubmitSectionBtn
                        divider={false}
                        isSubmitted={isSubmitted}
                        description={!isSubmitted ? '' : 'Section submitted.'}
                        isLoading={isSubmitLoading}
                        onClickSubmitHandler={() =>
                          changeSectionStatus(true, planId)
                        }
                      />
                    </Box>
                    <Box pb={3}></Box>
                  </Grid>
                );
              }
              return <Loader />;
            }}
          />
        </Box>
      </Grid>
    </PlanContentWrapper>
  );
};
