import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { FieldRenderProps } from 'react-final-form';
import { IFieldProps } from './types';

type Props = FieldRenderProps<string, any> & IFieldProps;

export const CheckboxField = (props: Props) => {
  const {
    input,
    templateOptions: { label, disabled, required },
  } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    input.onChange(event.target.checked);
  };

  return (
    <FormControl
      disabled={disabled}
      required={required}
    >
      <FormControlLabel
        checked={Boolean(input.value)}
        control={
          <Checkbox
            onChange={handleChange}
            color="primary"
          />
        }
        label={<Typography variant="h4">{label}</Typography>}
        labelPlacement="end"
      />
    </FormControl>
  );
};
