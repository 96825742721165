import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Grid, Divider } from '@material-ui/core';
import { PlanName } from 'components/Plan/PlanName';
import { SectionStatus } from 'components/SectionStatus';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import { FormBuilder } from 'components';
import { UpdateRiskAssessment } from 'graphql/UpdateRiskAssessmentMutation';
import { modernEnvironment } from 'config/environment';
import { GetPlanRiskAssessmentQuery } from 'graphql/GetPlanRiskAssessmentQuery';
import { GetPlanKeyThreatsQuery } from 'graphql/GetPlanKeyThreatsQuery';
import RiskAssessmentMitigationForm from 'data/plan/risk-assessment-mitigation.json';
import RiskAssessmentMitigationFormDisabled from 'data/plan/disabled/risk-assessment-mitigation.json';
import RiskAssessmentUntreatedRisksForm from 'data/plan/risk-assessment-untreated-risks.json';
import RiskAssessmentUntreatedRisksFormDisabled from 'data/plan/disabled/risk-assessment-untreated-risks.json';
import KeyThreatsForm from 'data/plan/risk-assessment-key-threats.json';
import KeyThreatsFormDisabled from 'data/plan/disabled/risk-assessment-key-threats.json';
import { QueryRenderer, commitMutation } from 'react-relay';
import { UpdatePlanKeyThreatMutation } from 'graphql/UpdatePlanKeyThreatMutation';
import { commitMutationForm } from 'helper/commitMutationForm';
import { arabicToRoman } from 'helper/arabicToRoman';
import { SubmitSectionBtn } from 'components/Buttons/SubmitSectionBtn';
import { PlanContentWrapper } from 'components/Plan/PlanContentWrapper';
import { UpdateRiskAssessmentStatusMutation } from 'graphql/UpdateRiskAssessmentStatusMutation';
import { CustomWarning } from 'components/Plan/CustomWarning';

interface IComponentProps {
  planId: string;
}

export const RiskAssessment = ({ planId }: IComponentProps) => {
  const mounted = useRef(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const changeSectionStatus = (newStatus, riskId) => {
    setIsSubmitLoading(true);
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: riskId,
        },
      },
      mutation: UpdateRiskAssessmentStatusMutation,
      updater: (store) => {
        const mutation = store.getRootField('submitRiskAssessment');
        const section = mutation.getLinkedRecord('riskAssessment');
        section.setValue(newStatus, 'submittedStatus');
      },
      onCompleted: (response) => {
        mounted.current && setIsSubmitLoading(false);
      },
      onError: (response) => {
        mounted.current && setIsSubmitted(newStatus);
        mounted.current && setIsSubmitLoading(false);
      },
    });
  };

  const showCustomWarning = () => (
    <CustomWarning>
      Risk determination needs to be submitted, in order to view/edit this
      section.
    </CustomWarning>
  );

  return (
    <PlanContentWrapper>
      <Grid container direction="column">
        <PlanName planId={planId}></PlanName>
        <Box p={1.5} pb={0.5}>
          <Typography variant="h1" component="h2">
            Risk Assessment
          </Typography>
        </Box>
        <Box p={1.5} pb={3.5}>
          <Typography variant="body1">
            Mitigation summary, Key Risk Reduction Methods, Extant/Untreated
            Risks
          </Typography>
        </Box>
        <SectionStatus requireLegalReview={true}></SectionStatus>
        <Box p={1.15} pb={4.5}>
          <Divider />
        </Box>

        <Box mb={7.5}>
          <QueryRenderer
            environment={modernEnvironment}
            query={GetPlanRiskAssessmentQuery}
            variables={{
              id: `/api/activity_plans/${planId}`,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error[0].message}</ValidationError>;
              } else if (props) {
                const { activityPlan } = props;
                if (
                  activityPlan.riskAssessment === null ||
                  activityPlan.riskDetermination === null
                ) {
                  return showCustomWarning();
                }

                const {
                  riskAssessment,
                  riskDetermination: {
                    submittedStatus: riskDeterminationSubmittedStatus,
                  },
                } = activityPlan;

                if (!riskDeterminationSubmittedStatus) {
                  return showCustomWarning();
                }

                const {
                  submittedStatus,
                  ...riskAssessmentWithoutStatus
                } = riskAssessment;

                return (
                  <FormBuilder
                    formObject={
                      !isSubmitted
                        ? RiskAssessmentMitigationForm
                        : RiskAssessmentMitigationFormDisabled
                    }
                    initialValues={riskAssessmentWithoutStatus}
                    onSubmit={(values) => {
                      UpdateRiskAssessment(modernEnvironment, {
                        id: riskAssessment.id,
                        ...values,
                      });
                    }}
                  />
                );
              }
              return <Loader />;
            }}
          />
        </Box>

        <Box mb={4.5}>
          <QueryRenderer
            environment={modernEnvironment}
            query={GetPlanKeyThreatsQuery}
            variables={{
              id: `/api/activity_plans/${planId}`,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error[0].message}</ValidationError>;
              } else if (props) {
                const { activityPlan } = props;

                if (activityPlan.riskDetermination === null) {
                  return <></>;
                }

                const {
                  riskDetermination: {
                    keyThreats,
                    submittedStatus: riskDeterminationSubmittedStatus,
                  },
                } = activityPlan;

                if (!riskDeterminationSubmittedStatus) {
                  return <></>;
                }

                return (
                  <Box>
                    <Box pl={1.15}>
                      <Typography variant="h3">
                        Key Risk Reduction Methods
                      </Typography>
                    </Box>

                    <Box mb={2} pl={1.15}>
                      <Typography variant="body1" color="textSecondary">
                        Specific actions/factors that will reduce/avoid risk and
                        or how specific risks/threats will be addressed.
                      </Typography>
                    </Box>
                    {keyThreats.map((keyThreat, index) => (
                      <Box key={keyThreat.id}>
                        <Box mb={1} pl={1.15}>
                          <Typography variant="h5" color="primary">
                            Risk/Threat Mitigation {arabicToRoman(index + 1)}
                          </Typography>
                        </Box>
                        <FormBuilder
                          formObject={
                            !isSubmitted
                              ? KeyThreatsForm
                              : KeyThreatsFormDisabled
                          }
                          initialValues={{ keyThreat }}
                          onSubmit={(values) => {
                            const {
                              keyThreat: { id, notes },
                            } = values;
                            commitMutationForm({
                              values: {
                                id,
                                notes,
                              },
                              mutation: UpdatePlanKeyThreatMutation,
                            });
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                );
              }
              return <Loader />;
            }}
          />
        </Box>

        <Box mb={2}>
          <QueryRenderer
            environment={modernEnvironment}
            query={GetPlanRiskAssessmentQuery}
            variables={{
              id: `/api/activity_plans/${planId}`,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error[0].message}</ValidationError>;
              } else if (props) {
                const { activityPlan } = props;

                if (
                  activityPlan.riskAssessment === null ||
                  activityPlan.riskDetermination === null
                ) {
                  return <></>;
                }

                const {
                  riskAssessment,
                  riskDetermination: {
                    submittedStatus: riskDeterminationSubmittedStatus,
                  },
                } = activityPlan;

                if (!riskDeterminationSubmittedStatus) {
                  return <></>;
                }

                const {
                  submittedStatus,
                  ...riskAssessmentWithoutStatus
                } = riskAssessment;

                setIsSubmitted(submittedStatus);
                return (
                  <>
                    <FormBuilder
                      formObject={
                        !isSubmitted
                          ? RiskAssessmentUntreatedRisksForm
                          : RiskAssessmentUntreatedRisksFormDisabled
                      }
                      initialValues={riskAssessmentWithoutStatus}
                      onSubmit={(values) => {
                        UpdateRiskAssessment(modernEnvironment, {
                          id: riskAssessment.id,
                          ...values,
                        });
                      }}
                    />
                    <SubmitSectionBtn
                      isSubmitted={isSubmitted}
                      description={!isSubmitted ? '' : 'Section submitted.'}
                      isLoading={isSubmitLoading}
                      onClickSubmitHandler={() =>
                        changeSectionStatus(true, riskAssessment.id)
                      }
                    />
                  </>
                );
              }
              return <Loader />;
            }}
          />
        </Box>
      </Grid>
    </PlanContentWrapper>
  );
};
