import React from 'react';
import { CustomisedDialog } from 'components/CustomisedDialog';
import { FormBuilder } from 'components';
import { QueryRenderer } from 'react-relay';
import { modernEnvironment } from 'config/environment';
import { commitMutationForm } from 'helper/commitMutationForm';
import { UpdateEmployeeMutation } from 'graphql/UpdateEmployeeMutation';
import { UpdateEmployeeEmergencyContactMutation } from 'graphql/UpdateEmployeeEmergencyContactMutation';
import personalDetails from 'data/personal-details.json';
import physicalDetails from 'data/physical-details.json';
import emergencyContacts from 'data/emergency-contacts.json';
import proofOfLife from 'data/proof-of-life.json';
import { GetEmployeeQuery } from '../../graphql/GetEmployeeQuery';
import { CURRENT_USER } from 'config/globals';
import { ValidationError } from 'components/ValidationError';
import { Loader } from 'components/Loader';
import { AutocompletePeopleContext } from 'contexts/AutocompletePeopleContext';
import { Grid, Box, Button } from '@material-ui/core';
import { FormSpy } from 'react-final-form';
import { useAutoSave } from 'hooks/useAutoSave';

interface IComponentProps {
  title: string;
  openModal: boolean;
  editId: string;
  closeModal(): void;
}
const formData = {
  personalDetails,
  physicalDetails,
  proofOfLife,
  emergencyContacts,
};
export const ModalWithFormBuilder = (props: IComponentProps) => {
  const { editId, closeModal, title } = props;
  const [result, handleChange] = useAutoSave(30);

  const handleClose = () => {
    closeModal();
  };

  const onSubmitHandler = (values, employeeId, mutation) => {
    commitMutationForm({
      values: {
        id: employeeId,
        ...values,
      },
      mutation,
      onUpdate: (store) => {
        if (
          editId !== 'emergencyContactsPrimary' &&
          editId !== 'emergencyContactsAlternate'
        ) {
          const updateEmployee = store.getRootField('updateEmployee');
          const employee = updateEmployee.getLinkedRecord('employee');

          Object.keys(values).forEach((item) => {
            employee.setValue(item, values[item]);
          });
        }
      },
    });
  };

  return (
    <CustomisedDialog title={title} open={true} onClose={handleClose}>
      <QueryRenderer
        environment={modernEnvironment}
        query={GetEmployeeQuery}
        variables={{
          id: CURRENT_USER.id,
        }}
        render={({ error, props }) => {
          if (error) {
            return <ValidationError>{error[0].message}</ValidationError>;
          } else if (props) {
            const { employee, employees } = props;

            const theEmployee =
              editId === 'emergencyContactsPrimary'
                ? employee.employeeEmergencyContacts.find(
                    (o) => o.type === 'primary'
                  )
                : editId === 'emergencyContactsAlternate'
                ? employee.employeeEmergencyContacts.find(
                    (o) => o.type === 'alternate'
                  )
                : employee;

            const theFormObject =
              editId === 'emergencyContactsPrimary' ||
              editId === 'emergencyContactsAlternate'
                ? formData.emergencyContacts
                : formData[editId];

            const data = [
              { id: 'participants', values: [], options: employees },
            ];
            data.forEach((dataObject) => {
              dataObject.options = dataObject.options.map((option) => ({
                fullName: `${option.firstName} ${option.lastName}`,
                ...option,
              }));
            });

            const mutation =
              editId === 'emergencyContactsPrimary' ||
              editId === 'emergencyContactsAlternate'
                ? UpdateEmployeeEmergencyContactMutation
                : UpdateEmployeeMutation;

            return (
              <AutocompletePeopleContext.Provider value={data}>
                <FormBuilder
                  title=""
                  enabledInnerHeadings={false}
                  formObject={theFormObject}
                  initialValues={theEmployee}
                  onSubmit={(values) => {
                    if (values.physicianContactNumber) {
                      delete values.physicianContactNumber.imei;
                    }
                    onSubmitHandler(values, theEmployee.id, mutation);
                  }}
                >
                  <FormSpy
                    subscription={{
                      values: true,
                      initialValues: true,
                      errors: false,
                      hasValidationErrors: true,
                    }}
                    onChange={({ values }) => {
                      handleChange({ values });
                    }}
                  />
                </FormBuilder>
                <Grid container justify="flex-end">
                  <Box p={0.75}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        onSubmitHandler(result, theEmployee.id, mutation);
                        handleClose();
                      }}
                    >
                      Done
                    </Button>
                  </Box>
                </Grid>
              </AutocompletePeopleContext.Provider>
            );
          }
          return <Loader />;
        }}
      />
    </CustomisedDialog>
  );
};
