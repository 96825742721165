import React from 'react';
import { Grid, Typography, AppBar, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { customTheme } from 'theme';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    width: '61%',
    left: 0,
    right: 'auto',
    boxShadow: 'none',
    zIndex: 1201,
  },
  dashboardHeader: {
    height: 60,
    padding: theme.spacing(0.5, 1, 0.5, 3),
    color: theme.palette.primary.contrastText,
    backgroundColor: customTheme.colors.slate,
  },
}));

interface IHeaderProps {
  title: string;
  backUrl: string;
}

export const SectionHeader = (props: IHeaderProps) => {
  const { title, backUrl } = props;
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <Box>
        <Grid
          className={classes.dashboardHeader}
          container
          justify="space-between"
          alignItems="center"
        >
          <Box
            maxWidth={'61%'}
            display="flex"
            justify="flex-start"
            alignItems="center"
          >
            <IconButton href={backUrl} aria-label="back">
              <ArrowBackIcon style={{ color: customTheme.colors.white }} />
            </IconButton>
            <Typography variant="h3">{title}</Typography>
          </Box>
        </Grid>
      </Box>
    </AppBar>
  );
};
