import { graphql } from 'react-relay';

export const GetEmployeePhysicalDetailsQuery = graphql`
  query GetEmployeePhysicalDetailsQuery($id: ID!) {
    employee(id: $id) {
      biologicalSex
      bloodType
      height
      eyeColor
      correctedVision
      hairColorLength
      #hasIdentifyingMarks
      identifyingMarksDescription
      primaryPhysicianName
      physicianContactNumber
      disabilitiesOrOtherMedicalConditions
      medicationsAndDosage
      drugOrOtherAllergies
    }
  }
`;
