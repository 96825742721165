/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetCurrentEmployeeQueryVariables = {
    id: string;
};
export type GetCurrentEmployeeQueryResponse = {
    readonly employee: {
        readonly id: string;
        readonly email: string;
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly mediaObjects: ReadonlyArray<{
            readonly id: string;
            readonly content: string | null;
        } | null> | null;
    } | null;
};
export type GetCurrentEmployeeQuery = {
    readonly response: GetCurrentEmployeeQueryResponse;
    readonly variables: GetCurrentEmployeeQueryVariables;
};



/*
query GetCurrentEmployeeQuery(
  $id: ID!
) {
  employee(id: $id) {
    id
    email
    firstName
    lastName
    mediaObjects(type: "avatar") {
      id
      content
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "employee",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "Employee",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "email",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "mediaObjects",
                    "storageKey": "mediaObjects(type:\"avatar\")",
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "type",
                            "value": "avatar"
                        }
                    ],
                    "concreteType": "EmployeeMediaObject",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "content",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetCurrentEmployeeQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "GetCurrentEmployeeQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "GetCurrentEmployeeQuery",
            "id": null,
            "text": "query GetCurrentEmployeeQuery(\n  $id: ID!\n) {\n  employee(id: $id) {\n    id\n    email\n    firstName\n    lastName\n    mediaObjects(type: \"avatar\") {\n      id\n      content\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'ea971e31b737eda58bf6462732b4289e';
export default node;
