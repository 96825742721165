import { graphql } from 'react-relay';

export const GetPlanHeaderQuery = graphql`
  query GetPlanHeaderQuery($id: ID!) {
    activityPlan(id: $id) {
      id
      name
      supporters(
        role_list: ["expert_legal", "expert_cyber", "expert_security"]
      ) {
        role
        employee {
          id
          email
          firstName
          lastName
          mediaObjects(type: "avatar") {
            content
          }
        }
      }
    }
  }
`;
