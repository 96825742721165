import moment from 'moment';

export const HEADERS = [
  { key: 'index', label: 'S/N (tot)' },
  { key: 'quantity', label: 'Qty' },
  { key: 'incidentDate', label: 'Date' },
  { key: 'gap', label: 'Interval' },
  { key: 'location', label: 'Location' },
  { key: 'type', label: 'Type' },
  { key: 'target', label: 'Target' },
  { key: 'details', label: 'Details', size: 'big' },
  { key: 'actions', label: '' },
];

export const formatIncidents = (incidents) => {
  const formatted = [...incidents].map((row) => ({
    ...row,
    incidentDate: moment(row.incidentDate).format('l'),
  }));

  return formatted;
};

export const getAllIncidentsQuantity = (incidents) => {
  if (incidents.length === 1) {
    return incidents[0].quantity;
  }
  return incidents.reduce((acc, cur) =>
    acc.quantity ? acc.quantity + cur.quantity : acc + cur.quantity
  );
};

export const getAllIncidentsDays = (incidents) => {
  const incidentDates = incidents.map((o) => o.incidentDate);
  const incidentDatesUnique = new Set(incidentDates);
  return incidentDatesUnique.size;
};

export const HEADERS_BY = [
  { key: 'type', label: 'Type' },
  { key: 'quantity', label: 'Qty' },
  {
    key: 'percent_of_all_types',
    label: '%(of all types)',
  },
  { key: 'rate', label: 'Rate(days/SIGACT)' },
];

export const formattedBy = (incidents, incidentDays, by) => {
  const incidentsCopy = [...incidents];
  const names = Array.from(new Set(incidentsCopy.map((o) => o[by])));
  const formatted = names.map((name) => ({
    type: name,
    quantity: getQuantity(by, name, incidents),
    percent_of_all_types: `${(
      (getQuantity(by, name, incidents) / getAllIncidentsQuantity(incidents)) *
      100
    ).toFixed(2)}%`,
    rate: (incidentDays / getQuantity(by, name, incidents)).toFixed(2),
  }));
  return formatted;
};

export const formattedCombinedBy = (incidents, incidentDays) => {
  const incidentsCopy = [...incidents];

  const namesType = Array.from(new Set(incidentsCopy.map((o) => o.type)));
  const namesTarget = Array.from(new Set(incidentsCopy.map((o) => o.target)));
  const formatted = [];
  namesType.forEach((type) => {
    namesTarget.forEach((target) => {
      const filteredIncidents = incidentsCopy.filter(
        (inc) => inc.type === type && inc.target === target
      );
      if (filteredIncidents.length === 0) {
        return;
      }
      const sum = getQuantity('target', target, filteredIncidents);
      formatted.push({
        type: `${type} / ${target}`,
        quantity: sum,
        percent_of_all_types: `${(
          (sum / getAllIncidentsQuantity(incidents)) *
          100
        ).toFixed(2)}%`,
        rate: (incidentDays / sum).toFixed(2),
      });
    });
  });

  return formatted;
};

const getQuantity = (prop, value, data) => {
  // TODO: can be rafactored with .reduce
  let res = 0;
  data.forEach((o) => {
    if (o[prop] === value) {
      res += o.quantity;
    }
  });
  return res;
};
