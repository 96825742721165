/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetEmployeePersonalDetailsQueryVariables = {
    id: string;
};
export type GetEmployeePersonalDetailsQueryResponse = {
    readonly employee: {
        readonly id: string;
        readonly email: string;
        readonly firstName: string | null;
        readonly middleName: string | null;
        readonly lastName: string | null;
        readonly mobilePhoneNumbers: unknown;
        readonly satellitePhoneNumbers: unknown;
        readonly vismoProfile: boolean | null;
        readonly birthDetails: string | null;
        readonly nationality: unknown;
        readonly employer: string | null;
        readonly employmentStatus: string | null;
        readonly insuranceProvider: string | null;
        readonly policyNumber: string | null;
        readonly department: string | null;
        readonly position: string | null;
        readonly roleInTrip: string | null;
        readonly bureauAssigned: unknown;
        readonly domicileCity: unknown;
        readonly lastHefatReceived: string | null;
    } | null;
    readonly employees: ReadonlyArray<{
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly id: string;
        readonly email: string;
    } | null> | null;
};
export type GetEmployeePersonalDetailsQuery = {
    readonly response: GetEmployeePersonalDetailsQueryResponse;
    readonly variables: GetEmployeePersonalDetailsQueryVariables;
};



/*
query GetEmployeePersonalDetailsQuery(
  $id: ID!
) {
  employee(id: $id) {
    id
    email
    firstName
    middleName
    lastName
    mobilePhoneNumbers
    satellitePhoneNumbers
    vismoProfile
    birthDetails
    nationality
    employer
    employmentStatus
    insuranceProvider
    policyNumber
    department
    position
    roleInTrip
    bureauAssigned
    domicileCity
    lastHefatReceived
  }
  employees {
    firstName
    lastName
    id
    email
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v5 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "employee",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "Employee",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "middleName",
                    "args": null,
                    "storageKey": null
                },
                (v4 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "mobilePhoneNumbers",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "satellitePhoneNumbers",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "vismoProfile",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "birthDetails",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "nationality",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "employer",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "employmentStatus",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "insuranceProvider",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "policyNumber",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "department",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "position",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "roleInTrip",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "bureauAssigned",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "domicileCity",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastHefatReceived",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "employees",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": [
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetEmployeePersonalDetailsQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v5 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "GetEmployeePersonalDetailsQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v5 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "GetEmployeePersonalDetailsQuery",
            "id": null,
            "text": "query GetEmployeePersonalDetailsQuery(\n  $id: ID!\n) {\n  employee(id: $id) {\n    id\n    email\n    firstName\n    middleName\n    lastName\n    mobilePhoneNumbers\n    satellitePhoneNumbers\n    vismoProfile\n    birthDetails\n    nationality\n    employer\n    employmentStatus\n    insuranceProvider\n    policyNumber\n    department\n    position\n    roleInTrip\n    bureauAssigned\n    domicileCity\n    lastHefatReceived\n  }\n  employees {\n    firstName\n    lastName\n    id\n    email\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '1b62b34e2a4eaf02cad09ef26310c53e';
export default node;
