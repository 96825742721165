import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  Divider,
} from '@material-ui/core';
import { ContentWrapper } from 'components/ContentWrapper';
import { theme } from 'theme';

const useStyles = makeStyles({
  card: {
    width: 475,
    minHeight: 292,
  },
  fixedBox: {
    backgroundColor: theme.palette.common.white,
  },
});

export const Home = () => {
  const classes = useStyles();
  return (
    <>
      <Box p={1.5} pb={3.5}>
        <Typography variant="h1" component="h2">
          Home
        </Typography>
      </Box>

      <ContentWrapper>
        <Grid container>
          <Box p={1.5}>
            <Card className={classes.card}>
              <CardContent>
                <Box height={232}>
                  <Box pb={1.5}>
                    <Typography variant="h2" component="h2">
                      Create New Activity Plan
                    </Typography>
                  </Box>
                  <Typography variant="body1" component="p" color="textSecondary">
                    Any registered user can create a new travel plan on behalf of
                    themselves or someone else.
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardActions>
                <Button variant="text" color="primary">
                  Create New
                </Button>
              </CardActions>
            </Card>
          </Box>
          <Box p={1.5}>
            <Card className={classes.card}>
              <CardContent>
                <Box height={232}>
                  <Box pb={1.5}>
                    <Typography variant="h2" component="h2">
                      Import Existing Activity Plan
                    </Typography>
                  </Box>
                  <Typography variant="body1" component="p" color="textSecondary">
                    Any registered user can create a new travel plan on behalf of
                    themselves or someone else.
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardActions>
                <Button variant="text" color="primary">
                  Import Existing
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
        <Grid container>
          <Box p={1.5}>
            <Card className={classes.card}>
              <CardContent>
                <Box height={232}>
                  <Box pb={1.5}>
                    <Typography variant="h2" component="h2">
                      View Active Plans
                    </Typography>
                  </Box>
                  <Typography variant="body1" component="p" color="textSecondary">
                    Any registered user can create a new travel plan on behalf of
                    themselves or someone else.
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardActions>
                <Button variant="text" color="primary">
                  View Active
                </Button>
              </CardActions>
            </Card>
          </Box>
          <Box p={1.5}>
            <Card className={classes.card}>
              <CardContent>
                <Box height={232}>
                  <Box pb={1.5}>
                    <Typography variant="h2" component="h2">
                      Learn About Latest Advisories
                    </Typography>
                  </Box>
                  <Typography variant="body1" component="p" color="textSecondary">
                    As a first step in planning any trip abroad, check the Travel
                    Advisories for your intended destination. You can see the
                    world at a glance on our color-coded map.
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardActions>
                <Button variant="text" color="primary">
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
      </ContentWrapper>
    </>
  );
};
