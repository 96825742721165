import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  //console.log('THEME', theme);
  return {
    root: {
      background: theme.palette.common.white,
    },
  };
});
