import { graphql } from 'react-relay';

export const GetPlanArchiveQuery = graphql`
  query GetPlanArchiveQuery($status: String, $name: String) {
    activityPlans(status: $status, name: $name) {
      id
      name
      durationFrom
      durationTo
      participants {
        id
        firstName
        lastName
      }
    }
  }
`;
