import React from 'react';
import { customTheme } from 'theme';
import {
  Typography,
  Paper,
  Box,
  makeStyles,
} from '@material-ui/core';

interface IComponentProps {
  name: string;
  date: string;
  selected: boolean;
  handleClick(): void;
}

const useStyles = makeStyles((theme) => ({
  planTile: {
    cursor: 'pointer',
  },
  selectedItem: {
    backgroundColor: customTheme.colors.brandGrey2,
  },
}));

export const PlanTile = (props: IComponentProps) => {
  const { name, date, selected, handleClick } = props;
  const classes = useStyles();

  return (
    <Box mb={1.5} width={'100%'} className={classes.planTile} onClick={handleClick}>
      <Paper className={selected ? classes.selectedItem : ''}>
        <Box p={1.5} pb={0}>
          <Typography variant="h4">
            {name}
          </Typography>
        </Box>
        <Box p={1.5}>
          <Typography>
            {date}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};
