import { graphql } from 'react-relay';

export const GetPlanActivitiesQuery = graphql`
  query GetPlanActivitiesQuery($id: ID!) {
    activityPlan(id: $id) {
      riskDetermination {
        submittedStatus
      }
      submittedStatusActivityLog
      activityLogs {
        dateTimeEST
        localizedTime
        gmtTime
        remarks
        author
        id
      }
    }
  }
`;
