import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { commitMutationForm } from 'helper/commitMutationForm';
import CloseIcon from '@material-ui/icons/Close';
import { DeletePlanParticipantMutation } from 'graphql/DeletePlanParticipantMutation';

interface IComponentProps {
  participantId: string;
  planId: string;
}

export const RemoveParticipantBtn = ({
  participantId,
  planId,
}: IComponentProps) => {
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const deleteParticipant = useCallback(() => {
    setIsDisabled(true);

    commitMutationForm({
      values: {
        id: `/api/participants/${participantId}`,
      },
      mutation: DeletePlanParticipantMutation,
      optimisticResponse: {
        deleteParticipant: {
          participant: {
            id: `/api/participants/${participantId}`,
          },
        },
      },
      configs: [
        {
          type: 'NODE_DELETE',
          deletedIDFieldName: `/api/participants/${participantId}`,
        },
      ],
      onUpdate: (store) => {
        const participants = store.getRootField('deleteParticipant');
        const participant = participants.getLinkedRecord('participant');
        store.delete(participant.getDataID());
      },

      onCompleteCallback: (response) => {
        mounted.current && setIsDisabled(false);
      },
      onErrorCallback: () => {
        mounted.current && setIsDisabled(false);
      },
    });
    history.replace(`/plan/${planId}/participant-info`);
  }, [planId, participantId, history]);

  return (
    <Button
      variant="text"
      color="primary"
      startIcon={<CloseIcon />}
      onClick={deleteParticipant}
      disabled={isDisabled}
    >
      Remove participant
    </Button>
  );
};
