import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { PlanName } from 'components/Plan/PlanName';
import { SimpleTile } from 'components/SimpleTile';
import { SectionStatus } from 'components/SectionStatus';
import { PlanContentWrapper } from 'components/Plan/PlanContentWrapper';
import { SubmitSectionBtn } from 'components/Buttons/SubmitSectionBtn';
import { commitMutation, QueryRenderer } from 'react-relay';
import { UpdateRiskMitigationStatusMutation } from 'graphql/UpdateRiskMitigationStatusMutation';
import { modernEnvironment } from 'config/environment';
import { GetPlanRiskMitigationQuery } from 'graphql/GetPlanRiskMitigationQuery';
import { ValidationError } from 'components/ValidationError';
import { Loader } from 'components/Loader';
import { CustomWarning } from 'components/Plan/CustomWarning';

interface IComponentProps {
  planId: string;
}

export const RiskMitigation = ({ planId }: IComponentProps) => {
  const basePlanRoute = `/plan/${planId}/risk-mitigation`;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const changeSectionStatus = (newStatus: boolean, mitigationId: string) => {
    setIsSubmitLoading(true);
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          id: mitigationId,
        },
      },
      mutation: UpdateRiskMitigationStatusMutation,
      updater: (store) => {
        const mutation = store.getRootField('submitRiskMitigation');
        const section = mutation.getLinkedRecord('riskMitigation');
        section.setValue(newStatus, 'submittedStatus');
      },
      onCompleted: (response) => {
        mounted.current && setIsSubmitted(newStatus);
        mounted.current && setIsSubmitLoading(false);
      },
      onError: (response) => {
        mounted.current && setIsSubmitLoading(false);
      },
    });
  };

  const showCustomWarning = () => (
    <CustomWarning>
      Risk determination needs to be submitted, in order to view/edit this
      section.
    </CustomWarning>
  );

  return (
    <PlanContentWrapper>
      <Grid container direction="column">
        <PlanName planId={planId}></PlanName>
        <Box p={1.5} pb={0.5}>
          <Typography variant="h1" component="h2">
            Risk Mitigation
          </Typography>
        </Box>
        <Box p={1.5} pb={3.5}>
          <Typography variant="body1">
            Summary of risks, their application to travelers and the intended
            activity and how they will be thematically mitigated.
          </Typography>
        </Box>
        <SectionStatus
          completedBy="Global Security"
          requireLegalReview={true}
        ></SectionStatus>

        <QueryRenderer
          environment={modernEnvironment}
          query={GetPlanRiskMitigationQuery}
          variables={{
            id: `/api/activity_plans/${planId}`,
          }}
          render={({ error, props }) => {
            if (error) {
              return <ValidationError>{error.message}</ValidationError>;
            } else if (props) {
              const { activityPlan } = props;
              if (
                activityPlan.riskMitigation === null ||
                activityPlan.riskDetermination === null
              ) {
                return showCustomWarning();
              }

              const {
                riskMitigation: { submittedStatus, id: mitigationId },
                riskDetermination: {
                  submittedStatus: riskDeterminationSubmittedStatus,
                },
              } = activityPlan;

              if (!riskDeterminationSubmittedStatus) {
                return showCustomWarning();
              }

              setIsSubmitted(submittedStatus);

              return (
                <>
                  <Box p={1.5}>
                    <Grid container>
                      <SimpleTile to={`${basePlanRoute}/key-behaviours`}>
                        <Box display="flex" flex={1}>
                          <Box flex={1}>
                            <Typography variant="body1">
                              Key Behaviours
                            </Typography>
                          </Box>
                        </Box>
                      </SimpleTile>
                    </Grid>
                  </Box>
                  <Box p={1.5}>
                    <SubmitSectionBtn
                      divider={false}
                      isSubmitted={isSubmitted}
                      description={!isSubmitted ? '' : 'Section submitted.'}
                      isLoading={isSubmitLoading}
                      onClickSubmitHandler={() =>
                        changeSectionStatus(true, mitigationId)
                      }
                    />
                  </Box>
                </>
              );
            }
            return <Loader />;
          }}
        />
        <Box pb={3}></Box>
      </Grid>
    </PlanContentWrapper>
  );
};
