/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GetPlanItineraryLegQueryVariables = {
    legId: string;
    planId: string;
};
export type GetPlanItineraryLegQueryResponse = {
    readonly itineraryLeg: {
        readonly id: string;
        readonly meansOfTravel: string | null;
        readonly fromLocation: unknown;
        readonly toLocation: unknown;
        readonly details: string | null;
        readonly travelDepartureTime: string | null;
        readonly travelArrivalTime: string | null;
        readonly travelDepartureTimezone: string | null;
        readonly travelArrivalTimezone: string | null;
        readonly notes: string | null;
        readonly visaStatus: string | null;
        readonly accreditationStatus: string | null;
        readonly participants: ReadonlyArray<{
            readonly id: string;
            readonly firstName: string | null;
            readonly lastName: string | null;
        } | null> | null;
    } | null;
    readonly activityPlan: {
        readonly id: string;
        readonly name: string | null;
        readonly statusItineraryLegs: string;
        readonly participants: ReadonlyArray<{
            readonly id: string;
            readonly firstName: string | null;
            readonly lastName: string | null;
        } | null> | null;
        readonly durationFrom: string;
        readonly durationTo: string;
    } | null;
};
export type GetPlanItineraryLegQuery = {
    readonly response: GetPlanItineraryLegQueryResponse;
    readonly variables: GetPlanItineraryLegQueryVariables;
};



/*
query GetPlanItineraryLegQuery(
  $legId: ID!
  $planId: ID!
) {
  itineraryLeg(id: $legId) {
    id
    meansOfTravel
    fromLocation
    toLocation
    details
    travelDepartureTime
    travelArrivalTime
    travelDepartureTimezone
    travelArrivalTimezone
    notes
    visaStatus
    accreditationStatus
    participants {
      id
      firstName
      lastName
    }
  }
  activityPlan(id: $planId) {
    id
    name
    statusItineraryLegs
    participants {
      id
      firstName
      lastName
    }
    durationFrom
    durationTo
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "legId",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "planId",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "participants",
        "storageKey": null,
        "args": null,
        "concreteType": "Participant",
        "plural": true,
        "selections": [
            (v1 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v3 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "itineraryLeg",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "legId"
                }
            ],
            "concreteType": "ItineraryLeg",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "meansOfTravel",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fromLocation",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "toLocation",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "details",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "travelDepartureTime",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "travelArrivalTime",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "travelDepartureTimezone",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "travelArrivalTimezone",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "notes",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "visaStatus",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "accreditationStatus",
                    "args": null,
                    "storageKey": null
                },
                (v2 /*: any*/)
            ]
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "activityPlan",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "planId"
                }
            ],
            "concreteType": "ActivityPlan",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "statusItineraryLegs",
                    "args": null,
                    "storageKey": null
                },
                (v2 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "durationFrom",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "durationTo",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "GetPlanItineraryLegQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v3 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "GetPlanItineraryLegQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v3 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "GetPlanItineraryLegQuery",
            "id": null,
            "text": "query GetPlanItineraryLegQuery(\n  $legId: ID!\n  $planId: ID!\n) {\n  itineraryLeg(id: $legId) {\n    id\n    meansOfTravel\n    fromLocation\n    toLocation\n    details\n    travelDepartureTime\n    travelArrivalTime\n    travelDepartureTimezone\n    travelArrivalTimezone\n    notes\n    visaStatus\n    accreditationStatus\n    participants {\n      id\n      firstName\n      lastName\n    }\n  }\n  activityPlan(id: $planId) {\n    id\n    name\n    statusItineraryLegs\n    participants {\n      id\n      firstName\n      lastName\n    }\n    durationFrom\n    durationTo\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '17a9255b8dcb9b08e47e4f08dee5901c';
export default node;
