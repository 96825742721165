/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type updateParticipantInput = {
    readonly id: string;
    readonly status?: string | null;
    readonly acknowledgeStatus?: boolean | null;
    readonly participantEmergencyContacts?: ReadonlyArray<string | null> | null;
    readonly itineraryLegs?: ReadonlyArray<string | null> | null;
    readonly mediaObjects?: ReadonlyArray<string | null> | null;
    readonly firstName?: string | null;
    readonly middleName?: string | null;
    readonly lastName?: string | null;
    readonly mobilePhoneNumbers?: unknown | null;
    readonly satellitePhoneNumbers?: unknown | null;
    readonly vismoProfile?: boolean | null;
    readonly birthDetails?: string | null;
    readonly nationality?: unknown | null;
    readonly employer?: string | null;
    readonly employmentStatus?: string | null;
    readonly insuranceProvider?: string | null;
    readonly policyNumber?: string | null;
    readonly department?: string | null;
    readonly position?: string | null;
    readonly roleInTrip?: string | null;
    readonly bureauAssigned?: unknown | null;
    readonly domicileCity?: unknown | null;
    readonly lastHefatReceived?: string | null;
    readonly biologicalSex?: string | null;
    readonly bloodType?: string | null;
    readonly height?: number | null;
    readonly eyeColor?: string | null;
    readonly correctedVision?: boolean | null;
    readonly hairColorLength?: string | null;
    readonly identifyingMarksDescription?: string | null;
    readonly primaryPhysicianName?: string | null;
    readonly physicianContactNumber?: unknown | null;
    readonly disabilitiesOrOtherMedicalConditions?: string | null;
    readonly medicationsAndDosage?: string | null;
    readonly drugOrOtherAllergies?: string | null;
    readonly pollQuestionsAnswers?: unknown | null;
    readonly level1Approver?: string | null;
    readonly level2Approver?: string | null;
    readonly level3Approver?: string | null;
    readonly clientMutationId?: string | null;
};
export type UpdateParticipantMutationVariables = {
    input: updateParticipantInput;
};
export type UpdateParticipantMutationResponse = {
    readonly updateParticipant: {
        readonly participant: {
            readonly id: string;
        } | null;
    } | null;
};
export type UpdateParticipantMutation = {
    readonly response: UpdateParticipantMutationResponse;
    readonly variables: UpdateParticipantMutationVariables;
};



/*
mutation UpdateParticipantMutation(
  $input: updateParticipantInput!
) {
  updateParticipant(input: $input) {
    participant {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "updateParticipantInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateParticipant",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "updateParticipantPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdateParticipantMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdateParticipantMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdateParticipantMutation",
            "id": null,
            "text": "mutation UpdateParticipantMutation(\n  $input: updateParticipantInput!\n) {\n  updateParticipant(input: $input) {\n    participant {\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '6b3ffa396d32dce449514327a2097662';
export default node;
