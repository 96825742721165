import { graphql } from 'react-relay';

export const UpdatePlanItineraryContactMutation = graphql`
  mutation UpdatePlanItineraryContactMutation(
    $input: updateItineraryContactInput!
  ) {
    updateItineraryContact(input: $input) {
      itineraryContact {
        id
        firstName
        middleName
        lastName
        mobilePhoneNumbers
        gender
        nationality
        email
        details
        activitiesLocationAssoc
      }
    }
  }
`;
