import React, { useState, useRef } from 'react';
import { Box, Typography, Divider, Grid } from '@material-ui/core';
import { PlanContentWrapper } from 'components/Plan/PlanContentWrapper';
import { PlanName } from 'components/Plan/PlanName';
import { FormBuilder } from 'components';
import { modernEnvironment } from 'config/environment';
import { UpdatePlanClosingNotes } from 'graphql/UpdatePlanClosingNotesMutation';
import { UpdateClosingNotesSubmittedStatusMutation } from 'graphql/UpdateClosingNotesSubmittedStatusMutation';
import { QueryRenderer, commitMutation } from 'react-relay';
import { Loader } from 'components/Loader';
import { ValidationError } from 'components/ValidationError';
import { SubmitSectionBtn } from 'components/Buttons/SubmitSectionBtn';
import { GetActivityPlanClosingNotesQuery } from 'graphql/GetActivityPlanClosingNotesQuery';
import { CustomWarning } from 'components/Plan/CustomWarning';

import CloseForm from 'data/plan/close-plan.json';
import CloseFormDisabled from 'data/plan/disabled/close-plan.json';

interface IComponentProps {
  planId: string;
}

export const Close = ({ planId }: IComponentProps) => {
  const mounted = useRef(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const changeSectionStatus = (newStatus, activityPlanClosingNotes) => {
    commitMutation(modernEnvironment, {
      variables: {
        input: {
          activityPlan: `/api/activity_plans/${planId}`,
          id: `${activityPlanClosingNotes.id}`,
          submittedStatus: newStatus,
        },
      },
      mutation: UpdateClosingNotesSubmittedStatusMutation,
      onCompleted: (response) => {
        mounted.current && setIsSubmitted(1);
        mounted.current && setIsSubmitLoading(false);
      },
      onError: (response) => {
        mounted.current && setIsSubmitLoading(false);
      },
    });
  };

  const showCustomWarning = () => (
    <CustomWarning>
      Risk determination and Risk Assessment need to be submitted, in order to
      view/edit this section.
    </CustomWarning>
  );

  return (
    <PlanContentWrapper>
      <Grid container direction="column">
        <PlanName planId={planId}></PlanName>
        <Box p={1.5} pb={0.5}>
          <Typography variant="h1" component="h2">
            Close
          </Typography>
        </Box>
        <Box p={1.5} pb={3.5}>
          <Typography variant="body1">
            Mitigation Summary, Key Risk Reduction Mehtods, Extant/Untreated
            Risks
          </Typography>
        </Box>
        <Box p={1.5} width={'50%'}>
          <Typography variant="body2" color="textSecondary">
            To be completed by
          </Typography>
          <Typography variant="h4">Global Security</Typography>
        </Box>
        <Box px={1.15} py={1.5}>
          <Divider />
        </Box>
        <Box>
          <QueryRenderer
            environment={modernEnvironment}
            query={GetActivityPlanClosingNotesQuery}
            variables={{
              id: `/api/activity_plans/${planId}`,
            }}
            render={({ error, props }) => {
              if (error) {
                return <ValidationError>{error[0].message}</ValidationError>;
              } else if (props) {
                const { activityPlan } = props;
                if (
                  activityPlan.riskDetermination === null ||
                  activityPlan.riskAssessment === null
                ) {
                  return showCustomWarning();
                }

                const {
                  activityPlanClosingNotes,
                  riskDetermination: {
                    submittedStatus: riskDeterminationSubmittedStatus,
                  },
                  riskAssessment: {
                    submittedStatus: riskAssessmentSubmittedStatus,
                  },
                } = activityPlan;

                if (
                  !riskDeterminationSubmittedStatus ||
                  !riskAssessmentSubmittedStatus
                ) {
                  return showCustomWarning();
                }

                setIsSubmitted(
                  activityPlanClosingNotes !== null &&
                    activityPlanClosingNotes.submittedStatus === true
                    ? true
                    : false
                );
                return (
                  <>
                    <FormBuilder
                      formObject={!isSubmitted ? CloseForm : CloseFormDisabled}
                      initialValues={activityPlanClosingNotes}
                      onSubmit={(values) => {
                        UpdatePlanClosingNotes(modernEnvironment, {
                          activityPlan: `/api/activity_plans/${planId}`,
                          id: `${activityPlanClosingNotes.id}`,
                          ...values,
                        });
                      }}
                    />
                    <SubmitSectionBtn
                      isSubmitted={isSubmitted}
                      description={isSubmitted ? 'Section submitted.' : ''}
                      isLoading={isSubmitLoading}
                      onClickSubmitHandler={() =>
                        changeSectionStatus(true, activityPlanClosingNotes)
                      }
                    />
                  </>
                );
              }
              return <Loader />;
            }}
          />
        </Box>
      </Grid>
    </PlanContentWrapper>
  );
};
