import { graphql } from 'react-relay';

export const GetParticipantPersonalDetailsQuery = graphql`
  query GetParticipantPersonalDetailsQuery($id: ID!) {
    participant(id: $id) {
      id
      status
      role
      email
      firstName
      middleName
      lastName
      mobilePhoneNumbers
      satellitePhoneNumbers
      vismoProfile
      birthDetails
      nationality
      employer
      employmentStatus
      insuranceProvider
      policyNumber
      department
      position
      roleInTrip
      bureauAssigned
      domicileCity
      lastHefatReceived
      level1Approver {
        id
        email
      }
      level2Approver {
        id
        email
      }
      level3Approver {
        id
        email
      }
      biologicalSex
      bloodType
      height
      eyeColor
      correctedVision
      hairColorLength
      identifyingMarksDescription
      primaryPhysicianName
      physicianContactNumber
      disabilitiesOrOtherMedicalConditions
      medicationsAndDosage
      drugOrOtherAllergies
      pollQuestionsAnswers
    }
    employees {
      firstName
      lastName
      id
      email
    }
  }
`;
