import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { capitalizeFirstLetter } from 'helper/capitaliseFirstLetter';
import { customTheme } from 'theme';

export const useStyles = makeStyles((theme) => ({
  box: {
    borderBottom: `1px solid ${customTheme.colors.brandGrey2}`,
  },
}));

interface IComponentProps {
  region: string;
  location: string;
  securityRisk: string;
  legalRisk: string;
  cyberRisk: string;
}

export const LocationRisk = ({
  region,
  location,
  securityRisk,
  legalRisk,
  cyberRisk,
}: IComponentProps) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="flex-start" px={1.5}>
      <Box mr={2} mb={1.5} borderBottom={1} className={classes.box}>
        <Typography variant="body2" color="textSecondary">
          REGION
        </Typography>
        <Typography variant="h5" color="textPrimary">
          {region ? capitalizeFirstLetter(region) : 'NA'}
        </Typography>
      </Box>
      <Box mr={2} mb={1.5} flex={1} borderBottom={1} className={classes.box}>
        <Typography variant="body2" color="textSecondary">
          LOCATION
        </Typography>
        <Typography variant="h5" color="textPrimary">
          {location ? capitalizeFirstLetter(location) : 'NA'}
        </Typography>
      </Box>
      <Box mr={2} mb={1.5} borderBottom={1} className={classes.box}>
        <Typography variant="body2" color="textSecondary">
          PHYSICAL RISK LEVEL
        </Typography>
        <Typography variant="h5" color="textPrimary">
          {securityRisk ? capitalizeFirstLetter(securityRisk) : 'NA'}
        </Typography>
      </Box>
      <Box mr={2} mb={1.5} borderBottom={1} className={classes.box}>
        <Typography variant="body2" color="textSecondary">
          LEGAL RISK LEVEL
        </Typography>
        <Typography variant="h5" color="textPrimary">
          {legalRisk ? capitalizeFirstLetter(legalRisk) : 'NA'}
        </Typography>
      </Box>
      <Box mb={1} borderBottom={1.5} className={classes.box}>
        <Typography variant="body2" color="textSecondary">
          CYBER RISK LEVEL
        </Typography>
        <Typography variant="h5" color="textPrimary">
          {cyberRisk ? capitalizeFirstLetter(cyberRisk) : 'NA'}
        </Typography>
      </Box>
    </Box>
  );
};
