import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface IComponentProps {
  isVisible: boolean;
}

export const FullscreenLoader = ({ isLoading }: IComponentProps) => {
  const classes = useStyles();
  return (
    <Backdrop open={isLoading} className={classes.backdrop}>
      <CircularProgress size={56} color="inherit" />
    </Backdrop>
  );
};
