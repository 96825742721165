import { useState, useCallback, useRef, useEffect } from 'react';
import diff from 'object-diff';
import isEqual from 'react-fast-compare';

export const useAutoSave = (delay) => {
  const [data, setData] = useState(null);
  const [result, setResult] = useState(null);
  const timeout = useRef(null);

  const diffCallback = useCallback(
    (values) => {
      if (data === null) {
        setData(values);
        return;
      }
      if (!isEqual(data, values)) {
        setResult(diff.custom({ isEqual }, data, values));
      }

      setData(values);
    },
    [data]
  );

  const handleChange = useCallback(
    ({ values, hasValidationErrors, errors }) => {
      if (hasValidationErrors) {
        clearTimeout(timeout.current);
        timeout.current = null;
        return;
      }

      if (timeout.current === null) {
        timeout.current = setTimeout(() => {
          diffCallback(values);
          timeout.current = null;
        }, delay);
      }
      if (timeout.current !== null) {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          diffCallback(values);
          timeout.current = null;
        }, delay);
      }
    },
    [timeout, delay, diffCallback]
  );

  useEffect(
    () => () => {
      if (timeout.current != null) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    },
    [timeout]
  );

  return [result, handleChange];
};
