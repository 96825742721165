import { commitMutation, graphql, Environment } from 'react-relay';

const mutation = graphql`
  mutation ImportActivityPlanMutation($input: importActivityPlanInput!) {
    importActivityPlan(input: $input) {
      activityPlan {
        id
      }
    }
  }
`;

export const ImportActivityPlan = (environment: Environment, values: {}) => {
  const variables = {
    input: values,
  };

  commitMutation(environment, {
    mutation,
    variables,
  });
};
