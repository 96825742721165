/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type updateItineraryContactInput = {
    readonly id: string;
    readonly firstName?: string | null;
    readonly middleName?: string | null;
    readonly lastName?: string | null;
    readonly gender?: string | null;
    readonly nationality?: unknown | null;
    readonly mobilePhoneNumbers?: unknown | null;
    readonly email?: string | null;
    readonly details?: string | null;
    readonly activitiesLocationAssoc?: string | null;
    readonly itineraryLegs?: ReadonlyArray<string | null> | null;
    readonly clientMutationId?: string | null;
};
export type UpdatePlanItineraryContactMutationVariables = {
    input: updateItineraryContactInput;
};
export type UpdatePlanItineraryContactMutationResponse = {
    readonly updateItineraryContact: {
        readonly itineraryContact: {
            readonly id: string;
            readonly firstName: string | null;
            readonly middleName: string | null;
            readonly lastName: string | null;
            readonly mobilePhoneNumbers: unknown | null;
            readonly gender: string | null;
            readonly nationality: unknown | null;
            readonly email: string | null;
            readonly details: string | null;
            readonly activitiesLocationAssoc: string | null;
        } | null;
    } | null;
};
export type UpdatePlanItineraryContactMutation = {
    readonly response: UpdatePlanItineraryContactMutationResponse;
    readonly variables: UpdatePlanItineraryContactMutationVariables;
};



/*
mutation UpdatePlanItineraryContactMutation(
  $input: updateItineraryContactInput!
) {
  updateItineraryContact(input: $input) {
    itineraryContact {
      id
      firstName
      middleName
      lastName
      mobilePhoneNumbers
      gender
      nationality
      email
      details
      activitiesLocationAssoc
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "input",
            "type": "updateItineraryContactInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateItineraryContact",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "updateItineraryContactPayload",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "itineraryContact",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ItineraryContact",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "middleName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "mobilePhoneNumbers",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "gender",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "nationality",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "details",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "activitiesLocationAssoc",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "UpdatePlanItineraryContactMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "UpdatePlanItineraryContactMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "UpdatePlanItineraryContactMutation",
            "id": null,
            "text": "mutation UpdatePlanItineraryContactMutation(\n  $input: updateItineraryContactInput!\n) {\n  updateItineraryContact(input: $input) {\n    itineraryContact {\n      id\n      firstName\n      middleName\n      lastName\n      mobilePhoneNumbers\n      gender\n      nationality\n      email\n      details\n      activitiesLocationAssoc\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'ba9ee09a54d7518ca50121262b6a881a';
export default node;
